import React from "react";
import PropTypes from "prop-types";
import "./CheckBoxTag.scss";

export default class CheckBoxTag extends React.Component {
  static propTypes = {
    id: PropTypes.string,
    value: PropTypes.string,
    name: PropTypes.string,
    label: PropTypes.string,
    onChange: PropTypes.func,
    checked: PropTypes.bool,
    type: PropTypes.oneOf(["checkbox", "radio"]),
  };

  static defaultProps = {
    type: "checkbox",
    onChange: () => null,
    onClick: () => null,
    checked: false,
  };

  radio = null;

  render() {
    return (
      <div className={"checkBoxTag"}>
        <input
          type={this.props.type}
          id={this.props.id}
          name={this.props.name}
          value={this.props.value || this.props.id}
          checked={this.props.checked}
          ref={ref => (this.radio = ref)}
        />
        <label
          htmlFor={this.props.id}
          onClick={() => this.props.onClick(this.radio)}
        >
          {this.props.label || this.props.value}
        </label>
      </div>
    );
  }
}
