import React from 'react';
import "./ListThematicLegend.scss";
import Thematics from "../../../Utils/Thematics";
import {THEMATIC_TYPE_ALL, THEMATIC_TYPE_COMMON, THEMATIC_TYPE_DOCUMENTATION} from "../../../Services/ApiThematics";


export default class ListThematicLegend extends React.Component {
    render() {
        return (
            <ul className={'ListThematicLegend'}>
                {Thematics.getLocalThematics(THEMATIC_TYPE_ALL, true).map((thematic, i) => {
                    if (thematic.type === THEMATIC_TYPE_COMMON || thematic.type === THEMATIC_TYPE_DOCUMENTATION) {
                        return <li key={i}>
                            <i style={{backgroundColor: thematic.color}}/>
                            <span>   {thematic.name}</span>
                        </li>
                    } else {
                        return null;
                    }
                })}
            </ul>
        )
    }
}