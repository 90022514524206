import axios from "axios";
import Api, {PATH} from "../Utils/Api";

export const THEMATIC_TYPE_COMMON        = 'COMMON';
export const THEMATIC_TYPE_DOCUMENTATION = 'DOCUMENTATION';
export const THEMATIC_TYPE_WORKGROUP     = 'WORKGROUP';
export const THEMATIC_TYPE_AGENDA        = 'AGENDA';
export const THEMATIC_TYPE_ALL           = 'ALL'; // Not a real type, but used to handle all
export const THEMATIC_TYPES              = [THEMATIC_TYPE_COMMON, THEMATIC_TYPE_DOCUMENTATION, THEMATIC_TYPE_WORKGROUP, THEMATIC_TYPE_ALL];

export default {

    /**
     * Get thematic by id
     * @param id
     * @param nestedEntities
     * @returns {AxiosPromise<any>}
     */
    get: (id, nestedEntities = []) => {
        return axios.get(`${PATH}/thematic/thematic/${id}?api[nested_entities]=${Api.buildNestedEntity(nestedEntities)}`);
    },

    /**
     Get all thematics for agenda
     * @param nestedEntities
     * @returns {AxiosPromise<any>}
     */
    getForAgenda: (nestedEntities = []) => {
        const params = {
            "api[nested_entities]": Api.buildNestedEntity(nestedEntities),
            "api[where]": `where (\`type\`=\`${THEMATIC_TYPE_COMMON}\` OR \`type\`=\`${THEMATIC_TYPE_WORKGROUP}\` OR \`type\`=\`${THEMATIC_TYPE_AGENDA}\`)`,
        };

        return axios.get(`${PATH}/thematic/thematic`, {params: params});
    },

    /**
     * Get all thematics (common + workgroup + documentation)
     * @returns {AxiosPromise<any>}
     */
    getAll: (nestedEntities = []) => {
        const params = {
            "api[nested_entities]": Api.buildNestedEntity(nestedEntities),
        };

        return axios.get(`${PATH}/thematic/thematic`, {params: params});
    }
}