import React from "react";
import PropTypes from "prop-types";
import SVGFile from "../SVG/SVGFile";
import { DateHandler } from "../../Utils/DateHandler";
import { Link } from "react-router-dom";
import "./File.scss";

export default class File extends React.Component {
    static propTypes = {
        file: PropTypes.object.isRequired,
        description: PropTypes.bool,
        edit: PropTypes.bool,
        showButton: PropTypes.bool,
        editMode: PropTypes.bool,
        deleteMode: PropTypes.bool,
        archiveMode: PropTypes.bool,
        color: PropTypes.string,
        className: PropTypes.oneOf(["grid", "list", "carousel"]),
    };

    static defaultProps = {
        className: "grid",
        description: true,
        edit: false,
        showButton: true,
        color: "#000000",
    };

    handleEvent(file) {
        if (this.props.editMode) return this.props.onEdit(file);
        else if (this.props.deleteMode) return this.props.onDelete(file);
        else if (this.props.archiveMode) return this.props.onArchive(file);
    }

    render() {
        const IS_MANAGE_MODE =
            this.props.editMode ||
            this.props.deleteMode ||
            this.props.archiveMode;
        const IS_ARCHIVED = this.props.file["archivedat"] || this.props.file.archived;

        return (
            <div
                className={`file ${this.props.className} ${
                    IS_ARCHIVED ? "archived" : ""
                } ${IS_MANAGE_MODE ? "manage" : ""}`.trim()}
                onClick={() => this.handleEvent(this.props.file)}
            >
                <div className="infoAndCta">
                    <div className="info">
                        <div>
                            {this.props.file.files[0] && (
                                <SVGFile
                                    color={this.props.color}
                                    className="icon"
                                >
                                    {this.props.file.files[0].type}
                                </SVGFile>
                            )}
                            <div>
                                <p className="date">
                                    {DateHandler.getPrettyDate(
                                        this.props.file.date ||
                                            this.props.file.publishedat.date
                                    )}
                                </p>
                                <p className="title">
                                    {this.props.file.title}{" "}
                                    {IS_ARCHIVED && `(Archivé)`}
                                </p>
                                {this.props.description && (
                                    <p
                                        className="desc"
                                        dangerouslySetInnerHTML={{
                                            __html: this.props.file.excerpt,
                                        }}
                                    />
                                )}
                            </div>
                        </div>
                    </div>

                    {/* CTA */}
                    {this.props.showButton &&
                        this.props.file.files[0] &&
                        !IS_ARCHIVED && (
                            <div className="cta">
                                <a
                                    href={
                                        this.props.file.files[0].url ||
                                        this.props.file.files[0].slug
                                    }
                                    target={"_blank"}
                                    style={{
                                        borderColor: this.props.color,
                                        color: this.props.color,
                                    }}
                                >
                                    <span tabIndex="-1">
                                        <em>Télécharger</em>
                                    </span>
                                </a>
                                <Link
                                    to={this.props.file.slug || "#"}
                                    style={{
                                        borderColor: this.props.color,
                                        color: this.props.color,
                                    }}
                                >
                                    <span tabIndex="-1">
                                        <em>En savoir plus </em>
                                    </span>
                                </Link>
                            </div>
                        )}
                </div>

                {/* Edit mode */}
                {this.props.editMode && <div className={"mode edit"} />}

                {/* Delete mode */}
                {this.props.deleteMode && <div className={"mode delete"} />}

                {/* Archive mode */}
                {this.props.archiveMode && <div className={"mode archive"} />}
            </div>
        );
    }
}
