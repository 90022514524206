import React from 'react';
import Master from "../../../../Components/Layout/Master";
import Banner from "../../../../Components/Banner/Banner";
import "./AdminManageDocumentation.scss";
import {path} from "../../../../Config/routes";
import {Redirect} from 'react-router-dom'
import {THEMATIC_TYPE_DOCUMENTATION} from "../../../../Services/ApiThematics";
import Thematics from "../../../../Utils/Thematics";
import ButtonThematic from "../../../../Components/Form/Button/ButtonThematic";


export default class AdminManageDocumentation extends React.Component {
    state = {
        thematics: [],
        breadCrumb: [],
        httpError: false
    };

    constructor(props) {
        super(props);
        this._isMounted = false;
    }

    componentDidMount() {
        this._isMounted = true;

        const THEMATICS = Thematics.getLocalThematics(THEMATIC_TYPE_DOCUMENTATION, true);
        this.setState({
            thematics: THEMATICS,
        });
}

    componentWillUnmount() {
        this._isMounted = false;
    }

    handleClickOnCategoryItem(categoryId) {
        this.props.history.push(path('adminManageDocumentationEditTree', {id: categoryId}));
        return <Redirect to={path('adminManageDocumentationEditTree', {id: categoryId})}/>
    }

    render() {
        let content = (
            <ButtonThematic thematics={this.state.thematics}
                            isAdmin={true}
                            onClick={(thematic) => this.handleClickOnCategoryItem(thematic.id)}/>
        );

        return (
            <Master id="adminManageDocumentation" httpError={this.state.httpError} admin={true} breadCrumb={this.state.breadCrumb} {...this.props}>
                {/* Banner */}
                <Banner title={'Gestion de l’arborescence'} size={'small'}/>
                {content}
            </Master>
        )
    }
}