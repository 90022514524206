import {Redirect} from 'react-router-dom';
import React from 'react'
import {path} from "../Config/routes";
import Roles from "./Roles";
import ApiRole from "../Services/ApiRole";

export default class Auth extends React.Component {
    /**
     * The user has a token
     * @return {boolean}
     */
    static isAuthenticated() {
        return !!localStorage.getItem('user');
    }

    /**
     * Add the user token
     * @param {object} user
     */
    static login(user) {
        localStorage.setItem('user', JSON.stringify(user));
    }

    /**
     * Remove the user token
     */
    static logout() {
        localStorage.clear();
        return (
            <Redirect from="/" to={path('login')}/>
        );
    }

    /**
     * Check if the authenticated user an administrator of a given thematic
     *
     * @param {number} thematicId
     * @returns {boolean}
     */
    static isThematicAdministrator(thematicId) {
        if (this.getRole().uid !== Roles.ROLE_ADMINISTRATOR.uid) {
            return false;
        }

        const USER = this.getUser();
        if (!USER.hasOwnProperty('thematic_administrator_of')) {
            return false;
        }

        return USER.thematic_administrator_of.indexOf(thematicId) >= 0;
    }

    static canManage(thematicId) {
        if (this.getRole().rank >= Roles.ROLE_ROOT.rank) {
            return true;
        } else if (this.getRole().rank === Roles.ROLE_ADMINISTRATOR.rank) {
            return this.isThematicAdministrator(thematicId || this._thematicId);
        }

        return false;
    }

    /**
     * Get user
     * @returns {string|any}
     */
    static getUser() {
        if (Auth.isAuthenticated()) {
            return JSON.parse(localStorage.getItem('user'));
        }
        return '';
    }

    /**
     * Get the rank of the authenticated user's role
     * @returns {*}
     */
    static getRoleRank() {
        return this.getRole()['rank'];
    }

    /**
     * Get the UID of the authenticated user's role
     * @returns {*}
     */
    static getRoleUID() {
        return this.getRole()['uid'];
    }

    /**
     * Get the roles hierarchy from the role of the authenticated user
     *
     * @returns {Promise<*>}
     */
    static async getRolesHierarchy() {
        const USER_ROLE_RANK = Auth.getRoleRank();
        const API_ROLES      = await ApiRole.getRoles();

        return API_ROLES.filter(role => {
            return USER_ROLE_RANK >= role.rank;
        });
    }

    /**
     * Get the whole role (rank + UID) of the authenticated user
     * @returns {{uid, rank}|*}
     */
    static getRole() {
        const USER = this.getUser();

        // User is not logged or has an unknown role: he is ANONYMOUS
        if (USER === '' || !Roles[USER.role]) {
            return Roles.ROLE_ANONYMOUS;
        }

        return Roles[USER.role];
    }

    /**
     * Is user at least an user?
     * @see Roles ROLES_XXX constants
     * @returns {boolean}
     */
    static isUserAtLeast() {
        return this._isAtLeastRole(Roles.ROLE_USER.uid)
    }

    /**
     * Is user at least a contributor?
     * @see Roles ROLES_XXX constants
     * @returns {boolean}
     */
    static isContributorAtLeast() {
        return this._isAtLeastRole(Roles.ROLE_CONTRIBUTOR.uid)
    }

    /**
     * Is user at least an admin? So he could be an admin, a root or a developer
     * @see Roles ROLES_XXX constants
     * @returns {boolean}
     */
    static isAdminAtLeast() {
        return this._isAtLeastRole(Roles.ROLE_ADMINISTRATOR.uid)
    }

    /**
     * Is user at least a root?
     * @see Roles ROLES_XXX constants
     * @returns {boolean}
     */
    static isRootAtLeast() {
        return this._isAtLeastRole(Roles.ROLE_ROOT.uid)
    }

    /**
     * Check if the current user has at least the given role
     *
     * @param role
     * @returns {*|boolean}
     * @private
     */
    static _isAtLeastRole(role) {
        return Roles[role] && this.getRole().rank >= Roles[role].rank;
    }

    /**
     * Update user
     * @param user
     */
    static updateUser(user) {
        const OLD_USER = Auth.getUser();
        const NEW_USER =
                  Object.assign(OLD_USER, {
                      email: user.email || OLD_USER.email,
                      first_name: user.first_name || OLD_USER.first_name,
                      last_name: user.last_name || OLD_USER.last_name,
                      name: user.name || OLD_USER.name,
                      role: user.role || OLD_USER.role,
                      token: user.token || OLD_USER.token,
                  });

        console.log(NEW_USER);

        localStorage.setItem('user', JSON.stringify(NEW_USER));
    }

    /**
     * Get the user token
     *
     * @return {string}
     */
    static getToken() {
        if (Auth.isAuthenticated()) {
            return JSON.parse(localStorage.getItem('user')).token;
        }

        return '';
    }

    /**
     * Get the user token
     *
     * @return {string}
     */
    static getRefreshToken() {
        if (Auth.isAuthenticated()) {
            return JSON.parse(localStorage.getItem('user')).refreshToken;
        }

        return '';
    }

    /**
     * Get user name
     * @returns {string|*}
     */
    static getUsername() {
        if (Auth.isAuthenticated()) {
            return JSON.parse(localStorage.getItem('user')).name;
        }

        return '';
    }

    static getFirstname() {
        if (Auth.isAuthenticated()) {
            return JSON.parse(localStorage.getItem('user')).first_name;
        }

        return '';
    }
}
