import Moment from "moment";
import joursFeries from "@socialgouv/jours-feries";
export class DateHandler {
    /**
     * Get from current date
     * @param date
     * @returns {moment.Moment}
     */
    static getMondayFromCurrentDate = (date = undefined) => {
        const now = Moment(date);

        return now.clone().weekday(0);
    };

    /**
     * Get from current date
     * @param date
     * @returns {moment.Moment}
     */
    static getSundayFromCurrentDate = (date = undefined) => {
        const now = Moment(date);

        return now.clone().weekday(6);
    };

    /**
     * @param {number} month
     * @returns {moment.Moment}
     */
    static getFirstDayOfMonth = (month = undefined) => {
        return Moment().month(month).startOf("month");
    };

    /**
     * @param {number}month
     * @returns {moment.Moment}
     */
    static getLastDayOfMonth = (month = undefined) => {
        return Moment().month(month).startOf("month");
    };

    /**
     * Get date format event
     * @param startDate
     * @param endDate
     * @returns {string}
     */
    static getFormatEvent = (startDate, endDate) => {
        return (
            Moment(startDate).format("DD/MM/YYYY [de] HH[h à ]") +
            Moment(endDate).format("HH[h]")
        );
    };

    /**
     * Get pretty date
     * @param date
     * @param format
     * @returns {string}
     */
    static getPrettyDate = (date = undefined, format = "DD/MM/YYYY") => {
        return Moment(date).format(format);
    };

    /**
     * Return date format api
     * @param date
     * @returns {string}
     */
    static formatForApi = (date = undefined, withTime = true) => {
        let format = withTime ? "YYYY-MM-DD HH:mm:ss" : "YYYY-MM-DD 00:00:00";
        return Moment(date).format(format);
    };

    /**
     * Return date format js
     * @param date
     * @returns {Date}
     */
    static getJsDate = (date = undefined) => {
        return Moment(date).toDate();
    };

    static add = (date = undefined, amount = 0, unit = "d") => {
        return Moment(date).add(amount, unit);
    };

    static lastDayOfMonth = (date = undefined) => {
        return Moment(date).endOf("month");
    };

    static getYear = (date) => {
        return Moment(date).year();
    };

    static getMonth = (date) => {
        return Moment(date).month();
    };

    static getQuarter = (date) => {
        return Moment(date).quarter();
    };

    static getMonthName = (month) => {
        return Moment(month, "M").format("MMMM");
    };

    static getDiff = (start, end, unit = "month") => {
        return Math.ceil(Math.abs(Moment(start).diff(end, unit, true)));
    };

    static isWorkingDay = (date) => {
        const format = "YYYY-MM-DD";
        if (!Moment.isMoment(date)) {
            date = Moment(date);
        }

        const isWeekEnd = ["6", "7"].includes(date.format("E"));

        if (isWeekEnd) {
            return false;
        }

        const feries = joursFeries(date.format("YYYY"));
        const formattedFeries = Object.values(feries).map((day) => {
            return Moment(day).format(format);
        });

        return !formattedFeries.includes(date.format(format));
    };
}
