import React from "react";
import ApiDocument, { DOC_RESULT_PAGE } from "../../../Services/ApiDocument";
import Master from "../../../Components/Layout/Master";
import Banner from "../../../Components/Banner/Banner";
import Search from "../../../Components/Search/Search";
import Modal from "../../../Components/Modal/Modal";
import FormAddDoc from "../../../Components/Form/FormAddDoc/FormAddDoc";
import Loader from "../../../Components/Loader/Loader";
import { DateHandler } from "../../../Utils/DateHandler";
import ApiTag from "../../../Services/ApiTag";
import "./AdminDocumentation.scss";
import * as qs from "qs";
import Alert from "../../../Components/Alert/Alert";
import { getColor, lightenDarkenColor } from "../../../Utils/Colors";
import { THEMATIC_TYPE_DOCUMENTATION } from "../../../Services/ApiThematics";
import { Http } from "../../../Utils/Api";
import { path } from "../../../Config/routes";
import Select from "react-select";
import Thematics from "../../../Utils/Thematics";
import ApiDocumentFilter from "../../../Services/ApiDocumentFilter";
import Auth from "../../../Security/Auth";
import ButtonThematic from "../../../Components/Form/Button/ButtonThematic";
import { isMobile } from "../../../Utils/Screen";

export default class AdminDocumentation extends React.Component {
    state = {
        results: null,
        resultsBackup: [],
        nbResults: 0,
        categories: [],
        currentThematic: null,
        optionSelected: [-1],
        selects: [],
        isSearch: false,
        breadCrumb: [
            { url: path("adminDocumentation"), label: "Espace documentation" },
        ],
        editMode: false,
        deleteMode: false,
        archiveMode: false,
        showModalAddDoc: false,
        showModalConfirmDelete: false,
        showModalConfirmToggleArchive: false,
        tempFile: null,
        loadingForm: false,
        loadingDocument: false,
        httpError: false,
        searchLoading: false,
        loadResults: false,
        alert: {
            show: false,
            action: "add",
            value: "",
        },
    };

    constructor(props) {
        super(props);
        this.backgroundColor = [getColor("blue")];
        this.pagination = null;
        this._filter = {};
        this.allThemacis = [];
        this._categoriesIds = [];
        this.admin = false;
        this.searchText = "";
        this.formSelect = false;
        this._docs = null;
        this._isMounted = false;
        this.pathName = "adminDocumentation";
        this._selects = [];
        this._optionSelected = [];
        this.pathParameters = {
            category: "",
            categoryTwo: "",
            categoryThree: "",
            categoryFour: "",
            categoryFive: "",
        };
        this.keyParameters = Object.keys(this.pathParameters);
    }

    componentDidMount() {
        this._isMounted = true;
        this.init();
    }

    componentDidUpdate(prevProps, prevState) {
        // Refresh if param change from menu
        if (!this.formSelect) {
            let paramsPrev = Object.keys(prevProps.match.params);
            let params = Object.keys(this.props.match.params);
            let newUrl = false;

            for (let i = 0; i < paramsPrev.length; i++) {
                if (
                    prevProps.match.params[paramsPrev[i]] !==
                    this.props.match.params[params[i]]
                ) {
                    newUrl = true;
                    break;
                }
            }

            if (newUrl) {
                this.init();
            }
        }
    }

    init() {
        this.setState({
            loadingDocument: true,
        });

        this._selects = [];
        this._optionSelected = [];
        this.formSelect = false;

        const REQUEST = new Promise((resolve) =>
            resolve(
                this._docs
                    ? this._docs
                    : Thematics.getLocalThematics(
                          THEMATIC_TYPE_DOCUMENTATION,
                          true
                      )
            )
        );

        REQUEST.then((doc) => {
            if (!this._isMounted) return;

            this._docs = doc;
            let categories = doc;
            let selects = this.setOptionsSelected(
                Object.keys(this.props.match.params),
                categories
            );
            this.allThemacis = categories;

            this.setState({
                categories: categories,
                optionSelected: selects.optionSelected,
                selects: selects.selects,
                loader: false,
                loadingDocument: false,
            });
            // Update the breadcrumb
            selects = selects.selects;

            for (let i = 0; i < selects.length; i++) {
                let option = selects[i].props.value;
                let key = parseInt(selects[i].key);

                if (option.value !== -1) {
                    this.selectHandler(option, key, false);
                }
            }
        }).catch((err) => {
            if (!this._isMounted) return;

            if (err.status && err.status >= 500) {
                this.setState({
                    httpError: true,
                });
            }
        });
    }

    componentWillUnmount() {
        this._isMounted = false;
        Http.cancel();
    }

    /**
     * Generate all select based of the router parameters
     *
     * @param {array} routerParams - All the router parameters
     * @param {array} categories - All categories for this level
     * @param {number} [index=0] - Index for the router parameters to watch
     *
     * @return {{selects: [number], optionSelected: [Select]}}
     */
    setOptionsSelected(routerParams, categories, index = 0) {
        let params = this.props.match.params;

        // The router parameter is set
        if (params[routerParams[index]]) {
            let indexCategories = -1;
            // Get the index category for the the current router parameter
            for (let i = 0; i < categories.length; i++) {
                if (categories[i].uid === params[routerParams[index]]) {
                    indexCategories = i;
                    break;
                }
            }

            // Router parameter not found
            if (indexCategories === -1) {
                console.error(
                    `${
                        params[routerParams[index]]
                    } do not exist in documentation`
                );
                return;
            }

            // Save the options selected and the select for this router parameter
            this._optionSelected.push(indexCategories);
            this._selects.push(
                this.createSelect(categories, indexCategories, index)
            );

            if (!this.admin) {
                return this.setOptionsSelected(
                    routerParams,
                    categories[indexCategories].categories,
                    index + 1
                );
            } else {
                return this.setOptionsSelected(routerParams, null, -1);
            }
        } else if (categories && categories.length > 0) {
            // Init the next select if it exists
            this._optionSelected.push(-1);
            this._selects.push(this.createSelect(categories, -1, index));

            return {
                optionSelected: this._optionSelected,
                selects: this._selects,
            };
        } else {
            return {
                optionSelected: this._optionSelected,
                selects: this._selects,
            };
        }
    }

    /**
     * @param optionSelected
     * @param selectPosition - Position of select changed
     * @param recreate
     */
    selectHandler(optionSelected, selectPosition = 1, recreate = true) {
        this.formSelect = true;
        let breadCrumb = this.state.breadCrumb;
        // If the select changed is not the last one
        if (selectPosition !== breadCrumb.length) {
            // Delete all index saved after the select changed
            breadCrumb = breadCrumb.slice(
                0,
                selectPosition - breadCrumb.length
            );
        }
        if (optionSelected.value !== -1) {
            for (let i = 0; i < this.keyParameters.length; i++) {
                let value =
                    i === selectPosition - 1
                        ? optionSelected.url
                        : this.pathParameters[this.keyParameters[i]];

                this.pathParameters[this.keyParameters[i]] =
                    i < selectPosition ? value : "";
            }

            breadCrumb[selectPosition] = {
                url: path("adminDocumentation", this.pathParameters),
                label: optionSelected.label,
            };
        }

        // Update the url with the select
        let urlParams = Object.keys(this.props.match.params);
        let param = {};
        for (let i = 0; i < urlParams.length; i++) {
            if (breadCrumb[i + 1]) {
                param[urlParams[i]] = breadCrumb[i + 1].url;
            } else {
                break;
            }
        }

        this.props.history.replace({
            pathname: breadCrumb[breadCrumb.length - 1].url,
            search: this.props.history.location.search,
        });

        this.setState({
            nbResults: 0,
            breadCrumb: breadCrumb,
            loadingDocument: true,
        });

        if (optionSelected.emulated && isMobile()) {
            Banner.selects.current.className = "selects selects-translate-1";
            Banner.reset.current.className = "fas fa-chevron-left";
        }

        this.change(
            optionSelected.value,
            optionSelected.id,
            selectPosition,
            recreate
        );
    }

    /**
     * @param selectValue
     * @param id - Id of the category selected
     * @param selectPosition - Position of select changed
     * @param recreate
     */
    change(selectValue, id, selectPosition = 1, recreate = true) {
        // Get option selected for the select changed
        let optionsSelected = this.state.optionSelected;

        optionsSelected[selectPosition - 1] = selectValue;

        let selects = recreate ? [] : this.state.selects;
        let categories = this.state.categories;
        let currentThematic = null;

        // If the select changed is not the last one
        if (selectPosition !== optionsSelected.length && recreate) {
            // Delete all index saved after the select changed
            optionsSelected = optionsSelected.slice(
                0,
                selectPosition - optionsSelected.length
            );
        }

        // Recreate all previous select
        for (let i = 0; i < optionsSelected.length; i++) {
            if (recreate) {
                selects.push(
                    this.createSelect(
                        categories.categories || categories,
                        this.state.optionSelected[i],
                        i
                    )
                );
            }
            if (this.state.optionSelected[i] !== -1) {
                if (0 == i) {
                    currentThematic = categories[this.state.optionSelected[i]];
                }
                categories =
                    categories[this.state.optionSelected[i]].categories ||
                    categories[this.state.optionSelected[i]];
            }
        }

        this.setState({
            selects: selects,
            currentThematic: currentThematic,
            optionSelected: optionsSelected,
        });

        // It is not the default option for the select changed
        if (selectValue !== -1 || optionsSelected.length >= 1) {
            // Create a new select if the previous as children and it's not admin page
            if (
                selectValue !== -1 &&
                (categories.categories !== undefined ||
                    categories.length > 0) &&
                recreate
            ) {
                optionsSelected.push(-1);
                selects.push(
                    this.createSelect(
                        categories.categories || categories,
                        -1,
                        optionsSelected.length - 1
                    )
                );
            }

            this.getCategoriesSelected(
                optionsSelected,
                selectPosition,
                selectValue,
                id
            );

            const PARAMS = qs.parse(this.props.location.search, {
                ignoreQueryPrefix: true,
            });

            ApiDocument.getDocuments(
                PARAMS.page || 1,
                DOC_RESULT_PAGE,
                this._filter,
                this._categoriesIds
            ).then((search) => {
                if (!this._isMounted) return;

                let data = search.payload;
                this.pagination = search.extra;
                this.setState(
                    {
                        selects: selects,
                        optionSelected: optionsSelected,
                        nbResults: search.extra.resources.total,
                        results: data,
                        loadingDocument: false,
                        editMode: false,
                        archiveMode: false,
                        deleteMode: false,
                    },
                    () => (this.formSelect = false)
                );
            });
        } else {
            this.setState(
                {
                    loadingDocument: false,
                    editMode: false,
                    archiveMode: false,
                    deleteMode: false,
                },
                () => (this.formSelect = false)
            );
        }
    }

    getCategoriesSelected(optionsSelected, selectPosition, selectValue, id) {
        if (optionsSelected[0] !== -1) {
            // Get the category selected
            let categories = this.allThemacis[optionsSelected[0]].categories;
            for (let i = 1; i < optionsSelected.length; i++) {
                if (optionsSelected[i] === -1) {
                    break;
                }

                // This category as children
                if (categories[optionsSelected[i]].categories.length > 0) {
                    categories = categories[optionsSelected[i]].categories;
                } else {
                    categories = categories[optionsSelected[i]];
                }
            }

            this._categoriesIds = [];
            if (Array.isArray(categories) && categories.length > 0) {
                this._categoriesIds = this.getLastChildrenId(categories);
            }

            // If it is not a thematic add this id to the list id
            if (selectPosition !== 1 && selectValue !== -1) {
                this._categoriesIds.push(id);
            } else if (
                selectPosition === 1 &&
                this._categoriesIds.length === 0
            ) {
                this._categoriesIds.push(0);
            }
        }
    }

    /**
     * Get all last id of category
     * @param categories
     * @param ids
     * @return {Array}
     */
    getLastChildrenId(categories, ids = []) {
        categories.forEach((category) => {
            if (category.categories.length > 0) {
                return this.getLastChildrenId(category.categories, ids);
            } else {
                ids.push(category.id);
            }
        });

        return ids;
    }

    /**
     * Create a select
     * @param {array} options - List of select's options
     * @param {number} [value=-1] - The value of options selected
     * @param {number } [key=0] - Key to loop
     * @return {string}
     */
    createSelect(options, value = -1, key = 0) {
        key++;
        const PLACEHOLDER =
            key === 1 ? "Choisissez un thème" : "Choisissez une catégorie";

        let optionSelected = {
            value: -1,
            label: PLACEHOLDER,
        };
        let selectOption = [optionSelected];

        for (let i = 0; i < options.length; i++) {
            let option = {
                value: i,
                label: options[i].name,
                url: options[i].uid,
                id: options[i].id,
                disabled:
                    !Auth.canManage(options[i].thematicid || options[i].id) ||
                    (options[i].categories.length === 0 &&
                        options[i].workgroups),
            };

            // Add only the direct children
            if (
                (key <= 2 &&
                    (options[i].parentid === 0 ||
                        options[i].parentid === undefined)) ||
                key >= 3
            ) {
                selectOption.push(option);

                if (value === i) {
                    optionSelected = option;
                }
            }
        }

        if (options[optionSelected.value] !== undefined) {
            this.backgroundColor[key] = lightenDarkenColor(
                options[optionSelected.value].color || this.backgroundColor[1],
                -(key - 1) * 20
            );
            this.backgroundColor[key + 1] = lightenDarkenColor(
                options[optionSelected.value].color || this.backgroundColor[1],
                -key * 20
            );
        }

        let color =
            value === -1 && key === 1
                ? this.backgroundColor[0]
                : this.backgroundColor[key];

        let customStyles = {
            container: (provided) => ({
                ...provided,
                height: "100%",
                width: "100%",
            }),
            control: (provided) => ({
                ...provided,
                backgroundColor: color,
                boxShadow: "none",
                border: 0,
                borderRadius: 0,
                height: "100%",
                width: "100%",
            }),
            valueContainer: (provided) => ({
                ...provided,
                justifyContent: "center",
            }),
            singleValue: (provided) => ({
                ...provided,
                color: getColor("white"),
            }),
            indicatorSeparator: (provided) => ({
                ...provided,
                display: "none",
            }),
            menu: (provided) => ({
                ...provided,
                backgroundColor: color,
            }),
        };

        return (
            <Select
                value={optionSelected}
                placeholder={PLACEHOLDER}
                key={key}
                name={`selectFilter${key}`}
                id={`selectFilter${key}`}
                styles={customStyles}
                onChange={(e) => this.selectHandler(e, key)}
                classNamePrefix="react-select"
                className="react-select__container"
                isSearchable={false}
                isOptionDisabled={(option) => {
                    return option.disabled;
                }}
                options={selectOption}
            />
        );
    }

    handleSearch(searchText) {
        this.searchText = searchText;

        if (searchText !== "") {
            this.setState({ searchLoading: true });
            this.getDocuments(1);
        }
    }

    onPaginationClick(page) {
        this.getDocuments(page);
    }

    getDocuments(page) {
        ApiDocument.getDocuments(
            page,
            DOC_RESULT_PAGE,
            this._filter,
            this._categoriesIds,
            this.searchText
        ).then((result) => {
            if (!this._isMounted) return;

            let data = result.payload;
            this.pagination = result.extra;
            this.setState({
                nbResults: result.extra.resources.total,
                results: data,
                searchLoading: false,
                loadResults: false,
            });
        });
    }

    filter(payload, page) {
        this.setState({ loadResults: true });
        const ORDERING_PROPERTY = payload.filter;
        const PARAMS = qs.parse(this.props.location.search, {
            ignoreQueryPrefix: true,
        });
        let orderBy = "";
        let sort = "";

        page = page || PARAMS.page || 1;

        // Sort
        if (ORDERING_PROPERTY !== "") {
            if (ORDERING_PROPERTY === "date") {
                sort = "published_at";
            } else if (ORDERING_PROPERTY === "name") {
                sort = "title";
            } else {
                sort = ORDERING_PROPERTY;
            }

            orderBy = payload.increasing ? "asc" : "desc";
        }

        this._filter = {
            where: payload.where,
            sort: sort,
            orderBy: orderBy,
        };

        this.getDocuments(page);
    }

    toggleEditMode() {
        this.setState((state) => ({
            deleteMode: false,
            archiveMode: false,
            editMode: !state.editMode,
        }));
    }

    toggleDeleteMode() {
        this.setState((state) => ({
            editMode: false,
            archiveMode: false,
            deleteMode: !state.deleteMode,
        }));
    }

    toggleArchiveMode() {
        this.setState((state) => ({
            editMode: false,
            deleteMode: false,
            archiveMode: !state.archiveMode,
        }));
    }

    openFormEditDoc(file) {
        file.file = file.files[0];
        file.author = file.author && file.author[0];
        if (file.date === undefined) {
            file.date =
                file.publishedat &&
                DateHandler.getJsDate(file.publishedat.date);
        }

        this.setState({
            tempFile: file,
            showModalAddDoc: true,
        });
    }

    openFormAddDoc() {
        this.setState({
            editMode: false,
            deleteMode: false,
            showModalAddDoc: true,
        });
    }

    openDeleteDoc(file) {
        this.setState({
            tempFile: file,
            showModalConfirmDelete: true,
        });
    }

    openToggleArchiveDoc(file) {
        this.setState({
            tempFile: file,
            showModalConfirmToggleArchive: true,
        });
    }

    confirmToggleArchiveDoc(file) {
        this.setState({
            loadingForm: true,
        });

        var isArchived = file.archivedat != "" && file.archivedat != null;

        ApiDocument.archive(file.id, isArchived ? 0 : 1).then(() => {
            if (!this._isMounted) return;

            if (isArchived) {
                file.archivedat = null;
            } else {
                file.archivedat = new Date();
            }

            this.setState(
                {
                    alert: {
                        show: true,
                        action: isArchived ? "unarchive" : "archive",
                        value: file.title,
                    },
                    nbResults: this.state.nbResults - 1,
                    showModalAddDoc: false,
                    showModalConfirmDelete: false,
                    showModalConfirmToggleArchive: false,
                    loadingForm: false,
                },
                () => {
                    this.setState({ tempFile: null });
                }
            );
        });
    }

    confirmDeleteDoc(file) {
        this.setState({
            loadingForm: true,
        });

        ApiDocument.remove(file.id).then(() => {
            if (!this._isMounted) return;

            let newsResults = this.state.results;
            for (let property in newsResults) {
                if (
                    newsResults.hasOwnProperty(property) &&
                    newsResults[property].id === file.id
                ) {
                    newsResults.splice(property, 1);
                }
            }

            this.pagination.resources.total--;

            this.setState(
                {
                    alert: {
                        show: true,
                        action: "delete",
                        value: file.title,
                    },
                    nbResults: this.state.nbResults - 1,
                    results: newsResults,
                    resultsBackup: newsResults,
                    showModalAddDoc: false,
                    showModalConfirmDelete: false,
                    showModalConfirmToggleArchive: false,
                    loadingForm: false,
                },
                () => {
                    this.setState({ tempFile: null });
                }
            );
        });
    }

    updateStateResult(file, safeDelete) {
        let newsResults = this.state.results;

        for (let property in newsResults) {
            if (
                newsResults.hasOwnProperty(property) &&
                newsResults[property].id === file.id
            ) {
                if (safeDelete) {
                    newsResults[
                        property
                    ].archivedat = DateHandler.getPrettyDate();
                } else {
                    newsResults.splice(property, 1);
                }
            }
        }

        this.pagination.resources.total--;

        this.setState(
            {
                nbResults: this.state.nbResults - 1,
                results: newsResults,
                resultsBackup: newsResults,
                showModalAddDoc: false,
                showModalConfirmDelete: false,
                showModalConfirmToggleArchive: false,
                loadingForm: false,
            },
            () => {
                this.setState({ tempFile: null });
            }
        );
    }

    async validateDoc(file) {
        file.commentable = false;
        file.featured = false;
        file.public = true;
        file.published = true;
        file.visual = "0";
        file.publishedat = DateHandler.formatForApi(file.date, false);

        // Update document
        if (file.id) {
            let promise = [];
            // The user has change the file
            if (file.file.name && file.files[0].name !== file.file.name) {
                const FORM_DATA_FILE = new FormData();
                FORM_DATA_FILE.append("files", file.file);
                promise.push(ApiDocument.uploadFile(FORM_DATA_FILE));
            }

            if (file.tags && file.tags.length > 0) {
                for (let i = 0; i < file.tags.length; i++) {
                    promise.push(
                        ApiTag.addTags(file.tags[i].name || file.tags[i])
                    );
                }
            }

            // Clean all tags before update
            if (file.id) {
                try {
                    await ApiTag.delete(file.id);
                } catch (e) {
                    console.log(e);
                }
            }

            Promise.all(promise).then((payload) => {
                if (!this._isMounted) return;

                let tagIndex = 0;
                let fileUpload = {
                    id: file.files[0].id,
                    type: file.files[0].type,
                    name: file.files[0].name,
                };
                const CATEGORY = file.category;

                if (
                    file.file.name &&
                    file.files[0].name !== file.file.name &&
                    payload[0]
                ) {
                    fileUpload = {
                        id: payload[0].identifier,
                        type: file.file.type,
                        name: file.file.name,
                    };
                    tagIndex = 1;
                }

                let tags = [];

                if (payload[tagIndex]) {
                    for (let i = tagIndex; i < payload.length; i++) {
                        tags.push(payload[i].id);
                    }
                }

                file.tags = tags;
                file.files = [fileUpload.id];
                file.category = file.category.id;

                ApiDocument.update(file, ["tags"]).then(async (document) => {
                    if (!this._isMounted) return;

                    // Remove all filters of the documents
                    await ApiDocument.removeFilters(file.id);

                    // Insert all filters, wait for it to be resolve with await
                    await this._addSpecificFilters(file, file.id);

                    let newsResults = this.state.results;

                    file.files = [fileUpload];
                    file.category = CATEGORY;
                    file.tags = document.tags;

                    for (let i = 0; i < newsResults.length; i++) {
                        if (newsResults[i].id === file.id) {
                            newsResults[i] = file;
                            break;
                        }
                    }
                    this.setState(
                        {
                            results: newsResults,
                            resultsBackup: newsResults,
                            showModalAddDoc: false,
                            alert: {
                                show: true,
                                action: "update",
                                value: file.title,
                            },
                        },
                        () => {
                            this.init();
                        }
                    );
                });
            });
        }
        // Add document
        else {
            let promise = [];

            // Form data
            if (file.file && isNaN(file.file)) {
                const FORM_DATA_FILE = new FormData();
                FORM_DATA_FILE.append("files", file.file);
                if (file.tags && file.tags.length > 0) {
                    FORM_DATA_FILE.append("tags", file.tags);
                }
                promise[0] = ApiDocument.uploadFile(FORM_DATA_FILE);
            }

            if (file.tags && file.tags.length > 0) {
                for (let i = 0; i < file.tags.length; i++) {
                    promise[i + 1] = ApiTag.addTags(file.tags[i]);
                }
            }

            // Upload file and add tags
            Promise.all(promise)
                .then((payload) => {
                    if (!this._isMounted) return;

                    const FILE = {
                        id: payload[0].identifier,
                        type: file.file.type,
                        name: file.file.name,
                    };
                    const CATEGORY = file.category;

                    if (payload[0]) file.files = [FILE.id];
                    if (payload[1]) {
                        let tags = [];
                        for (let i = 1; i < payload.length; i++) {
                            tags.push(payload[i].id);
                        }
                        file.tags = tags;
                    }

                    file.category = file.category.id;
                    delete file.file;

                    //Create Document
                    ApiDocument.add(file, "tags")
                        .then(async (newFile) => {
                            if (!this._isMounted) return;

                            newFile.files = [FILE];
                            newFile.category =
                                Object.keys(CATEGORY).length > 0
                                    ? CATEGORY
                                    : { thematic: file.thematic };

                            // Insert all filters, wait for it to be resolve with await
                            await this._addSpecificFilters(file, newFile.id);

                            this.pagination.resources.total++;

                            let newResult = this.state.results;
                            newResult.unshift(newFile);

                            this.setState({
                                results: newResult,
                                resultsBackup: newResult,
                                showModalAddDoc: false,
                                nbResults: this.state.nbResults + 1,
                                alert: {
                                    show: true,
                                    action: "add",
                                    value: file.title,
                                },
                            });
                        })
                        .catch((e) => {
                            if (!this._isMounted) return;

                            if (e.status === 409) {
                                this.setState({
                                    loadingForm: false,
                                    formError:
                                        "Il semble qu'un document avec le même nom existe déjà dans cette catégorie.",
                                });
                            } else {
                                this.setState({
                                    loadingForm: false,
                                    formError:
                                        "Erreur pendant la création de votre document.",
                                });
                            }
                        });
                })
                .catch(() => {
                    if (!this._isMounted) return;

                    //If file error
                    this.setState({
                        formError:
                            "Erreur pendant le téléchargement de fichier.",
                        loadingForm: false,
                    });
                });
        }
    }

    _addSpecificFilters(file, documentId) {
        let filters = [];

        if (file.maptype) {
            file.maptype.document = documentId;
            file.maptype.periodicity = file.periodicity;
            filters.push(file.maptype);
            delete file.maptype;
        }

        if (file.vehicletype) {
            file.vehicletype.document = documentId;
            filters.push(file.vehicletype);
            delete file.vehicletype;
        }

        let filtersPromises = [];
        filters.forEach((filter) => {
            filtersPromises.push(ApiDocumentFilter.add(filter));
        });

        // Insert all filters
        return Promise.all(filtersPromises);
    }

    _getCategorySelected() {
        let category = null;
        let selectedIndex = 0;
        let categories = this.state.categories;
        this.state.optionSelected.forEach((selected, key) => {
            if (selected >= 0) {
                category = categories[selected];
                if (key < this.state.optionSelected.length) {
                    categories = categories[selected].categories;
                }
                selectedIndex = key;
            }
        });

        // First level are thematics, so if it's the only select for which we made a choose, we don't have any category to return
        if (selectedIndex <= 0) {
            return {};
        }

        return category;
    }

    render() {
        let content = (
            <ButtonThematic
                thematics={this.state.categories}
                isAdmin={true}
                force={Auth.isRootAtLeast()}
                onClick={(category, i) =>
                    this.selectHandler({
                        label: category.name,
                        url: category.uid,
                        value: i,
                        id: category.id,
                    })
                }
            />
        );

        if (this.state.nbResults === 0 && this.state.loadingDocument) {
            content = <Loader type={"inline"} />;
        } else if (
            !this.state.loadingDocument &&
            this.state.optionSelected[0] !== -1 &&
            this.state.results
        ) {
            let currentThematicId = this.state.currentThematic
                ? this.state.currentThematic.id
                : 0;
            content = (
                <Search.Result
                    results={this.state.results}
                    nbResults={this.state.nbResults}
                    adminMode={true}
                    onFilter={(by, e) => this.filter(by, e)}
                    editMode={this.state.editMode}
                    deleteMode={this.state.deleteMode}
                    archiveMode={this.state.archiveMode}
                    category={this._getCategorySelected()}
                    onAdd={() => this.openFormAddDoc()}
                    onEdit={(file) => this.openFormEditDoc(file)}
                    onDelete={(file) => this.openDeleteDoc(file)}
                    onArchive={(file) => this.openToggleArchiveDoc(file)}
                    onToggleEditMode={() => this.toggleEditMode()}
                    onToggleDeleteMode={() => this.toggleDeleteMode()}
                    onToggleArchiveMode={() => this.toggleArchiveMode()}
                    requiredRoleForManagement={(user, role) =>
                        Auth.canManage(currentThematicId)
                    }
                    pagination={this.pagination}
                    fileColor={
                        this.state.categories[this.state.optionSelected[0]]
                            .color
                    }
                    loading={this.state.loadResults}
                    onPaginationClick={(page) => {
                        this.onPaginationClick(page);
                    }}
                />
            );
        }

        return (
            <Master
                id="adminDocumentation"
                httpError={this.state.httpError}
                admin={true}
                {...this.props}
            >
                {/* Banner */}
                <Banner
                    title={"Gestion des documents"}
                    search={true}
                    selects={this.state.selects}
                    searchLoading={this.state.searchLoading}
                    onSearch={(searchText) => this.handleSearch(searchText)}
                    {...this.props}
                />

                {/* Content */}
                {content}

                <Alert
                    show={this.state.alert.show}
                    type={"document"}
                    action={this.state.alert.action}
                    value={this.state.alert.value}
                    onFinish={() =>
                        this.state.alert.show &&
                        this.setState({
                            alert: {
                                ...this.state.alert,
                                show: false,
                            },
                        })
                    }
                />

                {/* Modal add doc */}
                {this.state.showModalAddDoc && (
                    <Modal
                        onClose={() =>
                            this.setState({ showModalAddDoc: false })
                        }
                    >
                        <FormAddDoc
                            file={
                                this.state.editMode && this.state.tempFile
                                    ? this.state.tempFile
                                    : null
                            }
                            onCancel={() =>
                                this.setState({
                                    showModalAddDoc: false,
                                    tempFile: null,
                                })
                            }
                            onValid={(file) => this.validateDoc(file)}
                            onDelete={(file) => this.confirmDeleteDoc(file)}
                            onArchive={(file) =>
                                this.confirmToggleArchiveDoc(file)
                            }
                            thematic={this.state.currentThematic.id}
                            formError={this.state.formError}
                        />
                    </Modal>
                )}

                {/* Modal confirm delete doc */}
                {this.state.showModalConfirmDelete && (
                    <Modal
                        autoWidth={true}
                        autoHeight={true}
                        onClose={() =>
                            this.setState({ showModalConfirmDelete: false })
                        }
                    >
                        <p>
                            Êtes-vous sûr de vouloir supprimer «{" "}
                            {this.state.tempFile.title} » ?
                        </p>
                        <div className={"buttonModal"}>
                            <button
                                type={"button"}
                                className={"cancel"}
                                onClick={() =>
                                    this.setState({
                                        showModalConfirmDelete: false,
                                    })
                                }
                            >
                                Non
                            </button>
                            <button
                                type={"button"}
                                onClick={() =>
                                    this.confirmDeleteDoc(this.state.tempFile)
                                }
                                disabled={this.state.loadingForm}
                            >
                                {this.state.loadingForm ? (
                                    <Loader
                                        type={"button"}
                                        backgroundColor={"transparent"}
                                    />
                                ) : (
                                    "oui"
                                )}
                            </button>
                        </div>
                    </Modal>
                )}

                {/* Modal confirm archive/unarchive doc */}
                {this.state.showModalConfirmToggleArchive && (
                    <Modal
                        autoWidth={true}
                        autoHeight={true}
                        onClose={() =>
                            this.setState({
                                showModalConfirmToggleArchive: false,
                            })
                        }
                    >
                        <p>
                            Êtes-vous sûr de vouloir{" "}
                            {this.state.tempFile.archivedat
                                ? "désarchiver"
                                : "archiver"}{" "}
                            « {this.state.tempFile.title} » ?
                        </p>
                        <div className={"buttonModal"}>
                            <button
                                type={"button"}
                                className={"cancel"}
                                onClick={() =>
                                    this.setState({
                                        showModalConfirmToggleArchive: false,
                                    })
                                }
                            >
                                Non
                            </button>
                            <button
                                type={"button"}
                                onClick={() =>
                                    this.confirmToggleArchiveDoc(
                                        this.state.tempFile
                                    )
                                }
                                disabled={this.state.loadingForm}
                            >
                                {this.state.loadingForm ? (
                                    <Loader
                                        type={"button"}
                                        backgroundColor={"transparent"}
                                    />
                                ) : (
                                    "oui"
                                )}
                            </button>
                        </div>
                    </Modal>
                )}
            </Master>
        );
    }
}
