import React from "react";
import PropTypes from "prop-types";
import "./ThematicsRectangle.scss";
import {
    THEMATIC_TYPE_ALL,
    THEMATIC_TYPE_COMMON,
    THEMATIC_TYPE_DOCUMENTATION,
    THEMATIC_TYPE_WORKGROUP,
    THEMATIC_TYPES,
} from "../../../Services/ApiThematics";
import Loader from "../../Loader/Loader";
import Auth from "../../../Security/Auth";
import Roles from "../../../Security/Roles";
import Thematics from "../../../Utils/Thematics";

export default class ThematicsRectangle extends React.Component {
    static propTypes = {
        title: PropTypes.string,
        thematics: PropTypes.array,
        defaultCheckedId: PropTypes.array,
        errorMsg: PropTypes.string,
        onChange: PropTypes.func,
        thematicsType: PropTypes.oneOf(THEMATIC_TYPES),
        isMultiple: PropTypes.bool,
        readonly: PropTypes.bool,
        disabled: PropTypes.bool,
    };

    static defaultProps = {
        title: "",
        thematics: [],
        defaultCheckedId: [],
        thematicsType: THEMATIC_TYPE_ALL, // useful if thematics props is not provided
        isMultiple: false,
        readonly: false,
        disabled: false,
        onChange: () => {},
    };

    state = {
        thematics: [],
        checkedItems: [],
        loading: true,
    };

    componentDidMount() {
        // No thematics provided? Get them from API (look at the "thematicsType" props to filter results)
        if (this.props.thematics.length <= 0) {
            let thematics;
            switch (this.props.thematicsType) {
                case THEMATIC_TYPE_WORKGROUP:
                    thematics = Thematics.getLocalThematics(
                        THEMATIC_TYPE_WORKGROUP,
                        true
                    );
                    break;

                case THEMATIC_TYPE_DOCUMENTATION:
                    thematics = Thematics.getLocalThematics(
                        THEMATIC_TYPE_DOCUMENTATION,
                        true
                    );
                    break;

                case THEMATIC_TYPE_COMMON:
                    thematics = Thematics.getLocalThematics(
                        THEMATIC_TYPE_COMMON,
                        true
                    );
                    break;

                case THEMATIC_TYPE_ALL:
                default:
                    thematics = Thematics.getLocalThematics(
                        THEMATIC_TYPE_ALL,
                        true
                    );
                    break;
            }

            this.setState({ thematics: thematics, loading: false });
        } else {
            this.setState({ thematics: this.props.thematics, loading: false });
        }

        // Checked items
        this.setState({ checkedItems: this.props.defaultCheckedId });
    }

    componentDidUpdate(prevProps) {
        if (
            prevProps.defaultCheckedId.length !==
            this.props.defaultCheckedId.length
        ) {
            this.setState({ checkedItems: this.props.defaultCheckedId });
        }
    }

    onInputChange(e) {
        if (this.props.readonly) return;

        let checkedItem = JSON.parse(e.target.value),
            checkedItems = this.state.checkedItems,
            haveBeenRemoved = false;

        // Handle click on an already selected thematic to remove it from list
        if (checkedItems.indexOf(checkedItem.id) >= 0 && !e.target.checked) {
            checkedItems = checkedItems.filter((value) => {
                return value !== checkedItem.id;
            });

            haveBeenRemoved = true;
        }

        // For non multiple selectable thematics, we only set the one clicked
        if (!this.props.isMultiple && !haveBeenRemoved) {
            checkedItems = [checkedItem.id];
        } else if (!haveBeenRemoved) {
            // For multiple selectable we add the new one to the list
            checkedItems.push(checkedItem.id);
        }

        // Call props "onChange" callback
        this.setState({ checkedItems: checkedItems }, () => {
            this.props.onChange(checkedItem, checkedItems);
        });
    }

    render() {
        const AUTH_ROLE = Auth.getRole();
        let thematics = this.state.thematics;
        if (AUTH_ROLE.rank < Roles.ROLE_ADMINISTRATOR.rank) {
            return <></>;
        }

        if (AUTH_ROLE.uid === Roles.ROLE_ADMINISTRATOR.uid) {
            // Remove thematics for which user is not admin
            thematics = thematics.filter((thematic) => {
                return Auth.isThematicAdministrator(thematic.id);
            });
        }

        return (
            <div
                className={`group-category${
                    this.props.errorMsg ? " error" : ""
                }`}
            >
                <div className={"title"}>{this.props.title}</div>
                <div className={"content"}>
                    <div className="row">
                        {this.state.loading ? (
                            <Loader type={"inline"} />
                        ) : (
                            thematics.map((thematic, i) => {
                                const ID = "event-cat-" + thematic.uid;

                                return (
                                    <div key={i} className={"col-md-6"}>
                                        <div className={"group-rect-radio"}>
                                            <input
                                                key={i}
                                                id={ID}
                                                type={
                                                    this.props.isMultiple
                                                        ? "checkbox"
                                                        : "radio"
                                                }
                                                name={"category"}
                                                value={JSON.stringify(thematic)}
                                                defaultChecked={
                                                    this.state.checkedItems.indexOf(
                                                        thematic.id
                                                    ) >= 0
                                                        ? true
                                                        : null
                                                }
                                                onClick={this.onInputChange.bind(
                                                    this
                                                )}
                                                readOnly={this.props.readonly}
                                                disabled={this.props.disabled}
                                            />
                                            <label
                                                htmlFor={ID}
                                                style={{
                                                    backgroundColor:
                                                        thematic.color,
                                                }}
                                            >
                                                <span>{thematic.name}</span>
                                            </label>
                                        </div>
                                    </div>
                                );
                            })
                        )}
                    </div>
                    {this.props.errorMsg && (
                        <span className={"errorMsg"}>
                            {this.props.errorMsg}
                        </span>
                    )}
                </div>
            </div>
        );
    }
}
