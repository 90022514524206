import React from 'react';
import PropTypes from "prop-types";
import {getType} from '../../Utils/DocType';

export default class SVGFile extends React.Component {

    static propTypes = {
        className: PropTypes.string,
        color: PropTypes.string.isRequired
    };

    static defaultProps = {
        className: '',
        color: 'grey'
    };

    render() {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width="153.386" height="189.733" viewBox="0 0 153.386 189.733" className={this.props.className}>
                <g transform="translate(-1026.325 -1179.017)">
                    <path data-name="Tracé 105"
                          d="M94.866,2H22.173A18.149,18.149,0,0,0,4.091,20.173L4,165.559a18.149,18.149,0,0,0,18.082,18.173h109.13a18.227,18.227,0,0,0,18.173-18.173V56.52Zm1.625,51.921V3.945l49.976,49.976Z"
                          transform="translate(1026.325 1181.017)"
                          fill="none" stroke={this.props.color}
                          strokeLinejoin="round" strokeWidth="8"/>
                    <text data-name="PPT"
                          transform="translate(1103 1322)"
                          fill={this.props.color}
                          fontSize="65" fontFamily="Oswald-Bold, Oswald"
                          fontWeight="700">
                        <tspan x="-51.577" y="0">{getType(this.props.children)}</tspan>
                    </text>
                </g>
            </svg>
        )
    }
}