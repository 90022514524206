import axios from "axios";
import {PATH} from "../Utils/Api";
import Roles from "../Security/Roles";

export default {

    /**
     * Get all roles
     * @returns {Promise<any>}
     */
    getRoles: () => {
        let filteredRoles = [];
        return axios.get(`${PATH}/user/role`, {
            params: {
                'api[sort]': 'rank',
                'api[order]': 'desc'
            }
        }).then((roles) => {
            if (roles.length > 0) {
                roles.map((role) => {
                    // Some roles are not used in frontend... remove them
                    if ([Roles.ROLE_ANONYMOUS.uid, Roles.ROLE_DEVELOPER.uid, Roles.ROLE_EXTERNAL_USER.uid, Roles.ROLE_INTERNAL_USER.uid].indexOf(role['uid']) < 0) {
                        filteredRoles.push(role);
                    }

                    return true;
                })
            }
            return new Promise(resolve => {
                resolve(filteredRoles)
            });
        });

    }
}
