import React from 'react';
import PropTypes from "prop-types";
import "./BreadCrumb.scss";
import {Link} from "react-router-dom";


export default class BreadCrumb extends React.Component {

    static propTypes = {
        levels: PropTypes.arrayOf(PropTypes.shape({
            url: PropTypes.string,
            label: PropTypes.string
        }))
    };

    render() {
        if (this.props.levels !== undefined && this.props.levels.length > 0) {
            return (
                <div className="breadCrumb">
                    <ul>
                        {this.props.levels.map((level, key) => (
                            <li key={key}>
                                {
                                    level.url && key !== this.props.levels.length - 1 ?
                                        <Link to={level.url}>{level.label}</Link> :
                                        level.label
                                }
                                {key !== this.props.levels.length - 1 ? ' >' : ''}
                            </li>
                        ))}
                    </ul>
                </div>
            );
        } else {
            return (<></>);
        }
    }
}