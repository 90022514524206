import React from 'react';
import PropTypes from "prop-types";
import CKEditor from "ckeditor4-react";
import InputTextLight from "../InputTextLight/InputTextLight";
import InputFile from "../InputFile/InputFile";
import Loader from "../../Loader/Loader";
import {CK_EDITOR} from "../../../Config/ckEditor";
import {DateHandler} from "../../../Utils/DateHandler";
import Auth from "../../../Security/Auth";
import "./FormAddNews.scss";


export default class FormAddNews extends React.Component {

    static propTypes = {
        news: PropTypes.shape({
            id: PropTypes.number,
            title: PropTypes.string,
            allowNotificationPush: PropTypes.bool,
            content: PropTypes.string,
            visual: PropTypes.shape({
                url: '',
                tempUrl: ''
            }),
            files: PropTypes.array,
            _updatePublicationDate: PropTypes.bool
        }),
        onCancel: PropTypes.func,
        onValid: PropTypes.func,
        onDelete: PropTypes.func,
        errors: PropTypes.string,
        loading: PropTypes.bool
    };

    state = {
        form: {
            title: '',
            allowNotificationPush: false,
            content: '',
            featured: 0,
            visual: undefined,
            files: undefined,
            _updatePublicationDate: 1
        },
        errors: {
            title: '',
            content: '',
            visual: '',
            file: ''
        },
        formError: ''
    };

    componentDidMount() {
        const news = this.props.news;
        if (news) {
            this.setState({form: news});
        }
    }

    handleFormValue(key, value) {
        this.setState((state) => ({
            form: {
                ...state.form,
                [key]: value
            }
        }));
    }

    /**
     * @ type ('visual, 'file')
     */
    handleUpload(file, type) {
        if (!file) return;
        this.setState((state) => ({form: {...state.form, [type]: file}}))
    }

    handleFormError(key, value) {
        this.setState((state) => ({
            errors: {
                ...state.errors,
                [key]: value
            }
        }));
    }

    removeFileUpload(type) {
        this.setState((state) => ({form: {...state.form, [type]: undefined}}));
    }

    formIsValid() {
        // Reset error
        this.setState({
            formError: '',
            errors: {
                title: '',
                content: '',
                visual: '',
                file: ''
            }
        });

        let hasFormError;

        // Title
        if (!this.state.form.title) {
            this.handleFormError('title', 'Le titre est requis.');
            hasFormError = true;
        } else if (this.state.form.title.length < 3) {
            this.handleFormError('title', 'Le titre doit avoir au minimum 3 caractères.');
            hasFormError = true;
        }

        // Content
        if (!this.state.form.content) {
            this.handleFormError('content', 'La description est requise.');
            hasFormError = true;
        }

        if (!this.state.form.visual) {
            this.handleFormError('visual', 'Une image est requise.');
            hasFormError = true;
        }

        if (hasFormError) {
            this.setState({formError: 'Des erreurs sont présentes dans le formulaire.'});
            return false;
        } else {
            return true;
        }
    }

    validForm() {
        if (!this.formIsValid()) return;

        if (this.state.form._updatePublicationDate || 'undefined' === typeof this.state.form._updatePublicationDate) {
            this.state.form._updatePublicationDate = true;
        }

        // If update
        if (this.state.form.visual && this.state.form.visual.id) {
            return this.props.onValid(this.state.form)
        }

        // Set temp visual url
        if (this.state.form.visual) {
            let FILE_READER = new FileReader();

            FILE_READER.readAsDataURL(this.state.form.visual);
            FILE_READER.onload = (e) => {
                this.setState((state) => {
                    let form                  = state.form;
                    state.form.visual.tempUrl = e.target.result;

                    return {form: form}
                });
            };
        }

        return this.props.onValid(this.state.form)
    }

    render() {
        const FILE_NAME = this.state.form.files && this.state.form.files.length > 0 ? this.state.form.files[0].name : '';

        return (
            <div className={'formAddNews'}>
                <div className="row">
                    <div className="col">
                        {this.props.news
                            ? <div className={'headerFormAddNews'}>
                                <h3 className={'titleModal'}>Editer l’actualité « {this.state.form.title} »</h3>
                            </div>
                            : <h3 className={'titleModal'}>Ajouter une actualité</h3>
                        }
                    </div>
                    <div className="col-md-auto">
                        <div className={'stateCurrentDoc'}>
                            {this.props.news && <button type={'button'} className={'underline blue'} onClick={() => this.props.onDelete(this.props.news)}>Supprimer</button>}
                        </div>
                    </div>
                </div>

                <hr/>
                <br/>

                <form>
                    {/* Title */}
                    <InputTextLight label={'Titre de l’actualité*'}
                                    value={this.state.form.title}
                                    errorMsg={this.state.errors.title}
                                    onChange={(e) => this.handleFormValue('title', e.target.value)}/>


                    {/* Editor WYSIWYG */}
                    <h5 className={'subTitle'} style={{marginBottom: 10, marginTop: 0}}>Description*</h5>

                    <div className={`wysiwyg${this.state.errors.content ? ' error' : ''}`}>
                        <CKEditor
                            onBeforeLoad={(CKEditor) => {
                                CKEditor.disableAutoInline = true;
                                CK_EDITOR.config.filebrowserUploadUrl = CK_EDITOR.baseUploadUrl + Auth.getToken();
                            }}
                            config={CK_EDITOR.config}
                            data={this.state.form.content}
                            type="classic"
                            onChange={(event) => {
                                const data = event.editor.getData();
                                this.handleFormValue('content', data)
                            }}
                        />
                        {this.state.errors.content && <span className={'errorMsg'}>{this.state.errors.content}</span>}
                    </div>

                    {/* Inputs file */}
                    <div className="row">
                        <div className="col-md-6">
                            {/* Visual */}
                            <InputFile id={'visual'}
                                       label={'Image*'}
                                       name={'visual'}
                                       fileName={this.state.form.visual && this.state.form.visual.name}
                                       accept={'.gif,.png,.jpg,.jpeg'}
                                       errorMsg={this.state.errors.visual}
                                       onRemove={this.state.form.id ? null : () => this.removeFileUpload('visual')}
                                       onChange={(file) => {this.handleUpload(file, 'visual');}}/>
                        </div>
                        <div className="col-md-6">
                            {/* File */}
                            <InputFile id={'file'}
                                       label={'Pièce-jointe'}
                                       name={'files'}
                                       fileName={FILE_NAME}
                                       errorMsg={this.state.errors.files}
                                       onRemove={() => this.removeFileUpload('files')}
                                       onChange={(file) => this.handleUpload([file], 'files')}/>
                        </div>
                    </div>

                    {/* Send push notification */}
                    <h5 className="subTitle d-flex align-items-center" style={{marginBottom: 10, marginTop: 40}}>
                        <span style={{marginRight: 10}}>Valider l'envoi de la notification</span>
                        <input type="checkbox"
                               onChange={(e) => this.handleFormValue('allowNotificationPush', e.target.checked ? 1 : 0)}
                        />
                    </h5>

                    {/* Featured */}
                    {this.props.news ? 
                    <h5 className="subTitle d-flex align-items-center" style={{marginBottom: 10, marginTop: 0}}>
                        <span style={{marginRight: 10}}>Mettre à jour la date de publication</span>
                        <input type="checkbox"
                               checked={'undefined' === typeof this.state.form._updatePublicationDate ? 1 : this.state.form._updatePublicationDate}
                               onChange={(e) => this.handleFormValue('_updatePublicationDate', e.target.checked ? 1 : 0)}/>
                    </h5> : ''}
                </form>

                <p className={'required'}><b>* Champs obligatoires</b></p>

                <br/>

                {(this.state.formError || this.props.errors) && <p className={'centerText'} style={{color: 'red'}}>{this.state.formError || this.props.errors}</p>}

                {/* Button */}
                <div className={'buttonModal'}>
                    <button type={'button'} className={'cancel'} onClick={() => this.props.onCancel()}>Annuler</button>
                    <button type={'button'} onClick={() => this.validForm()} disabled={this.props.loading}>
                        {this.props.loading ? <Loader type={"button"} backgroundColor={'transparent'} color={'blue'}/> : 'Valider'}
                    </button>
                </div>
            </div>
        )
    }
}