import React from 'react';
import ApiNews from "../../Services/ApiNews";
import {animateScroll} from "react-scroll";
import Master from "../../Components/Layout/Master";
import Banner from "../../Components/Banner/Banner";
import PreviewNews from "../../Components/Layout/PreviewNews";
import TitleLeftIcon from "../../Components/Text/TitleLeftIcon";
import Loader from "../../Components/Loader/Loader";
import {DateHandler} from "../../Utils/DateHandler";
import {path} from "../../Config/routes";
import {Link} from "react-router-dom";
import {Http} from "../../Utils/Api";
import "./SingleNew.scss";


export default class SingleNew extends React.Component {

    state = {
        loadingNews: true,
        loadingFeaturedNews: true,
        news: {
            createdat: {
                date: ''
            },
            publishedat: {
                date: ''
            },
            visual: {
                url: ''
            },
            files: []
        },
        featuredNews: [],
        httpError: false
    };

    constructor(props) {
        super(props);
        this._isMounted = false;
        this.id         = null;
        if (this.props.match.params.id !== undefined) {
            this.id = this.props.match.params.id;
        }
    }

    componentDidMount() {
        this._isMounted = true;
        this.getNews();
    }

    componentWillUnmount() {
        this._isMounted = false;
        Http.cancel();
    }

    getNews() {
        this.setState({
            loadingNews: true,
            loadingFeaturedNews: true
        });

        ApiNews.get(this.id, ['visual', 'files']).then((news) => {
            if (!this._isMounted) return;
            this.setState({
                loadingNews: false,
                news: news
            });
        }).catch(() => {
            if (!this._isMounted) return;
            this.setState({
                httpError: true
            })
        });
        ApiNews.getFeaturedNews('visual', 2, this.id).then((news) => {
            if (!this._isMounted) return;
            this.setState({
                loadingFeaturedNews: false,
                featuredNews: news
            });
        }).catch((err) => {
            if (!this._isMounted) return;
            if (err.status && err.status >= 500) {
                this.setState({
                    httpError: true
                });
            }
        });
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.match.params.id !== prevProps.match.params.id) {
            // Scroll smooth to top
            animateScroll.scrollToTop();
            this.id = this.props.match.params.id;
            this.getNews();
        }
    }

    render() {
        const BREADCRUMB = [
            {
                url: path('news'),
                label: 'Actualités'
            },
            {
                label: this.state.news.title
            }
        ];

        return (
            <Master id={'singleNew'} httpError={this.state.httpError} breadCrumb={BREADCRUMB} {...this.props}>

                {/* Banner */}
                <Banner imgSrc={'/assets/images/background/news.jpg'}
                        supTitle={this.state.news.publishedat.date && DateHandler.getPrettyDate(this.state.news.publishedat.date)}
                        title={this.state.news.title}
                        size={'small'}
                        placeholderLoading={this.state.loadingNews}
                />

                {/* Single news */}
                <section className="post pageContent container">
                    {this.state.loadingNews
                        ? <Loader type={"inline"}/>
                        : <>
                            {/* Content & visual  */}
                            <div className="row">
                                <div className="col-md-6" dangerouslySetInnerHTML={{__html: this.state.news.content}}/>
                                <div className="col-md-6 d-flex justify-content-center align-items-center flex-column">
                                    <img src={this.state.news.visual.url} alt={this.state.news.title} className={'imgResponsive'}/>
                                </div>
                            </div>
                            {/* File attachments */}
                            {this.state.news.files.length > 0 && <div className="attachments">
                                {this.state.news.files.map((file, i) => (
                                    <a className={'btn'} href={file.url} target={'_blank'} key={i}>Télécharger la pièce-jointe</a>
                                ))}
                            </div>}
                        </>}
                </section>

                {/* More news */}
                <section className="moreNews">
                    <TitleLeftIcon>PLUS D’ACTUALITÉS</TitleLeftIcon>

                    {this.state.loadingFeaturedNews
                        ? <Loader type={"inline"} backgroundColor={'transparent'}/>
                        : <>
                            <div className="container">
                                <div className="row">
                                    <div className="col">
                                        {this.state.featuredNews[0] && <PreviewNews news={this.state.featuredNews[0]} left={true}/>}
                                    </div>
                                    <div className="col">
                                        {this.state.featuredNews[1] && <PreviewNews news={this.state.featuredNews[1]} left={true}/>}
                                    </div>
                                </div>
                            </div>

                            <br/>

                            <div className="show-all-news">
                                <Link to={path('news')} className={'underline'}>Voir les actualités</Link>
                            </div>
                        </>}
                </section>
            </Master>
        )
    }
}