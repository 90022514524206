import axios from "axios";
import {PATH} from "../Utils/Api";

export default {

    /**
     * Return all available companies
     *
     * @returns {AxiosPromise<any>}
     */
    getAll: () => {
        return axios.get(`${PATH}/user/company`);
    },
}