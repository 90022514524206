import React from 'react';
import PropTypes from "prop-types";
import ApiProfile from "../../../Services/ApiProfile";
import ListTags from "../../List/ListTags/ListTags";
import InputSearch from "../InputSearch/InputSearch";
import WorkgroupMembersListSearch from "../../List/WorkgroupMembersListSearch/WorkgroupMembersListSearch";
import "../FormAddWorkGroup/FormAddWorkGroup.scss";
import ListRoleLegend from "../../List/ListRoleLegend/ListRoleLegend";


export default class SearchUsers extends React.Component {

    static propTypes = {
        workGroup: PropTypes.shape({
            id: PropTypes.number,
            publishedat: PropTypes.shape({
                date: PropTypes.string.isRequired
            }),
            name: PropTypes.string,
            description: PropTypes.string,
            users: PropTypes.arrayOf(PropTypes.shape({
                id: PropTypes.number.isRequired,
                user: PropTypes.shape({
                    profile: PropTypes.shape({
                        firstname: PropTypes.string.required,
                        lastname: PropTypes.string.required,
                    }).isRequired
                }).isRequired
            }))
        }),
        error: PropTypes.string,
        onChange: PropTypes.func
    };

    state = {
        form: {
            users: {},
        },
        // Error
        error: {
            name: '',
            status: '',
        },
        formError: '',
        usersLabels: [],
        workGroupUsersToDelete: [],
        profiles: [],
        loading: false,
        loadingAutocomplete: false,
    };

    constructor(props) {
        super(props);
        this._isMounted                    = false;
        this._interval                     = null;
        this._intervalCleared              = true;
        this._searchHasBeenDoneAtLeastOnce = false;
        this._inputSearchRef               = React.createRef();

        if (this.props.workGroup) {
            this.state.form = {...this.props.workGroup};

            // Format user labels
            const USERS_LABELS = [];
            if (this.props.workGroup.users.length) {
                this.props.workGroup.users.forEach(workgroupUser => {
                    USERS_LABELS.push({
                        id: workgroupUser.user.id,
                        name: `${workgroupUser.user.profile.firstname} ${workgroupUser.user.profile.lastname}`,
                        membertype: parseInt(workgroupUser.membertype),
                        workgroupUserId: workgroupUser.id
                    });
                });

                // Then we delete the users in the workgroup, since we don't want to send already workgroup's users, only new ones
                this.state.form.users = [];
            }
            this.state.usersLabels = USERS_LABELS;
        }
    }

    componentDidMount() {
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    handleError(key, value) {
        this.setState((state) => ({error: {...state.error, [key]: value}}));
    }

    async searchUser(searchToken) {

        if (this._intervalCleared && searchToken.length > 2) {
            this._intervalCleared = false;
            this.setState({loadingAutocomplete: true});

            this._interval = setInterval(async () => {
                if (!this._isMounted) return;

                try {
                    const EXCLUDED_PROFILES_ID = this.state.profiles.map(profile => {
                        return profile.id
                    });
                    const PROFILES             = await ApiProfile.searchByFirstNameOrLastName(searchToken, EXCLUDED_PROFILES_ID);

                    if (!this._isMounted) return;

                    this.setState({profiles: PROFILES});
                } catch (e) {
                    console.error(e);
                    // TODO display error msg?
                } finally {
                    if (this._isMounted) {
                        clearTimeout(this._interval);
                        this._intervalCleared              = true;
                        this._searchHasBeenDoneAtLeastOnce = true;
                        this.setState({loadingAutocomplete: false});
                    }
                }
            }, 800);
        }

    }

    addProfile(profile, memberType) {
        this.addUser(`${profile.firstname} ${profile.lastname}`, profile.user.id, memberType);

        this._inputSearchRef.current.setState({value: ''});
        this._searchHasBeenDoneAtLeastOnce = false;
    }

    addUser(userFullName, userId, memberType) {
        let users       = {...this.state.form.users};
        let usersLabels = this.state.usersLabels;

        if (!users.hasOwnProperty(userId)) {
            // Add new user
            users[userId] = {id: userId, memberType: memberType};
            usersLabels.push({id: userId, name: userFullName, membertype: parseInt(memberType)});
        }

        this.setState((state) => ({
            usersLabels: usersLabels,
            form: {...this.state.form, users: users},
            error: {...state.error, users: ''},
        }), () => {
            this.props.onChange(users, usersLabels, this.state.workGroupUsersToDelete);
            this.handleError('users', '')
        });
    }

    removeUser(userId) {
        let users       = this.state.form.users;
        let usersLabels = this.state.usersLabels;

        // Remove user from selected users
        delete users[userId];

        // Also remove the tag  on which the user is displayed
        const WG_USERS_TO_DELETE = this.state.workGroupUsersToDelete;
        for (let i = 0; i < usersLabels.length; i++) {
            if (usersLabels[i].id === userId) {
                // Handle workGroupUser that we will need to delete (in case of editing)
                if (usersLabels[i].hasOwnProperty('workgroupUserId')) {
                    WG_USERS_TO_DELETE.push(usersLabels[i].workgroupUserId);
                }

                // Remove displayed tag
                usersLabels.splice(i, 1);

                break; // We deleted the good one, stop loop
            }
        }

        this.setState((state) => ({
            usersLabels: usersLabels,
            workGroupUsersToDelete: WG_USERS_TO_DELETE,
            form: {...state.form, users: users},
            error: {...state.error, users: ''}
        }), () => {
            this.props.onChange(users, usersLabels, WG_USERS_TO_DELETE);
        });
    }

    render() {
        return (
            <>
                <InputSearch label={"Nom du participant"}
                             ref={this._inputSearchRef}
                             placeholder={"Sandrine"}
                             errorMsg={this.state.error.users}
                             autoComplete={"off"}
                             loading={this.state.loadingAutocomplete}
                             onChange={searchToken => this.searchUser(searchToken)}
                />

                <ListRoleLegend/>

                {/* Tags */}
                <ListTags tags={this.state.usersLabels} skin={'grey'} onRemove={(item) => this.removeUser(item)}/>

                {/* Member list */}
                {this.state.profiles.length > 0 &&
                <WorkgroupMembersListSearch profiles={this.state.profiles}
                                            searchHasBeenDoneAtLeastOnce={this._searchHasBeenDoneAtLeastOnce}
                                            onAddMember={(profile, memberType) => this.addProfile(profile, memberType)}/>}

                {this.props.error && <span className={'errorMsg'}>{this.props.error}</span>}
            </>
        )
    }
}

