import React from 'react';
import PropTypes from "prop-types";
import Button from "../Form/Button/Button";
import {WORKGROUP_STATUS_ACTIVE, WORKGROUP_STATUS_SPECIFIC, WORKGROUP_STATUS_STANDBY} from "../../Services/ApiWorkGroup";
import "./Filter.scss";

export default class FilterWorkGroup extends React.Component {

    static propTypes = {
        onFilter: PropTypes.func,
        numberResult: PropTypes.number
    };

    static defaultProps = {
        type: 'all',
        id: 0
    };

    state = {
        mobileFilter: false
    };

    subFilter(value, e) {
        const TARGET  = e.target;
        const BUTTONS = document.querySelectorAll('.subFilter button');

        for (let i = 0; i < BUTTONS.length; i++) {
            BUTTONS[i].classList.remove('active')
        }

        TARGET.classList.add('active');
        document.querySelector('.allDocFilter span em').innerText = TARGET.innerText;

        // Hide sub filter after click
        TARGET.parentNode.parentNode.classList.add('close');
        setTimeout(() => {TARGET.parentNode.parentNode.classList.remove('close');}, 100);

        this.props.onFilter(value);
    }

    render() {

        return (
            <>
                {/* Number results workgroup */}
                {this.props.numberResult !== undefined && <div className="how-many-results white">
                    <p><span>{this.props.numberResult}</span> Groupe{this.props.numberResult > 1 ? 's' : ''} de travail</p>
                </div>}

                {/* Filter */}
                <div className="filter">
                    <div className="container">
                        <ul className={this.state.mobileFilter ? 'mobile' : null}>
                            <li>
                                <div>TRIER PAR :</div>
                                <button type={'button'} className={'toggleMobile'} onClick={() => this.setState((state) => ({mobileFilter: !state.mobileFilter}))}>
                                    <span>Filtre</span>
                                </button>
                            </li>
                            <li>
                                <Button className={'allDocFilter'} type="button" disabled={true}>Statut</Button>
                                <ul className={'subFilter'}>
                                    <li>
                                        <button type={'button'} onClick={(e) => this.subFilter(null, e)}>Statut</button>
                                    </li>
                                    <li>
                                        <button type={'button'} onClick={(e) => this.subFilter(WORKGROUP_STATUS_ACTIVE, e)}>Groupe actif</button>
                                    </li>
                                    <li>
                                        <button type={'button'} onClick={(e) => this.subFilter(WORKGROUP_STATUS_SPECIFIC, e)}>Groupe spécifique</button>
                                    </li>
                                    <li>
                                        <button type={'button'} onClick={(e) => this.subFilter(WORKGROUP_STATUS_STANDBY, e)}>Groupe en veille</button>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                </div>
            </>
        )
    }
}