import React from "react";
import Master from "../../../Components/Layout/Master";
import Banner from "../../../Components/Banner/Banner";
import "./Contact.scss";

export default class Contact extends React.Component {
    render() {
        return (
            <Master {...this.props}>
                <div id={"contact"}>
                    <Banner
                        title={"Contact"}
                        size={"small"}
                        imgSrc={"/assets/images/background/home.jpg"}
                    />
                    <div className="pageContent container">
                        <p>
                            Pour toute demande d’information, vous pouvez nous
                            contacter :{" "}
                        </p>

                        <br />

                        <div className="contactCell">
                            <div>
                                <span>Par email</span>
                                <b>
                                    <a href="mailto:myasfa@autoroutes.fr">
                                        myasfa@autoroutes.fr
                                    </a>
                                </b>
                            </div>
                        </div>
                    </div>
                </div>
            </Master>
        );
    }
}
