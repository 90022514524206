import React from 'react';
import PropTypes from "prop-types";
import "./SelectMultipleWorkGroup.scss";

export default class SelectMultipleWorkGroup extends React.Component {

    static propTypes = {
        label: PropTypes.string,
        id: PropTypes.string,
        workgroups: PropTypes.object,
        name: PropTypes.string,
        errorMsg: PropTypes.string,
        onChange: PropTypes.func,
        className: PropTypes.string,
    };

    static defaultProps = {
        className: '',
        placeholder: '',
        required: false,
    };

    constructor(props) {
        super(props);
        this.wrapSelect = React.createRef();
    }


    handleChangeOption(e) {
        const TARGET           = e.target;
        const ELEMENTS_SELECTED = this.wrapSelect.current.querySelectorAll('input:checked');
        let values             = [];

        for (let i = 0; i < ELEMENTS_SELECTED.length; i++) {
            values.push(ELEMENTS_SELECTED[i].value)
        }

        this.props.onChange({
            target : TARGET,
            elements_selected : ELEMENTS_SELECTED,
            value : values
        });
    }

    render() {
        let classWrapSelect = 'wrapSelect';
        if (this.props.errorMsg) {
            classWrapSelect += ' error'
        }

        return (
            <div className={`SelectMultipleWorkGroup ${this.props.className}`} style={this.props.style}>

                {/* Label */}
                {this.props.label && <span className={'label'}>{this.props.label}</span>}

                {/* Wrap */}
                <div className={classWrapSelect} ref={this.wrapSelect}>

                    {/* Options group */}
                    {Object.keys(this.props.workgroups).map((workGroupName, i) => {
                        return <div className={'optionGroup'} key={i}>
                            <span>{workGroupName}</span>

                            {/* Options */}
                            {this.props.workgroups[workGroupName].map((value, i) => {
                                const ID = 'option-work-group-' + value.id;
                                return <div key={i}>
                                    <input id={ID} type="checkbox" value={value.id} onChange={(e) => this.handleChangeOption(e)}/>
                                    <label className={'option'} htmlFor={ID}>{value.name}</label>
                                </div>
                            })}
                        </div>
                    })}
                </div>

                {this.props.errorMsg && <span className={'errorMsg'}>{this.props.errorMsg}</span>}
            </div>
        )
    }
}