import React from 'react';
import ApiCategories from "../../../../../Services/ApiCategories";
import Master from "../../../../../Components/Layout/Master";
import Banner from "../../../../../Components/Banner/Banner";
import ToolsManage from "../../../../../Components/ToolsManage/ToolsManage";
import CategoryBuild from "../../../../../Components/Form/CategoryBuild/CategoryBuild";
import Loader from "../../../../../Components/Loader/Loader";
import CategoryTree from "../../../../../Components/CategoryTree/CategoryTree";
import Modal from "../../../../../Components/Modal/Modal";
import InputTextLight from "../../../../../Components/Form/InputTextLight/InputTextLight";
import SelectCategories from "../../../../../Components/Form/SelectCategories/SelectCategories";
import SmoothScroll from "../../../../../Utils/SmoothScroll";
import Alert from "../../../../../Components/Alert/Alert";
import {path} from "../../../../../Config/routes";
import "./EditTree.scss";
import Thematics from "../../../../../Utils/Thematics";
import Auth from "../../../../../Security/Auth";
import {triggerEvent} from "../../../../../Utils/Event";


export default class EditTree extends React.Component {
    state = {
        thematicTree: null,
        breadCrumb: [{url: path('adminDocumentation'), label: 'Documentation - Aroborescence'}],
        editMode: false,
        archiveMode: false,
        showNewTree: false,
        loading: true,
        thematic: null,
        editingCategory: null,
        parentCategory: null,
        saving: false,
        error: {
            name: null,
            parentCategory: null,
        },
        alert: {
            show: false,
            action: 'add',
            value: ''
        }
    };

    constructor(props) {
        super(props);

        this.toggleArchiveMode   = this.toggleArchiveMode.bind(this);
        this.toggleEditMode      = this.toggleEditMode.bind(this);
        this.openModalHandleTree = this.openModalHandleTree.bind(this);
        this.updateThematicTree  = this.updateThematicTree.bind(this);
        this.validateEditForm    = this.validateEditForm.bind(this);
    }

    componentDidMount() {
        this.thematicId      = parseInt(this.props.match.params.id);
        const THEMATIC       = Thematics.getThematic(this.thematicId);
        const THEMATICS_TREE = THEMATIC.categories || [];
        let breadCrumb       = this.state.breadCrumb;

        breadCrumb.push({url: '', label: THEMATIC['name']});
        this.setState({
            breadCrumb: breadCrumb,
            thematicTree: THEMATICS_TREE,
            loading: false,
            thematic: THEMATIC,
        });
    }

    getTree() {
        return ApiCategories.getTreeFromThematicId(this.thematicId);
    }

    updateThematicTree(thematicTree, categoryName, action = 'add') {
        this.setState({
            thematicTree: thematicTree,
            alert: {
                show: true,
                action: action,
                value: categoryName
            }
        }, () => {
            Thematics.updateThematicCategories(this.thematicId, thematicTree);
        });
    }

    openModalHandleTree() {
        this.setState({
            editMode: false,
            archiveMode: false,
            showNewTree: true
        })
    }

    toggleEditMode() {
        this.setState((state) => ({
            editMode: !state.editMode,
            archiveMode: false,
            showNewTree: false,
        }))
    }

    toggleArchiveMode() {
        this.setState((state) => ({
            editMode: false,
            archiveMode: !state.archiveMode,
            showNewTree: false,
        }))
    }

    onClickEditCategory(category) {
        this.setState({editingCategory: category}, async () => {
            this.modalRef = React.createRef();
            if (category.hasOwnProperty('parentid') && category.parentid > 0) {
                this.setState({loading: true});
                const PARENT_CATEGORY = await ApiCategories.get(category.parentid);
                this.setState({loading: false, parentCategory: PARENT_CATEGORY});
            }
        })
    }


    selectParentCategoryOnChange([parentCategory]) {
        if (parentCategory === undefined) {
            parentCategory = null;
        }

        this.setState({
            parentCategory: parentCategory,
            editingCategory: {...this.state.editingCategory, parentid: parentCategory ? parentCategory.id : 0}
        });
    }

    checkEditFormValid() {
        return new Promise((resolve, reject) => {
            this.setState((state) => {
                    let category = '';
                    if (state.editingCategory.id === state.editingCategory.parentid) {
                        category = `Vous ne pouvez pas ajouter la catégorie au sein d'elle même`;
                    }
                    return {
                        error: {
                            name: state.editingCategory.name ? '' : 'Le nom de la sous-catégorie est requis',
                            parentCategory: category,
                        }
                    }
                }
                , () => {
                    let formErrors = [];
                    for (let property in this.state.error) {
                        if (this.state.error.hasOwnProperty(property) && this.state.error[property] !== '') {
                            formErrors.push(this.state.error[property]);
                        }
                    }

                    if (formErrors.length > 0) {
                        this.setState({formError: formErrors.join('<br />')});
                        reject();
                    } else {
                        this.setState({formError: ''});
                    }

                    resolve();
                });
        });
    }

    validateEditForm() {
        this.setState({saving: true});
        this.checkEditFormValid().then(async () => {

            // Build category data to update
            const CATEGORY_TO_UPDATE_DATA = {
                id: this.state.editingCategory.id,
                name: this.state.editingCategory.name,
                parentid: this.state.editingCategory.parentid,
                parent: this.state.editingCategory.parentid,
            };

            // Update the category
            await ApiCategories.edit(CATEGORY_TO_UPDATE_DATA).then(payload => {
                this.setState({
                    saving: false,
                    editingCategory: null,
                    thematicTree: null,
                    alert: {
                        show: true,
                        action: 'update',
                        value: this.state.editingCategory.name
                    }
                }, async () => {
                    // Then update the tree by reloading it
                    const TREE = await this.getTree();
                    this.updateThematicTree(TREE, CATEGORY_TO_UPDATE_DATA.name);
                });
            });
        }).catch(e => {
            // Error occurred
            const MODAL_CONTENT = this.modalRef.current.parentElement;
            new SmoothScroll(MODAL_CONTENT.querySelector('.requiredInfo'), {
                context: MODAL_CONTENT,
            });

            this.setState(state => {
                if (state.formError === '') {
                    return {formError: "Une erreur est survenue, veuillez réessayer"};
                }
            });
        }).finally(() => {
            this.setState({saving: false});
        })
    }

    archiveCategory(category) {
        ApiCategories.archive(category.id).then(() => {
            triggerEvent('category_tree.update.header_menu');

            this.getTree().then((tree) => {
                this.updateThematicTree(tree, category.name, 'archive');
            });
        });
    }

    unArchiveCategory(category) {
        ApiCategories.unArchive(category.id).then(() => {
            triggerEvent('category_tree.update.header_menu');

            this.getTree().then((tree) => {
                this.updateThematicTree(tree, category.name, 'unarchive');
            });
        });
    }

    render() {
        return <Master id="adminManageDocumentationEditTree" admin={true}
                       breadCrumb={this.state.breadCrumb} {...this.props}>
            <Alert show={this.state.alert.show}
                   type={"category"}
                   action={this.state.alert.action}
                   value={this.state.alert.value}
                   onFinish={() => this.state.alert.show && this.setState({
                       alert: {
                           ...this.state.alert,
                           show: false
                       }
                   })}
            />
            <Banner title={this.state.thematic && this.state.thematic.name}
                    backgroundColor={this.state.thematic ? this.state.thematic.color : '#ccc'}
                    backButton={path('adminManageDocumentation')} height={360}
            />
            <div className="container">
                <ToolsManage delete={false} archive={true} onAdd={this.openModalHandleTree}
                             editActive={this.state.editMode}
                             archiveActive={this.state.archiveMode}
                             role={(user, role) => Auth.canManage(this.thematicId)}
                             onEdit={this.toggleEditMode}
                             onArchive={this.toggleArchiveMode}/>
                {this.state.showNewTree && <CategoryBuild thematicTree={this.state.thematicTree}
                                                          thematicId={parseInt(this.props.match.params.id)}
                                                          thematicName={this.state.thematic.name}
                                                          onAdd={this.updateThematicTree}
                                                          onClose={() => this.setState({showNewTree: false})}/>}
            </div>
            <div className={"container"}>
                {this.state.thematicTree ? <CategoryTree {...this.props} tree={this.state.thematicTree}
                                                         editMode={this.state.editMode}
                                                         onClickEditCategory={category => this.onClickEditCategory(category)}
                                                         archiveMode={this.state.archiveMode}
                                                         onArchive={category => this.archiveCategory(category)}
                                                         onUnArchive={category => this.unArchiveCategory(category)}
                /> : <div style={{padding: '50px 0'}}><Loader type={"inline"}/></div>}

            </div>

            {/* Modal edit three */}
            {this.state.editingCategory &&
            <Modal onClose={() => this.setState({editingCategory: null})}
                   onCancel={() => this.setState({editingCategory: null})}
                   title={`Editer « ${this.state.editingCategory.name} »`}
                   saveLabel={"Valider"}
                   onValidate={this.validateEditForm}
                   loading={this.state.saving}
            >
                <div className={'formEditCategory'} ref={this.modalRef}>
                    {this.state.error.editingCategory !== '' &&
                    <span className={'globalErrorMsg'}>{this.state.error.editingCategory}</span>}

                    {/* Button */}
                    <form>
                        {this.state.loading ?
                            <Loader type={"inline"}/>
                            :
                            <>
                                <InputTextLight id={'name'} label={"Nom de la sous-catégorie*"}
                                                value={this.state.editingCategory.name}
                                                onChange={(e) => this.setState({
                                                    editingCategory: {
                                                        ...this.state.editingCategory,
                                                        name: e.target.value
                                                    }
                                                })}
                                />

                                <span className={'label'}>Parent de la sous-catégorie</span>
                                <SelectCategories
                                    title={'Sélectionnez le parent de la sous-catégorie*'}
                                    selected={this.state.parentCategory ? [this.state.parentCategory] : []}
                                    onChange={parentCategory => this.selectParentCategoryOnChange(parentCategory)}
                                    multiple={false}
                                    // errorMsg={this.state.error.categories}
                                    thematic={this.state.thematic}
                                    categories={this.state.thematicTree}
                                    whenNotMultipleUnselect={true}
                                    selectWithChildItem={true}
                                />

                                {!this.state.parentCategory &&
                                <span className={"thematicRoot"}>La sous-catégorie sera placée à la racine de la thématique <strong>« {this.state.thematic.name} »</strong></span>
                                }

                                <input id={'parent'} type={"hidden"}
                                       value={this.state.parentCategory ? this.state.parentCategory.id : '0'}/>

                                <p className="requiredInfo"><b>* Champs obligatoires</b></p>

                                {this.state.formError &&
                                <p className={'centerText error'}>{this.state.formError}</p>}
                            </>
                        }
                    </form>
                </div>
            </Modal>
            }
        </Master>
    }
}