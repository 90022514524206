import React from 'react';
import qs from 'qs';
import Routing from "./Components/Router/Routing";
import Loader from "./Components/Loader/Loader";
import Auth from "./Security/Auth";
import axios from 'axios/index'
import {path} from "./Config/routes";
import "./Styles/app.scss";
import {Http} from "./Utils/Api";
import ReactGA from 'react-ga';
import ApiAccount from "./Services/ApiAccount";

class App extends React.Component {

    state = {
        appLoading: true,
        redirectToLLogin: false,
    };

    refreshTokenTimeout = null;

    componentDidMount() {

        this.initRequestConfig();

        // Loader
        if (process.env.NODE_ENV !== 'development') {
            ReactGA.initialize("UA-47098504-4");
            setTimeout(() => {
                this.setState({appLoading: false});
            }, 2000);
        } else {
            this.setState({appLoading: false});
        }

        let lastInput = new Date();
        const handleActivity = () => {
            if (Auth.isAuthenticated()) {
                const INPUT = new Date();
                // The last click happend more than 5 minutes ago
                if ((INPUT.getTime() - lastInput.getTime()) >= 1000 * 60 * 4) {
                    lastInput = INPUT;
                    ApiAccount.refreshToken(Auth.getRefreshToken()).then(result => {
                        Auth.login({
                            ...Auth.getUser(),
                            refreshToken: result.refresh_token,
                            token: result.token
                        });
                        this.initRequestConfig();
                    });
                }
            }
        };

        
        document.addEventListener('click', handleActivity);
        document.addEventListener('mousemove', handleActivity);
        
    }

    initRequestConfig() {
        // Set default header
        axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

        // Request interceptors
        axios.interceptors.request.use((config) => {
            if (Auth.isAuthenticated()) {
                config.headers['Authorization'] = `Bearer ${Auth.getToken()}`;
            }

            // If json header is set
            if (
                config.headers['Content-Type'] !== 'application/json;charset=utf-8' 
                && config.headers['Content-Type'] !== 'multipart/form-data'
                && "string" !== typeof config.data
            ) {
                // Convert json data to FormData
                config.data = qs.stringify(config.data);
            }

            // Set cancel request executor
            config.cancelToken = new axios.CancelToken(function executor(cancel) {
                // An executor function receives a cancel function as a parameter
                Http.cancel = cancel;
            });

            return config;
        }, function (error) {
            // If it's dev log the response
            if (process.env.NODE_ENV === 'development') {
                console.error(error.response);
            }
            return Promise.reject(error);
        });

        // Response interceptor
        axios.interceptors.response.use((response) => {
            if (response && response.data) {
                // Status must lead with a 2 which are the right HTTP code status
                if (!response.data.status || ('' + response.data.status)[0] !== '2') {
                    throw new Error('Invalid API status return format');
                }
    
                const PAYLOAD = response.data.payload;
    
                if (response.data.extra) {
                    return {
                        payload: PAYLOAD,
                        extra: response.data.extra
                    }
                }
                // Keep only the api payload return
                return PAYLOAD;
            }

            return response;
        }, (error) => {
            let err;

            if (error && error.response && error.response.data) {
                err = error.response.data;
            } else if (error && error.response) {
                err = error.response
            } else {
                err = error;
            }

            // If 401 redirect login
            if (err.status === 401 && Auth.isAuthenticated()) {
                alert("Votre session a expiré, Vous allez être déconnecté");
                localStorage.removeItem('user');
                // window.location.replace(window.location.origin + path('login'));
            }

            // if (typeof err.payload.exception.message !== 'undefined') {
            //     console.error(err.payload.exception.message);
            // }

            console.log('err', err);
            return Promise.reject(err);
        });
    }

    render() {
        return this.state.appLoading ? (<Loader/>) : (<Routing/>);
    }
}

export default App;
