import React from "react";
import ApiAccount from '../../Services/ApiAccount';
import {Link, Redirect} from "react-router-dom";
import {path} from "../../Config/routes";
import Auth from "../../Security/Auth";
import Master from "../../Components/Layout/Master";
import Button from "../../Components/Form/Button/Button";
import {loadReCaptcha} from 'react-recaptcha-v3'
import {ReCaptcha} from 'react-recaptcha-v3'
import {Http} from "../../Utils/Api";
import {GOOGLE_API} from "../../Config/google";
import ApiGoogle from "../../Services/ApiGoogle";
import * as qs from "qs";
import "./Login.scss";
import InputText from "../../Components/Form/InputText/InputText";


export default class Recovery extends React.Component {

    state = {
        password: '',
        password_c: '',
        error_password: '',
        error_password_c: '',
        error: '',
        isRobot: false,
        success: false
    };

    componentDidMount() {
        const PARAMS = qs.parse(this.props.location.search, {ignoreQueryPrefix: true});

        this.id = PARAMS.id;
        this.token = PARAMS.token;

        // Load reCaptcha v3
        loadReCaptcha(GOOGLE_API.SITE_KEY);
    }

    componentWillUnmount() {
        Http.cancel();
    }

    verifyCaptcha = (token) => {
        ApiGoogle.verifyReCaptcha(token).then((payload) => {
            if (payload.score >= 0.5) return;
            this.setState({isRobot: true});
        });
    };

    resetError() {
        this.setState({
            error: '',
            error_password: '',
            error_password_c: ''
        })
    }


    postNewPassword(event) {
        event.preventDefault();
        this.resetError();

        let hasError;

        if (this.state.password === '') {
            this.setState({error_password: 'Le champ "Mot de passe" est requis.'});
            hasError = true;
        }
        if (this.state.password_c === '') {
            this.setState({error_password_c: 'Le champ "Confirmation du mot de passe" est requis.'});
            hasError = true;
        } else if (this.state.password !== this.state.password_c) {
            this.setState({error: 'Le mot de passe ne sont pas identique'});
            hasError = true;
        }

        if (hasError) return;

        this.setState({loading: true});

        ApiAccount.validNewPassword(this.id, this.token, this.state.password).then(() => {
            this.setState({success: true})
        }).catch((response) => {
            this.setState({loading: false});
            let msg = '';

            if (response.status === 404) {
                msg = "Ce compte n'existe pas";

            } else if (response.payload && response.payload.messages) {
                msg = response.payload.messages;
            } else {
                msg = 'Une erreur est survenue.';
            }

            this.setState({error: msg});
        });
    }

    render() {
        if (Auth.isAuthenticated()) {
            return (
                <Redirect to={path('home')}/>
            )
        } else {

            const CONTENT = <div style={{width: '100%'}}>
                <h1>{this.state.success ? 'Mot de passe validé' : 'Réinitialisation du mot de passe'}</h1>
                <p>{this.state.success ? 'Votre nouveau mot de passe a bien été modifié' : 'Veuillez saisir votre nouveau mot de passe'}</p>

                {this.state.success ? <Link to={path('login')} className={'underline'} style={{color: 'white'}}>
                    Connectez-vous
                </Link> : <>
                    <form onSubmit={(e) => this.postNewPassword(e)}>
                        {/* Password */}
                        <InputText id={'password'}
                                   label={'Mot de passe*'}
                                   type={'password'}
                                   autoComplete={'new-password'}
                                   errorMsg={this.state.error_password}
                                   styleLabel={{marginBottom: 5}}
                                   value={this.state.password}
                                   onChange={(e) => this.setState({password: e.target.value})}/>

                        {/* Info */}
                        <div className="alertPrimary" role="alert" style={this.state.error_password ? {marginTop: 22} : null}>
                            Le mot de passe doit avoir entre 8 et 20 caractères incluant une majuscule, une minuscule, un chiffre et un caractère spécial (@$!%*?&).
                        </div>

                        {/* Password confirm */}
                        <InputText id={'password_c'}
                                   label={'Confirmation du mot de passe*'}
                                   type={'password'}
                                   autoComplete={'new-password'}
                                   errorMsg={this.state.error_password_c}
                                   value={this.state.password_c}
                                   onChange={(e) => this.setState({password_c: e.target.value})}/>


                        {/* Error message */}
                        {this.state.error !== '' && <div className={'errorMessage'}>{this.state.error}</div>}

                        {/* Submit */}
                        <Button type={"submit"} loading={this.state.loading}>Envoyer</Button>
                    </form>

                    <Link to={path('login')} className={'underline'} style={{color: 'white'}}>
                        Vous avez des accès à l’extranet ? Connectez-vous
                    </Link></>}
            </div>;

            return (
                <Master showMenu={false} id={"login"} footer={false} {...this.props}>
                    <div className="overlay"/>
                    <div className={'content'}>
                        <ReCaptcha
                            sitekey={GOOGLE_API.SITE_KEY}
                            action='login'
                            verifyCallback={this.verifyCaptcha}
                        />
                        {/* Content */}
                        {this.state.isRobot ? <div className={'centerText'} style={{width: '100%'}}>Il semblerait que vous êtes un robot :(</div> : CONTENT}
                    </div>
                    <footer>
                        <nav>
                            <ul>
                                <li>
                                    <a href={'https://www.autoroutes.fr/index.htm'} target={'_blank'}>Autoroutes.fr</a> :
                                </li>
                            </ul>
                        </nav>
                    </footer>
                </Master>
            );
        }
    }
}
