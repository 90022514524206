import React from 'react';
import PropTypes from "prop-types";
import "./InputTextLight.scss";

export default class InputTextLight extends React.Component {

    static propTypes = {
        type: PropTypes.string,
        label: PropTypes.string,
        placeholder: PropTypes.string,
        id: PropTypes.string,
        value: PropTypes.string,
        name: PropTypes.string,
        tabindex: PropTypes.string,
        autoComplete: PropTypes.string,
        min: PropTypes.number,
        max: PropTypes.number,
        errorMsg: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
        onChange: PropTypes.func
    };

    static defaultProps = {
        type: 'text',
        onChange: () => {}
    };

    render() {
        return (
            <label htmlFor={this.props.id} className={'inputTextLight'}>
                {this.props.label && <span className={'label'}>{this.props.label}</span>}
                <input type={this.props.type}
                       id={this.props.id}
                       className={this.props.errorMsg ? ' error' : null}
                       name={this.props.name ? this.props.name : this.props.id}
                       value={this.props.value || ''}
                       placeholder={this.props.placeholder}
                       autoComplete={this.props.autoComplete}
                       tabIndex={this.props.tabindex}
                       onChange={(e) => this.props.onChange(e)}
                       min={this.props.min}
                       max={this.props.max}
                />
                <span className={'line'}/>
                {this.props.errorMsg && <span className={'errorMsg'}>{this.props.errorMsg}</span>}
            </label>
        )
    }
}