import PropTypes from "prop-types";
import {String} from "../../Utils/String";
import React from "react";
import TitleLeftIcon from "../Text/TitleLeftIcon";

export default class Block extends React.Component {

    static propTypes = {
        color: PropTypes.oneOf(['white', 'green', 'blue', 'dark']),
        className: PropTypes.string,
        title: PropTypes.string.isRequired,
        titleColor: PropTypes.string,
        iconTitleColor: PropTypes.string,
        bigTitleColor: PropTypes.oneOf(['white', 'whiteBold', 'black'])
    };

    static defaultProps = {
        color: 'white',
        bigTitleColor: 'white'
    };

    className() {
        if (this.props.className !== undefined && this.props.className !== '') {
            return ' ' + this.props.className;
        } else {
            return '';
        }
    }

    render() {
        return (
            <div className={`block block-${this.props.color}${this.className()}`}>
                <span className={`big-title ${this.props.bigTitleColor}`}>{String.capitalize(this.props.title)}</span>
                <TitleLeftIcon color={this.props.titleColor} iconColor={this.props.iconTitleColor}>{this.props.title}</TitleLeftIcon>
                {this.props.children}
            </div>
        );
    }
}