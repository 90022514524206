import React from 'react';
import PropTypes from "prop-types";
import Button from "../Form/Button/Button";
import "./Filter.scss";
import DateRangePicker from "../DateRangePicker/DateRangePicker";
import {getType, getMimeType} from "../../Utils/DocType";
import ApiDocument from "../../Services/ApiDocument";
import Auth from "../../Security/Auth";
import ApiWorkGroup from "../../Services/ApiWorkGroup";
import MAP_TYPES, {DOCUMENT_FILTER_TYPE_MAP} from "../../Utils/Filters/MapTypes";
import VEHICLE_TYPES, {DOCUMENT_FILTER_TYPE_VEHICLE} from "../../Utils/Filters/VehicleTypes";
import {Http} from "../../Utils/Api";
import {DateHandler} from "../../Utils/DateHandler";

export default class FilterDoc extends React.Component {

    static propTypes = {
        onFilter: PropTypes.func,
        adminMode: PropTypes.bool,
        showType: PropTypes.bool,
        refreshed: PropTypes.bool,
        type: PropTypes.oneOf(['all', 'workgroup', 'category']),
        id: PropTypes.number,
        category: PropTypes.shape({hasspecificfilters: PropTypes.bool}),
        filters: PropTypes.shape({meeting: PropTypes.object, doctype: PropTypes.object}),
    };

    static defaultProps = {
        type: 'all',
        id: 0,
        category: null,
        filters: {meeting: {}, doctype: {}},
        refreshed: false
    };

    state = {
        mobileFilter: false,
        startDate: '',
        endDate: '',
        showDatepicker: false,
        showType: true,
        documentType: [],
        meetings: [],
        extendedFilter: [],
        onFilter: () => {
        }
    };

    constructor(props) {
        super(props);
        this.btnFilters             = [];
        // Suffixes for the special filters since it will generate multi join over the same table on DB, we must suffix alias properly
        this._sepcialFilterSuffixes = {
            [DOCUMENT_FILTER_TYPE_MAP]: '',
            [DOCUMENT_FILTER_TYPE_VEHICLE]: 1,
        };
        // Init all where conditions
        this._where                 = {
            'periodicity': null,
            'visibility': null,
            'doc_type': null,
            [`${DOCUMENT_FILTER_TYPE_MAP.toLowerCase()}_type`]: null,
            [`${DOCUMENT_FILTER_TYPE_VEHICLE.toLowerCase()}_type`]: null,
        };
        this._filter                = '';

        // Refs
        this._visibilityRef      = React.createRef();
        this._docTypeRef         = React.createRef();
        this._mapTypeRef         = React.createRef();
        this._vehicleTypeRef     = React.createRef();
        this._dateRangePickerRef = React.createRef();
        this._dateRange          = React.createRef();
        this._meeting            = React.createRef();
        this._mapPeriod          = React.createRef();

        this._buttons = [this._mapPeriod, this._meeting, this._dateRange, this._visibilityRef, this._docTypeRef, this._mapTypeRef, this._vehicleTypeRef];

        if (this.props.periodicity) {
            this._monthFilter     = this.createMonthFilter();
            this._quarterlyFilter = this.createQuarterlyFilter();
            this._yearFilter      = this.createYearFilter();
        }
    }

    componentDidMount() {
        this.init();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.periodicity) {
            this._monthFilter     = this.createMonthFilter();
            this._quarterlyFilter = this.createQuarterlyFilter();
            this._yearFilter      = this.createYearFilter();
        } else if (prevProps.id !== this.props.id || this.props.refreshed) {
            this.init();
        }
    }

    static getDerivedStateFromProps(props, state) {
        if (props.filters.meeting.id || props.filters.doctype.id) {
            let hasMeeting = !props.filters.meeting.id;

            state.meetings.forEach((meeting) => {
                if (meeting.id === props.filters.meeting.id) {
                    hasMeeting = true;
                }
            });

            if (!hasMeeting) {
                const meeting = props.filters.meeting;
                state.meetings.push({id: meeting.id, title: meeting.description, date: meeting.date})
            }
        }
        return null
    }

    init() {
        if (this.props.type === 'workgroup') {
            this.getMeeting();
        }

        this.getDocumentTypes();
    }

    getMeeting() {
        ApiDocument.getWorkGroupMeeting(this.props.id).then((meeting) => {
            meeting.sort(function(a,b) {
                if     (a['date'].date === b['date'].date) { return 0; }
                return (a['date'].date  <  b['date'].date) ? 1 : -1;
            });
            this.setState({
                meetings: meeting
            })
        });
    }

    getDocumentTypes() {
        const USER           = Object.assign({id: Auth.getUser().id}, Auth.getRole());
        const DOCUMENT_TYPES = this.props.type === 'workgroup' ? ApiWorkGroup.getDocumentTypesByWorkgroup(this.props.id) : ApiDocument.getDocumentTypes(this.props.type, this.props.id, USER);

        DOCUMENT_TYPES.then((types) => {
            this.setState({
                documentType: types
            })
        });
    };

    createMonthFilter() {
        const MONTH_DATE = this.props.periodicity.filter((item) => item.value === MAP_TYPES[0].value);

        let months = [];

        MONTH_DATE.forEach((date) => {
            const PERIODICITY = new Date(date.periodicity ? date.periodicity.date : new Date());
            let month         = PERIODICITY.getMonth() + 1;
            let year          = PERIODICITY.getFullYear();
            const NAME        = DateHandler.getMonthName(month) + ' ' + year;

            if (!months.find((month) => month.name === NAME)) {
                const START_DATE = DateHandler.formatForApi(new Date(`${year}-${month}-01 00:00:00`));

                months.push({
                    start: START_DATE,
                    end: DateHandler.formatForApi(DateHandler.lastDayOfMonth(START_DATE)),
                    name: NAME
                });
            }
        });

        return months;
    }

    createQuarterlyFilter() {
        const QUARTER_DATE = this.props.periodicity.filter((item) => item.value === MAP_TYPES[1].value);

        let quarters = [];

        QUARTER_DATE.forEach((date) => {
            const PERIODICITY = new Date(date.periodicity.date);
            let month         = PERIODICITY.getMonth() + 1;
            let year          = PERIODICITY.getFullYear();
            const QUARTER     = DateHandler.getQuarter(PERIODICITY);
            const NAME        = QUARTER + 'T' + year;

            if (!quarters.find((quarter) => quarter.name === NAME)) {
                const START_DATE = DateHandler.formatForApi(new Date(`${year}-${month}-01 00:00:00`));
                const END_DATE = DateHandler.add(START_DATE, '2', 'M');

                quarters.push({
                    start: START_DATE,
                    end: DateHandler.formatForApi(DateHandler.lastDayOfMonth(END_DATE)),
                    name: NAME
                });
            }
        });

        return quarters;
    }

    createYearFilter() {
        const YEAR_DATE = this.props.periodicity.filter((item) => item.value === MAP_TYPES[2].value);

        let years = [];
        YEAR_DATE.forEach((date) => {
            const PERIODICITY = new Date(date.periodicity.date);
            let year          = PERIODICITY.getFullYear();
            const NAME        = year;

            if (!years.find((quarter) => quarter.name === NAME))
                years.push({
                    start: DateHandler.formatForApi(new Date(`${year}-01-01 00:00:00`)),
                    end: DateHandler.formatForApi(new Date(`${year}-12-31 00:00:00`)),
                    name: NAME
                })
        });

        return years;
    }

    componentWillUnmount() {
        Http.cancel();
    }

    filter(by, e) {
        const TARGET        = e.currentTarget;
        const IS_ACTIVE     = TARGET.classList.contains('active');
        const IS_DECREASING = TARGET.classList.contains('decreasing');

        // remove all other active filter
        for (let i = 0; i < this.btnFilters.length; i++) {
            this.btnFilters[i].classList.remove('active');
            this.btnFilters[i].classList.remove('decreasing');
        }

        TARGET.classList.add('active');
        if (!IS_ACTIVE) {
            TARGET.classList.add('decreasing');
        } else if (IS_ACTIVE && !IS_DECREASING) {
            TARGET.classList.add('decreasing')
        } else {
            TARGET.classList.remove('decreasing')
        }

        this._filter = by;
        this.buildFilter(!(IS_ACTIVE && IS_DECREASING));
    }

    setRef(input) {
        if (input !== undefined && input !== null) {
            this.btnFilters.push(input.reference());
        }
    }

    _handleSubFilterRendering(target, button, isAllValues) {
        const SUB_FILTER = button.nextElementSibling;
        const BUTTONS    = SUB_FILTER.querySelectorAll('button');

        for (let i = 0; i < BUTTONS.length; i++) {
            BUTTONS[i].classList.remove('active')
        }

        if (target && target.hasOwnProperty('classList')) {
            target.classList.add('active');
        }
        if (isAllValues) {
            button.querySelector('span em').innerText = button.getAttribute('title');
        } else {
            button.querySelector('span em').innerText = target.innerText;
        }

        SUB_FILTER.classList.add('close');
        setTimeout(() => {
            SUB_FILTER.classList.remove('close');
        }, 100);
    }

    visibilityFilter(value, e) {
        this._handleSubFilterRendering(e.target, this._visibilityRef.current.btn, value === null);

        // Filter
        if (value === 'all-doc') {
            this._where['visibility'] = null;
        } else if (value === 'public') {
            this._where['visibility'] = '(`archived_at` = `null`)';
        } else if (value === 'archived') {
            this._where['visibility'] = '(`archived_at` not `null`)'
        }

        this.buildFilter(true);
    }

    timeFilter(field = 'published_at') {
        if (this.state.startDate !== '' && this.state.endDate !== '') {
            this._where['periodicity'] = `(\`${field}\`>=\`${this.state.startDate}\` AND \`${field}\`<=\`${this.state.endDate}\` AND \`filters.type\` = \`MAP\`)`;
        } else {
            delete this._where['periodicity'];
        }
        this.buildFilter(true);
    }

    dateChange(e) {
        if (e.startDate && e.endDate) {
            this.setState({
                startDate: e.startDate,
                endDate: e.endDate
            }, () => {
                this._handleSubFilterRendering({innerText: `${DateHandler.getPrettyDate(e.startDate)} au ${DateHandler.getPrettyDate(e.endDate)}`},
                    this._dateRange.current.btn, false);
                this.timeFilter();
            });
        }
    }

    typeFilter(value, e) {
        this._handleSubFilterRendering(e.target, this._docTypeRef.current.btn, value === null);

        if (value === null) {
            this._where['doc_type'] = null;
        } else {
            if (this.props.type === 'workgroup') {
                this._where['doc_type'] = `(\`docType\`=\`${value}\`)`;
            } else {
                const APPLICATION_TYPE = getMimeType(value);

                let where = `(\`files.type\`= \`${APPLICATION_TYPE[0]}\``;
                for (let i = 1; i < APPLICATION_TYPE.length; i++) {
                    where += ` OR \`files.type\` = \`${APPLICATION_TYPE[i]}\``;
                }

                this._where['doc_type'] = `${where})`;

                if (APPLICATION_TYPE.length <= 0) {
                    console.warn(`${value} is not a valid application type`);
                }
            }
        }

        this.buildFilter(true);
    }

    setExtendedFilter(typeMap) {
        let extendedFilter = [];
        if (typeMap === 'MONTHLY') {
            extendedFilter = this._monthFilter
        } else if (typeMap === 'QUARTERLY') {
            extendedFilter = this._quarterlyFilter
        } else if (typeMap === 'ANNUALLY') {
            extendedFilter = this._yearFilter
        }

        if (typeof extendedFilter === 'undefined') {
            extendedFilter = [];
        }

        this.setState({
            extendedFilter: extendedFilter.reverse()
        })
    }

    handleSpecificFilter(filter, e) {
        const FILER_TYPE = filter.type.toLowerCase();
        this._handleSubFilterRendering(e.target, this[`_${FILER_TYPE}TypeRef`].current.btn, filter.value === null);
        let suffix = this._sepcialFilterSuffixes[filter.type];

        if (FILER_TYPE === DOCUMENT_FILTER_TYPE_MAP.toLowerCase()) {
            this.setExtendedFilter(filter.value)
        }

        if (filter.value === null) {
            this._where[`${FILER_TYPE}_type`] = null;
        } else {
            this._where[`${FILER_TYPE}_type`] = `(\`filters${suffix}.value\` = \`${filter.value}\` and \`filters${suffix}.type\` = \`${filter.type}\`)`;
        }

        if (this._mapPeriod.current && (this.state.startDate === '' || filter.type === DOCUMENT_FILTER_TYPE_MAP)) {
            this._mapPeriod.current.btn.querySelector('span em').innerText = this._mapPeriod.current.btn.getAttribute('title');
            this._where['periodicity'] = null;
        }

        this.buildFilter(true);
    }

    handleExtendedFilter(periodicity, e) {
        this._handleSubFilterRendering(e.target, this._mapPeriod.current.btn, periodicity.name === null);

        this.setState({
            startDate: periodicity.start,
            endDate: periodicity.end
        }, () => {
            this.timeFilter('filters.periodicity')
        })
    }

    buildFilter(increasing) {
        // Close all submenu
        this._buttons.forEach((btn) => {
            if (btn.current !== null) {
                btn.current.btn.parentElement.classList.remove('active');
            }
        });
        let where = Object.values(this._where).filter(where => (where !== null)).join(' and ');

        this.props.onFilter({
            where: where,
            filter: this._filter,
            increasing: increasing,
            minDate: this.state.startDate,
            maxDate: this.state.endDate
        });
    }

    meetingFilter(value, event) {
        if (value === null && this._where.meeting) {
            delete this._where.meeting
        } else if (value) {
            this._where.meeting = `\`meeting\`=${value}`
        }

        this._handleSubFilterRendering(event.target, this._meeting.current.btn, value === null);

        this.buildFilter(true);
    }

    openSubMenu(elem) {
        this._buttons.forEach((btn) => {
           if (btn !== elem && btn.current !== null) {
               btn.current.btn.parentElement.classList.remove('active');
           }
        });
        elem.current.btn.parentElement.classList.toggle('active');
    }

    render() {

        return (
            <div className="filter">
                <div className={`container ${this.props.category && this.props.category.hasspecificfilters ? 'big' : ''}`}>
                    <ul className={this.state.mobileFilter ? 'mobile' : null}>
                        <li>
                            <div>TRIER PAR&thinsp;:</div>
                            <button type={'button'} className={'toggleMobile'}
                                    onClick={() => this.setState((state) => ({mobileFilter: !state.mobileFilter}))}>
                                <span>Filtre</span>
                            </button>
                        </li>
                        <li>
                            <Button type="button" onClick={(e) => this.filter('views', e)}
                                    ref={(input) => this.setRef(input)}>
                                Les plus consultés
                            </Button>
                        </li>
                        <li>
                            <Button type="button" onClick={(e) => this.filter('date', e)}
                                    ref={(input) => this.setRef(input)}>
                                Les plus récents
                            </Button>
                        </li>
                        <li>
                            <Button type="button" title={'Période'} ref={this._dateRange} onClick={() => this.openSubMenu(this._dateRange)}>
                                Période
                            </Button>
                            <ul className={'subFilter range'}>
                                <li>
                                    <DateRangePicker
                                        ref={this._dateRangePickerRef}
                                        startDate={this.state.startDate}
                                        endDate={this.state.endDate}
                                        onChange={(e) => this.dateChange(e)}/>
                                </li>
                                <li className={'resetPeriodicityFilter'}>
                                    <button type="button" onClick={() => {
                                        this.setState({
                                            startDate: null,
                                            endDate: null
                                        }, () => {
                                            this._handleSubFilterRendering(null, this._dateRange.current.btn, true);
                                            this._where['periodicity'] = null;
                                            this._dateRangePickerRef.current.setState({startDate: null, endDate: null});
                                            this.buildFilter(true);
                                        });
                                    }
                                    }>
                                        Retirer le filtre
                                    </button>
                                </li>
                            </ul>
                        </li>
                        {!this.props.category &&
                        <>
                            <li>
                                <Button type="button" ref={this._meeting} onClick={() => this.openSubMenu(this._meeting)}
                                        title={'Date de réunion'}>
                                    Date de réunion
                                </Button>
                                <ul className={'subFilter'}>
                                    <li>
                                        <button type={'button'} onClick={(e) => this.meetingFilter(null, e)}>
                                            Toutes les réunions
                                        </button>
                                    </li>
                                    {this.state.meetings.map((meeting, key) => {

                                        if (meeting.title === null) {

                                            return (
                                                <li key={key}>
                                                <button type={'button'}
                                                        onClick={(e) => this.meetingFilter(meeting.id, e)}>
                                                    {DateHandler.getPrettyDate(meeting.date.date, '[Le ] DD/MM/YYYY')}
                                                </button>
                                            </li>
                                            )

                                        }

                                        return (
                                            <li key={key}>
                                                <button type={'button'}
                                                        onClick={(e) => this.meetingFilter(meeting.id, e)}>
                                                    {DateHandler.getPrettyDate(meeting.date.date, '[Le ] DD/MM/YYYY')}
                                                    <br/>
                                                    {meeting.title}
                                                </button>
                                            </li>
                                        )
                                    })}
                                </ul>
                            </li>
                        </>}
                        {this.props.adminMode
                            && <li>
                                <Button className={'allDocFilter'} type="button" onClick={() => this.openSubMenu(this._visibilityRef)}
                                        ref={this._visibilityRef}>Tous les
                                    documents</Button>
                                <ul className={'subFilter'}>
                                    <li>
                                        <button type={'button'}
                                                onClick={(e) => this.visibilityFilter('all-doc', e)}>Tous les
                                            documents
                                        </button>
                                    </li>
                                    <li>
                                        <button type={'button'}
                                                onClick={(e) => this.visibilityFilter('public', e)}>Documents
                                            publiés
                                        </button>
                                    </li>
                                    <li>
                                        <button type={'button'}
                                                onClick={(e) => this.visibilityFilter('archived', e)}>Documents
                                            archivés
                                        </button>
                                    </li>
                                </ul>
                            </li>}
                                <li>
                                    <Button type="button" onClick={(e) => this.filter('name', e)}
                                            ref={(input) => this.setRef(input)}>
                                        Ordre alphabétique
                                    </Button>
                                </li>
                                {this.props.showType && <li>
                                    <Button type="button" ref={this._docTypeRef} onClick={() => this.openSubMenu(this._docTypeRef)}
                                            title={'Type de document'}>
                                        Type de document
                                    </Button>
                                    <ul className={'subFilter'}>
                                        <li>
                                            <button type={'button'} onClick={(e) => this.typeFilter(null, e)}>
                                                Tous les types de document
                                            </button>
                                        </li>
                                        {this.state.documentType.map((type, key) => {
                                            const TYPE = this.props.type === 'workgroup' ? type.name : getType(type).toUpperCase();

                                            return (
                                                <li key={key}>
                                                    <button type={'button'}
                                                            onClick={(e) => this.typeFilter(this.props.type === 'workgroup' ? type.id : TYPE, e)}>
                                                        {TYPE}
                                                    </button>
                                                </li>
                                            )
                                        })}
                                    </ul>
                                </li>
                                }
                        {/* TODO - Make things more generic */}
                        {this.props.category && this.props.category.hasspecificfilters &&
                        <>
                            {/* Filter by MAP types */}
                            <li>
                                <Button type="button" ref={this._mapTypeRef} title={'Périodicité'} onClick={() => this.openSubMenu(this._mapTypeRef)}>
                                    Périodicité
                                </Button>
                                <ul className={'subFilter'}>
                                    <li>
                                        <button type={'button'} onClick={(e) => this.handleSpecificFilter({
                                            value: null,
                                            type: DOCUMENT_FILTER_TYPE_MAP
                                        }, e)}>
                                            Périodicité
                                        </button>
                                    </li>
                                    {MAP_TYPES.map((mapType, key) => {
                                        return (
                                            <li key={key}>
                                                <button type={'button'}
                                                        onClick={(e) => this.handleSpecificFilter(mapType, e)}>
                                                    {mapType.name}
                                                </button>
                                            </li>
                                        )
                                    })}
                                </ul>
                            </li>
                            {this.state.extendedFilter.length > 0 &&
                            <>
                                {/* Filter by MAP types */}
                                <li>
                                    <Button type="button" title={'Période de carte'} ref={this._mapPeriod} onClick={() => this.openSubMenu(this._mapPeriod)}>
                                        Période de carte
                                    </Button>
                                    <ul className={'subFilter extendedFilter'}>
                                        <li>
                                            <button type={'button'} onClick={(e) => this.handleExtendedFilter({
                                                start: '',
                                                end: ''
                                            }, e)}>
                                                Période de carte
                                            </button>
                                        </li>
                                        {this.state.extendedFilter.map((periodicity, key) => {
                                            return (
                                                <li key={key}>
                                                    <button type={'button'}
                                                            onClick={(e) => this.handleExtendedFilter(periodicity, e)}>
                                                        {periodicity.name}
                                                    </button>
                                                </li>
                                            )
                                        })}
                                    </ul>
                                </li>
                            </>
                            }
                            {/* Filter by VEHICLE types */}
                            <li>
                                <Button type="button" ref={this._vehicleTypeRef} onClick={() => this.openSubMenu(this._vehicleTypeRef)}
                                        title={'Type de véhicule'}>
                                    Type de véhicule
                                </Button>
                                <ul className={'subFilter'}>
                                    <li>
                                        <button type={'button'} onClick={(e) => this.handleSpecificFilter({
                                            value: null,
                                            type: DOCUMENT_FILTER_TYPE_VEHICLE
                                        }, e)}>
                                            Tous les types de véhicules
                                        </button>
                                    </li>
                                    {VEHICLE_TYPES.map((vehicleType, key) => {
                                        return (
                                            <li key={key}>
                                                <button type={'button'}
                                                        onClick={(e) => this.handleSpecificFilter(vehicleType, e)}>
                                                    {vehicleType.name}
                                                </button>
                                            </li>
                                        )
                                    })}
                                </ul>
                            </li>
                        </>
                        }
                    </ul>
                </div>
            </div>
        )
    }
}
