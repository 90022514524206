import React from "react";
import PropTypes from "prop-types";
import ApiDocument from '../../Services/ApiDocument';
import Block from "./Block";
import File from "../File/File";
import Loader from "../Loader/Loader";
import {path} from "../../Config/routes";
import {Link} from "react-router-dom";


export default class Document extends React.Component {

    static propTypes = {
        onLoaded: PropTypes.func
    };

    static defaultProps = {
        onLoaded: () => {}
    };

    state = {
        indexDoc: 1,
        loading: true,
        documents: []
    };

    constructor(props) {
        super(props);
        this._isMounted = false;
    }

    componentDidMount() {
        this._isMounted = true;

        ApiDocument.getDocuments(1, 5, {}).then((documents) => {
            if (!this._isMounted) return;
            this.setState({
                documents: documents.payload.filter((doc) => doc.category && doc.category.thematic),
                loading: false
            });

            this.props.onLoaded(true);
        }).catch((err) => {
            if (!this._isMounted) return;
            if (err.status && err.status === 500) {
                this.props.onLoaded(false);
            }
        });
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    render() {
        let listDocs = '';
        if (this.state.documents.length > 0) {
            listDocs = <>
                {/* List */}
                <div className="listDoc">
                    {this.state.documents.map((result, i) => {
                        if (result.category && result.category.thematic) {
                            return <li key = {i}><File
                                file = {result}
                                color = {result.category.thematic.color}
                                className = {'list'}
                                description = {false}/>
                        </li>
                        } else {
                            return <div key={i} />
                        }
                    })}
                </div>
                {/* See all docs*/}
                <div id={'more'}>
                    <Link to={path('documentationList')} className={'underline'}>Voir tous les documents</Link>
                </div>
            </>
        } else {
            listDocs = <div className={"noDocuments"}>Aucun document disponible</div>
        }

        return (
            <Block title='Documentation' color="white" className="document-in-front" titleColor={'#AAAAAA'}
                   iconTitleColor={'#0449A7'} bigTitleColor={'black'}>
                {this.state.loading
                    ? <div className={'wrapLoader'}><Loader type={"inline"} backgroundColor={'transparent'}/></div>
                    : listDocs
                }
            </Block>
        );
    }
}
