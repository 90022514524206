import axios from "axios";
import Api, {PATH} from "../Utils/Api";

export default {

    /**
     * Get all member roles available
     * This won't call the API really... Since it's an ENUM on database. We assume that you will change it here if one
     * more is added, for performances purpose
     *
     * @returns {{MEMBER_ROLE_ANIMATOR: string, MEMBER_ROLE_MEMBER: string, MEMBER_ROLE_ADMINISTRATOR: string}}
     */
    getAllMemberTypes: () => {
        const MEMBER_TYPES                      = {};
        MEMBER_TYPES[MEMBER_TYPE_ADMINISTRATOR] = "Gestionnaire";
        MEMBER_TYPES[MEMBER_TYPE_ANIMATOR]      = "Animateur";
        MEMBER_TYPES[MEMBER_TYPE_MEMBER]        = "Membre";

        return MEMBER_TYPES;
    },

    /**
     * Add a new relation between user and workgroup
     *
     * @param {number} userId
     * @param {number} workGroupId
     * @param {number} memberType
     * @param {boolean} isContributor
     * @param {array} nestedEntities
     * @returns {AxiosPromise<any>}
     */
    add: (userId, workGroupId, memberType = MEMBER_TYPE_MEMBER, isContributor = false, nestedEntities = ['user.profile.company']) => {
        return axios.post(`${PATH}/workGroup/workGroupUser?api[nested_entities]=${Api.buildNestedEntity(nestedEntities)}`, {
            user: userId,
            workgroup: workGroupId,
            member_type: memberType,
            is_contributor: isContributor
        });
    },

    /**
     * Remove user to workgroup
     * @param id
     * @returns {AxiosPromise}
     */
    remove: (id) => {
        return axios.delete(`${PATH}/workGroup/workGroupUser/${id}`);
    },

    /**
     * Update user member type
     * @param id
     * @param memberType
     * @returns {AxiosPromise}
     */
    updateMemberType: (id, memberType) => {
        return axios.patch(`${PATH}/workgroup/workGroupUser/${id}`, {
            membertype: memberType
        });
    },

    getAllOfUser(userId) {
        return axios.get(`${PATH}/workgroup/workGroupUser?api[nested_entities]=workgroups&api[where]=where \`user\` = \`${userId}\``);
    },
}


export const MEMBER_TYPE_ADMINISTRATOR = 1;
export const MEMBER_TYPE_ANIMATOR      = 2;
export const MEMBER_TYPE_MEMBER        = 3;

