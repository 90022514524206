import {LOCATION} from "../Config/location";
import axios from "axios";

export const PATH    = `${LOCATION.api}/api/${LOCATION.apiVersion}`;
export const PATH_UX = `${LOCATION.api}/ux/${LOCATION.apiVersion}`;

export let Http = {
    /**
     * Cancel all request This method is set in App.js with axios executor (axios.CancelToken)
     */
    cancel: function () {
    },

    hello: () => {
        return axios.get(`${PATH}/hello`);
    },
};

export class QueryBuilder {
    _sort            = null;
    _order           = 'asc';
    _where           = null;
    _page            = null;
    _limit           = null;
    _resources       = null;
    // only use for the default api
    _nested_entities = [];
    // Only use for the search
    _method          = 'full-text';
    _operator        = 'and';
    _inTitle         = 1;
    _type            = [];
    _tags            = [];
    _dateFrom        = '';
    _dateTo          = '';

    /**
     *
     * @param limit
     * @returns {QueryBuilder}
     */
    setLimit(limit) {
        this._limit     = limit;
        this._resources = limit;

        return this;
    }

    /**
     *
     * @param resources
     * @returns {QueryBuilder}
     */
    setResources(resources) {
        this._limit     = resources;
        this._resources = resources;

        return this;
    }

    getLimit() {
        return this._limit;
    }

    /**
     *
     * @param way
     * @returns {QueryBuilder}
     */
    setOrder(way) {
        this._order = way;

        return this;
    }

    /**
     *
     * @returns {QueryBuilder}
     */
    setOrderDesc() {
        this._order = 'desc';

        return this;
    }

    /**
     *
     * @returns {QueryBuilder}
     */
    setOrderAsc() {
        this._order = 'asc';

        return this;
    }

    /**
     *
     * @param where
     * @returns {QueryBuilder}
     */
    setWhere(where) {
        this._where = where;

        return this;
    }

    /**
     *
     * @returns {QueryBuilder}
     */
    resetWhere() {
        this._where = null;

        return this;
    }

    /**
     *
     * @param page
     * @returns {QueryBuilder}
     */
    setPage(page) {
        this._page = page;

        return this;
    }

    /**
     *
     * @param field
     * @returns {QueryBuilder}
     */
    setSort(field) {
        this._sort = field;

        return this;
    }

    /**
     *
     * @param nestedEntities
     * @returns {QueryBuilder}
     */
    setNestedEntities(nestedEntities) {
        this._nested_entities = nestedEntities;

        return this;
    }

    setMethod(method) {
        this._method = method;

        return this;
    }

    setOperator(operator) {
        this._operator = operator;

        return this;
    }

    setInTitle(inTitle) {
        this._inTitle = (inTitle) ? 0 : 1;

        return this;
    }

    setType(type) {
        this._type = type;

        return this;
    }

    setTags(tags) {
        this._tags = tags;

        return this;
    }

    setDateFrom(date) {
        this._dateFrom = date;
        return this;
    }

    setDateTo(date) {
        this._dateTo = date;
        return this;
    }

    build() {
        const PARAMS = {
            "api[sort]": this._sort,
            "api[order]": this._order,
            "api[limit]": this._limit,
            "api[resources]": this._resources,
            "api[nested_entities]": (typeof this._nested_entities === 'string') ? this._nested_entities : this._nested_entities.join(','),
            "api[page]": this._page,
        };

        if (this._where !== null) {
            PARAMS['api[where]'] = `where ${this._where}`;
        }

        return PARAMS
    }

    buildSearch() {
        const PARAMS = {
            'search[method]': this._method,
            'search[everywhere]': this._inTitle,
            'search[sort]': this._sort,
            'search[order]': this._order,
            'search[resources]': this._resources,
            'search[operator]': this._operator,
            'search[page]': this._page,
        };

        if (this._type.length > 0) {
            PARAMS['search[types]'] = (typeof this._type === 'string') ? this._type : this._type.join(', ');
        }

        if (this._tags.length > 0) {
            PARAMS['search[tags]'] = (typeof this._tags === 'string') ? this._tags : this._tags.join(',');
        }

        if (this._dateFrom) {
            PARAMS['search[min_date]'] = this._dateFrom;
        }

        if (this._dateTo) {
            PARAMS['search[max_date]'] = this._dateTo;
        }

        return PARAMS;
    }
}

export default {

    /**
     * Set nested entities
     * @param entities string|[]
     * @returns string
     */
    buildNestedEntity: (entities) => {
        return (typeof entities === 'string') ? entities : entities.join(',');
    }


}