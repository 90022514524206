import React from 'react';
import {BrowserRouter as Router, Redirect, Route, Switch} from 'react-router-dom';
import {path, routes} from '../../Config/routes';
import Auth from '../../Security/Auth';

export default class Routing extends React.Component {

    render() {
        return (
            <Router>
                <Switch>
                    {routes.map((route, i) => (
                        <Route exact={route.exact || false} key={i} path={route.path}
                               render={(props) => {
                                   // User try to go to an admin page and is not an admin
                                   if (route.isAdmin && Auth.isAuthenticated() && !Auth.isAdminAtLeast()) {
                                       return <Redirect
                                           to={{
                                               pathname: path('home'),
                                               state: {from: props.location}
                                           }}
                                       />
                                   }
                                   // User is log or route is public
                                   return Auth.isAuthenticated() || route.public === true ?
                                       <route.component {...props}/> :
                                       <Redirect
                                           to={{
                                               pathname: path('login'),
                                               state: {from: props.location}
                                           }}
                                       />
                               }}
                        />
                    ))}
                </Switch>
            </Router>
        );
    }
}