import React from "react";
import Roles from "../../Security/Roles";
import Auth from "../../Security/Auth";
import Moment from "moment";
import {Link} from "react-router-dom";
import {path} from "../../Config/routes";

// TODO CALCULATE LEFT VALUE
export default class EventItem extends React.Component {

    state = {
        class: 'small',
        style: {
            zIndex: 5,
            backgroundColor: this.props.event.item.thematic.color,
            width: '100%',
            left: 0
        }
    };

    constructor(props) {
        super(props);

        this.zMax = 8;
        this.zMin = 5;
    }

    componentDidMount() {
        this.defaultDimension();
    }

    defaultDimension() {
        const SIZE = 100 / (this.props.event.item.nbEvents || 1);

        this.setState({
            style: {
                ...this.state.style,
                zIndex: this.zMin,
                width: SIZE + '%',
                left: SIZE * (this.props.event.item.position || 0) + '%'
            }
        })
    }

    lowerZindex() {
        if (this.state.style.zIndex === this.zMax) {
            this.defaultDimension();
        }

    }

    raiseZindex() {
        if (this.state.style.zIndex === this.zMin) {
            return this.setState({
                style: {
                    ...this.state.style,
                    zIndex: this.zMax,
                    width: '100%',
                    left: 0
                }
            })
        }

    }

    render() {
        // By default only root can edit/add event
        let canEdit = this.props.role.rank >= Roles.ROLE_ROOT.rank;
        // But thematic administrators are also able to do it for their handled thematics
        if (this.props.role.uid === Roles.ROLE_ADMINISTRATOR.uid && Auth.isThematicAdministrator(this.props.event.item.thematic.id)) {
            canEdit = true;
        }

        let duration  = Moment(this.props.event.item.endDateTime).diff(Moment(this.props.event.item.startDateTime), 'minute') / 60;
        let className = duration <= 1 ? ' small' : '';
        className += duration >= 0.5 && duration < 1 ? ' md' : '';
        className += duration < 0.5 ? ' xs' : '';

        const STYLE = Object.assign({}, this.state.style, {
            height: this.props.rowsPerHour * duration * this.props.cellHeight + 'px'
        });

        const SIZE = parseInt(this.state.style.width);
        const SHOW_DETAIL = SIZE === 100 || (SIZE === 50 && this.props.numberOfDays === 1);

        return (
            <div className={'eventItem' + className}
                 style={STYLE}
                 onMouseEnter={() => this.raiseZindex()}
                 onMouseLeave={() => this.lowerZindex()}>
                {SIZE > 25 && <div className={'eventItemStartDateTime'}>
                    {Moment(this.props.event.item.startDateTime).format('HH:mm')}
                </div>}
                {SIZE >= 50 && <>
                    <div className={'eventItemWorkTitle'} title={this.props.event.item.thematic.name}>
                        {this.props.event.item.thematic.name}
                    </div>
                    <div className={'eventItemTitle'}>
                        {this.props.event.item.name}
                    </div>
                    {canEdit && <div className={'controlEventItem'}>
                        <button className={'editEventItem'} onClick={() => this.props.event.edit(this.props.event.item)}>
                            <i/></button>
                        <button className={'removeEventItem'} onClick={() => this.props.event.remove(this.props.event.item)}>
                            <i/>
                        </button>
                    </div>
                    }
                    {SHOW_DETAIL &&
                    <div className={'lastEventItem'}>
                        <Link to={path('agendaDetail', {id: this.props.event.item._id})} className={'readMoreEvent'}>En
                            savoir plus</Link>
                        <button className={'addEventToPersonalCalendar'} onClick={() => this.props.onAddPersonalEvent(this.props.event.item)}>
                            <img src="/assets/images/icon/agenda/calendar-more.svg" alt="Ajouter l'évenement à votre agenda personel"/>
                        </button>
                    </div>
                    }
                </>}
            </div>
        )
    }
}