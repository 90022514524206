import React from 'react';
import PropTypes from "prop-types";
import "./InputRadioInline.scss";

export default class InputRadioInline extends React.Component {

    static propTypes = {
        name: PropTypes.string,
        data: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.string,
            label: PropTypes.string,
            value: PropTypes.string,
            checked: PropTypes.bool,
            defaultChecked: PropTypes.bool
        })).isRequired,
        onChange: PropTypes.func
    };

    render() {
        return (
            <div className={'inputRadioInline'}>
                {this.props.data.map((data, i) => (
                    <div className="inputRadioGroup" key={i}>
                        <input id={data.id} type="radio" name={this.props.name} value={data.value} checked={data.checked} defaultChecked={data.defaultChecked} onChange={(e) => {
                            if (this.props.onChange) {
                                return this.props.onChange(e.target.value)
                            }
                        }}/>
                        <label htmlFor={data.id}>
                            <span>{data.label}</span>
                        </label>
                    </div>
                ))}
            </div>
        )
    }
}