import React from 'react';
import PropTypes from 'prop-types';
import './Modal.scss';
import Loader from "../Loader/Loader";


export default class Modal extends React.Component {

    static propTypes = {
        onClose: PropTypes.func,
        autoWidth: PropTypes.bool,
        autoHeight: PropTypes.bool,
        cancelLabel: PropTypes.string,
        saveLabel: PropTypes.string,
        title: PropTypes.string,
        onCancel: PropTypes.func,
        onValidate: PropTypes.func,
        loading: PropTypes.bool,
    };

    static defaultProps = {
        autoWidth: false,
        autoHeight: false,
        cancelLabel: "Annuler",
        saveLabel: "Sauvegarder",
        title: null,
        onCancel: null,
        onValidate: null,
        loading: false,
    };

    componentDidMount() {
        document.body.style.overflow = 'hidden';
    }

    componentWillUnmount() {
        document.body.style.overflow = '';
    }

    handleClose(e) {
        if (e.target.className === 'modalOverlay' || e.currentTarget.id === 'btnCloseModal') {
            this.props.onClose();
        }
    }

    render() {
        let className = 'modalOverlay';
        className += (this.props.onValidate || this.props.onCancel) ? ' ownFooter' : '';
        className += (this.props.title) ? ' ownHeader' : '';

        return (
            <div className={className} onMouseDown={(e) => this.handleClose(e)}>
                <div
                    className={`modalBox${this.props.autoHeight ? ' autoHeight' : ''}${this.props.autoWidth ? ' autoWidth' : ''}`}>
                    <div className={"modalHeader"}>
                        {this.props.title &&
                        <h3 className={'titleModal centerText'}>{this.props.title}</h3>
                        }
                        <button id={'btnCloseModal'} onClick={(e) => this.handleClose(e)}>
                            <img src="/assets/images/icon/close-blue.svg" alt="Fermer la fenêtre"/>
                        </button>
                    </div>
                    <div className="modalContent">
                        {this.props.children}
                    </div>
                    {(this.props.onValidate || this.props.onCancel) &&
                    <div className={"modalFooter"}>
                        <div className={'buttonModal'}>
                            {this.props.onCancel &&
                            <button type={'button'} className={'cancel'}
                                    onClick={this.props.onCancel}>
                                {this.props.cancelLabel}
                            </button>
                            }
                            {this.props.onValidate &&
                            <button type={'button'}
                                    onClick={this.props.onValidate}
                                    disabled={this.props.loading}>
                                {this.props.loading ?
                                    <Loader type="button" color={'white'} backgroundColor={"#D0ED4D "}/> :
                                    this.props.saveLabel
                                }
                            </button>
                            }
                        </div>
                    </div>}
                </div>
            </div>
        )
    }
}