import React from 'react';
import PropTypes from "prop-types";
import CKEditor from "ckeditor4-react";
import Rdate from "react-datetime";
import ApiWorkGroup from "../../../Services/ApiWorkGroup";
import ApiDocument from "../../../Services/ApiDocument";
import ApiTag from "../../../Services/ApiTag";
import InputTextLight from "../InputTextLight/InputTextLight";
import ListTags from "../../List/ListTags/ListTags";
import TagsInputWithButton from "../TagsInputWithButton/TagsInputWithButton";
import InputFile from "../InputFile/InputFile";
import CheckBoxSlider from "../CheckBoxSlider/CheckBoxSlider";
import Auth from "../../../Security/Auth";
import {DateHandler} from "../../../Utils/DateHandler";
import Loader from "../../Loader/Loader";
import InputRadioSimple from "../InputRadioSimple/InputRadioSimple";
import Select from "../Select/Select";
import {CK_EDITOR} from "../../../Config/ckEditor";
import "./FormAddDocWorkGroup.scss";


export default class FormAddDocWorkGroup extends React.Component {

    static propTypes = {
        document: PropTypes.shape({
            id: PropTypes.number,
            title: PropTypes.string,
            content: PropTypes.string,
            files: PropTypes.array,
            tags: PropTypes.array,
            meeting: PropTypes.object,
            doctype: PropTypes.object,
            public: PropTypes.bool,
            featured: PropTypes.bool,
            published: PropTypes.bool,
            commentable: PropTypes.bool

        }),
        currentWorkGroup: PropTypes.object,
        onCancel: PropTypes.func,
        onValid: PropTypes.func,
        onDelete: PropTypes.func,
        loading: PropTypes.bool
    };

    state = {
        form: {
            id: '',
            workgroup_id: this.props.currentWorkGroup.id,
            date: new Date(),
            archived_at: '',
            author: Auth.getUser().id,
            public: 1,
            featured: 0,
            published: 1,
            commentable: 1,
            title: '',
            content: '',
            tags: [],
            doctype: null,
            meeting: null,
            files: [
                {
                    name: '',
                    size: 0
                }
            ],
        },
        // Error
        error: {
            date: '',
            title: '',
            tags: '',
            files: '',
        },
        formError: '',
        // Data
        meetings: [],
        documenttypes: [],
        tag: '',
        // Temp data
        tempDateMeeting: '',
        tempNameMeeting: '',
        tempNameTypeDoc: '',
        tempErrorDateMeeting: '',
        tempErrorNameMeeting: '',
        tempErrorNameTypeDoc: '',
        // Select
        selectedMeeting: undefined,
        selectedTypeDoc: undefined,
        // Loading state
        loadingForm: false,
        loadingAddTypeDoc: false,
        loadingAddMeeting: false,
        showMeetingChoice: false,
        // Toggle
        showMeetingDatePicker: false,
        showInputAddType: false
    };

    constructor(props) {
        super(props);
        this._isMounted  = false;
        this.inputTagRef = React.createRef();
        this.thematic    = {};
    }

    getDocumentTypes() {

        // Get documents type & meeting for  list input <select>
        Promise.all([ApiWorkGroup.getDocumentTypesByWorkgroup(this.props.currentWorkGroup.id), ApiWorkGroup.getMeetings(this.props.currentWorkGroup.id)]).then((payload) => {
            if (!this._isMounted) return;

            const HAS_MEETING = !!(this.props.document && this.props.document.meeting !== null);

            payload[1].sort(function(a,b) {
                if     (a['date'].date === b['date'].date) { return 0; }
                return (a['date'].date  <  b['date'].date) ? -1 : 1;
            });

            this.setState({
                documenttypes: payload[0],
                meetings: payload[1].reverse(),
                selectedMeeting: (this.props.document && this.props.document.meeting && this.props.document.meeting.id) || undefined,
                selectedTypeDoc: (this.props.document && this.props.document.doctype && this.props.document.doctype.id) || undefined,
                showMeetingChoice: HAS_MEETING
            })
        });

    }

    componentDidMount() {
        this._isMounted = true;

        this.getDocumentTypes();

        let propsDocument = this.props.document;

        if (propsDocument) {
            let keys     = Object.keys(propsDocument);
            let document = this.props.document;
            for (let i = 0; i < keys.length; i++) {
                if (this.state.form[keys[i]] === undefined && keys[i] !== 'files') {
                    delete document[keys[i]];
                }
            }

            this.setState({form: document});
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    handleFormValue(key, value, callback) {
        this.setState((state) => ({
            form: {...state.form, [key]: value}
        }), () => {
            if (callback) return callback();
        });
    }

    handleError(key, value) {
        this.setState((state) => ({error: {...state.error, [key]: value}}));
    }

    handleTagValue(value) {

        value = value.trim().toLocaleLowerCase().replace(/^ /, '');

        this.setState({tag: value})
    }

    handleFileChange(file) {
        if (!file) return;
        this.setState((state) => ({form: {...state.form, files: [file]}}))
    }

    onKeyDownTag(e) {
        if (e.key === 'Enter') {
            e.preventDefault();
            this.addTag(this.state.tag)
        }
    }

    addTag(value) {
        if (!value) return;

        value = value.trim().toLocaleLowerCase().replace(/^ /, '');

        let tags = this.state.form.tags;

        if (tags.length >= 3) {
            this.handleError('tags', 'Vous ne pouvez pas ajouter plus de 3 tags.')
        } else {
            if (tags.indexOf(value) === -1) {
                // Add new tag
                tags.push(value);
            }

            // focus the input text for the tags
            this.inputTagRef.current.focus();

            this.setState((state) => ({
                form: {...this.state.form, tags: tags},
                error: {...state.error, tags: ''},
                tag: ''
            }), () => {
                this.handleError('tags', '')
            });
        }
    }

    removeTags(value) {
        let tags = this.state.form.tags;

        // Remove tag into array
        tags.splice(tags.map(function(x) {return x.id; }).indexOf(value), 1);

        this.setState((state) => ({
            form: {...this.state.form, tags: tags},
            error: {...state.error, tags: ''}
        }));
    }

    addNewMeeting(date, name) {
        this.setState({
            loadingAddMeeting: false,
            tempErrorDateMeeting: date === '' ? 'Vous devez choisir une date' : '',
            tempErrorNameMeeting: name === '' ? 'Vous devez saisir un nom de réunion' : ''
        });

        // Add meeting
        if (date !== '' && name !== '') {
            this.setState({
                loadingAddMeeting: true
            });
            ApiWorkGroup.addMeeting(date, name).then((payload) => {
                if (!this._isMounted) return;

                this.setState({
                    showMeetingDatePicker: false,
                    loadingAddMeeting: false,
                    selectedMeeting: payload.id,
                    meetings: [
                        ...this.state.meetings,
                        {
                            date: {date: date},
                            id: payload.id,
                            description: name
                        }
                    ],
                    form: {
                        ...this.state.form,
                        meeting: {
                            id: payload.id
                        }
                    }
                });
            }).catch(({payload}) => {
                if (!this._isMounted) return;
                this.setState({
                    loadingAddMeeting: false,
                    tempErrorNameMeeting: payload.errors && payload.errors.name
                })
            });
        }
    }

    addNewDocumentType(name) {
        this.setState({loadingAddTypeDoc: true});
        name = name.trim().replace(/^ /, '');
        ApiWorkGroup.addDocumentTypes(name).then((payload) => {
            if (!this._isMounted) return;
            let conflict = false;
            if (payload.existed) {
                for (let i = 0; i < this.state.documenttypes.length; i++) {
                    if (name === this.state.documenttypes[i].name) {
                        conflict = true;
                    }
                }
            }

            if (conflict) {
                this.setState({
                    loadingAddTypeDoc: false,
                    tempErrorNameTypeDoc: 'Cette valeur est déja utilisé.'
                })
            } else {
                this.setState({
                    showInputAddType: false,
                    loadingAddTypeDoc: false,
                    selectedTypeDoc: payload.id,
                    tempNameTypeDoc: '',
                    tempErrorNameTypeDoc: '',
                    documenttypes: [
                        ...this.state.documenttypes,
                        {
                            id: payload.id,
                            name: name
                        }
                    ],
                    form: {
                        ...this.state.form,
                        doctype: payload.id
                    }
                });
            }
        }).catch(({status}) => {
            if (!this._isMounted) return;
            let msg = '';

            if (status === 409) {
                msg = 'Cette valeur est déja utilisé.'
            } else if (status === 400) {
                msg = 'Cette chaîne est trop courte ou vide.'
            } else {
                msg = 'Erreur.'
            }

            this.setState({
                loadingAddTypeDoc: false,
                tempErrorNameTypeDoc: msg
            })
        });
    }

    checkFormValid() {
        let formHasError = false;

        return new Promise(resolve => {
            this.setState((state) => {
                    const FILE_IS_EMPTY = (state.form.files[0].length === 0) || state.form.files[0].name === '';
                    let titleError      = '';

                    if (!this.state.form.title) {
                        titleError = 'Le titre est requis.';

                    } else if (this.state.form.title.length < 3) {
                        titleError = 'Le titre doit avoir au minimum 3 caractères.';
                    }

                    return {
                        error: {
                            title: titleError,
                            files: FILE_IS_EMPTY ? 'Le fichier est requis' : '',
                            date: this.state.form.date ? '' : 'La date est requise'
                        }
                    }
                }
                , () => {
                    for (let property in this.state.error) {
                        if (this.state.error.hasOwnProperty(property) && this.state.error[property] !== '') {
                            formHasError = true;
                        }
                    }

                    if (formHasError) {
                        this.setState({formError: 'Des erreurs sont présentes dans le formulaire'});
                    } else {
                        this.setState({
                            formError: '',
                            loadingForm: true
                        });
                    }

                    resolve(formHasError);
                });
        });
    }

    formatDataForApi() {
        let document = {
            workgroup: this.state.form.workgroup_id,
            publishedat: DateHandler.formatForApi(this.state.form.date, false),
            author: this.state.form.author,
            title: this.state.form.title,
            public: this.state.form.public === true || this.state.form.public === 1 ? 1 : 0,
            featured: this.state.form.featured,
            published: this.state.form.published,
            commentable: this.state.form.commentable,
            visual: 0,
            files: [this.state.form.files[0].id],
            meeting: !this.state.form.meeting ? 'null' : this.state.form.meeting.id,
            content: (this.state.form.content) ? this.state.form.content : ''
        };

        if (this.state.form.id) document.id = this.state.form.id;
        if (this.state.form.tags) document.tags = this.state.form.tags;
        document.docType = (this.state.form.doctype && this.state.form.doctype > 0) ? this.state.form.doctype : this.state.selectedTypeDoc;

        return document;
    }

    validateForm() {
        this.checkFormValid().then((formAsError) => {
                if (!this._isMounted) return;
                if (formAsError) return;

                this.setState({loadingForm: true});

                const FORM_DATA = new FormData();
                FORM_DATA.append('files', this.state.form.files[0]);

                // If file is already uploaded
                if (this.state.form.files[0].id) {
                    this.addOrUpdateNewDocument();
                } else {
                    // Upload file document
                    ApiDocument.uploadFile(FORM_DATA).then((payload) => {
                            if (!this._isMounted) return;
                            let file = this.state.form.files[0];

                            file.id  = payload.identifier;
                            file.url = payload.url;

                            this.setState({
                                form: {
                                    ...this.state.form,
                                    files: [file]
                                }
                            }, () => {
                                this.addOrUpdateNewDocument();
                            });
                        }
                    );
                }
            }
        )
        ;
    }

    async addOrUpdateNewDocument() {
        const DOC_DATA_FOR_API = this.formatDataForApi();
        let tagsPromises       = [];

        // Promise tags
        if (DOC_DATA_FOR_API.tags && DOC_DATA_FOR_API.tags.length > 0) {
            for (let i = 0; i < DOC_DATA_FOR_API.tags.length; i++) {
                const TAG_NAME  = (typeof DOC_DATA_FOR_API.tags[i] === "string") ? DOC_DATA_FOR_API.tags[i] : DOC_DATA_FOR_API.tags[i].name;
                tagsPromises[i] = ApiTag.addTags(TAG_NAME);
            }
        }

        // Clean all tags before add news/update
        if(DOC_DATA_FOR_API.id){
            try {
                await ApiTag.delete(DOC_DATA_FOR_API.id);
            } catch (e) {
                console.log(e);
            }
        }

        // Upload file and add tags
        Promise.all(tagsPromises).then((payload) => {
            if (!this._isMounted) return;

            let promise;
            let tags = [];

            if (payload[0]) {
                for (let i = 0; i < payload.length; i++) {
                    tags.push(payload[i].id)
                }
            }

            DOC_DATA_FOR_API.tags = tags;

            if (DOC_DATA_FOR_API.id) {
                promise = ApiDocument.update(DOC_DATA_FOR_API, ['meeting', 'tags']);
            } else {
                promise = ApiDocument.add(DOC_DATA_FOR_API, ['meeting', 'tags']);
            }

            // Add new document
            promise.then((document) => {
                if (!this._isMounted) return;

                let docWithFile = document;

                docWithFile.files      = this.state.form.files || [];
                docWithFile.doctype    = {};

                if (DOC_DATA_FOR_API.docType) {
                    docWithFile.doctype.id = (typeof DOC_DATA_FOR_API.docType === "number") ? DOC_DATA_FOR_API.docType : DOC_DATA_FOR_API.docType.id;
                }

                let doctypes = this.state.documenttypes;

                doctypes.forEach((doctype) => {
                   if (doctype.id === this.state.form.doctype) {
                       docWithFile.doctype = doctype;
                   }
                });

                this.setState({loadingForm: false}, () => {
                    return this.props.onValid(docWithFile, !DOC_DATA_FOR_API.id)
                });
            }).catch((e) => {
                if (!this._isMounted) return;
                this.setState({loadingForm: false, showModalAddDoc: false});
                if (e.payload) {
                    this.setState({
                        formError: e.payload.errors && e.payload.errors.title
                    })
                }
            });
        });
    }

    removeFileUpload() {
        this.setState((state) => ({
            form: {
                ...state.form, files: [
                    {
                        name: '',
                        size: 0
                    }
                ]
            }
        }));
    }

    render() {

        return (
            <div className={'formAddDoc'}>
                {this.props.document
                    ? <div className={'headerFormAddDoc'}>
                        <div className="row">
                            <div className="col">
                                <h3 className={'titleModal'}>Editer le document « {this.state.form.title} »</h3>
                            </div>
                            <div className="col-md-auto">
                                <div className={'stateCurrentDoc'}>
                                    <CheckBoxSlider id={'archived_at'}
                                                    name={'archived_at'}
                                                    labelLeft={'Publié'}
                                                    labelRight={'Archivé'}
                                                    checked={!!this.state.form.archived_at}
                                                    onChange={(e) => this.handleFormValue('archived_at', e.target.checked ? 'checked' : '')}
                                    />
                                    <button type={'button'} className={'underline blue'} onClick={() => this.props.onDelete(this.props.document)}>Supprimer</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    : <h3 className={'titleModal'}>Ajouter un document</h3>}
                <h4 className={'subTitleModal'}>{this.props.currentWorkGroup.name}</h4>
                <hr/>
                <br/>

                <form>
                    <div className="row">
                        <div className="col">
                            {/* Title */}
                            <InputTextLight label={'Titre du document*'}
                                            value={this.state.form.title}
                                            errorMsg={this.state.error.title}
                                            onChange={(e) => this.handleFormValue('title', e.target.value)}/>

                            {/* Tags */}
                            <TagsInputWithButton label={'Tags du document (max. 3 tags)'}
                                                 placeholder={'Nom du tag'}
                                                 value={this.state.tag}
                                                 textButton={'Ajouter'}
                                                 errorMsg={this.state.error.tags}
                                                 inputRef={this.inputTagRef}
                                                 onSubmit={(e) => this.addTag(e)}
                                                 onKeyDown={(e) => this.onKeyDownTag(e)}
                                                 onChange={(value) => this.handleTagValue(value)}
                                                 withAutoComplete={true}
                            />
                            {/* Tags */}
                            <ListTags tags={this.state.form.tags} skin={'blue'} onRemove={(item) => this.removeTags(item)}/>

                            {/* Document type select */}
                            <Select
                                placeholder={' '}
                                style={{marginBottom: 5}}
                                label={"Type de document"}
                                selected={this.state.selectedTypeDoc}
                                value={this.state.documenttypes.map((documentType) => {
                                    return {
                                        id: documentType.id,
                                        name: documentType.name,
                                    }
                                })}
                                onChange={(e) => {
                                    this.setState({
                                        selectedTypeDoc: +e.target.value || 'null',
                                        form: {
                                            ...this.state.form,
                                            doctype: {
                                                id: e.target.value ? +e.target.value : null
                                            }
                                        }
                                    });
                                }}
                            />

                            {/* Document type inout */}
                            {this.state.showInputAddType &&
                            <TagsInputWithButton placeholder={'Nom du type de document'}
                                                 value={this.state.tempNameTypeDoc}
                                                 errorMsg={this.state.tempErrorNameTypeDoc}
                                                 onSubmit={(value) => this.addNewDocumentType(value)}
                                                 onChange={(value) => this.setState({tempNameTypeDoc: value})}
                                                 withAutoComplete={false}
                                                 loading={this.state.loadingAddTypeDoc}
                                                 style={{marginBottom: 16}}
                                                 textButton={'Ajouter'}
                            />}
                            <button className={`btnAddItem d-flex align-items-center${this.state.showInputAddType ? ' active' : ''}`}
                                    type={'button'}
                                    onClick={() => this.setState({showInputAddType: !this.state.showInputAddType})}>
                                <span>Ajouter un type de document</span>
                            </button>
                        </div>
                        <div className="col">
                            {/* Date picker */}
                            <div className={`timePickerWrapper${this.state.error.date ? ' error' : ''}`}>
                                <div className="agendCtrls-time-picker">
                                    <label>Date de publication*</label>
                                    <Rdate value={DateHandler.getPrettyDate(this.state.form.date)}
                                           input={false}
                                           viewMode="days"
                                           timeFormat={false}
                                           onChange={(e) => this.handleFormValue('date', e.format('YYYY-MM-DD HH:mm:ss'))}/>


                                </div>
                                {this.state.error.date && <span className={'errorMsg'}>{this.state.error.date}</span>}
                            </div>
                        </div>
                    </div>

                    <div style={{height: 40}}/>

                    {/* File */}
                    <InputFile id={'attachments'}
                               label={'Pièce-jointe*'}
                               fileName={this.state.form.files[0].name}
                               errorMsg={this.state.error.files}
                               onChange={(file) => this.handleFileChange(file)}
                               onRemove={() => this.removeFileUpload()}/>

                    {/* Editor WYSIWYG */}
                    <h5 className={'subTitle'} style={{marginBottom: 10, marginTop: 0}}>Description</h5>
                    <CKEditor
                        onBeforeLoad={(CKEditor) => {
                            CKEditor.disableAutoInline = true;
                            CK_EDITOR.config.filebrowserUploadUrl = CK_EDITOR.baseUploadUrl + Auth.getToken();
                        }}
                        config={CK_EDITOR.config}
                        data={this.state.form.content}
                        type="classic"
                        onChange={(event) => {
                            const data = event.editor.getData();
                            this.handleFormValue('content', data)
                        }}
                    />

                    {/* Related to a meeting */}
                    <h5 className="subTitle" style={{marginBottom: 10, marginTop: 40}}>Souhaitez-vous rattacher ce document à une réunion ?*</h5>
                    <InputRadioSimple name={'meeting'}
                                      onChange={(value) => {
                                          if (+value === 0) {
                                              this.setState({selectedMeeting: undefined});
                                              this.handleFormValue('meeting', null)
                                          }
                                          this.setState({showMeetingChoice: !this.state.showMeetingChoice})
                                      }} data={[
                        {
                            id: 'meetingTrue',
                            value: '1',
                            label: 'Oui',
                            checked: this.state.showMeetingChoice

                        },
                        {
                            id: 'meetingFalse',
                            value: '0',
                            label: 'Non',
                            checked: !this.state.showMeetingChoice

                        }]}
                    />

                    {/* Choice meeting */}
                    {this.state.showMeetingChoice && <div className={'row'}>
                        <div className={'col-md-6'} style={{marginTop: 20}}>
                            <Select
                                style={{marginBottom: 0}}
                                label={"Choix d'une réunion"}
                                placeholder={' '}
                                selected={this.state.selectedMeeting}
                                value={this.state.meetings.map((meeting) => {

                                    if (meeting.description === null || meeting.description === undefined || meeting.description === '') {

                                        return {
                                            id: meeting.id,
                                            name: DateHandler.getPrettyDate(meeting.date.date, '[Le ] DD/MM/YYYY [à] HH[h]')
                                        }

                                    }

                                    return {
                                        id: meeting.id,
                                        name: DateHandler.getPrettyDate(meeting.date.date, '[Le ] DD/MM/YYYY [à] HH[h]') + ' - ' + meeting.description
                                    }

                                })}
                                onChange={(e) => {
                                    if (this.state.selectedMeeting) {
                                        this.setState({selectedMeeting: undefined});
                                    }

                                    this.handleFormValue('meeting', e.target.value ? {id: +e.target.value} : null)
                                }}
                            />
                            <button className={`btnAddItem d-flex align-items-center${this.state.showMeetingDatePicker ? ' active' : ''}`}
                                    type={'button'}
                                    onClick={() => this.setState({showMeetingDatePicker: !this.state.showMeetingDatePicker})}>
                                <span>Ajouter une date de réunion</span>
                            </button>
                        </div>
                        <div className="col-md-6">
                            {/* Date picker meeting */}
                            {this.state.showMeetingDatePicker && <>
                                <div className={`timePickerWrapper${this.state.error.date ? ' error' : ''}`}>
                                    <div className="agendCtrls-time-picker">
                                        <label>Date de la réunion*</label>
                                        <Rdate value={new Date(this.state.tempDateMeeting)}
                                               input={false}
                                               viewMode="days"
                                               timeFormat={true}
                                               onChange={(e) => this.setState({tempDateMeeting: e.format('YYYY-MM-DD HH:mm:ss')})}
                                        />
                                    </div>
                                    {this.state.tempErrorDateMeeting &&
                                    <span className={'errorMsg'}>{this.state.tempErrorDateMeeting}</span>}
                                </div>
                                <br/>
                                <div className={'centerText'}>
                                    <InputTextLight label={'Nom de la réunion*'}
                                                    errorMsg={this.state.tempErrorNameMeeting}
                                                    value={this.state.tempNameMeeting}
                                                    onChange={(e) => this.setState({tempNameMeeting: e.target.value})}/>
                                    <button type={'button'}
                                            className={'btn'}
                                            disabled={this.state.loadingAddMeeting}
                                            onClick={() => this.addNewMeeting(this.state.tempDateMeeting, this.state.tempNameMeeting)}>
                                        {this.state.loadingAddMeeting ? <Loader type={'button'}/> : 'Ajouter'}
                                    </button>
                                </div>
                            </>
                            }
                        </div>
                    </div>}

                    {/* Public */}
                    <h5 className="subTitle" style={{marginBottom: 10, marginTop: 40}}>Visibilité du document</h5>
                    <InputRadioSimple name={'public'} onChange={() => {
                        this.handleFormValue('public', !this.state.form.public);
                    }} data={[
                        {
                            id: 'publicTrue',
                            value: 'public',
                            label: 'Public',
                            checked: this.state.form.public === true || this.state.form.public === 1

                        },
                        {
                            id: 'publicFalse',
                            value: 'private',
                            label: 'Privé (visible uniquement par les membres du GT)',
                            checked: this.state.form.public === false || this.state.form.public === 0

                        }]}
                    />

                    {/* Featured */}
                    <h5 className="subTitle d-flex align-items-center" style={{marginBottom: 10, marginTop: 40}}>
                        <span style={{marginRight: 10}}>Mettre le document à la Une</span>
                        <input type="checkbox"
                               id="featured"
                               name="featured"
                               checked={this.state.form.featured}
                               onChange={(e) => this.handleFormValue('featured', e.target.checked ? 1 : 0)}/>
                    </h5>

                    {/* Commentable */}
                    <h5 className="subTitle d-flex align-items-center" style={{marginBottom: 10, marginTop: 40}}>
                        <span style={{marginRight: 10}}>Autoriser les commentaires</span>
                        <input type="checkbox"
                               id="commentable"
                               name="commentable"
                               checked={this.state.form.commentable}
                               onChange={(e) => this.handleFormValue('commentable', e.target.checked ? 1 : 0)}/>
                    </h5>
                </form>

                <br/>

                <p className={'required'}><b>* Champs obligatoires</b></p>

                <br/>

                {this.state.formError && <p className={'centerText'} style={{color: 'red'}}>{this.state.formError}</p>}

                {/* Button */}
                <div className={'buttonModal'}>
                    <button type={'button'} className={'cancel'} onClick={() => this.props.onCancel()}>Annuler</button>
                    <button type={'button'} onClick={() => this.validateForm()} disabled={this.state.loadingForm}>
                        {this.state.loadingForm || this.props.loading ?
                            <Loader type={"button"} backgroundColor={'transparent'}/> : 'Valider'}
                    </button>
                </div>
            </div>
        )
    }
}
