/**
 * Rename object property
 * @param oldProp
 * @param newProp
 * @param object
 * @returns {*}
 */
export const renameObjectProp = (oldProp, newProp, object) => {
    object[newProp] = object[oldProp];
    delete object[oldProp];
    return object;
};
