import React from 'react';
import PropTypes from 'prop-types';
import Modal from "../Modal";
import Loader from "../../Loader/Loader";
import './ModalConfirm.scss';


export default class ModalConfirm extends React.Component {

    static propTypes = {
        loading: PropTypes.bool,
        confirmText: PropTypes.string,
        btnCancelText: PropTypes.string,
        btnConfirmText: PropTypes.string,
        onClose: PropTypes.func,
        onCancel: PropTypes.func,
        onConfirm: PropTypes.func
    };

    static defaultProps = {
        loading: false,
        confirmText: `Êtes-vous sûr de vouloir supprimer cet élément ?`,
        btnCancelText: `Non`,
        btnConfirmText: `Oui`,
        onClose: () => {},
        onCancel: () => {},
        onConfirm: () => {},
    };

    render() {
        return (<Modal autoWidth={true} autoHeight={true} onClose={this.props.onClose}>
                <p>
                    {this.props.confirmText}
                </p>
                <div className={'buttonModal'}>
                    <button type={'button'} className={'cancel'} onClick={this.props.onCancel}>{this.props.btnCancelText}</button>
                    <button type={'button'} onClick={this.props.onConfirm}>
                        {this.props.loading ? <Loader type={"button"} backgroundColor={'transparent'} color={'blue'}/> : this.props.btnConfirmText}
                    </button>
                </div>
            </Modal>
        )
    }
}