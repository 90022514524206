import axios from "axios";
import {LOCATION} from "../Config/location";

/**
 * This Api service is quite different from the other one because it handles the Account, so no API version is required here
 * @type {string}
 */
const PATH = `${LOCATION.api}/account`;
const REFRESH_TOKEN = `${LOCATION.api}/api/token/refresh`;

export default {

    /**
     * Login
     * @param email
     * @param password
     * @returns {AxiosPromise<any>}
     */
    login: (email, password) => {
        return axios.post(`${PATH}/login`, {
            email: email,
            password: password
        }, {
            headers: {'Content-Type': 'application/json;charset=utf-8'},
        });
    },

    /**
     * Registration
     * @param data
     * @returns {AxiosPromise<any>}
     */
    register: (data) => {
        return axios.post(`${PATH}/registration`, data);
    },

    /**
     * Registration data
     * @returns {AxiosPromise<any>}
     */
    registrationData: () => {
        return axios.get(`${PATH}/registration/data`)
    },

    /**
     * Forgot password
     * @param email
     * @returns {Promise<unknown>}
     */
    forgotPassword: (email) => {
        return axios.post(`${PATH}/recovery`, {email: email})
    },

    /**
     * Valid new password
     * @param id
     * @param token
     * @param password
     * @returns {AxiosPromise<any>}
     */
    validNewPassword: (id, token, password) => {
        return axios.post(`${PATH}/recovery/validation`, {
            id: id,
            token: token,
            password: password
        })
    },

    getUserByToken: (token) => {
        return axios.get(`${PATH}/token/user/${token}`)
    },

    createPassword: (user) => {
        return axios.post(`${PATH}/create/password`, user);
    },

    refreshToken: (token) => {
        return axios.post(REFRESH_TOKEN,{'refresh_token': token});
    },

    getRefreshTokenUrl: () => {
        return REFRESH_TOKEN;
    }
}

