import React from 'react';
import PropTypes from "prop-types";
import "./InputText.scss";


export default class InputText extends React.Component {

    static propTypes = {
        id: PropTypes.string.isRequired,
        type: PropTypes.string,
        label: PropTypes.string,
        placeholder: PropTypes.string,
        value: PropTypes.string,
        name: PropTypes.string,
        maxLength: PropTypes.number,
        autoComplete: PropTypes.string,
        styleLabel : PropTypes.object,
        errorMsg: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
        onChange: PropTypes.func
    };

    static defaultProps = {
        type: 'text'
    };

    render() {
        return (
            <label htmlFor={this.props.id} className={'inputText'} style={this.props.styleLabel}>
                {this.props.label && <span className={'label'}>{this.props.label}</span>}
                <input type={this.props.type}
                       id={this.props.id}
                       className={this.props.errorMsg ? ' error' : null}
                       name={this.props.name ? this.props.name : this.props.id}
                       value={this.props.value}
                       placeholder={this.props.placeholder}
                       autoComplete={this.props.autoComplete}
                       onChange={(e) => this.props.onChange(e)}
                       maxLength={this.props.maxLength}
                />
                <span className={'line'}/>
                {this.props.errorMsg && <span className={'errorMsg'}>{this.props.errorMsg}</span>}
            </label>
        )
    }
}