import React from "react";
import Roles from "../../Security/Roles";
import Auth from "../../Security/Auth";
import Moment from "moment";
import { Link } from "react-router-dom";
import { path } from "../../Config/routes";
//
export default function EventRow({ evt, role, onEdit, onDelete, onDownload }) {
    let canEdit = role.rank >= Roles.ROLE_ROOT.rank;
    if (
        role.uid === Roles.ROLE_ADMINISTRATOR.uid &&
        Auth.isThematicAdministrator(evt.thematic.id)
    ) {
        canEdit = true;
    }
    return (
        <li
            className="eventsList-event Event"
            style={{
                backgroundColor: evt.thematic.color,
            }}
        >
            {canEdit && (
                <div className="Event-actions">
                    <button
                        className="editEventItem"
                        onClick={() => onEdit(evt, true)}
                    >
                        <i></i>
                    </button>
                    <button
                        className="removeEventItem"
                        onClick={() => onDelete(evt)}
                    >
                        <i />
                    </button>
                </div>
            )}
            <div className="Event-date">
                {Moment(evt.startingat.date).format("HH:mm")}
                {" - "}
                {Moment(evt.finishingat.date).format("HH:mm")}
            </div>
            <div className="Event-thematic">{evt.thematic.name}</div>
            <div className="Event-title">{evt.title}</div>

            <div className="Event-link">
                <Link
                    to={path("agendaDetail", { id: evt.id })}
                    className={"readMoreEvent"}
                >
                    En savoir plus
                </Link>
            </div>
            <button className="Event-download" onClick={() => onDownload(evt)}>
                +
            </button>
        </li>
    );
}
