import axios from "axios";
import {PATH} from "../Utils/Api";

const NESTED_CATEGORIES = 'categories.categories.categories.categories.categories.categories';

export default {

    /**
     * Return a category by its ID
     * @param id
     * @returns {AxiosPromise<any>}
     */
    get: (id) => {
        return axios.get(`${PATH}/content/category/${id}`)
    },

    /**
     * Edit a category
     *
     * @param {{}} category
     *
     * @returns {AxiosPromise<any>}
     */
    edit: (category) => {
        return axios.patch(`${PATH}/content/category/${category.id}`, category);
    },

    getTreeFromThematicId: thematicId => {
        const params = {
            "api[nested_entities]": NESTED_CATEGORIES,
            "api[where]": `where (\`thematic\` = \`${thematicId}\` AND \`parent_id\` = \`0\`)`,
        };

        return axios.get(`${PATH}/content/category`, {params: params});
    },

    /**
     * Archive a category from its ID
     * @param id
     * @returns {AxiosPromise<any>}
     */
    archive: id => {
        return axios.patch(`${PATH}/content/category/${id}/archived/1`)
    },

    /**
     * Unarchive a category from its ID
     * @param id
     * @returns {AxiosPromise<any>}
     */
    unArchive: id => {
        return axios.patch(`${PATH}/content/category/${id}/archived/0`)
    },

    /**
     * Add a new category.
     * You can choose in which parent with the parameter "parentId"
     *
     * @param {String}name
     * @param {Number}thematicId
     * @param {Number|null}parentId
     * @returns {AxiosPromise<any>}
     */
    add: (name, thematicId, parentId = null) => {
        let params = {name: name, thematic: thematicId, has_specific_filters: thematicId === 7};
        if (parentId !== null) {
            // We have to send both! parent_id for doctrine validation, and parent for Graphene transformation of related entity
            params['parent_id'] = parentId;
            params['parent']    = parentId;
        }

        return axios.post(`${PATH}/content/category`, params)
    }
}