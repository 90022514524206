import React from "react";
import Master from "../../Components/Layout/Master";
import Search from "../../Components/Search/Search";
import Banner from "../../Components/Banner/Banner";
import Loader from "../../Components/Loader/Loader";
import "./DocumentationList.scss";
import * as qs from "qs";
import { getColor, lightenDarkenColor } from "../../Utils/Colors";
import { THEMATIC_TYPE_DOCUMENTATION } from "../../Services/ApiThematics";
import { Http } from "../../Utils/Api";
import { path } from "../../Config/routes";
import ApiDocument, { DOC_RESULT_PAGE } from "../../Services/ApiDocument";
import Select from "react-select";
import Thematics from "../../Utils/Thematics";
import ButtonThematic from "../../Components/Form/Button/ButtonThematic";
import { isMobile } from "../../Utils/Screen";

export function filterDocument(payload, page, params) {
    const filter = payload.filter;
    let orderBy = "";
    let sort = "";
    let where = "";

    // Sort
    if (filter !== "") {
        if (filter === "date") {
            sort = "published_at";
        } else if (filter === "name") {
            sort = "title";
        } else {
            sort = filter;
        }

        orderBy = payload.increasing ? "asc" : "desc";
    }

    if (payload.where !== "") {
        where += " AND " + payload.where;
    }

    return {
        where: where,
        sort: sort,
        orderBy: orderBy,
        page: page || params.page || 1,
    };
}

export default class DocumentationList extends React.Component {
    state = {
        categories: [],
        nbResults: 0,
        results: null,
        optionSelected: [-1],
        selects: [],
        isSearch: false,
        breadCrumb: [
            { url: path("documentationList"), label: "Espace documentation" },
        ],
        loader: true,
        loadingDocument: false,
        httpError: false,
        loadResults: false,
        periodicity: [],
    };

    constructor(props) {
        super(props);
        this.backgroundColor = [getColor("blue")];
        this.pagination = null;
        this._filter = {};
        this.allThemacis = [];
        this._categoriesIds = [];
        this.admin = false;
        this.searchText = "";
        this.formSelect = false;
        this._categories = null;
        this._isMounted = false;
        this._filter = {
            where: "",
            // where: "`archived_at`=`null`",
        };
        this.pathParameters = {
            category: "",
            categoryTwo: "",
            categoryThree: "",
            categoryFour: "",
            categoryFive: "",
        };
        this.keyParameters = Object.keys(this.pathParameters);
    }

    componentDidMount() {
        this._isMounted = true;
        this.init();

        window.addEventListener("category_tree.updated.local_storage", this.init);
    }

    componentDidUpdate(prevProps, prevState) {
        // Refresh if param change from menu
        if (!this.formSelect) {
            let paramsPrev = Object.keys(prevProps.match.params);
            let params = Object.keys(this.props.match.params);
            let newUrl = false;

            for (let i = 0; i < paramsPrev.length; i++) {
                if (
                    prevProps.match.params[paramsPrev[i]] !==
                    this.props.match.params[params[i]]
                ) {
                    newUrl = true;
                    break;
                }
            }

            if (newUrl) {
                window.scroll(0, 0);
                this.init();
            }
        }
    }

    init() {
        this.setState({
            loadingDocument: true,
            breadCrumb: [this.state.breadCrumb[0]],
        });

        this._selects = [];
        this._optionSelected = [];
        this.formSelect = false;

        this._categories = this._categories
            ? this._categories
            : Thematics.getLocalThematics(
                  THEMATIC_TYPE_DOCUMENTATION,
                  true,
                  false
              );

        let categories = this._categories.filter(
            (thematics) => thematics.categories.length > 0
        );
        let selects = this.setOptionsSelected(
            Object.keys(this.props.match.params),
            categories
        );
        this.allThemacis = categories;

        this.setState(
            {
                categories: categories,
                optionSelected: selects ? selects.optionSelected : [-1],
                selects: selects ? selects.selects : [],
                loader: false,
                loadingDocument: false,
            },
            () => {
                // Update the breadcrumb
                selects = selects ? selects.selects : [];

                for (let i = 0; i < selects.length; i++) {
                    let option = selects[i].props.value;
                    let key = parseInt(selects[i].key);

                    if (option.value !== -1) {
                        this.selectHandler(option, key, false);
                    }
                }
            }
        );
    }

    componentWillUnmount() {
        Http.cancel();
        this._isMounted = false;

        window.removeEventListener("category_tree.updated.local_storage", this.init, false);
    }

    /**
     * Generate all select based of the router parameters
     *
     * @param {array} routerParams - All the router parameters
     * @param {array} categories - All categories for this level
     * @param {number} [index=0] - Index for the router parameters to watch
     *
     * @return {{selects: [number], optionSelected: [Select]}}
     */
    setOptionsSelected(routerParams, categories, index = 0) {
        let params = this.props.match.params;

        // The router parameter is set
        if (params[routerParams[index]]) {
            let indexCategories = -1;
            // Get the index category for the the current router parameter
            for (let i = 0; i < categories.length; i++) {
                if (categories[i].uid === params[routerParams[index]]) {
                    indexCategories = i;
                    break;
                }
            }

            // Router parameter not found
            if (indexCategories === -1) {
                console.error(
                    `${
                        params[routerParams[index]]
                    } do not exist in documentation`
                );
                return;
            }

            // Save the options selected and the select for this router parameter
            this._optionSelected.push(indexCategories);
            this._selects.push(
                this.createSelect(categories, indexCategories, index)
            );

            if (!this.admin) {
                return this.setOptionsSelected(
                    routerParams,
                    categories[indexCategories].categories,
                    index + 1
                );
            } else {
                return this.setOptionsSelected(routerParams, null, -1);
            }
        } else if (categories && categories.length > 0) {
            // Init the next select if it exist
            this._optionSelected.push(-1);
            this._selects.push(this.createSelect(categories, -1, index));
            return {
                optionSelected: this._optionSelected,
                selects: this._selects,
            };
        } else {
            return {
                optionSelected: this._optionSelected,
                selects: this._selects,
            };
        }
    }

    /**
     * @param optionSelected
     * @param selectPosition - Position of select changed
     * @param recreate
     */
    selectHandler(optionSelected, selectPosition = 1, recreate = true) {
        this.formSelect = true;
        let breadCrumb = this.state.breadCrumb;
        // If the select changed is not the last one
        if (selectPosition !== breadCrumb.length) {
            // Delete all index saved after the select changed
            breadCrumb = breadCrumb.slice(
                0,
                selectPosition - breadCrumb.length
            );
        }
        if (optionSelected.value !== -1) {
            for (let i = 0; i < this.keyParameters.length; i++) {
                let value =
                    i === selectPosition - 1
                        ? optionSelected.url
                        : this.pathParameters[this.keyParameters[i]];

                this.pathParameters[this.keyParameters[i]] =
                    i < selectPosition ? value : "";
            }

            breadCrumb[selectPosition] = {
                url: path("documentationList", this.pathParameters),
                label: optionSelected.label,
            };
        }

        // Update the url with the select
        let urlParams = Object.keys(this.props.match.params);
        let param = {};
        for (let i = 0; i < urlParams.length; i++) {
            if (breadCrumb[i + 1]) {
                param[urlParams[i]] = breadCrumb[i + 1].url;
            } else {
                break;
            }
        }

        this.props.history.replace({
            pathname: breadCrumb[breadCrumb.length - 1].url,
            search: this.props.history.location.search,
        });

        this.setState({
            nbResults: 0,
            breadCrumb: breadCrumb,
            loadingDocument: true,
        });

        if (optionSelected.emulated && isMobile()) {
            Banner.selects.current.className = "selects selects-translate-1";
            Banner.reset.current.className = "fas fa-chevron-left";
        }

        this.change(
            optionSelected.value,
            optionSelected.id,
            selectPosition,
            recreate
        );
    }

    /**
     * @param selectValue
     * @param id - Id of the category selected
     * @param selectPosition - Position of select changed
     * @param recreate
     */
    change(selectValue, id, selectPosition = 1, recreate = true) {
        // Get option selected for the select changed
        let optionsSelected = this.state.optionSelected;

        optionsSelected[selectPosition - 1] = selectValue;

        let selects = recreate ? [] : this.state.selects;
        let categories = this.state.categories;

        // If the select changed is not the last one
        if (selectPosition !== optionsSelected.length && recreate) {
            // Delete all index saved after the select changed
            optionsSelected = optionsSelected.slice(
                0,
                selectPosition - optionsSelected.length
            );
        }

        // Recreate all previous select
        for (let i = 0; i < optionsSelected.length; i++) {
            if (recreate) {
                selects.push(
                    this.createSelect(
                        categories.categories || categories,
                        this.state.optionSelected[i],
                        i
                    )
                );
            }
            if (this.state.optionSelected[i] !== -1) {
                categories =
                    categories[this.state.optionSelected[i]].categories ||
                    categories[this.state.optionSelected[i]];
            }
        }

        this.setState({
            selects: selects,
            optionSelected: optionsSelected,
        });

        // It is not the default option for the select changed
        if (selectValue !== -1 || optionsSelected.length >= 1) {
            // Create a new select if the previous as children and it's not admin page
            if (
                selectValue !== -1 &&
                (categories.categories !== undefined ||
                    categories.length > 0) &&
                recreate &&
                !this.admin
            ) {
                optionsSelected.push(-1);
                selects.push(
                    this.createSelect(
                        categories.categories || categories,
                        -1,
                        optionsSelected.length - 1
                    )
                );
            }

            this.getCategoriesSelected(
                optionsSelected,
                selectPosition,
                selectValue,
                id
            );

            const PARAMS = qs.parse(this.props.location.search, {
                ignoreQueryPrefix: true,
            });

            ApiDocument.getDocuments(
                PARAMS.page || 1,
                DOC_RESULT_PAGE,
                this._filter,
                this._categoriesIds
            ).then((search) => {
                if (!this._isMounted) return;

                let data = search.payload;
                this.pagination = search.extra;
                this.setState(
                    {
                        selects: selects,
                        optionSelected: optionsSelected,
                        nbResults: search.extra.resources.total,
                        results: data,
                        loadingDocument: false,
                    },
                    () => {
                        this.formSelect = false;
                        this._filter.sort = "periodicity";
                        this._filter.orderBy = "asc";
                        ApiDocument.getDocumentsFilters(
                            this._filter,
                            this._categoriesIds
                        ).then((result) => {
                            this._filter.sort = "";
                            this._filter.orderBy = "desc";
                            if (result.length > 0) {
                                this.setState({
                                    periodicity: result,
                                });
                            }
                        });
                    }
                );
            });
        } else {
            this.setState(
                {
                    loadingDocument: false,
                },
                () => (this.formSelect = false)
            );
        }
    }

    getCategoriesSelected(optionsSelected, selectPosition, selectValue, id) {
        if (optionsSelected[0] !== -1) {
            // Get the category selected
            let categories = this.allThemacis[optionsSelected[0]].categories;
            for (let i = 1; i < optionsSelected.length; i++) {
                if (optionsSelected[i] === -1) {
                    break;
                }

                // This category has children
                if (categories[optionsSelected[i]].categories.length > 0) {
                    categories = categories[optionsSelected[i]].categories;
                } else {
                    categories = categories[optionsSelected[i]];
                }
            }

            this._categoriesIds = [];
            if (Array.isArray(categories) && categories.length > 0) {
                this._categoriesIds = this.getLastChildrenId(categories);
            }

            // If it is not a thematic add this id to the list id
            if (selectPosition !== 1 && selectValue !== -1) {
                this._categoriesIds.push(id);
            } else if (
                selectPosition === 1 &&
                this._categoriesIds.length === 0
            ) {
                this._categoriesIds.push(0);
            }
        }
    }

    /**
     * Get all last id of category
     * @param categories
     * @param ids
     * @return {Array}
     */
    getLastChildrenId(categories, ids = []) {
        categories.forEach((category) => {
            if (category.categories.length > 0) {
                return this.getLastChildrenId(category.categories, ids);
            } else {
                ids.push(category.id);
            }
        });

        return ids;
    }

    /**
     * Create a select
     * @param {array} options - List of select's options
     * @param {number} [value=-1] - The value of options selected
     * @param {number } [key=0] - Key to loop
     * @return {string}
     */
    createSelect(options, value = -1, key = 0) {
        key++;
        const PLACEHOLDER =
            key === 1 ? "Choisissez un thème" : "Choisissez une catégorie";

        let optionSelected = {
            value: -1,
            label: PLACEHOLDER,
        };
        let selectOption = [optionSelected];

        for (let i = 0; i < options.length; i++) {

            let option = {
                value: i,
                label: options[i].name,
                url: options[i].uid,
                id: options[i].id,
                disabled: key === 1 && options[i].categories.length === 0,
                archived: !!options[i].archivedat,
            };

            // Add only the direct children
            if (
                (key <= 2 &&
                    (options[i].parentid === 0 ||
                        options[i].parentid === undefined)) ||
                key >= 3
            ) {
                selectOption.push(option);

                if (value === i) {
                    optionSelected = option;
                }
            }
        }

        if (options[optionSelected.value] !== undefined) {
            this.backgroundColor[key] = lightenDarkenColor(
                options[optionSelected.value].color || this.backgroundColor[1],
                -(key - 1) * 20
            );
            this.backgroundColor[key + 1] = lightenDarkenColor(
                options[optionSelected.value].color || this.backgroundColor[1],
                -key * 20
            );
        }

        let color =
            value === -1 && key === 1
                ? this.backgroundColor[0]
                : this.backgroundColor[key];

        let customStyles = {
            container: (provided) => ({
                ...provided,
                height: "100%",
                width: "100%",
            }),
            control: (provided) => ({
                ...provided,
                backgroundColor: color,
                boxShadow: "none",
                border: 0,
                borderRadius: 0,
                height: "100%",
                width: "100%",
            }),
            valueContainer: (provided) => ({
                ...provided,
                justifyContent: "center",
            }),
            singleValue: (provided, state) => ({
                ...provided,
                color: state.data.archived ? getColor("lightGrey") : getColor("white"),
            }),
            indicatorSeparator: (provided) => ({
                ...provided,
                display: "none",
            }),
            menu: (provided) => ({
                ...provided,
                backgroundColor: color,
            }),
            option: (provided, { data, isDisabled, isFocused, isSelected }) => {
                let style = provided;
                style.color = getColor("white");
                if (data.hasOwnProperty('archived') && data.archived) {
                    style.color = getColor("lightGrey");
                }

                return style;
            }

        };

        return (
            <Select
                value={optionSelected}
                placeholder={PLACEHOLDER}
                key={key}
                name={`selectFilter${key}`}
                id={`selectFilter${key}`}
                styles={customStyles}
                onChange={(e) => this.selectHandler(e, key)}
                classNamePrefix="react-select"
                className="react-select__container"
                isSearchable={false}
                isOptionDisabled={(option) => {
                    return option.disabled;
                }}
                options={selectOption}
            />
        );
    }

    handleSearch(searchText) {
        this.searchText = searchText;

        if (searchText !== "") {
            this.setState({ searchLoading: true });
            this.getDocuments(1);
        }
    }

    onPaginationClick(page) {
        this.setState({ loadingDocument: true, nbResults: 0 }, () =>
            this.getDocuments(page)
        );
    }

    getDocuments(page) {
        ApiDocument.getDocuments(
            page,
            DOC_RESULT_PAGE,
            this._filter,
            this._categoriesIds,
            this.searchText
        ).then((result) => {
            if (!this._isMounted) return;

            let data = result.payload;
            this.pagination = result.extra;
            this.setState({
                nbResults: result.extra.resources.total,
                results: data,
                searchLoading: false,
                loadResults: false,
                loadingDocument: false,
            });
        });
    }

    filter(payload, page) {
        this.setState({ loadResults: true });
        const PARAMS = qs.parse(this.props.location.search, {
            ignoreQueryPrefix: true,
        });

        this._filter.where = "";

        let filter = filterDocument(payload, page, PARAMS);

        this._filter.where += filter.where;
        this._filter.sort = filter.sort;
        this._filter.orderBy = filter.orderBy;

        this.getDocuments(filter.page);
    }

    _getCategorySelected() {
        let category = null;
        let selectedIndex = 0;
        let categories = this.state.categories;
        this.state.optionSelected.forEach((selected, key) => {
            if (selected >= 0) {
                category = categories[selected];
                if (key < this.state.optionSelected.length) {
                    categories = categories[selected].categories;
                }
                selectedIndex = key;
            }
        });

        // First level are thematics, so if it's the only select for which we made a choose, we don't have any category to return
        if (selectedIndex <= 0) {
            return {};
        }

        return category;
    }

    render() {
        let content = (
            <ButtonThematic
                thematics={this.state.categories}
                onClick={(category, i) => {
                    this.selectHandler({
                        label: category.name,
                        url: category.uid,
                        id: category.id,
                        value: i,
                        emulated: true,
                    });
                }}
            />
        );

        if (this.state.nbResults === 0 && this.state.loadingDocument) {
            content = <Loader type={"inline"} />;
        } else if (
            !this.state.loadingDocument &&
            this.state.optionSelected[0] !== -1 &&
            this.state.results
        ) {
            content = (
                <Search.Result
                    results={this.state.results}
                    nbResults={this.state.nbResults}
                    onFilter={(by, e) => this.filter(by, e)}
                    pagination={this.pagination}
                    category={this._getCategorySelected()}
                    fileColor={
                        this.state.categories[this.state.optionSelected[0]]
                            .color
                    }
                    loading={this.state.loadResults}
                    periodicity={this.state.periodicity}
                    onPaginationClick={(page) => {
                        this.onPaginationClick(page);
                    }}
                />
            );
        }

        return (
            <Master
                id="documentation"
                httpError={this.state.httpError}
                breadCrumb={this.state.breadCrumb}
                {...this.props}
            >
                <Banner
                    imgSrc={"/assets/images/background/doc.jpg"}
                    title={"Espace documentation"}
                    search={!this.state.loader}
                    selects={this.state.selects}
                    onSearch={(searchText) => this.handleSearch(searchText)}
                    {...this.props}
                />
                {content}
            </Master>
        );
    }
}
