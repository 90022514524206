import React from "react";
import PropTypes from "prop-types";
import "./ToolsManage.scss";
import Auth from "../../Security/Auth";
import Roles from "../../Security/Roles";
import Archive from "./Icons/Archive";

export default class ToolsManage extends React.Component {
    static propTypes = {
        // Required role to be allowed to manage resource
        // This could be a role, or a callback that must return a boolean after checking permissions
        role: PropTypes.oneOfType([
            PropTypes.shape({
                uid: PropTypes.string,
                rank: PropTypes.number,
            }),
            PropTypes.func,
        ]),
        add: PropTypes.bool,
        edit: PropTypes.bool,
        delete: PropTypes.bool,
        archive: PropTypes.bool,
        onChange: PropTypes.func,
        onAdd: PropTypes.func,
        onEdit: PropTypes.func,
        onDelete: PropTypes.func,
        onArchive: PropTypes.func,
        disableToggle: PropTypes.bool,
        addActive: PropTypes.bool,
        editActive: PropTypes.bool,
        deleteActive: PropTypes.bool,
        archiveActive: PropTypes.bool,
    };

    static defaultProps = {
        add: true,
        edit: true,
        delete: true,
        archive: false,
        addActive: false,
        editActive: false,
        deleteActive: false,
        archiveActive: false,
        role: Roles.ROLE_ROOT,
        onChange: () => {},
        onAdd: () => {},
        onEdit: () => {},
        onDelete: () => {},
        onArchive: () => {},
    };

    constructor(props) {
        super(props);
        this.toolsManageRef = React.createRef();
    }

    componentDidMount() {
        if (this.props.addActive) {
            this.toolsManageRef.current
                .querySelector(".add")
                .classList.add("active");
        } else if (this.props.editActive) {
            this.toolsManageRef.current
                .querySelector(".edit")
                .classList.add("active");
        } else if (this.props.deleteActive) {
            this.toolsManageRef.current
                .querySelector(".delete")
                .classList.add("active");
        } else if (this.props.archiveActive) {
            this.toolsManageRef.current
                .querySelector(".archive")
                .classList.add("active");
        }
    }

    _authenticatedUserIsAllowed() {
        if (typeof this.props.role === "function") {
            return this.props.role(Auth.getUser(), Auth.getRole());
        } else {
            return Auth.getRoleRank() >= this.props.role.rank;
        }
    }

    toggleActive(e) {
        if (this.props.disableToggle) return;

        const BUTTONS = this.toolsManageRef.current.querySelectorAll("button");
        const BUTTONS_CLICKED = e.currentTarget;

        // Remove all active button
        for (let i = 0; i < BUTTONS.length; ++i) {
            if (BUTTONS_CLICKED.className === BUTTONS[i].className) {
                if (BUTTONS_CLICKED.classList.contains("active")) {
                    BUTTONS_CLICKED.classList.remove("active");
                } else {
                    BUTTONS_CLICKED.classList.add("active");
                }
            } else {
                BUTTONS[i].classList.remove("active");
            }
        }

        this.props.onChange();
    }

    render() {
        if (!this._authenticatedUserIsAllowed()) {
            return <div className={"toolsManage notAllowed"} />;
        }

        return (
            <div className={"toolsManage"} ref={this.toolsManageRef}>
                <ul>
                    {/* Add */}
                    {this.props.add && (
                        <li>
                            <button
                                type={"button"}
                                title={"Ajouter"}
                                className={"add"}
                                onClick={(e) => {
                                    this.toggleActive(e);
                                    this.props.onAdd();
                                }}
                            >
                                <svg
                                    id="Calque_1"
                                    data-name="Calque 1"
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="64"
                                    height="64"
                                    viewBox="0 0 64 64"
                                >
                                    <defs>
                                        <clipPath id="clip-path">
                                            <circle
                                                cx="32"
                                                cy="32"
                                                r="32"
                                                fill="none"
                                            />
                                        </clipPath>
                                    </defs>
                                    <title>Ajouter</title>
                                    <g id="Groupe_409" data-name="Groupe 409">
                                        <g
                                            id="Groupe_222"
                                            data-name="Groupe 222"
                                        >
                                            <g
                                                id="Ellipse_39"
                                                data-name="Ellipse 39"
                                            >
                                                <circle
                                                    cx="32"
                                                    cy="32"
                                                    r="32"
                                                    fill="#fff"
                                                />
                                                <circle
                                                    cx="32"
                                                    cy="32"
                                                    r="31"
                                                    fill="none"
                                                    stroke="#0449a7"
                                                    strokeWidth="2"
                                                />
                                            </g>
                                            <g clipPath="url(#clip-path)">
                                                <g
                                                    id="Groupe_de_masques_10"
                                                    data-name="Groupe de masques 10"
                                                >
                                                    <g id="baseline-add-24px">
                                                        <path
                                                            id="Tracé_216"
                                                            data-name="Tracé 216"
                                                            d="M50.67,34.67h-16v16H29.33v-16h-16V29.33h16v-16h5.34v16h16Z"
                                                            fill="#0449a7"
                                                        />
                                                        <path
                                                            id="Tracé_217"
                                                            data-name="Tracé 217"
                                                            d="M0,0H64V64H0Z"
                                                            fill="none"
                                                        />
                                                    </g>
                                                </g>
                                            </g>
                                        </g>
                                        <g clipPath="url(#clip-path)">
                                            <g
                                                id="Groupe_de_masques_16"
                                                data-name="Groupe de masques 16"
                                            >
                                                <g id="baseline-add-24px-2">
                                                    <path
                                                        id="Tracé_237"
                                                        data-name="Tracé 237"
                                                        d="M50.67,34.67h-16v16H29.33v-16h-16V29.33h16v-16h5.34v16h16Z"
                                                        fill="#0449a7"
                                                    />
                                                    <path
                                                        id="Tracé_238"
                                                        data-name="Tracé 238"
                                                        d="M0,0H64V64H0Z"
                                                        fill="none"
                                                    />
                                                </g>
                                            </g>
                                        </g>
                                    </g>
                                </svg>
                            </button>
                        </li>
                    )}
                    {/* Edit */}
                    {this.props.edit && (
                        <li>
                            <button
                                type={"button"}
                                title={"Éditer"}
                                className={"edit"}
                                onClick={(e) => {
                                    this.toggleActive(e);
                                    this.props.onEdit();
                                }}
                            >
                                <svg
                                    id="Calque_1"
                                    data-name="Calque 1"
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="64"
                                    height="64"
                                    viewBox="0 0 64 64"
                                >
                                    <title>Editer</title>
                                    <g id="Groupe_409" data-name="Groupe 409">
                                        <g
                                            id="Groupe_222"
                                            data-name="Groupe 222"
                                        >
                                            <g
                                                id="Tracé_269"
                                                data-name="Tracé 269"
                                            >
                                                <path
                                                    className="penTraceBackground"
                                                    d="M32,0A32,32,0,1,1,0,32,32,32,0,0,1,32,0Z"
                                                    fill="none"
                                                />
                                                <path
                                                    className="penTraceBorder"
                                                    d="M32,2A30,30,0,0,0,2,32,30,30,0,0,0,32,62,30,30,0,0,0,62,32,30,30,0,0,0,32,2m0-2A32,32,0,1,1,0,32,32,32,0,0,1,32,0Z"
                                                    fill="#0449a7"
                                                />
                                            </g>
                                            <g
                                                id="Groupe_de_masques_11"
                                                data-name="Groupe de masques 11"
                                            >
                                                <g id="baseline-create-24px">
                                                    <path
                                                        className="penTrace"
                                                        data-name="Tracé 218"
                                                        d="M14.27,42.34v7.39h7.39L43.44,27.94l-7.38-7.38ZM49.16,22.23a2,2,0,0,0,0-2.77h0l-4.61-4.61a2,2,0,0,0-2.78,0h0l-3.6,3.6,7.39,7.39,3.6-3.6Z"
                                                        fill="#0449a7"
                                                    />
                                                    <path
                                                        data-name="Tracé 219"
                                                        d="M8.36,8.36H55.64V55.64H8.36Z"
                                                        fill="none"
                                                    />
                                                </g>
                                            </g>
                                        </g>
                                    </g>
                                </svg>
                            </button>
                        </li>
                    )}
                    {/* Archive */}
                    {this.props.archive && (
                        <li>
                            <button
                                type={"button"}
                                title={"Archiver"}
                                className={"archive"}
                                onClick={(e) => {
                                    this.toggleActive(e);
                                    this.props.onArchive();
                                }}
                            >
                                <Archive />
                            </button>
                        </li>
                    )}
                    {/* Delete */}
                    {this.props.delete && (
                        <li>
                            <button
                                type={"button"}
                                title={"Supprimer"}
                                className={"delete"}
                                onClick={(e) => {
                                    this.toggleActive(e);
                                    this.props.onDelete();
                                }}
                            >
                                <svg
                                    id="Calque_1"
                                    data-name="Calque 1"
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="64"
                                    height="64"
                                    viewBox="0 0 64 64"
                                >
                                    <defs>
                                        <clipPath id="clip-path">
                                            <circle
                                                cx="32"
                                                cy="32"
                                                r="23.64"
                                                fill="none"
                                            />
                                        </clipPath>
                                    </defs>
                                    <title>Supprimer</title>
                                    <g id="Groupe_409" data-name="Groupe 409">
                                        <g
                                            id="Groupe_222"
                                            data-name="Groupe 222"
                                        >
                                            <g
                                                id="Ellipse_42"
                                                data-name="Ellipse 42"
                                            >
                                                <circle
                                                    cx="32"
                                                    cy="32"
                                                    r="32"
                                                    fill="#fff"
                                                />
                                                <circle
                                                    className="penTraceBackground"
                                                    cx="32"
                                                    cy="32"
                                                    r="31"
                                                    fill="none"
                                                    stroke="#0449a7"
                                                    strokeWidth="2"
                                                />
                                            </g>
                                            <g clipPath="url(#clip-path)">
                                                <g
                                                    id="Groupe_de_masques_13"
                                                    data-name="Groupe de masques 13"
                                                >
                                                    <g
                                                        id="baseline-restore_from_trash-24px"
                                                        data-name="baseline-restore from trash-24px"
                                                    >
                                                        <path
                                                            className="penTrace"
                                                            id="Union_6"
                                                            data-name="Union 6"
                                                            d="M24.12,49.73a4,4,0,0,1-3.94-3.94V22.15H43.82V45.79a4,4,0,0,1-3.94,3.94ZM18.21,20.18V16.24h6.9l2-2h9.85l2,2h6.89v3.94Z"
                                                            fill="#0449a7"
                                                        />
                                                        <path
                                                            id="Tracé_223"
                                                            data-name="Tracé 223"
                                                            d="M8.36,8.36H55.64V55.64H8.36Z"
                                                            fill="none"
                                                        />
                                                    </g>
                                                </g>
                                            </g>
                                        </g>
                                    </g>
                                </svg>
                            </button>
                        </li>
                    )}
                </ul>
            </div>
        );
    }
}
