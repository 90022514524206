import React from "react";
import PropTypes from "prop-types";
import ApiCompanies from "../../../Services/ApiCompanies";
import { THEMATIC_TYPE_ALL } from "../../../Services/ApiThematics";
import ApiProfile from "../../../Services/ApiProfile";
import ApiUser from "../../../Services/ApiUser";
import ApiWorkGroupUser, {
    MEMBER_TYPE_ADMINISTRATOR,
    MEMBER_TYPE_ANIMATOR,
    MEMBER_TYPE_MEMBER,
} from "../../../Services/ApiWorkGroupUser";
import InputRadioSimple from "../InputRadioSimple/InputRadioSimple";
import InputTextLight from "../InputTextLight/InputTextLight";
import SelectWorkGroups from "../SelectWorkGroups/SelectWorkGroups";
import ButtonEdit from "../Button/ButtonEdit/ButtonEdit";
import Select from "../Select/Select";
import Loader from "../../Loader/Loader";
import RadioRoles from "../../Admin/Form/Input/RadioRoles/RadioRoles";
import Roles from "../../../Security/Roles";
import SelectCategories from "../SelectCategories/SelectCategories";
import Modal from "../../Modal/Modal";
import ThematicsRectangle from "../ThematicsRectangle/ThematicsRectangle";
import CategoryTree from "../../CategoryTree/CategoryTree";
import InputEmail from "../InputEmail/InputEmail";
import ListTags from "../../List/ListTags/ListTags";
import ListRoleLegend from "../../List/ListRoleLegend/ListRoleLegend";
import Thematics from "../../../Utils/Thematics";
import { DateHandler } from "../../../Utils/DateHandler";
import "./FormAddUser.scss";
import Auth from "../../../Security/Auth";

function fixDates(obj) {
    const fixedObj = {};

    Object.keys(obj).forEach((key) => {
        const item = obj[key];
        if (item.date && item.timezone && item.timezone_type) {
            fixedObj[key] = DateHandler.formatForApi(item.date);
        } else {
            fixedObj[key] = item;
        }
    });

    return fixedObj;
}
export default class FormAddUser extends React.Component {
    static propTypes = {
        user: PropTypes.object,
        roles: PropTypes.array.isRequired,
        onCancel: PropTypes.func,
        onValid: PropTypes.func,
    };

    state = {
        loading: true,
        currentStep: 1,
        showWorkGroups: true,
        roles: [],
        allRoles: [],
        companies: [],
        thematics: [],
        thematicTree: [],
        emailValid: true,
        categories: [],
        initialWorkGroups: [],
        workGroupsOfUser: [],
        workGroupsContributor: [],
        form: {
            user: {
                // Step 1
                membertype: "ASFA",
                // Step 2
                email: "",
            },
            profile: {
                lastname: "",
                firstname: "",
                address: "",
                zipcode: "",
                company: {
                    id: 0,
                },
                city: "",
                job: "",
                phone: "",
                mobile: "",
                email_subscription: false,
            },
            // Step 3,
            workgroups: [],
            // Step 4
            role: {
                id: 0,
                uid: "",
                name: "",
                description: "",
            },
            thematicsadministrator: [],
            // Step 5,
            categories: [],
        },
        error: {
            lastname: "",
            firstname: "",
            company: "",
            job: "",
            email: "",
            role: "",
            userCreation: "",
            workgroups: "",
            phone: "",
            mobile: "",
        },
    };

    memberTypes = {
        ASFA: { label: "Membre ASFA", id: "ASFA" },
        SCA: { label: "Membre SCA", id: "SCA" },
        EXTERNAL: { label: "Membre externe", id: "EXTERNAL" },
    };

    constructor(props) {
        super(props);
        this._isMounted = false;
        if (
            this.props.user &&
            this.props.user.id > 0 &&
            this.props.user.approvedat
        ) {
            this.state.currentStep = 6;
        }

        this._currentUserId = Auth.getUser().id;
        this.localThematics = Thematics.getLocalThematics(THEMATIC_TYPE_ALL);
    }

    async componentDidMount() {
        this._isMounted = true;

        let stateToSet = { ...this.state };

        const USER = { ...this.props.user };
        if (USER && USER.hasOwnProperty("profile")) delete USER.profile;

        const ROLES = this.props.roles;

        // Get all companies and thematics
        const COMPANIES = await ApiCompanies.getAll();
        COMPANIES.sort((a, b) => {
            return a.name > b.name ? 1 : -1;
        });
        // Transform thematics into array
        const THEMATICS = Object.keys(this.localThematics).map((key) => {
            return this.localThematics[key];
        });

        const DONT_SELECT = ["Affaires sociales (PRIVE)"];

        if (this.props.user) {
            const PROFILE = this.props.user.profile;

            let userRole = { uid: USER.highestrole };
            ROLES.forEach((role) => {
                if (USER.highestrole === role.uid) {
                    userRole = {
                        uid: role.uid,
                        description: role.description,
                        name: role.name,
                    };
                }
            });

            // We need to get user's workGroups
            let promiseWorkGroups = this.getUserWorkGroups(USER.id);

            // Get user's categories
            let promiseCategories = this.getUserCategories(USER.id);

            // For administrators, we get their handles thematics
            let promiseThematicsAdmin = this.getUserThematicsAdministrator(
                USER.id,
                userRole.uid
            );

            // Fetch all async data
            let [
                workGroups,
                categories,
                thematicsAdministrator,
            ] = await Promise.all([
                promiseWorkGroups,
                promiseCategories,
                promiseThematicsAdmin,
            ]);

            let categoriesWithoutParent = [];

            categories.forEach((category) => {
                if (category.categories.length === 0) {
                    categoriesWithoutParent.push(category);
                }
            });

            USER.workgroups = workGroups;
            USER.categories = categoriesWithoutParent;

            stateToSet = {
                form: {
                    user: USER,
                    profile: PROFILE,
                    role: userRole,
                    workgroups: USER.workgroups,
                    categories: USER.approvedat
                        ? USER.categories
                        : this.selectAllCategories(THEMATICS, DONT_SELECT),
                    thematicsadministrator: thematicsAdministrator,
                },
                showWorkGroups: workGroups.length > 0,
                initialWorkGroups: [...workGroups],
            };
        } else {
            stateToSet.form = {
                ...stateToSet.form,
                categories: this.selectAllCategories(THEMATICS, DONT_SELECT),
            };
        }

        // Set roles
        if (ROLES) {
            stateToSet.roles = ROLES;
            stateToSet.allRoles = ROLES;
        }

        // Finally merge all states
        stateToSet = {
            ...stateToSet,
            companies: COMPANIES,
            thematics: THEMATICS,
            thematicTree: THEMATICS,
            categories: THEMATICS,
            loading: false,
        };

        this.setState(stateToSet, () => {
            this._defineContributorOnWorkGroups(
                USER.highestrole === Roles.ROLE_CONTRIBUTOR.uid ? null : false
            );
        });

        this.formRef = React.createRef();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    getUserThematicsAdministrator(userId, roleUID) {
        return new Promise(async (resolve) => {
            let thematicsAdministrator = [];
            if (roleUID === Roles.ROLE_ADMINISTRATOR.uid) {
                thematicsAdministrator = await ApiUser.getThematicsAdministrator(
                    userId
                );
                // Just keep IDs
                thematicsAdministrator = thematicsAdministrator.map(
                    (thematicAdministrator) => {
                        return thematicAdministrator.id;
                    }
                );
            }

            resolve(thematicsAdministrator);
        });
    }

    getUserCategories(userId) {
        return new Promise(async (resolve) => {
            const CATEGORIES = await ApiUser.getCategories(userId);

            resolve(
                CATEGORIES.map((category) => {
                    category.color = this.localThematics[
                        category["thematicid"]
                    ].color;
                    return category;
                })
            );
        });
    }

    getUserWorkGroups(userId) {
        return ApiWorkGroupUser.getAllOfUser(userId);
    }

    selectAllCategories(
        items,
        dontSelect = [],
        categories = [],
        excludeAcsID = []
    ) {
        // Force lower case to avoid issue
        dontSelect = dontSelect.map((value) => {
            return value.toLowerCase();
        });

        items.forEach((item) => {
            if (item["thematicid"]) {
                item.color = this.localThematics[item["thematicid"]].color;
            }

            const SUB_CATEGORIES =
                item.categories && item.categories.length > 0
                    ? item.categories
                    : null;

            if (SUB_CATEGORIES) {
                // Add excludeAcsID in array if not exist
                if (
                    item.thematic &&
                    dontSelect.indexOf(
                        item.thematic.name.toLocaleLowerCase()
                    ) !== -1
                ) {
                    excludeAcsID.push(item.id);
                } else if (
                    excludeAcsID.indexOf(item.parentid) !== -1 &&
                    excludeAcsID.indexOf(item.id) === -1
                ) {
                    excludeAcsID.push(item.id);
                }

                return this.selectAllCategories(
                    item.categories,
                    dontSelect,
                    categories,
                    excludeAcsID
                );
            }
            // Push element except "dontSelect" categories and children
            else if (
                item.thematic &&
                dontSelect.indexOf(item.thematic.name.toLocaleLowerCase()) ===
                    -1 &&
                item.parent &&
                excludeAcsID.indexOf(item.parentid) === -1
            ) {
                categories.push(item);
            }
        });

        return categories;
    }

    handleUserFormValue(key, value) {
        this.setState((prevState) => ({
            form: {
                ...prevState.form,
                user: {
                    ...prevState.form.user,
                    [key]: value,
                },
            },
        }));
    }

    handleProfileFormValue(key, value) {
        this.setState((prevState) => ({
            form: {
                ...prevState.form,
                profile: {
                    ...prevState.form.profile,
                    [key]: value,
                },
            },
        }));
    }

    handleCompanyFormValue(values) {
        this.setState((prevState) => ({
            form: {
                ...prevState.form,
                profile: {
                    ...prevState.form.profile,
                    company: {
                        ...prevState.form.profile.company,
                        id: values.id,
                        name: values.name,
                    },
                },
            },
        }));
    }

    handleFormValue(key, value) {
        this.setState((state) => ({
            form: {
                ...state.form,
                [key]: value,
            },
        }));
    }

    removeContributor(removedItemId) {
        let userRelatedWorkGroups = this.state.form.workgroups;
        let workGroupsContributor = this.state.workGroupsContributor;

        for (let i = 0; i < userRelatedWorkGroups.length; i++) {
            if (removedItemId === userRelatedWorkGroups[i].workgroup.id) {
                // Update relation between user and workgroup (he is no longer a contributor)
                userRelatedWorkGroups[i].iscontributor = false;

                // Also handle the workgroup tag list
                for (let j = 0; j < workGroupsContributor.length; j++) {
                    if (removedItemId === workGroupsContributor[j].id) {
                        workGroupsContributor.splice(j, 1);
                        break; // We found it, stop loop
                    }
                }
                break; // We found it, stop loop
            }
        }

        this.handleFormValue("workgroups", userRelatedWorkGroups);
        this.setState({ workGroupsContributor: workGroupsContributor });
    }

    checkFormValid() {
        let formHasError = false;

        return new Promise((resolve, reject) => {
            switch (this.state.currentStep) {
                case 2:
                    this.setState(
                        {
                            error: {
                                lastname: this.state.form.profile.lastname
                                    ? ""
                                    : "Le nom est requis",
                                firstname: this.state.form.profile.firstname
                                    ? ""
                                    : "Le nom est requis",
                                company:
                                    this.state.form.profile.company.id > 0
                                        ? ""
                                        : "La société est requise",
                                job: this.state.form.profile.job
                                    ? ""
                                    : "La fonction est requise",
                                email: this.state.form.user.email
                                    ? ""
                                    : `L'adresse email est requise`,
                                phone: this.state.form.profile.phone
                                    ? this.isValidPhoneNumber(
                                          this.state.form.profile.phone
                                      )
                                        ? ""
                                        : `Numéros à 10 chiffres seulement`
                                    : "",
                                mobile: this.state.form.profile.mobile
                                    ? this.isValidPhoneNumber(
                                          this.state.form.profile.mobile
                                      )
                                        ? ""
                                        : `Numéros à 10 chiffres seulement`
                                    : "",
                            },
                        },
                        async () => {
                            for (let property in this.state.error) {
                                if (
                                    this.state.error.hasOwnProperty(property) &&
                                    this.state.error[property] !== ""
                                ) {
                                    formHasError = true;
                                }
                            }

                            if (!this.state.emailValid) {
                                formHasError = true;
                            }

                            // Check that the email is not already used
                            if (this.state.error.email === "") {
                                const EXISTING_USER = await ApiUser.getUserByEmail(
                                    this.state.form.user.email,
                                    this.props.user && this.props.user.id > 0
                                        ? this.props.user.id
                                        : 0
                                );

                                if (EXISTING_USER) {
                                    formHasError = true;
                                    this.setState(
                                        {
                                            error: {
                                                ...this.state.error,
                                                email:
                                                    "Cette adresse e-mail est déjà utilisée",
                                            },
                                        },
                                        () => {
                                            formHasError ? reject() : resolve();
                                        }
                                    );
                                } else {
                                    formHasError ? reject() : resolve();
                                }
                            } else {
                                reject();
                            }
                        }
                    );

                    break;

                case 3:
                    formHasError =
                        this.state.showWorkGroups &&
                        this.state.form.workgroups.length === 0;

                    if (!formHasError && !this.state.showWorkGroups) {
                        this.handleFormValue("workgroups", []);
                        this.setState({ error: { workgroups: "" } }, () =>
                            resolve()
                        );
                    } else if (formHasError) {
                        this.setState(
                            {
                                error: {
                                    workgroups:
                                        "Vous devez sélectionner un groupe de travail",
                                },
                            },
                            () => {
                                reject();
                            }
                        );
                    } else {
                        this.setState({ error: { workgroups: "" } }, () =>
                            resolve()
                        );
                    }

                    break;

                case 4:
                    this.setState(
                        {
                            error: {
                                role:
                                    this.state.form.role.uid !== ""
                                        ? ""
                                        : "Vous devez sélectionnez un rôle",
                            },
                        },
                        () => {
                            for (let property in this.state.error) {
                                if (
                                    this.state.error.hasOwnProperty(property) &&
                                    this.state.error[property] !== ""
                                ) {
                                    formHasError = true;
                                }
                            }

                            formHasError ? reject() : resolve();
                        }
                    );

                    break;

                default:
                    resolve();
                    break;
            }
        });
    }

    isValidPhoneNumber(phone) {
        if ("" === phone) {
            return true; // Allow empty values
        }

        return /^\d{10}$/.test(phone);
    }

    filterAvailableRolesFromMemberType(memberType) {
        let roles = [];
        if (memberType === this.memberTypes.EXTERNAL.id) {
            this.state.roles.forEach((role) => {
                if (role.rank <= Roles.ROLE_CONTRIBUTOR.rank) {
                    roles.push(role);
                }
            });
            this.setState({ roles: roles }, () => {
                this.handleFormValue("role", Roles.ROLE_USER);
            });
        } else {
            roles = this.state.allRoles;
            this.setState({ roles: roles });
        }
    }

    back() {
        let prevStep = this.state.currentStep - 1;
        if (
            this.state.currentStep === 5 &&
            this.state.form.user.id === this._currentUserId
        ) {
            prevStep--;
        }

        this.setState(
            (state) => ({ currentStep: prevStep }),
            () => (this.formRef.current.parentElement.scrollTop = 0)
        );
    }

    next() {
        this.checkFormValid()
            .then(() => {
                if (!this._isMounted) return;

                let goToNext = true;
                let nextStep = this.state.currentStep + 1;

                switch (this.state.currentStep) {
                    case 3:
                        if (this.state.form.user.id === this._currentUserId) {
                            nextStep++;
                        }
                        if (
                            this.state.form.role.uid ===
                            Roles.ROLE_CONTRIBUTOR.uid
                        ) {
                            this._defineContributorOnWorkGroups();
                        } else {
                            this._defineContributorOnWorkGroups(false);
                        }

                        let roles = [];
                        if (!this.state.showWorkGroups) {
                            this.state.roles.forEach((role) => {
                                if (role.uid !== Roles.ROLE_CONTRIBUTOR.uid) {
                                    roles.push(role);
                                }
                            });
                            this.setState({ roles: roles }, () => {
                                this.handleFormValue("role", Roles.ROLE_USER);
                            });
                        } else {
                            roles = this.state.allRoles;
                            this.setState({ roles: roles });
                        }

                        break;
                    case 4:
                        if (this.state.form.role.uid === Roles.ROLE_ROOT.uid) {
                            this.setState(
                                () => ({ currentStep: 6 }),
                                () =>
                                    (this.formRef.current.parentElement.scrollTop = 0)
                            );
                            goToNext = false;
                        }
                        break;

                    default:
                        goToNext = true;
                        break;
                }

                if (goToNext) {
                    this.setState(
                        (state) => ({ currentStep: nextStep }),
                        () => (this.formRef.current.parentElement.scrollTop = 0)
                    );
                }
            })
            .catch(() => {
                // Nothing... we display error by settings states, but leave it to prevent not necessary browser console error logs
            });

        this.props.onValid();
    }

    _saveWorkGroupsUser(workGroupsUser, userId) {
        // Add the workgroup to the user
        let wgUserPromises = [];

        for (let i = 0; i < workGroupsUser.length; i++) {
            wgUserPromises.push(
                ApiWorkGroupUser.add(
                    userId,
                    workGroupsUser[i]["workgroup"]["id"],
                    workGroupsUser[i]["membertype"] || MEMBER_TYPE_MEMBER,
                    workGroupsUser[i]["iscontributor"],
                    ["user.profile.company", "workgroup"]
                )
            );
            wgUserPromises.push(
                ApiUser.addSubscription(
                    { workgroups: workGroupsUser[i]["workgroup"]["id"] },
                    userId
                )
            );
        }

        // Wait for all promises to finish
        return Promise.all(wgUserPromises);
    }

    validateForm() {
        this.setState({ loading: true }, async () => {
            let refreshedUserData = [];
            const USER_DATA = this.state.form.user;
            USER_DATA.roles = this.state.form.role.uid;
            USER_DATA.thematicsadministrator = this.state.form.thematicsadministrator;
            USER_DATA.workgroups = this.state.form.workgroups;
            USER_DATA.categories = this.state.form.categories;

            // Save the workGroups before remove it from the user's data that we will send
            let workGroupsUser = USER_DATA.workgroups;
            delete USER_DATA.workgroups;
            delete USER_DATA["selfsigned"];

            // Update member
            if (this.state.form.user && this.state.form.user.id > 0) {
                USER_DATA.profile = this.state.form.profile;
                try {
                    // TODO - I don't know from where is it coming!? On User entity there is no $role property...
                    delete USER_DATA["role"];
                    let toApproved = false;

                    // Approve the user
                    if (!USER_DATA.approvedat) {
                        await ApiUser.approve(USER_DATA.id);
                        USER_DATA.approvedat = DateHandler.formatForApi(
                            new Date()
                        );
                        toApproved = true;
                    }

                    // Update profile
                    await ApiProfile.update(
                        USER_DATA.profile.id,
                        USER_DATA.profile
                    );

                    // Delete all user's categories before resending it
                    await ApiUser.deleteCategories(USER_DATA.id);

                    // Delete all user's roles before resending it if it's not the current user
                    if (USER_DATA.id !== this._currentUserId) {
                        await ApiUser.deleteRoles(USER_DATA.id);
                    }

                    if (!this._isMounted) return;

                    let categories = [];

                    USER_DATA.categories.forEach((category) => {
                        categories.push({
                            id: category.id,
                            parentid: category.parentid,
                        });
                    });

                    USER_DATA.categories = categories;

                    // Then user
                    const updateParams = {
                        ...fixDates(USER_DATA),
                        profile: fixDates(USER_DATA.profile),
                        categories: fixDates(USER_DATA.categories),
                    };
                    refreshedUserData = await ApiUser.update(updateParams);

                    // Remove link between workgroup and users before relink it
                    let wgUserPromises = [];
                    for (
                        let i = 0;
                        i < this.state.initialWorkGroups.length;
                        i++
                    ) {
                        if (
                            this.state.initialWorkGroups[i].hasOwnProperty(
                                "id"
                            ) &&
                            this.state.initialWorkGroups[i]["id"] > 0
                        ) {
                            wgUserPromises.push(
                                ApiWorkGroupUser.remove(
                                    this.state.initialWorkGroups[i]["id"]
                                )
                            );
                        }
                    }

                    // Wait for all promises to finish
                    await Promise.all(wgUserPromises);

                    // Add the workgroup to the user
                    refreshedUserData.workgroups = await this._saveWorkGroupsUser(
                        workGroupsUser,
                        USER_DATA.id
                    );

                    refreshedUserData.update = true;

                    this.setState({ loading: false }, () =>
                        this.props.onValid(refreshedUserData, toApproved)
                    );
                } catch (error) {
                    if (!this._isMounted) return;
                    this.setState({
                        error: { userCreation: "Une erreur est survenue" },
                    });
                } finally {
                    this.setState({ loading: false });
                }
            }
            // Add member
            else {
                try {
                    // Firstly we add profile
                    const PROFILE = await ApiProfile.add(
                        this.state.form.profile
                    );
                    try {
                        USER_DATA.profile = PROFILE.id;

                        let categories = [];

                        USER_DATA.categories.forEach((category) => {
                            categories.push({
                                id: category.id,
                                parentid: category.parentid,
                            });
                        });

                        USER_DATA.categories = categories;

                        refreshedUserData = await ApiUser.add(USER_DATA);
                        refreshedUserData.profile = this.state.form.profile;
                        refreshedUserData.profile.id = USER_DATA.profile;
                        refreshedUserData.highestrole = this.state.form.role.uid;

                        // Add the workgroup to the user
                        refreshedUserData.workgroups = await this._saveWorkGroupsUser(
                            workGroupsUser,
                            refreshedUserData["id"]
                        );
                    } catch (error) {
                        // Cannot insert User, remove profile...
                        ApiProfile.delete(PROFILE.id);
                        throw error;
                    }

                    this.setState({ loading: false }, () =>
                        this.props.onValid(refreshedUserData)
                    );
                } catch (error) {
                    if (error.message) {
                        console.error(error.message);
                    }
                    this.setState({
                        error: { userCreation: "Une erreur est survenue" },
                    });
                } finally {
                    this.setState({ loading: false });
                }
            }
        });
    }

    _defineContributorOnWorkGroups(isContributor = null) {
        let workGroupsUsers = this.state.form.workgroups;
        let workGroupsContributor = [];
        let workGroupsOfUser = [];

        for (let i = 0; i < workGroupsUsers.length; i++) {
            let workGroupUser = workGroupsUsers[i];

            if (workGroupUser.workgroup) {
                workGroupUser.workgroup.membertype = parseInt(
                    workGroupUser.membertype
                );
            }
            if (isContributor !== null) {
                workGroupUser.iscontributor = isContributor;
            }

            workGroupsOfUser.push(workGroupUser.workgroup);

            let found = 0;
            for (let j = 0; j < workGroupsContributor.length; j++) {
                if (
                    workGroupsContributor[j].id === workGroupUser.workgroup.id
                ) {
                    found = i;
                    break; // We found it, stop loop
                }
            }

            // User is no longer a contributor, so remove the workgroup from the tag list
            if (
                found > 0 &&
                !workGroupUser.iscontributor &&
                workGroupsContributor.length > 0
            ) {
                workGroupsContributor = workGroupsContributor.splice(found, 1);
            }
            // User is a contributor, add the workgroup into the tag list if not already done
            else if (found <= 0 && workGroupUser.iscontributor) {
                workGroupsContributor.push(workGroupUser.workgroup);
            }
        }

        this.handleFormValue("workgroups", workGroupsUsers);
        this.setState({
            workGroupsContributor: workGroupsContributor,
            workGroupsOfUser: workGroupsOfUser,
        });
    }

    handleRadioRolesChange(role) {
        const ROLE = JSON.parse(role);
        this.handleFormValue("role", ROLE);

        if (ROLE.uid === Roles.ROLE_CONTRIBUTOR.uid) {
            this._defineContributorOnWorkGroups(true);
        } else {
            this._defineContributorOnWorkGroups(false);
        }
    }

    onRemoveWorkGroup(workGroup) {
        if (workGroup) {
            let workGroupsContributor = this.state.workGroupsContributor;
            let workGroupsUser = this.state.form.workgroups;

            // Let's find the workgroup in the list
            for (let j = 0; j < workGroupsUser.length; j++) {
                if (workGroup.id === workGroupsUser[j].workgroup.id) {
                    // We found it, remove it then
                    workGroupsUser.splice(j, 1);

                    // Also find it in the workgroup list for which user could be contributor
                    for (let i = 0; i < workGroupsContributor.length; i++) {
                        if (workGroupsContributor[i].id === workGroup.id) {
                            workGroupsContributor.splice(i, 1);
                        }
                    }

                    break; // We don't have to continue the loop
                }
            }

            // Finally update states
            this.handleFormValue("workgroups", workGroupsUser);
            this.setState({ workGroupsContributor: workGroupsContributor });
        }
    }

    onAddWorkGroup(workGroup) {
        let workGroupsContributor = this.state.workGroupsContributor;
        let workGroupsUser = this.state.form.workgroups;
        let doPush = true;

        // Ensure that the workgroup is not already in the list
        for (let j = 0; j < workGroupsUser.length; j++) {
            if (workGroup.id === workGroupsUser[j].workgroup.id) {
                doPush = false;
                break;
            }
        }

        if (doPush) {
            workGroupsUser.push({
                userId: this.state.form.id || 0,
                membertype: workGroup.membertype || MEMBER_TYPE_MEMBER,
                iscontributor:
                    this.state.form.role.uid === Roles.ROLE_CONTRIBUTOR.uid,
                workgroup: workGroup,
            });

            // If contributor and we are adding a new workgroup, we must add it to the tags list of workGroup for which user is contributor
            if (this.state.form.role.uid === Roles.ROLE_CONTRIBUTOR.uid) {
                workGroupsContributor.push(workGroup);
            }

            // Update state!
            this.handleFormValue("workgroups", workGroupsUser);
            this.setState({ workGroupsContributor: workGroupsContributor });
        }
    }

    render() {
        let adminRoleSelectedThematics = [];
        let adminRoleSelectedThematicsUid = [];

        this.state.thematics.forEach((thematic) => {
            if (
                this.state.form.thematicsadministrator &&
                this.state.form.thematicsadministrator.indexOf(thematic.id) >= 0
            ) {
                adminRoleSelectedThematics.push(thematic);
                adminRoleSelectedThematicsUid.push(thematic.id);
            }
        });

        const DATA_MEMBER_TYPES = [
            {
                id: "membre-asfa",
                value: this.memberTypes.ASFA.id,
                label: this.memberTypes.ASFA.label,
                checked:
                    this.state.form.user.membertype === "ASFA" ||
                    !this.state.form.user.membertype,
            },
            {
                id: "membre-sca",
                value: this.memberTypes.SCA.id,
                label: this.memberTypes.SCA.label,
                checked: this.state.form.user.membertype === "SCA",
            },
            {
                id: "membre-externe",
                value: this.memberTypes.EXTERNAL.id,
                label: this.memberTypes.EXTERNAL.label,
                checked: this.state.form.user.membertype === "EXTERNAL",
            },
        ];

        const FORM = (
            <>
                {/* Step 1 */}
                {this.state.currentStep === 1 && (
                    <div>
                        <h4 className={"titleHighlight"}>
                            Etape 1 - Type d'utilisateur
                        </h4>
                        <div className="coreModal">
                            <InputRadioSimple
                                name={"membertype"}
                                data={DATA_MEMBER_TYPES}
                                onChange={(value) => {
                                    this.handleUserFormValue(
                                        "membertype",
                                        value
                                    );
                                    this.filterAvailableRolesFromMemberType(
                                        value
                                    );
                                }}
                            />
                        </div>
                    </div>
                )}

                {/* Step 2 */}
                {this.state.currentStep === 2 && (
                    <div>
                        <h4 className={"titleHighlight"}>
                            Etape 2 - Informations personnelles
                        </h4>
                        <div className="coreModal">
                            <div className="row">
                                <div className="col-md-6">
                                    <InputTextLight
                                        label={"Nom*"}
                                        value={this.state.form.profile.lastname}
                                        placeholder={"Nom"}
                                        autoComplete={"off"}
                                        onChange={(e) =>
                                            this.handleProfileFormValue(
                                                "lastname",
                                                e.target.value
                                            )
                                        }
                                        errorMsg={this.state.error.lastname}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <InputTextLight
                                        label={"Prénom*"}
                                        value={
                                            this.state.form.profile.firstname
                                        }
                                        placeholder={"Prénom"}
                                        autoComplete={"off"}
                                        onChange={(e) =>
                                            this.handleProfileFormValue(
                                                "firstname",
                                                e.target.value
                                            )
                                        }
                                        errorMsg={this.state.error.firstname}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <Select
                                        placeholder={"Sélectionner la société"}
                                        label={"Société"}
                                        required={true}
                                        value={this.state.companies}
                                        selected={
                                            this.state.form.profile.company.id
                                        }
                                        errorMsg={this.state.error.company}
                                        onChange={(e) => {
                                            return this.handleCompanyFormValue({
                                                id: e.target.value,
                                                name: e.target.querySelector(
                                                    `option[value="${e.target.value}"]`
                                                ).innerHTML,
                                            });
                                        }}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <InputTextLight
                                        label={"Fonction*"}
                                        value={this.state.form.profile.job}
                                        placeholder={"Fonction"}
                                        autoComplete={"off"}
                                        onChange={(e) =>
                                            this.handleProfileFormValue(
                                                "job",
                                                e.target.value
                                            )
                                        }
                                        errorMsg={this.state.error.job}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <InputEmail
                                        label={"Adresse email*"}
                                        id={"user-email"}
                                        type={"email"}
                                        value={this.state.form.user.email}
                                        placeholder={"Adresse e-mail"}
                                        autoComplete={"off"}
                                        onChange={(e, hasError) => {
                                            this.handleUserFormValue(
                                                "email",
                                                e.target.value
                                            );
                                            this.setState({
                                                emailValid: !hasError,
                                            });
                                        }}
                                        errorMsg={this.state.error.email}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <InputTextLight
                                        label={"Adresse postale"}
                                        value={this.state.form.profile.address}
                                        placeholder={"Adresse"}
                                        autoComplete={"off"}
                                        onChange={(e) =>
                                            this.handleProfileFormValue(
                                                "address",
                                                e.target.value
                                            )
                                        }
                                    />
                                </div>
                                <div className="col-md-6">
                                    <InputTextLight
                                        label={"Code postal"}
                                        value={this.state.form.profile.zipcode}
                                        placeholder={"Code postal"}
                                        autoComplete={"off"}
                                        onChange={(e) =>
                                            this.handleProfileFormValue(
                                                "zipcode",
                                                e.target.value
                                            )
                                        }
                                    />
                                </div>
                                <div className="col-md-6">
                                    <InputTextLight
                                        label={"Ville"}
                                        value={this.state.form.profile.city}
                                        placeholder={"Ville"}
                                        autoComplete={"off"}
                                        onChange={(e) =>
                                            this.handleProfileFormValue(
                                                "city",
                                                e.target.value
                                            )
                                        }
                                    />
                                </div>
                                <div className="col-md-6">
                                    <InputTextLight
                                        label={"Téléphone fixe"}
                                        value={this.state.form.profile.phone}
                                        placeholder={"Téléphone fixe"}
                                        autoComplete={"off"}
                                        onChange={(e) =>
                                            this.handleProfileFormValue(
                                                "phone",
                                                e.target.value
                                            )
                                        }
                                        errorMsg={this.state.error.phone}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <InputTextLight
                                        label={"Téléphone mobile"}
                                        value={this.state.form.profile.mobile}
                                        placeholder={"Téléphone mobile"}
                                        autoComplete={"off"}
                                        onChange={(e) =>
                                            this.handleProfileFormValue(
                                                "mobile",
                                                e.target.value
                                            )
                                        }
                                        errorMsg={this.state.error.mobile}
                                    />
                                </div>
                            </div>
                            <p className={"required"}>
                                <b>* Champs obligatoires</b>
                            </p>
                        </div>
                    </div>
                )}

                {/* Step 3 */}
                {this.state.currentStep === 3 && (
                    <div>
                        <h4 className={"titleHighlight"}>
                            Etape 3 - Groupes de travail
                        </h4>
                        <div className="coreModal">
                            <h5 className={"subTitle"} style={{ marginTop: 0 }}>
                                L'utilisateur est-il membre de groupe de travail
                                ?
                            </h5>
                            <InputRadioSimple
                                name={"userHasWorkGroup"}
                                onChange={() => {
                                    this.setState((state) => ({
                                        showWorkGroups: !state.showWorkGroups,
                                    }));
                                }}
                                data={[
                                    {
                                        id: "userHasWorkGroupTrue",
                                        value: "oui",
                                        label: "Oui",
                                        checked: this.state.showWorkGroups,
                                    },
                                    {
                                        id: "userHasWorkGroupFalse",
                                        value: "non",
                                        label: "Non",
                                        checked: !this.state.showWorkGroups,
                                    },
                                ]}
                            />

                            {this.state.showWorkGroups && (
                                <div>
                                    <SelectWorkGroups
                                        title={
                                            "Sélectionnez les groupes de travail"
                                        }
                                        selected={this.state.workGroupsOfUser}
                                        selectAllButton={true}
                                        listRoleLegend={true}
                                        multiple={true}
                                        onRemove={(workGroup) =>
                                            this.onRemoveWorkGroup(workGroup)
                                        }
                                        onAdd={(workGroup) =>
                                            this.onAddWorkGroup(workGroup)
                                        }
                                    />
                                </div>
                            )}

                            {this.state.error.workgroups !== "" && (
                                <span className={"errorMsg"}>
                                    {this.state.error.workgroups}
                                </span>
                            )}
                        </div>
                    </div>
                )}

                {/* Step 4 */}
                {this.state.currentStep === 4 &&
                    this.state.form.user.id !== this._currentUserId && (
                        <div>
                            <h4 className={"titleHighlight"}>
                                Etape 4 - Rôle de l'utilisateur
                            </h4>
                            <RadioRoles
                                name={"role"}
                                roles={this.state.roles}
                                selectedRole={this.state.form.role.uid}
                                onChange={(role) =>
                                    this.handleRadioRolesChange(role)
                                }
                                administratorThematicsChecked={
                                    this.state.form.thematicsadministrator
                                }
                                onSelectAdministratorThematics={(
                                    adminThematics
                                ) => {
                                    this.handleFormValue(
                                        "thematicsadministrator",
                                        adminThematics
                                    );
                                }}
                                errorMsg={this.state.error.role}
                            />
                            {this.state.form.role.uid ===
                                Roles.ROLE_CONTRIBUTOR.uid && (
                                <ListTags
                                    tags={this.state.workGroupsContributor}
                                    onRemove={(item) =>
                                        this.removeContributor(item)
                                    }
                                />
                            )}
                        </div>
                    )}

                {/* Step 5 */}
                {this.state.currentStep === 5 && (
                    <div>
                        <h4 className={"titleHighlight"}>
                            Etape 5 - Droit de consultation
                        </h4>
                        <div className="coreModal">
                            <h5 className={"subTitle"} style={{ marginTop: 0 }}>
                                Droit de lecture uniquement des thématiques
                                suivantes :
                            </h5>
                            <SelectCategories
                                multiple={true}
                                title={"Sélectionnez les thématiques"}
                                selected={this.state.form.categories}
                                onChange={(thematics) =>
                                    this.handleFormValue(
                                        "categories",
                                        thematics
                                    )
                                }
                                categories={this.state.categories}
                                selectAllButton={true}
                                selectAllChildren={true}
                            />
                        </div>
                    </div>
                )}

                {/* Step 6 */}
                {this.state.currentStep === 6 && (
                    <div id={"summaryFormAddUser"}>
                        <h4 className={"titleHighlight"}>
                            Fiche récapitulative
                        </h4>

                        {/* User type */}
                        <h5 className={"subTitle"} style={{ marginTop: 0 }}>
                            <span>Type d'utilisateur</span>
                            <ButtonEdit
                                onClick={() =>
                                    this.setState({ currentStep: 1 })
                                }
                            />
                        </h5>

                        <table className={"tableData"}>
                            <tbody>
                                <tr>
                                    <td>
                                        <span>
                                            {
                                                this.memberTypes[
                                                    this.state.form.user
                                                        .membertype
                                                ].label
                                            }
                                        </span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                        <hr />

                        {/* Personal info */}
                        <h5 className={"subTitle"}>
                            <span>Informations personnelles</span>
                            <ButtonEdit
                                onClick={() =>
                                    this.setState({ currentStep: 2 })
                                }
                            />
                        </h5>

                        <table className={"tableData"}>
                            <tbody>
                                <tr>
                                    <td>
                                        <b>Nom</b>
                                    </td>
                                    <td>
                                        <span>
                                            {this.state.form.profile.lastname}
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <b>Prénom</b>
                                    </td>
                                    <td>
                                        <span>
                                            {this.state.form.profile.firstname}
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <b>Société</b>
                                    </td>
                                    <td>
                                        <span>
                                            {
                                                this.state.form.profile.company
                                                    .name
                                            }
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <b>Fonction</b>
                                    </td>
                                    <td>
                                        <span>
                                            {this.state.form.profile.job}
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <b>Adresse email</b>
                                    </td>
                                    <td>
                                        <span>
                                            {this.state.form.user.email}
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <b>Adresse postale</b>
                                    </td>
                                    <td>
                                        <span>
                                            {this.state.form.profile.address}
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <b>Code postal</b>
                                    </td>
                                    <td>
                                        <span>
                                            {this.state.form.profile.zipcode}
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <b>Ville</b>
                                    </td>
                                    <td>
                                        <span>
                                            {this.state.form.profile.city}
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <b>Téléphone fixe</b>
                                    </td>
                                    <td>
                                        <span>
                                            {this.state.form.profile.phone}
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <b>Téléphone mobile</b>
                                    </td>
                                    <td>
                                        <span>
                                            {this.state.form.profile.mobile}
                                        </span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                        <hr />

                        {/* Works groups */}
                        <h5 className={"subTitle"}>
                            <span>Groupes de travail</span>
                            <ButtonEdit
                                onClick={() =>
                                    this.setState({ currentStep: 3 })
                                }
                            />
                        </h5>

                        <div>
                            {this.state.form.workgroups.length > 0 ? (
                                <div>
                                    <p>
                                        L'utilisateur est membre des groupes de
                                        travail :
                                    </p>
                                    <ListRoleLegend />
                                </div>
                            ) : (
                                <table className={"tableData"}>
                                    <tbody>
                                        <tr>
                                            <td>
                                                {this.state.form.role.uid ===
                                                Roles.ROLE_ROOT.uid ? (
                                                    <span>
                                                        L'utilisateur étant
                                                        Super Administrateur, il
                                                        peut voir tous les
                                                        groupes de travail
                                                    </span>
                                                ) : (
                                                    <span>
                                                        L'utilisateur n'est
                                                        membre d'aucun groupe de
                                                        travail
                                                    </span>
                                                )}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            )}
                        </div>

                        <br />

                        <div className="tagsSelect">
                            <ul>
                                {this.state.workGroupsOfUser.map((item, i) => {
                                    let className = "grey";
                                    if (item && item.membertype) {
                                        if (
                                            item.membertype ===
                                            MEMBER_TYPE_ANIMATOR
                                        ) {
                                            className = "green";
                                        } else if (
                                            item.membertype ===
                                            MEMBER_TYPE_ADMINISTRATOR
                                        ) {
                                            className = "blue";
                                        }
                                    }
                                    return (
                                        <li key={i} className={className}>
                                            <span>{item.name}</span>
                                        </li>
                                    );
                                })}
                            </ul>
                        </div>

                        <hr />

                        {/* Role */}
                        {this.state.form.user.id !== this._currentUserId && (
                            <>
                                <h5 className={"subTitle"}>
                                    <span>Rôle de l’utilisateur</span>
                                    <ButtonEdit
                                        onClick={() =>
                                            this.setState({ currentStep: 4 })
                                        }
                                    />
                                </h5>
                                <table className={"tableData"}>
                                    <tbody>
                                        <tr>
                                            <td>
                                                <div>
                                                    <b>
                                                        {
                                                            this.state.form.role
                                                                .name
                                                        }
                                                    </b>
                                                </div>
                                                <span className={"subLabel"}>
                                                    {
                                                        this.state.form.role
                                                            .description
                                                    }
                                                </span>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                {this.state.form.role.uid ===
                                    Roles.ROLE_ADMINISTRATOR.uid && (
                                    <ThematicsRectangle
                                        thematics={adminRoleSelectedThematics}
                                        defaultCheckedId={
                                            adminRoleSelectedThematicsUid
                                        }
                                        isMultiple={true}
                                        readonly={true}
                                        disabled={true}
                                    />
                                )}
                            </>
                        )}

                        {/* Consultation rights */}
                        <h5 className={"subTitle"}>
                            <span>Droits de consultation</span>
                            <ButtonEdit
                                onClick={() =>
                                    this.setState({ currentStep: 5 })
                                }
                            />
                        </h5>
                        <table className={"tableData"}>
                            <tbody>
                                <tr>
                                    <td>
                                        {this.state.form.role.uid ===
                                        Roles.ROLE_ROOT.uid ? (
                                            <span>
                                                L'utilisateur étant Super
                                                Administrateur, il peut voir
                                                tous les documents
                                            </span>
                                        ) : (
                                            <CategoryTree
                                                tree={
                                                    this.state.form.categories
                                                }
                                            />
                                        )}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                )}
            </>
        );

        return (
            <Modal
                onClose={this.props.onClose}
                title={
                    this.props.user && this.props.user.id > 0
                        ? `Modifier l'utilisateur ${this.props.user.profile.firstname} ${this.props.user.profile.lastname}`
                        : "Ajouter un utilisateur"
                }
                saveLabel={
                    this.state.currentStep >= 6
                        ? "Valider"
                        : "Enregistrer et continuer"
                }
                onCancel={this.props.onClose}
                onValidate={
                    this.state.currentStep < 6
                        ? () => this.next()
                        : () => this.validateForm()
                }
                loading={this.state.loading}
            >
                <div className={"formAddUser"} ref={this.formRef}>
                    {/* Back */}
                    {this.state.currentStep > 1 && (
                        <button
                            className={"backButton"}
                            onClick={() => this.back()}
                        >
                            <img
                                src={
                                    "/assets/images/vector/triangle-left-blue.svg"
                                }
                                alt="Retour"
                            />
                        </button>
                    )}

                    {this.state.error.userCreation !== "" && (
                        <span className={"globalErrorMsg"}>
                            {this.state.error.userCreation}
                        </span>
                    )}

                    {/* Button */}
                    <form>
                        {this.state.loading ? <Loader type={"inline"} /> : FORM}
                    </form>
                </div>
            </Modal>
        );
    }
}
