import React from "react";
import "./ButtonThematic.scss"
import PropTypes from "prop-types";
import Auth from "../../../Security/Auth";
import Button from "./Button";
import Loader from "../../Loader/Loader";

export default class ButtonThematic extends React.Component {

    static propTypes = {
        thematics: PropTypes.array.isRequired,
        onClick: PropTypes.func.isRequired,
        isAdmin: PropTypes.bool,
        force: PropTypes.bool,
        isWorkgroup: PropTypes.bool
    };

    static defaultProps = {
        isAdmin: false,
        isWorkgroup: false,
        force: false
    };

    render() {
        return (
            <div className="list-thematics container">
                <div className="row">
                {/* Thematics */}
                {this.props.thematics.length > 0 ? this.props.thematics.map((thematic, i) => {
                    let className = 'button-thematic';

                    if ((this.props.thematics.length % 2 === 0 && i === this.props.thematics.length - 2) ||
                        i === this.props.thematics.length - 1) {
                        className += ' last';
                    }
                    const CLICKABLE = this.props.isAdmin && !this.props.force ?
                        Auth.canManage(thematic.id) :
                        thematic.hasOwnProperty('categories') && thematic.categories.length > 0;

                    className += (CLICKABLE || this.props.isWorkgroup) ? '' : ' disabled';

                    return (
                        <Button to={'/'} className={className.trim()} style={{backgroundColor: thematic.color}} key={i}
                                onClick={() => {
                                    if (!CLICKABLE && ! this.props.isWorkgroup) return;

                                    this.props.onClick(thematic, i)
                                }}>
                            {thematic.name}
                        </Button>);
                }) : <Loader type={"inline"}/>}

                    {this.props.thematics.length % 2 !== 0 && <div className="empty-category"/>}
                </div>
            </div>
        );
    }
}
