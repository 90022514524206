import axios from "axios";
import {LOCATION} from "../Config/location";

/**
 * Google api service
 */
export default {

    /**
     * Verify re captcha
     * @param token
     * @returns {AxiosPromise<any>}
     */
    verifyReCaptcha: (token) => {
        return axios.post(`${LOCATION.api}/google/recaptcha`, {
            token : token
        });
    },
}

