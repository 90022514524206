import axios from "axios";
import Api, {PATH} from "../Utils/Api";
import {LOCATION} from "../Config/location";

export const NEWS_RESULT_PAGE = 10;

export default {

    /**
     * Get last news
     * @param nestedEntities
     * @param limit
     * @returns {AxiosPromise<any>}
     */
    getLastNews: (nestedEntities = [], limit = 3) => {
        const params = {
            "api[sort]": 'updated_at',
            "api[order]": 'desc',
            "api[limit]": `${limit}`,
            "api[nested_entities]": Api.buildNestedEntity(nestedEntities),
        };

        return axios.get(`${PATH}/content/news`, {params: params});
    },

    /**
     * @param nestedEntities
     * @param {QueryBuilder} queryBuilder
     * @param searchText
     * @return {AxiosPromise<any>}
     */
    getNews: (nestedEntities = [], queryBuilder, searchText = '') => {
        if (searchText !== '') {
            queryBuilder.setWhere(`(\`title\` LIKE \`%${searchText}%\` OR \`content\` LIKE \`%${searchText}%\`)`);
        }

        return axios.get(`${PATH}/content/news`, {params: queryBuilder.build()});
    },

    /**
     * Get featured news
     * @param nestedEntities
     * @param limit
     * @param currentId
     * @returns {AxiosPromise<any>}
     */
    getFeaturedNews: (nestedEntities = [], limit = 2, currentId = 0) => {
        const params = {
            "api[sort]": 'updated_at',
            "api[order]": 'desc',
            "api[limit]": `${limit}`,
            "api[nested_entities]": Api.buildNestedEntity(nestedEntities),
            "api[where]": `where (\`published\` = 1 AND \`id\` <> \`${currentId})\``,
        };

        return axios.get(`${PATH}/content/news`, {params: params});
    },

    /**
     * Upload a resource news
     * @param data
     * @returns {AxiosPromise<any>}
     */
    uploadFileNews: (data) => {
        return axios.post(`${LOCATION.api}/api/${LOCATION.apiVersion}/content/news/upload`, data, {headers: {'Content-Type': 'multipart/form-data'}})
    },

    /**
     * Get single new
     * @param id
     * @param nestedEntities
     * @returns {Promise<any>}
     */
    get: (id, nestedEntities = []) => {
        const params = {
            "api[nested_entities]": Api.buildNestedEntity(nestedEntities)
        };
        return axios.get(`${PATH}/content/news/${id}`, {params: params});
    },

    /**
     * Add news
     * @param news
     * @returns {Promise<unknown>}
     */
    post: (news) => {
        return axios.post(`${PATH}/content/news`, news);
    },

    /**
     * Patch news
     * @param id
     * @param news
     * @returns {AxiosPromise<any>}
     */
    patch: (id, news) => {
        return axios.patch(`${PATH}/content/news/${id}`, news);
    },

    /**
     * Remove news
     * @param id
     * @returns {Promise<unknown>}
     */
    delete: (id) => {
        return axios.delete(`${PATH}/content/news/${id}`);
    }
}