import axios from 'axios';
import {PATH} from "../Utils/Api";

export default {

    /**
     * Search
     * @param search
     * @param {QueryBuilder} queryBuilder
     * @returns {Promise<any>}
     */
    search: (search, queryBuilder) => {
        return axios.get(`${PATH}/content/everywhere/search/${search}`, {params: queryBuilder.buildSearch()});
    }
}