import axios from "axios";
import Api, {PATH} from "../Utils/Api";

export default {

    /**
     * Set document
     * @param documentFilter
     * @param nestedEntities
     * @returns {AxiosPromise<any>}
     */
    add: (documentFilter, nestedEntities = []) => {
        let nested = '';

        if (nestedEntities.length > 0) {
            nested = '?api[nested_entities]=' + Api.buildNestedEntity(nestedEntities);
        }

        return axios.post(`${PATH}/content/documentFilter${nested}`, documentFilter);
    },

    /**
     * Remove document
     * @param id
     * @returns {Promise<unknown>}
     */
    remove: (id) => {
        return axios.delete(`${PATH}/content/documentFilter/${id}`)
    },

    /**
     * Get all document's filters
     *
     * @param id
     * @returns {AxiosPromise<any>}
     */
    getByDocumentId(id) {
        const PARAMS = {
            "api[where]": `where \`document\` = \`${id}\``,
        };

        return axios.get(`${PATH}/content/documentFilter`, {params: PARAMS})
    }
}