import React from 'react';
import PropTypes from "prop-types";
import {MEMBER_TYPE_ADMINISTRATOR, MEMBER_TYPE_ANIMATOR} from "../../../Services/ApiWorkGroupUser";
import './ListTags.scss';

export default class ListTags extends React.Component {

    static propTypes = {
        tags: PropTypes.array,
        onRemove: PropTypes.func,
        skin: PropTypes.oneOf(['grey', 'blue'])
    };

    static defaultProps = {
        tags: [],
        skin: 'grey'
    };

    render() {
        return (
            <div className="listTags">
                <ul>
                    {/* Array of tags */}
                    {this.props.tags.map((item, i) => {
                        let className = this.props.skin;
                        if (item.membertype) {
                            if (item.membertype === MEMBER_TYPE_ANIMATOR) {
                                className = 'green';
                            } else if (item.membertype === MEMBER_TYPE_ADMINISTRATOR) {
                                className = 'blue'
                            }
                        }

                        const CLOSE_IMG = className === 'blue' ? '/assets/images/icon/close-white.svg' : '/assets/images/icon/close-blue.svg';

                        return (<li key={i} className={className} style={{backgroundColor: item.color || ''}}>
                            <span style={{color: item.color ? 'white' : ''}}>{item.name || item}</span>
                            <button type={'button'} onMouseDown={() => this.props.onRemove(item.id || item)}>
                                <img src={CLOSE_IMG} alt="Supprimer le tag"/>
                            </button>
                        </li>)
                    })}
                </ul>
            </div>
        )
    }
}