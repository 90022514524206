import React from 'react';
import PropTypes from "prop-types";
import File from "../../File/File";
import Pagination from "../../Pagination/Pagination";
import Button from "../../Form/Button/Button";
import FilterDoc from "../../Filter/FilterDoc";
import ToolsManage from "../../ToolsManage/ToolsManage";
import Loader from "../../Loader/Loader";
import "./Results.scss";
import PreviewNews from "../../Layout/PreviewNews";


export default class Result extends React.Component {

    static propTypes = {
        results: PropTypes.array.isRequired,
        nbResults: PropTypes.number.isRequired,
        onFilter: PropTypes.func,
        label: PropTypes.string,
        isForDocument: PropTypes.bool,
        adminMode: PropTypes.bool,
        adminModeFront: PropTypes.bool,
        editMode: PropTypes.bool,
        deleteMode: PropTypes.bool,
        onAdd: PropTypes.func,
        loading: PropTypes.bool,
        onToggleEditMode: PropTypes.func,
        onToggleDeleteMode: PropTypes.func,
        onToggleArchiveMode: PropTypes.func,
        onEdit: PropTypes.func,
        onDelete: PropTypes.func,
        onPaginationClick: PropTypes.func,
        pagination: PropTypes.object,
        color: PropTypes.string,
        fileColor: PropTypes.string,
        showTypeFilter: PropTypes.bool,
        requiredRoleForManagement: ToolsManage.propTypes.role,
        type: PropTypes.oneOf(['all', 'workgroup', 'category']),
        id: PropTypes.number,
        category: PropTypes.shape({hasspecificfilters: PropTypes.bool}),
        filters: PropTypes.shape({meeting: PropTypes.object, doctype: PropTypes.object}),
        refreshed: PropTypes.bool,
    };

    static defaultProps = {
        color: 'white',
        fileColor: null,
        label: '',
        isForDocument: true,
        requiredRoleForManagement: ToolsManage.defaultProps.role,
        onChange: () => {
        },
        onAdd: () => {
        },
        onEdit: () => {
        },
        onDelete: () => {
        },
        onArchive: () => {
        },
        onPaginationClick: () => {
        },
        page: 1,
        showTypeFilter: true,
        type: 'all',
        id: 0,
        category: null,
        refreshed: false,
        filters: {meeting: {}, doctype: {}}
    };

    state = {
        printResult: this.props.adminMode ? 'list' : localStorage.getItem('documentListPrintResult') || 'grid'
    };

    constructor(props) {
        super(props);

        this._container = React.createRef();
    }


    changePrint(type) {
        this.setState({
            printResult: type
        });

        localStorage.setItem('documentListPrintResult', type);
    }

    render() {
        let content = (
            <p className={'no-result'}>Aucun résultat</p>
        );

        if (this.props.results.length > 0) {
            content = (
                <div
                    className={'container results ' + this.state.printResult + (this.props.nbResults % 2 === 0 ? ' pair' : '')}>
                    {this.props.results.map((result, i) => {
                        let color = '#000000';

                        if (result.index === undefined || result.index === 'content_document') {
                            if (result.thematic && !Array.isArray(result.thematic)) {
                                color = result.thematic.color;
                            } else if (result.workgroup && !Array.isArray(result.workgroup)) {
                                color = result.workgroup.thematic.color;
                            }

                            return <File file={result}
                                         key={i}
                                         className={this.state.printResult}
                                         showButton={!this.props.adminMode}
                                         editMode={this.props.editMode}
                                         deleteMode={this.props.deleteMode}
                                         archiveMode={this.props.archiveMode}
                                         onEdit={(file) => this.props.onEdit(file)}
                                         onDelete={(file) => this.props.onDelete(file)}
                                         onArchive={(file) => this.props.onArchive(file)}
                                         color={this.props.fileColor || color}
                            />
                        } else if (result.index && result.index === 'content_news') {
                            let news = result;
                            news.createdat = {date: news.date};
                            news.id = news.identifier;
                            return <div key={i}><PreviewNews news={news} infoBubble={false} left={true} excerptLength={80}/></div>;
                        } else {
                            return null;
                        }
                    })}
                </div>);
        }

        return (
            <div className="search-result" ref={this._container}>
                {/* Result number */}
                <div className={'how-many-results ' + this.props.color}>
                    <p>
                        {this.props.loading
                            ? 'Chargement...' : this.props.pagination.resources.total === 0 && [26, 27, 28, 29, 30, 31].includes(this.props.id) ? <>Accès réservé</>
                            : <>
                                <span>{this.props.pagination.resources.total}</span>
                                {this.props.label === '' ? 'résultat' + ((this.props.pagination.resources.total > 1) ? 's' : '') + ' à votre recherche' : this.props.label}
                            </>
                        }
                    </p>
                </div>

                {/* Filter */}
                <FilterDoc onFilter={(by, e) => this.props.onFilter(by, e)}
                           type={this.props.type}
                           id={this.props.id}
                           showType={this.props.showTypeFilter}
                           adminMode={this.props.adminMode}
                           category={this.props.category}
                           filters={this.props.filters}
                           periodicity={this.props.periodicity}
                           refreshed={this.props.refreshed}
                           key={this.props.id}
                />

                {/* Grid or List button */}
                {!this.props.adminMode && this.props.results.length > 0 && <div className="print-results">
                    <Button type='button' className={'list' + (this.state.printResult === 'list' ? ' active' : '')}
                            onClick={() => this.changePrint('list')}/>
                    <Button type="button" className={'grid' + (this.state.printResult === 'grid' ? ' active' : '')}
                            onClick={() => this.changePrint('grid')}/>
                </div>}

                {/* Manage tools */}
                {((this.props.adminMode || this.props.adminModeFront) && !this.props.loading) &&
                <div className={'container'}>
                    <ToolsManage onAdd={this.props.onAdd}
                                 onEdit={this.props.onToggleEditMode}
                                 onDelete={this.props.onToggleDeleteMode}
                                 onArchive={this.props.onToggleArchiveMode}
                                 role={this.props.requiredRoleForManagement}
                                 editActive={this.props.editMode}
                                 archiveActive={this.props.archiveMode}
                                 deleteActive={this.props.deleteMode}
                                 archive={true}
                    />
                </div>}

                {/* List result */}
                {this.props.loading ? <div style={{marginBottom: 100}}><Loader type={"inline"}/></div> : content}
                {this.props.pagination &&
                <Pagination onClick={(page) => {
                    window.scroll(0, window.scrollY + this._container.current.getBoundingClientRect().top);
                    this.props.onPaginationClick(page);
                }} pagination={this.props.pagination}/>}
            </div>
        );
    }
}
