import React from 'react';
import PropTypes from "prop-types";
import Button from "../Form/Button/Button";
import "./Banner.scss";
import {Link} from "react-router-dom";
import Select from "react-select";
import {isMobile} from "../../Utils/Screen";
import Auth from "../../Security/Auth";


export default class Banner extends React.Component {

    static propTypes = {
        height: PropTypes.number,
        imgSrc: PropTypes.string,
        supTitle: PropTypes.string,
        title: PropTypes.string,
        size: PropTypes.oneOf(['small', 'normal', 'big']),
        content: PropTypes.element,
        backgroundColor: PropTypes.string,
        search: PropTypes.bool,
        selects: PropTypes.array,
        searchLoading: PropTypes.bool,
        onSearch: PropTypes.func,
        onToggleSearch: PropTypes.func,
        backButton: PropTypes.string,
        placeholderLoading: PropTypes.bool,
        location: PropTypes.object
    };

    static defaultProps = {
        height: 600,
        imgSrc: '',
        supTitle: '',
        title: '',
        size: 'normal',
        backgroundColor: 'white',
        search: false,
        onSearch: () => {
        },
        onToggleSearch: () => {
        },
        backButton: ''
    };

    state = {
        isSearch: true,
        selects: [],
        searchText: ''
    };

    static selects = React.createRef();
    static reset   = React.createRef();

    constructor(props) {
        super(props);

        this._selectsIndex = React.createRef();
        this._timeout = null;
    }

    componentDidMount() {
        if (this.props.selects) {
            this.setState({isSearch: false});
            this.selectChange()
        }
    }

    componentWillUnmount() {
        clearTimeout(this._timeout);
    }

    search(e) {
        e && e.preventDefault();
        return this.props.onSearch(this.state.searchText);
    }

    resetSelect() {
        let split = this.props.location.pathname.split('/');
        const BASE_CURRENT_PATH = split[1] === 'admin' ? `${split[1]}/${split[2]}` : split[1];
        this.props.history.push('/' + BASE_CURRENT_PATH);
    }

    resetSearch() {
        window.location.reload();
    }

    selectChange() {
        this._timeout = setTimeout(() => {
            this._selectsIndex              = Banner.selects.current.children.length - 1;
            Banner.selects.current.className = `selects selects-translate-${this._selectsIndex}`;
            if (this._selectsIndex > 0 && isMobile()) {
                Banner.reset.current.className   = 'fas fa-chevron-left';
            }
        }, 200)
    }

    resetOnClick() {
        if (this.props.selects) {
            this._selectsIndex--;

            Banner.selects.current.className = `selects selects-translate-${this._selectsIndex}`;
            if (this._selectsIndex < 0 || !isMobile()) {
                this._selectsIndex            = 0;
                Banner.reset.current.className = 'fas fa-times';
                this.resetSelect();
            }
        } else {
            this.resetSearch();
        }
    }

    render() {
        const HEIGHT         = this.props.size === 'small' ? 450 : this.props.height;
        const URL_BACKGROUND = (this.props.imgSrc === '') ? null : `url("${this.props.imgSrc}")`;

        return (
            <div ref={this.ref} className={`banner ${Auth.isAuthenticated() ? '' : 'logout'}`} style={{
                height: HEIGHT,
                backgroundImage: this.props.placeholderLoading ? null : URL_BACKGROUND,
                backgroundColor: this.props.placeholderLoading ? '#eeeeee' : this.props.backgroundColor
            }}>
                <div className="container">
                    {/* Title header */}
                    {(this.props.title || this.props.supTitle || this.props.content || this.props.placeholderLoading) &&
                    <div className={`bannerTitle ${this.props.size}`}>
                        {/* Back button */}
                        {this.props.backButton !== '' &&
                        <Link to={this.props.backButton} className={'btn-back'}><img src="/assets/images/icon/btn-back-blue.svg" alt=""/> Retour</Link>}
                        {/* Subtitle */}
                        {}<span className={`bannerSupTitle${this.props.placeholderLoading ? ' placeholder' : ''}`}>{!this.props.placeholderLoading && this.props.supTitle}</span>
                        {/* Title*/}
                        <h1 className={this.props.placeholderLoading ? 'placeholder' : null}>{!this.props.placeholderLoading && this.props.title}</h1>
                        {/* Content */}
                        {this.props.content}
                    </div>}

                    {/* Search */}
                    {this.props.search && <div className="search">
                        <Button onClick={() => this.resetOnClick()}><span ref={Banner.reset} className={"fas fa-times"}/></Button>
                        {this.state.isSearch ?
                            <form className="search-form " onSubmit={(e) => this.search(e)}>
                                <input type="type" placeholder={'Que recherchez-vous ?'} value={this.state.searchText} onChange={(e) => this.setState({searchText: e.target.value})}/>
                                <Button onClick={() => this.search()} loading={this.props.searchLoading}><span className={"fas fa-search"}/></Button>
                                <input type="submit"/>
                            </form> :
                            <div className="selects" ref={Banner.selects}>
                                {this.props.selects && this.props.selects.map((select, key) => {
                                    return <Select {...select.props} key={key} onChange={(e) => {
                                        this.selectChange(e);
                                        select.props.onChange(e, key);
                                    }
                                    }/>
                                })}
                            </div>
                        }
                    </div>}
                    {this.props.children}
                </div>
            </div>
        )
    }
}