import React from 'react'

export default class Roles extends React.Component {

    static ROLE_ANONYMOUS     = {'rank': 8192 , 'uid': 'ROLE_ANONYMOUS'};       // This role has no permission, it is used only by core
    static ROLE_EXTERNAL_USER = {'rank': 10240, 'uid': 'ROLE_EXTERNAL_USER'};
    static ROLE_INTERNAL_USER = {'rank': 12088, 'uid': 'ROLE_INTERNAL_USER'};
    static ROLE_USER          = {'rank': 16384, 'uid': 'ROLE_USER'};            // Can read some contents
    static ROLE_CONTRIBUTOR   = {'rank': 32768, 'uid': 'ROLE_CONTRIBUTOR'};     // Can contribute in the groups
    static ROLE_ADMINISTRATOR = {'rank': 49152, 'uid': 'ROLE_ADMINISTRATOR'};   // Can administer the themes
    static ROLE_ROOT          = {'rank': 57344, 'uid': 'ROLE_ROOT'};            // Can administer everything
    static ROLE_DEVELOPER     = {'rank': 65536, 'uid': 'ROLE_DEVELOPER'};       // Can administer everything and access to all the logs, debug events and dynamic tests...

    static getHighestRole = (roles) => {

        let lowestRoleUid  = this.ROLE_ANONYMOUS['uid'];
        let lowestRoleRank = this.ROLE_ANONYMOUS['rank'];

        roles.forEach((role) => {

            if (role['rank'] > lowestRoleRank) {

                lowestRoleUid  = role['uid'];
                lowestRoleRank = role['rank'];

            }

        });

        return lowestRoleUid;
    };

    static getLowestRole = (roles) => {

        let highestRoleUid  = this.ROLE_DEVELOPER['uid'];
        let highestRoleRank = this.ROLE_DEVELOPER['rank'];

        roles.forEach((role) => {

            if (role['rank'] < highestRoleRank) {

                highestRoleUid  = role['uid'];
                highestRoleRank = role['rank'];

            }

        });

        return highestRoleUid;
    }
}

