import React from 'react';
import ApiDocument from "../../Services/ApiDocument";
import DocumentUtil from "../../Utils/Document";
import Banner from "../../Components/Banner/Banner";
import Master from "../../Components/Layout/Master";
import {DateHandler} from '../../Utils/DateHandler';
import SVGFile from "../../Components/SVG/SVGFile";
import Loader from "../../Components/Loader/Loader";
import "./Document.scss";
import {Http} from "../../Utils/Api";
import {CK_EDITOR_EMPTY} from "../../Config/ckEditor";
import CKEditor from "ckeditor4-react";
import Button from "../../Components/Form/Button/Button";
import Auth from "../../Security/Auth";
import {path} from "../../Config/routes";
import * as Clipboard from "clipboard-polyfill"


export default class Document extends React.Component {

    state = {
        loader: true,
        breadCrumb: [],
        textCopied: false,
        document: {
            author: '',
            workgroup: {
                thematic: {
                    color: ''
                }
            },
            category: {
                thematic: {
                    color: ''
                }
            },
            files: [{
                thumbnail: ''
            }]
        },
        comment: '',
        isForDocument: true,
        httpError: false,
        loadingPost: false
    };

    isForDocumentation = true;

    constructor(props) {
        super(props);
        this._isMounted = false;
    }

    componentDidMount() {
        this._isMounted = true;

        const [type]            = Object.keys(this.props.match.params);
        this.isForDocumentation = (type !== 'workgroup');

        this.setState({
            loader: true,
            isForDocument: this.isForDocumentation
        }, () => {
            ApiDocument.get(this.props.match.url, !this.state.isForDocument).then((document) => {
                if (!this._isMounted) return;
                const DOC        = document[0];
                const BREADCRUMB = this.state.isForDocument
                    ? DocumentUtil.getDocumentPath(DOC).breadCrumb
                    : [
                        {url: path('WorkGroupList'),label: 'Groupe de travail'},
                        {
                            url: path('WorkGroupList', {category: DOC.workgroup.thematic.uid}),
                            label: DOC.workgroup.thematic.name},
                        {url: path('workGroups', {id: DOC.workgroup.id}),label: DOC.workgroup.name},
                        {label: DOC.title}
                    ];

                ApiDocument.getShortUrl(this.props.match.url).then(url => {
                    DOC.shortUrl = url.length > 0 ? url[0].shorturl : '';

                    this.setState({
                        breadCrumb: BREADCRUMB,
                        document: DOC,
                        loader: false
                    });
                });
            }).catch((err) => {
                if (!this._isMounted) return;

                if (err.status && err.status >= 500) {
                    this.setState({
                        httpError: true
                    });
                }
            });
        });
    }

    componentWillUnmount() {
        this._isMounted = false;
        Http.cancel();
    }

    publishComment(e) {
        e.preventDefault();

        const USER = Auth.getUser();

        const COMMENT = {
            document: this.state.document.id,
            content: this.state.comment,
            author: USER.id,
            published: 1
        };

        this.setState({
            loadingPost: true
        });

        ApiDocument.addComment(COMMENT).then(() => {
            const NEW_COMMENT = {
                author: {
                    profile: {
                        lastname: USER.last_name,
                        firstname: USER.first_name
                    }
                },
                content: this.state.comment
            };

            const COMMENTS = this.state.document.comments;
            COMMENTS.push(NEW_COMMENT);

            this.setState((state) => {
                return {
                    document: {
                        ...state.document,
                        comments: COMMENTS
                    },
                    comment: '',
                    loadingPost: false
                }
            });
        });
    }

    copyShortUrl() {
        const SORT_URL = window.location.origin + this.state.document.shortUrl;

        Clipboard.writeText(SORT_URL).then(() => {
            this.setState({
                textCopied: true
            }, () => {
                setTimeout(() => {
                    this.setState({
                        textCopied: false
                    })
                }, 2 * 1000)
            })
        })
    }

    render() {
        const COLOR  = this.state.isForDocument ? this.state.document.category.thematic.color : this.state.document.workgroup.thematic.color;
        const AUTHOR = this.state.document.author.profile;

        let commentary = null;

        if (!this.state.isForDocument && this.state.document.commentable) {
            commentary =
                <div className={'commentaries'}>
                    <div className={'container content'}>
                        <div className={'col'}>
                            <h2>COMMENTAIRES</h2>
                            {this.state.document.comments.length === 0 ? <p>Aucun commentaire</p> :
                                this.state.document.comments.map((comment, key) => {
                                    let date = comment.createdat ? 'le ' + DateHandler.getPrettyDate(comment.createdat.date, "DD/MM/YY [à] HH[h]mm") : "à l'instant";
                                    return (
                                        <div className={'commentary'} key={key}>
                                            <span className="round" style={{backgroundColor: COLOR}}/>
                                            <span className={'user'}>
                                                {comment.author.profile.firstname} {comment.author.profile.lastname} <small><i>{date}</i></small>
                                            </span>
                                            <div className={'comment'} dangerouslySetInnerHTML={{__html: comment.content}}/>
                                        </div>
                                    );
                                })
                            }
                        </div>
                        <hr/>
                        <div className={'col'}>
                            <h3>Ajouter un commentaire</h3>
                            <form onSubmit={(e) => this.publishComment(e)}>
                                <CKEditor
                                    onBeforeLoad={(config) => (config.disableAutoInline = true)}
                                    config={CK_EDITOR_EMPTY.config}
                                    data={this.state.comment}
                                    type="classic"
                                    onChange={(event) => {
                                        const data = event.editor.getData();
                                        this.setState({comment: data})
                                    }}
                                />
                                <div className="cta">
                                    <Button type={'submit'} loading={this.state.loadingPost} style={{
                                        borderColor: COLOR,
                                        color: COLOR
                                    }}>
                                        Publier
                                    </Button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>;
        }

        const displayedDate = this.state.document.publishedat || this.state.document.createdat;

        return (
            <Master id="document" httpError={this.state.httpError} breadCrumb={this.state.breadCrumb} {...this.props}>
                <Banner backgroundColor={COLOR} placeholderLoading={this.state.loader}>
                    {!this.state.loader && (
                        <div className={"bannerTitle big"}>
                            <div className={'text'}>
                                <span className={`bannerSupTitle`}
                                      style={{color: COLOR}}>{DateHandler.getPrettyDate(displayedDate.date)}</span>
                                <h1>
                                    {this.state.document.title}
                                    {this.state.document.shortUrl !== '' && <span title={'partager'} className={'fas fa-share-alt share'} onClick={() => this.copyShortUrl()}>
                                        {this.state.textCopied && <span className={'copy'} style={{backgroundColor: COLOR}}>
                                            Url copié
                                        </span>}
                                    </span> }
                                </h1>
                                <div className={"cta"}>
                                    <a href={this.state.document.files[0].url} target={'_blank'} style={{
                                        borderColor: COLOR,
                                        color: COLOR
                                    }}>
                                        <span tabIndex="-1"><em>Télécharger</em></span>
                                    </a>
                                    {/*<a href={this.state.document.files[0].url} target={'_blank'} style={{*/}
                                    {/*    borderColor: COLOR,*/}
                                    {/*    color: COLOR*/}
                                    {/*}}>*/}
                                    {/*    <span tabIndex="-1"><em>Imprimer</em></span>*/}
                                    {/*</a>*/}
                                </div>
                            </div>
                            <div className={'icon'}>
                                <SVGFile color={COLOR} className='icon'>{this.state.document.files[0].type}</SVGFile>
                            </div>
                        </div>
                    )}
                </Banner>
                <div className={'documentContent container'}>
                    {this.state.loader ? (
                        <Loader type={"inline"}/>
                    ) : (
                        <>
                            <div className={'content'}>
                                <h2>DESCRIPTION</h2>

                                <div dangerouslySetInnerHTML={{__html: this.state.document.content}}/>

                                <span className={"author"} style={{color: COLOR}}>
                                    <i>Ajouté par {AUTHOR.firstname} {AUTHOR.lastname}</i>
                                </span>

                                <div className="tags">
                                    {this.state.document.tags.map((tag, i) => (
                                        <span className="tag" key={i}>{tag.name}</span>
                                    ))}
                                </div>
                            </div>
                        </>
                    )}
                </div>

                {commentary}

            </Master>
        )
    }
}
