import React from 'react';
import PropTypes from "prop-types";
import "./InputEmail.scss";
import InputText from "../InputText/InputText";
import InputTextLight from "../InputTextLight/InputTextLight";

export default class InputEmail extends React.Component {

    static propTypes = {
        id: PropTypes.string.isRequired,
        label: PropTypes.string,
        placeholder: PropTypes.string,
        value: PropTypes.string,
        name: PropTypes.string,
        autoComplete: PropTypes.string,
        errorMsg: PropTypes.string,
        onChange: PropTypes.func,
        isLight: PropTypes.bool,
    };

    static defaultProps = {
        isLight: true,
        checkUnique: true,
    };

    state = {
        errorMsg: ''
    };

    componentDidMount() {
        this.setState({
            errorMsg: this.props.errorMsg
        })
    }

    checkEmailAddressValidity(e) {
        let errorMsg = '';
        if (!e.target.checkValidity()) {
            errorMsg = `Le champ "${this.props.label}" n'est pas un email valide`;
        }

        this.setState({errorMsg: errorMsg});

        this.props.onChange(e, errorMsg !== '');
    }

    render() {
        let input = <></>;

        const ERROR_MESSAGE = this.props.errorMsg || this.state.errorMsg;
        
        if (this.props.isLight) {
            input = <InputTextLight
                type={"email"}
                label={this.props.label}
                id={this.props.id}
                className={ERROR_MESSAGE ? ' error' : null}
                name={this.props.name ? this.props.name : this.props.id}
                value={this.props.value}
                placeholder={this.props.placeholder}
                autoComplete={this.props.autoComplete}
                tabIndex={this.props.tabindex}
                errorMsg={ERROR_MESSAGE}
                onChange={(e) => this.checkEmailAddressValidity(e)}
            />
        } else {
            input = <InputText
                type={"email"}
                label={this.props.label}
                id={this.props.id}
                className={ERROR_MESSAGE ? ' error' : null}
                name={this.props.name ? this.props.name : this.props.id}
                value={this.props.value}
                placeholder={this.props.placeholder}
                autoComplete={this.props.autoComplete}
                tabIndex={this.props.tabindex}
                errorMsg={ERROR_MESSAGE}
                onChange={(e) => this.checkEmailAddressValidity(e)}
            />
        }

        return input;

    }
}