export class String {

    /**
     * Excerpt
     * @param string
     * @param length
     * @returns {string}
     */
    static excerpt = (string, length) => {
        return (string.length > length) ? string.slice(0, length) + '... ' : string;
    };

    /**
     * Capitalize string
     * @param string
     * @return {string}
     */
    static capitalize = (string) => {
        if (typeof string !== "string") {
            return '';
        }

        return string.charAt(0).toUpperCase() + string.slice(1);
    };

}
