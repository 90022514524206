import React from "react";
import PropTypes from "prop-types";
import ApiCategories from "../../../Services/ApiCategories";
import "./CategoryBuild.scss";

export default class CategoryBuild extends React.Component {
  static defaultProps = {
    onClose: () => {},
    onChange: () => {},
    onAdd: () => {},
  };

  static propTypes = {
    onChange: PropTypes.func,
    onClose: PropTypes.func,
    onAdd: PropTypes.func,
    thematicTree: PropTypes.array,
    thematicId: PropTypes.number,
    thematicName: PropTypes.string,
  };

  state = {
    visible: true,
    selectedCategory: null,
    thematicTree: this.props.thematicTree,
    currentLevel: 1,
    newCategoryName: "",
    error: null,
  };

  currentNavTree = [];

  static removeItemSelected(item) {
    // Remove class select
    const EL = document.getElementById(item.uid);
    EL && EL.classList.remove("select");
  }

  constructor(props) {
    super(props);
    this._isMounted = false;
    this.selectInputs = React.createRef();
    this.onPrev = this.onPrev.bind(this);
    this.onClose = this.onClose.bind(this);
    this.onAddCategory = this.onAddCategory.bind(this);
    this.onCategoryNameChange = this.onCategoryNameChange.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  onPrev() {
    if (this.state.currentLevel > 0) {
      this.setState({ currentLevel: this.state.currentLevel - 1 }, () => {
        // Remove prev when we are at top level
        if (this.state.currentLevel <= 1) {
          this.selectInputs.current
            .querySelector(".selectInput .header button")
            .classList.remove("prev");
        }

        const ACTIVE_LIST = this.selectInputs.current.querySelector(
          ".selectInput ul.active"
        );
        if (ACTIVE_LIST.classList.contains("level-1")) return;

        // Add prev
        const GRAND_PARENT = ACTIVE_LIST.parentNode.parentNode;

        GRAND_PARENT.classList.add("active");
        ACTIVE_LIST.classList.remove("active");

        // Handle current nav tree (remove last item for sure because at least user clicked on a menu with children inside)
        let currentNavTreeLastItemBeforeRemove = this.getCurrentNavTreeLastItem();
        this.currentNavTree.pop();

        // Now we need to look if we have to remove an other item (for eg. user clicked on a menu with children inside, then on a child, then on the return button
        // So we need to come back to the 2 items before
        if (
          currentNavTreeLastItemBeforeRemove &&
          !currentNavTreeLastItemBeforeRemove.menu
        ) {
          this.currentNavTree.pop();
        }

        // Put new item as the selected one
        this.setState(
          { selectedCategory: this.getCurrentNavTreeLastItem() },
          () => {
            this.props.onChange(this.state.selectedCategory);
          }
        );
      });
    }
  }

  getCurrentNavTreeLastItem() {
    return this.currentNavTree.length > 0
      ? this.currentNavTree[this.currentNavTree.length - 1]
      : null;
  }

  onNav(item, level, e) {
    const TARGET = e.currentTarget;

    this.setState({ currentLevel: level }, () => {
      // Open sub level
      if (TARGET.classList.contains("withChild")) {
        const GRAND_PARENT = TARGET.parentNode.parentNode;
        const OLD_SELECTED_CATEGORY = this.selectInputs.current.querySelector(
          ".select"
        );

        GRAND_PARENT.classList.remove("active");
        TARGET.nextElementSibling.classList.add("active");
        this.selectInputs.current
          .querySelector(".selectInput .header button")
          .classList.add("prev");

        // If an other category was selected, we unselect it in CSS too and remove it from current nav tree
        if (OLD_SELECTED_CATEGORY) {
          OLD_SELECTED_CATEGORY.classList.remove("select");
        }
      } else if (this.state.selectedCategory !== item) {
        TARGET.classList.add("select");

        // Remove last selected category before changing to the new one
        this.state.selectedCategory &&
          CategoryBuild.removeItemSelected(this.state.selectedCategory);

        // Handle current nav tree (remove last item when we navigating at the same level)
        if (level === this.currentNavTree.length) {
          this.currentNavTree.pop();
        }
      } else {
        // Select/Unselect item
        if (!TARGET.classList.contains("select")) {
          TARGET.classList.add("select");
        } else {
          TARGET.classList.remove("select");
          // Handle current nav tree (remove last item since we unselect it)
          this.currentNavTree.pop();
          item = this.getCurrentNavTreeLastItem();
        }
      }

      // Finally put new item as the new selected one
      this.setState({ selectedCategory: item }, () => {
        if (this.getCurrentNavTreeLastItem() !== item) {
          this.currentNavTree.push(this.state.selectedCategory);
        }

        this.props.onChange(this.state.selectedCategory);
      });
    });
  }

  onClose() {
    this.setState({ visible: false });

    this.props.onClose(this);
  }

  buildTree(categories, level) {
    level++;

    return categories.map((category, i) => {
      if (category.archivedat) {
        // return "";
      }

      const SUB_CATEGORIES =
        category.categories && category.categories.length > 0
          ? category.categories
          : null;
      return (
        <li key={i}>
          <button
            id={category.uid}
            type={"button"}
            className={SUB_CATEGORIES ? "withChild" : null}
            onClick={e => this.onNav(category, level - 1, e)}
          >
            {category.name}
          </button>
          {SUB_CATEGORIES && (
            <ul className={`level-${level}`}>
              {this.buildTree(SUB_CATEGORIES, level)}
            </ul>
          )}
        </li>
      );
    });
  }

  addNode(tree, nodeToAdd, parentId) {
    // Add it to root categories
    if (parentId === null) {
      tree.push(nodeToAdd);

      return tree;
    }

    // Or check for deeper level
    for (let i = 0; i < tree.length; i++) {
      const NODE = tree[i];
      if (NODE.id === parentId) {
        NODE.categories.push(nodeToAdd);
        break;
      } else if (NODE.categories) {
        this.addNode(NODE.categories, nodeToAdd, parentId);
      }
    }

    return tree;
  }

  onAddCategory(e) {
    e.preventDefault();
    const PARENT_ID = this.state.selectedCategory
      ? this.state.selectedCategory.id
      : null;

    ApiCategories.add(
      this.state.newCategoryName,
      this.props.thematicId,
      PARENT_ID
    )
      .then(response => {
        const THEMATIC = {
          id: response.id,
          name: this.state.newCategoryName,
          categories: [],
          parent: [],
        };

        let tree = this.addNode(this.state.thematicTree, THEMATIC, PARENT_ID);

        this.setState({
          thematicTree: tree,
          newCategoryName: "",
        });
        this.props.onAdd(tree, THEMATIC.name);
      })
      .catch(error => {
        if (!this._isMounted) return;
        if (error.status === 409) {
          this.setState({
            error: "Cette catégorie existe déjà (elle est peut-être archivée).",
          });
        }
      });
  }

  onCategoryNameChange(e) {
    this.setState({ newCategoryName: e.target.value });
  }

  render() {
    if (!this.state.visible) return <></>;

    return (
      <div className={"categoryBuild"} ref={this.selectInputs}>
        {/* Select */}
        <div className={"selectInput"}>
          <div className="header">
            <button type={"button"} onClick={this.onPrev}>
              <span>Retour</span>
            </button>
            <button onClick={this.onClose} className={"btn-close"}>
              <img
                src="/assets/images/icon/close-blue.svg"
                alt="Fermer la fenêtre"
              />
            </button>
          </div>

          <div className="core">
            {this.state.thematicTree ? (
              <ul className={"level-1 active"}>
                {this.buildTree(this.state.thematicTree, 1)}
              </ul>
            ) : (
              <strong>
                Cette thématique ne contient aucune sous catégorie
              </strong>
            )}
          </div>
          <div className="footer">
            <strong>
              {this.state.selectedCategory
                ? `Ajouter une nouvelle sous-catégorie dans "${this.state.selectedCategory.name}"`
                : `Ajouter une nouvelle sous-catégorie dans "${this.props.thematicName}"`}
            </strong>

            {this.state.error && (
              <p style={{ color: "red" }}>{this.state.error}</p>
            )}

            <form className={"form"} onSubmit={this.onAddCategory}>
              <input
                type={"text"}
                placeholder={"Nouvelle sous-catégorie"}
                value={this.state.newCategoryName}
                onChange={this.onCategoryNameChange}
                name={"category-name"}
              />
              <button type={"submit"}>
                <span>Ajouter</span>
              </button>
            </form>
          </div>
        </div>
      </div>
    );
  }
}
