import React from 'react';
import PropTypes from "prop-types";
import ApiWorkGroup from "../../Services/ApiWorkGroup";
import Block from "../Layout/Block";
import Carousel from "./Carousel";
import WorkGroup from "../WorkGroup/WorkGroup";
import Auth from "../../Security/Auth";
import './CarouselWorkGroups.scss'


export default class CarouselWorkGroups extends React.Component {

    static propTypes = {
        onLoaded: PropTypes.func
    };

    static defaultProps = {
        onLoaded: () => {}
    };

    state = {
        workgroups: [],
        requestedWorkgroups: false
    };

    constructor(props) {
        super(props);
        this._isMounted = false;
    }

    componentDidMount() {
        this._isMounted = true;

        ApiWorkGroup.getForUser(Auth.getUser().id, ['workgroups.workgroup.thematic', 'workgroups.workgroup.documents.category', 'workgroups.workgroup.documents.files']).then((user) => {
            if (!this._isMounted) return;
            this.setState({
                workgroups: user.workgroups,
                requestedWorkgroups: true
            });

            this.props.onLoaded(true);
        }).catch((err) => {
            if (!this._isMounted) return;
            if (err.status && err.status === 500) {
                this.props.onLoaded(false);
            }
        });
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    render() {
        let placeholder = '';
        if (this.state.requestedWorkgroups) {
            placeholder = <div className={"noWorkgroups"}><em>Vous ne faites partie d'aucun groupe de travail</em></div>
        } else {
            placeholder = <ul className={'placeHolderWorkGroups'}>
                <li>
                    <div className={'title'}>
                        {this.state.workgroups.length > 0}
                    </div>
                    <ul>
                        <li/>
                        <li/>
                    </ul>
                    <div className={'link'}/>
                </li>
                <li>
                    <div className={'title'}/>
                    <ul>
                        <li/>
                        <li/>
                        <li/>
                    </ul>
                    <div className={'link'}/>
                </li>
                <li>
                    <div className={'title'}/>
                    <ul>
                        <li/>
                        <li/>
                    </ul>
                    <div className={'link'}/>
                </li>
            </ul>;
        }

        let workgroups = [];
        if (this.state.workgroups) {
            this.state.workgroups.forEach((workgroup, i) => {
                if (workgroup.workgroup && workgroup.workgroup.thematic && !workgroup.workgroup.archivedat) {

                    // Sort documents by publishedat DESC, createdat DESC
                    workgroup.workgroup.documents = workgroup.workgroup.documents.sort((document1, document2) => {
                        let publishedat1 = new Date(document1.publishedat.date);
                        let publishedat2 = new Date(document2.publishedat.date);

                        if (publishedat1 > publishedat2) return -1;
                        if (publishedat1 < publishedat2) return 1;

                        let createdat1 = new Date(document1.createdat.date);
                        let createdat2 = new Date(document2.createdat.date);

                        if (createdat1 > createdat2) return -1;
                        if (createdat1 < createdat2) return 1;
                    });

                    workgroups.push(
                        <WorkGroup key = {i} workGroup = {workgroup.workgroup} limitDoc = {3} color = {workgroup.workgroup && workgroup.workgroup.thematic.color}/>
                    );
                }
            });
        }

        const CAROUSEL = <Carousel slidesToShow={3} showDots={false} infinite={false} center={true} showArrow={true}>
            {workgroups.map((workgroup) => {
                return workgroup;
            })}
        </Carousel>;

        return (
            <Block color="dark" className="group-list" title="Mes groupes de travail" titleColor={'white'}>
                {this.state.workgroups && this.state.workgroups.length > 0 ? CAROUSEL : placeholder}
            </Block>
        )
    }
}
