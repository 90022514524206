import React from 'react';
import ApiWorkGroupUser, {MEMBER_TYPE_ADMINISTRATOR, MEMBER_TYPE_ANIMATOR} from "../../../Services/ApiWorkGroupUser";
import PropTypes from 'prop-types';
import Select from "../../Form/Select/Select";
import Button from "../../Form/Button/Button";
import './MembersListForWorkGroup.scss';
import Auth from "../../../Security/Auth";
import Roles from "../../../Security/Roles";


export default class MembersListForWorkGroup extends React.Component {

    static propTypes = {
        thematicId: PropTypes.number.isRequired,
        users: PropTypes.arrayOf(PropTypes.shape({
            user: PropTypes.shape({
                profile: PropTypes.shape({
                    firstname: PropTypes.string,
                    lastname: PropTypes.string,
                    address: PropTypes.string,
                    job: PropTypes.string,
                    phone: PropTypes.string,
                    mobile: PropTypes.string,
                    company: PropTypes.shape({
                        id: PropTypes.id,
                        name: PropTypes.string,
                    }),
                }),
                email: PropTypes.string,
                lowestrole: PropTypes.string,
                highestrole: PropTypes.string,
            }),
            membertype: PropTypes.string
        })).isRequired,
        onRemoveUser: PropTypes.func,
        onChangeRole: PropTypes.func
    };

    static defaultProps = {
        skin: 'default',
        onRemoveUser: () => {
        },
        onChangeRole: () => {
        }
    };

    _selectMemberTypesValues = [];
    state                    = {
        memberType: null,
        errorMsg: '',
        profileInError: 0,
    };

    constructor(props) {
        super(props);

        // Get all available member types
        this._wgUserMemberTypes = ApiWorkGroupUser.getAllMemberTypes();

        // Build member types select values list
        Object.keys(this._wgUserMemberTypes).forEach((memberTypeValue) => {
            this._selectMemberTypesValues.push({id: memberTypeValue, name: this._wgUserMemberTypes[memberTypeValue]});
        });
    }

    _checkMemberTypeValidity(memberType) {
        return Object.keys(this._wgUserMemberTypes).indexOf(memberType) >= 0;
    }

    handleChangeRole(users, memberType) {
        if (this._checkMemberTypeValidity(memberType)) {
            this.setState({errorMsg: '', profileInError: 0});
        }

        this.setState({memberType: memberType});
        this.props.onChangeRole(users, memberType);
    }

    render() {
        const AUTH_ROLE      = Auth.getRole();
        // By default only super admin can manage members
        let canManageMembers = AUTH_ROLE.rank >= Roles.ROLE_ROOT.rank;
        // But simple admin can manage some workGroups if they are in their handled thematics
        if (AUTH_ROLE.uid === Roles.ROLE_ADMINISTRATOR.uid && Auth.isThematicAdministrator(this.props.thematicId)) {
            canManageMembers = true;
        }

        return (
            <div className={'MembersListForWorkGroup'}>
                <ul key>
                    {this.props.users.map((users, i) => {
                            let className = 'grey';

                            if (users.membertype) {
                                if (+users.membertype === MEMBER_TYPE_ANIMATOR) {
                                    className = 'green';
                                } else if (+users.membertype === MEMBER_TYPE_ADMINISTRATOR) {
                                    className = 'blue'
                                }
                            }
                            return <li key={i} className={className}>
                                <div>
                                    <div
                                        className={'profile'}>{users.user.profile.lastname.substr(0, 1)}{users.user.profile.firstname.substr(0, 1)}</div>
                                    <div>
                                        <b className={'name'}>{users.user.profile.lastname} {users.user.profile.firstname}</b>
                                        <div className={'details'}>
                                            {this.props.skin === 'default' && <table cellSpacing={0}>
                                                <tbody>
                                                <tr>
                                                    <td className={users.user.profile.company.name ? null : 'blank'}>
                                                        <span>{users.user.profile.company.name}</span></td>
                                                    <td className={users.user.address ? null : 'blank'}>
                                                        <span>{users.user.address}</span></td>
                                                </tr>
                                                <tr>
                                                    <td className={users.user.profile.job ? null : 'blank'}>
                                                        <span>{users.user.profile.job}</span></td>
                                                    <td className={users.user.profile.phone ? null : 'blank'}>
                                                        <span>{users.user.profile.phone}</span></td>
                                                </tr>
                                                <tr>
                                                    <td className={users.user.email ? null : 'blank'}>
                                                        <span>{users.user.email}</span></td>
                                                    <td className={users.user.profile.mobile ? null : 'blank'}>
                                                        <span>{users.user.profile.mobile}</span></td>
                                                </tr>
                                                </tbody>
                                            </table>}
                                        </div>
                                    </div>

                                    {canManageMembers
                                        ?
                                        <div className={"roles-and-add"}>
                                            <div>
                                                <Select id={'role'}
                                                        value={this._selectMemberTypesValues}
                                                        selected={users.membertype}
                                                        className={"form-wg-add-user"}
                                                        onChange={e => this.handleChangeRole(users, e.target.value)}/>
                                                {this.state.errorMsg !== '' && this.state.profileInError === users.user.profile.id &&
                                                <span className={"errorMsg"}>{this.state.errorMsg}</span>}
                                            </div>
                                            <div>
                                                <Button type={"button"} onClick={() => this.props.onRemoveUser(users, i)}>Retirer
                                                    ce membre</Button>
                                            </div>
                                        </div>
                                        : <div className={"roles-and-add"}>
                                            <div>
                                                <span style={{color: 'black'}}>{this._wgUserMemberTypes[users.membertype]}</span>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </li>
                        }
                    )}
                </ul>
            </div>
        )
    }

}