const DOC = 'doc';
const XLS = 'xls';
const PPT = 'ppt';
const PDF = 'pdf';
const ZIP = 'zip';
const RAR = 'rar';

const TYPES = {
    'application/pdf': PDF,
    'application/msword': DOC,
    'application/CDFV2': DOC,
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document': DOC,
    'application/vnd.ms-excel': XLS,
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': XLS,
    'application/vnd.ms-powerpoint': PPT,
    'application/vnd.openxmlformats-officedocument.presentationml.presentation': PPT,
    'application/application/vnd.oasis.opendocument.presentation': PPT,
    'application/application/vnd.oasis.opendocument.spreadsheet': XLS,
    'application/application/vnd.oasis.opendocument.text': DOC,
    'text/plain': DOC,
    'application/zip': ZIP,
    'application/x-rar-compressed': RAR
};

export function getAllType() {
    return [
        DOC,
        XLS,
        PPT,
        PDF,
        ZIP,
        RAR
    ]
}

export function getMimeType(type) {
    let applications = [];
    let keysTypes = Object.keys(TYPES);

    keysTypes.forEach((key) => {
        if (TYPES[key] === type.toLowerCase()) {
            applications.push(key);
        }
    });

    return applications;
}

export function getType(mimeType) {
    const MIME_TYPE_SPLIT = mimeType.split('/');
    const MIME_TYPE = MIME_TYPE_SPLIT[1] || MIME_TYPE_SPLIT[0];
    return (TYPES[mimeType.toLowerCase()] || MIME_TYPE.substring(0, 3)).toUpperCase();
}