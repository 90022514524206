import {loadScript} from "./LoadScript";

/**
 * Google autocomplete input
 * @requires loadScript
 */
export class GoogleAutocomplete {

    /**
     * Constructor
     */
    constructor(inputElement, options) {
        this.input = inputElement;
        this.language = 'FR';
        this.apiKey = process.env.REACT_APP_GOOGLE_MAP_API_KEY;
        this.scriptSrc = '//maps.googleapis.com/maps/api/js?key=' + this.apiKey + '&libraries=places&language=' + this.language + '';
        this.options = options || {
            //types: ['geocode'],
            types: ['(cities)'],
            componentRestrictions: {country: "fr"}
        };
        this.componentForm = {
            locality: 'long_name',
            postal_code: 'short_name'
        };
        this.autocomplete = {
            getPlace: () => {},
            setFields: () => {}
        };

        this.onValid = () => {};

        loadScript(this.scriptSrc).then(() => this.initAutocomplete());
    }

    /**
     * Init
     */
    initAutocomplete() {
        const OPTIONS = {
            types: ['address'],
            componentRestrictions: {country: "fr"}
        };

        this.autocomplete = new window.google.maps.places.Autocomplete(this.input, OPTIONS);
        // Avoid paying for data that you don't need by restricting the set of place fields that are returned to just the address components.
        this.autocomplete.setFields(['address_component']);
        // When the user selects an address from the drop-down, populate the address fields in the form.
        this.autocomplete.addListener('place_changed', this.fillInAddress);
        // Hack for hide autocomplete google...
        this.input.addEventListener('focus', this.forceDisabledAutocomplete);
    }

    /**
     * Force disabled browser auto complete
     */
    forceDisabledAutocomplete = () => {
        this.input.setAttribute('autocomplete', 'new-password');
    };

    /**
     * Fill address
     */
    fillInAddress = () => {
        // Get the place details from the autocomplete object.
        let place = this.autocomplete.getPlace();

        // Reset input component
        for (let component in this.componentForm) {
            document.getElementById(component).value = '';
            document.getElementById(component).disabled = false;
        }

        // Get each component of the address from the place details, and then fill-in the corresponding field on the form.
        for (let i = 0; i < place.address_components.length; i++) {
            let addressType = place.address_components[i].types[0];
            if (this.componentForm[addressType]) {
                let val = place.address_components[i][this.componentForm[addressType]];
                document.getElementById(addressType).value = val;
            }
        }

        this.onValid();
    };
}