import React from 'react';
import PropTypes from "prop-types";
import "./ButtonEdit.scss";


export default class ButtonEdit extends React.Component {

    static propTypes = {
        disabled: PropTypes.bool,
        onClick: PropTypes.func,
    };

    render() {
        return (
            <button type={'button'} onClick={this.props.onClick} className={'btnEdit'} disabled={this.props.disabled}>
                <img src="/assets/images/icon/pen-circle.svg" alt="Éditer"/>
            </button>
        )
    }
}