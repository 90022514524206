import {
    THEMATIC_TYPE_ALL,
    THEMATIC_TYPE_COMMON,
    THEMATIC_TYPE_DOCUMENTATION,
    THEMATIC_TYPE_WORKGROUP
} from "../Services/ApiThematics";

export let sortCategories = categories => {
    return categories.sort((a, b) => {
        let aName = a.name.toUpperCase();
        let bName = b.name.toUpperCase();

        return (aName < bName) ? -1 : (aName > bName) ? 1 : 0;
    });
};


const THEMATICS_HANDLER = {

    /**
     * Store thematics into local storage
     * @param thematics
     */
    storeThematicsLocally: thematics => {
        localStorage.setItem('thematics', JSON.stringify(thematics));
    },

    /**
     * Update a thematic tree on the local storage
     *
     * @param {number} thematicId
     * @param {Array} categories
     */
    updateThematicCategories: (thematicId, categories) => {
        const THEMATICS = THEMATICS_HANDLER.getLocalThematics();
        if (THEMATICS.hasOwnProperty(thematicId)) {
            THEMATICS[thematicId].categories = sortCategories(categories);

            THEMATICS_HANDLER.storeThematicsLocally(THEMATICS);
        }
    },

    /**
     * Get a thematic from its ID
     *
     * @param id
     * @param withoutArchived
     * @returns {null}|{{}}
     */
    getThematic: (id, withoutArchived = false) => {
        const THEMATICS = THEMATICS_HANDLER.getLocalThematics(THEMATIC_TYPE_ALL, false, withoutArchived);

        return THEMATICS.hasOwnProperty(id) ? THEMATICS[id] : null;
    },

    /**
     * Get all thematics stored into the local storage
     *
     * @param {string} type
     * @param {boolean} asArray
     * @param {boolean} withoutArchived
     * @returns {[]|any}
     */
    getLocalThematics: (type = THEMATIC_TYPE_ALL, asArray = false, withoutArchived = false) => {
        let thematics = JSON.parse(localStorage.getItem('thematics'));

        Object.keys(thematics).forEach(thematicId => {
            switch (type) {
                case THEMATIC_TYPE_DOCUMENTATION:
                    if (thematics[thematicId].type !== THEMATIC_TYPE_DOCUMENTATION && thematics[thematicId].type !== THEMATIC_TYPE_COMMON) {
                        delete thematics[thematicId];
                    }
                    break;

                case THEMATIC_TYPE_WORKGROUP:
                    if (thematics[thematicId].type !== THEMATIC_TYPE_WORKGROUP && thematics[thematicId].type !== THEMATIC_TYPE_COMMON) {
                        delete thematics[thematicId];
                    }
                    break;

                case THEMATIC_TYPE_COMMON:
                    if (thematics[thematicId].type !== THEMATIC_TYPE_COMMON) {
                        delete thematics[thematicId];
                    }
                    break;

                case THEMATIC_TYPE_ALL:
                default:
                    // Nothing to do here
                    break;
            }

            if (withoutArchived && thematics[thematicId]) {
                thematics[thematicId].workgroups = thematics[thematicId].workgroups.filter((wg) => !wg.archivedat);
            }
        });

        // If it's for front remove archived
        if (withoutArchived) {
            Object.values(thematics).forEach((thematic) => {
                thematic.categories = removeArchivedCategories(thematic.categories);
            });
        }

        if (asArray) return Object.values(thematics);

        return thematics;
    }

};

function removeArchivedCategories(categories, categoriesNotArchived = []) {
    for (let i = 0; i < categories.length; i++) {
        if (categories[i].archivedat === undefined) {
            categoriesNotArchived.push(categories[i]);
            if (categories[i].categories.length > 0) {
                categories[i].categories = removeArchivedCategories(categories[i].categories);
            }
        }
    }

    return categoriesNotArchived;
}

export default THEMATICS_HANDLER;
