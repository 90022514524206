import React from "react";
import ApiCategories from "../../../Services/ApiCategories";
import { THEMATIC_TYPE_COMMON } from "../../../Services/ApiThematics";
import SelectMultiple from "../SelectMultiple/SelectMultiple";
import Loader from "../../Loader/Loader";
import PropTypes from "prop-types";
import "./SelectCategories.scss";
import Thematics from "../../../Utils/Thematics";

export default class SelectCategories extends React.Component {
  static propTypes = {
    categories: PropTypes.array,
    title: PropTypes.string,
    selected: PropTypes.array,
    multiple: PropTypes.bool.isRequired,
    selectAllButton: PropTypes.bool,
    selectAllChildren: PropTypes.bool,
    onChange: PropTypes.func,
    onRemove: PropTypes.func,
    onAdd: PropTypes.func,
    whenNotMultipleUnselect: SelectMultiple.propTypes.whenNotMultipleUnselect,
    selectWithChildItem: SelectMultiple.propTypes.selectWithChildItem,
    onSave: PropTypes.func,
    loadingSave: PropTypes.bool,
    listThematicLegend: PropTypes.bool,
  };

  static defaultProps = {
    selectAllButton: false,
    selected: [],
    whenNotMultipleUnselect:
      SelectMultiple.defaultProps.whenNotMultipleUnselect,
    selectWithChildItem: SelectMultiple.defaultProps.selectWithChildItem,
    onChange: () => {},
    onRemove: () => {},
    onAdd: () => {},
    onSave: undefined,
  };

  state = {
    options: [],
    loading: true,
    height: 0,
  };

  constructor(props) {
    super(props);
    this.childRef = React.createRef();
  }

  async componentDidMount() {
    let categories = [];
    if (this.props.thematic) {
      categories =
        this.props.categories && this.props.categories.length > 0
          ? this.props.categories
          : await ApiCategories.getTreeFromThematicId(this.props.thematic);
    } else {
      categories =
        this.props.categories && this.props.categories.length > 0
          ? this.props.categories
          : Thematics.getLocalThematics(THEMATIC_TYPE_COMMON, true);
    }

    this.setState(
      {
        options: categories,
        height: categories.length * 70 + 10,
      },
      () => {
        this.setState({ loading: false });
      }
    );
  }

  buildTree(items, level = 1) {
    level++;

    return items.map((item, i) => {
      const SUB_CATEGORIES =
        item.categories && item.categories.length > 0 ? item.categories : null;

      return (
        <li key={i}>
          {this.props.selectAllChildren && SUB_CATEGORIES && (
            <>
              <input
                id={"all-sub-" + item.uid}
                className={"checkAllChild"}
                type={"checkbox"}
                onChange={e => this.onChangeSelectAllChildren(e)}
              />
              <label
                className={"labelCheckAllChild"}
                htmlFor={"all-sub-" + item.uid}
              />
            </>
          )}
          <button
            id={item.uid + "-" + item.id}
            type={"button"}
            className={SUB_CATEGORIES ? "withChild" : null}
            onClick={e => {
              // Second param: Check if we loop on a thematic or a category (thematic doesn't have a parent property)
              this.childRef.current.onChange(
                item,
                e.currentTarget,
                item.hasOwnProperty("parent")
              );
            }}
          >
            {item.name}
          </button>
          {SUB_CATEGORIES && (
            <ul
              className={`level-${level}`}
              style={{ height: this.state.height }}
            >
              {this.buildTree(SUB_CATEGORIES, level)}
            </ul>
          )}
        </li>
      );
    });
  }

  onChangeSelectAllChildren(e) {
    const TARGET = e.target;
    const LIST = Array.prototype.filter.call(
      TARGET.parentNode.children,
      function (child) {
        return child.tagName === "UL";
      }
    )[0];
    let items = [];

    if (TARGET.checked) {
      items = LIST.querySelectorAll("button:not(.withChild):not(.select)");
    } else {
      items = LIST.querySelectorAll("button.select");
    }

    items.forEach(item => item.click());
  }

  render() {
    return this.state.loading ? (
      <Loader type={"inline"} />
    ) : (
      <SelectMultiple
        ref={this.childRef}
        title={this.props.title}
        multiple={this.props.multiple}
        selected={this.props.selected}
        selectAllButton={this.props.selectAllButton}
        height={this.state.height}
        onRemove={this.props.onRemove}
        whenNotMultipleUnselect={this.props.whenNotMultipleUnselect}
        selectWithChildItem={this.props.selectWithChildItem}
        onChange={this.props.onChange}
        onAdd={this.props.onAdd}
        onSave={this.props.onSave}
        loadingSave={this.props.loadingSave}
        listThematicLegend={true}
      >
        {this.buildTree(this.props.categories || this.state.options)}
      </SelectMultiple>
    );
  }
}
