import React from 'react';
import PropTypes from "prop-types";
import "./Button.scss";
import Loader from "../../Loader/Loader";


export default class Button extends React.Component {

    static propTypes = {
        type: PropTypes.string,
        loading: PropTypes.bool,
        onClick: PropTypes.func,
        style: PropTypes.object,
        className: PropTypes.string,
        disabled: PropTypes.bool,
        title: PropTypes.string,
    };

    static defaultProps = {
        type: 'button',
        loader: false,
        disabled: false
    };

    constructor(props) {
        super(props);
        this.btn = null;
    }

    reference() {
        return this.btn;
    }

    render() {
        const TEXT_VISIBILITY = this.props.loading ? 'hidden' : 'visible';

        return (
            <button ref={(input) => this.btn = input} type={this.props.type} className={this.props.className} onClick={this.props.onClick} style={this.props.style} disabled={this.props.disabled} title={this.props.title}>
                {this.props.loading && <Loader type={'button'} backgroundColor={'transparent'}/>}
                <span tabIndex='-1'>
                    <em style={{visibility: TEXT_VISIBILITY}}>{this.props.children}</em>
                </span>
            </button>
        )
    }
}