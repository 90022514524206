import React from "react";
import Rdate from "react-datetime";
import PropTypes from "prop-types";
import CKEditor from "ckeditor4-react";
import InputTextLight from "../InputTextLight/InputTextLight";
import ListTags from "../../List/ListTags/ListTags";
import TagsInputWithButton from "../TagsInputWithButton/TagsInputWithButton";
import InputFile from "../InputFile/InputFile";
import SelectCategories from "../SelectCategories/SelectCategories";
import CheckBoxSlider from "../CheckBoxSlider/CheckBoxSlider";
import "./FormAddDoc.scss";
import Auth from "../../../Security/Auth";
import { CK_EDITOR } from "../../../Config/ckEditor";
import "./FormAddDoc.scss";
import Loader from "../../Loader/Loader";
import Thematic from "../../../Utils/Thematics";
import Select from "../Select/Select";
import MAP_TYPES, {
  DOCUMENT_FILTER_TYPE_MAP,
  MAP_TYPES_JSON,
} from "../../../Utils/Filters/MapTypes";
import {
  DOCUMENT_FILTER_TYPE_VEHICLE,
  VEHICLE_TYPES_JSON,
} from "../../../Utils/Filters/VehicleTypes";
import ApiDocumentFilter from "../../../Services/ApiDocumentFilter";
import { DateHandler } from "../../../Utils/DateHandler";

export default class FormAddDoc extends React.Component {
  static propTypes = {
    file: PropTypes.shape({
      id: PropTypes.number,
      date: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
      title: PropTypes.string,
      excerpt: PropTypes.string,
      content: PropTypes.string,
      visual: PropTypes.string,
      category: PropTypes.object,
      author: PropTypes.number,
      archivedat: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
    }),
    thematic: PropTypes.number,
    onCancel: PropTypes.func,
    onValid: PropTypes.func,
    onDelete: PropTypes.func,
    onArchive: PropTypes.func,
    formError: PropTypes.string,
  };

  state = {
    form: {
      id: "",
      date: new Date(),
      title: "",
      excerpt: "",
      content: "",
      visual: "",
      archivedat: "",
      file: {
        name: "",
        size: 0,
      },
      tags: [],
      category: {},
      author: Auth.getUser().id,
      maptype: "",
      vehicletype: "",
      monthPeriodicity: new Date().getMonth() + 1,
      quarterlyPeriodicity: Math.ceil((new Date().getMonth() + 1) / 3),
      yearPeriodicity: new Date().getFullYear(),
    },
    error: {
      date: "",
      title: "",
      tags: "",
      file: "",
      categories: "",
      maptype: "",
      vehicletype: "",
    },
    categories: [],
    workgroups: [],
    tag: "",
    formError: "",
    loadingForm: false,
    categoryWithSpecificFilters: null,
  };

  constructor(props) {
    super(props);
    this._isMounted = false;
    this.inputTagRef = React.createRef();
    this.thematic = {};
  }

  async componentDidMount() {
    this._isMounted = true;
    let propsFile = this.props.file;
    let formError = this.props.formError;

    if (formError) {
      this.setState({ formError: formError });
    }

    if (propsFile) {
      let keys = Object.keys(propsFile);
      let file = { ...propsFile };

      for (let i = 0; i < keys.length; i++) {
        if (this.state.form[keys[i]] === undefined && keys[i] !== "files") {
          delete file[keys[i]];
        }
      }

      // Get specific filters
      if (propsFile.category.hasspecificfilters) {
        file.filters = await ApiDocumentFilter.getByDocumentId(file.id);
        if (file.filters.length > 0) {
          file.filters.forEach(filter => {
            if (filter.type === DOCUMENT_FILTER_TYPE_MAP) {
              file.maptype = filter;
              const periodicity = new Date(
                filter.periodicity.date || filter.periodicity
              );
              file.yearPeriodicity = periodicity.getFullYear();
              file.monthPeriodicity = periodicity.getMonth() + 1;
              file.quarterlyPeriodicity = Math.ceil(file.monthPeriodicity / 3);
            } else if (filter.type === DOCUMENT_FILTER_TYPE_VEHICLE) {
              file.vehicletype = filter;
              delete filter.periodicity;
            }
          });
        }
      }

      file.category.color = file.category.thematic.color;

      this.setState({
        form: file,
        categoryWithSpecificFilters: propsFile.category.hasspecificfilters
          ? file.category
          : null,
      });
    }

    const THEMATIC = Thematic.getThematic(this.props.thematic, true);
    this.thematic = THEMATIC;
    this.setState({ categories: THEMATIC.categories });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.formError !== this.props.formError) {
      this.setState({ formError: this.props.formError, loadingForm: false });
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  handleFormValue(key, value, callback) {
    this.setState(
      state => ({
        form: { ...state.form, [key]: value },
      }),
      () => {
        if (callback) return callback();
      }
    );
  }

  handleError(key, value) {
    this.setState(state => ({ error: { ...state.error, [key]: value } }));
  }

  handleTagValue(value) {
    value = value.trim().toLocaleLowerCase().replace(/^ /, "");

    this.setState({ tag: value });
  }

  handleFileChange(file) {
    if (!file) return;
    this.setState(state => ({ form: { ...state.form, file: file } }));
  }

  onKeyDownTag(e) {
    if (e.key === "Enter") {
      e.preventDefault();

      this.addTag(this.state.tag);
    }
  }

  addTag(value) {
    if (!value) return;

    value = value.trim().toLocaleLowerCase().replace(/^ /, "");

    let tags = this.state.form.tags;

    if (tags.length >= 3) {
      this.handleError("tags", "Vous ne pouvez pas ajouter plus de 3 tags.");
    } else {
      if (tags.indexOf(value) === -1) {
        // Add new tag
        tags.push(value);
      }

      // focus the input text for the tags
      this.inputTagRef.current.focus();

      this.setState(
        state => ({
          form: { ...this.state.form, tags: tags },
          error: { ...state.error, tags: "" },
          tag: "",
        }),
        () => {
          this.handleError("tags", "");
        }
      );
    }
  }

  removeTags(value) {
    let tags = this.state.form.tags;

    // Create a new doc
    if (isNaN(value)) {
      tags.splice(tags.indexOf(value), 1);
    } else {
      // Update a doc
      tags.splice(
        tags
          .map(function (x) {
            return x.id;
          })
          .indexOf(value),
        1
      );
    }

    this.setState(state => ({
      form: { ...this.state.form, tags: tags },
      error: { ...state.error, tags: "" },
    }));
  }

  checkFormValid() {
    let formHasError = false;

    return new Promise(resolve => {
      this.setState(
        state => {
          const FILE_IS_EMPTY =
            typeof state.form.file === "undefined" ||
            state.form.file.name === "";
          return {
            error: {
              title: this.state.form.title ? "" : "Le titre est requis",
              file: FILE_IS_EMPTY ? "Le fichier est requis" : "",
              categories:
                this.state.categories.length > 0 &&
                Object.keys(this.state.form.category).length === 0
                  ? "La thématique est requise"
                  : "",
              date: this.state.form.date ? "" : "La date est requise",
              maptype:
                !state.categoryWithSpecificFilters ||
                (state.categoryWithSpecificFilters && state.form.maptype)
                  ? ""
                  : "Le type de carte est requis",
              vehicletype:
                !state.categoryWithSpecificFilters ||
                (state.categoryWithSpecificFilters && state.form.vehicletype)
                  ? ""
                  : "Le type de véhicule est requis",
            },
          };
        },
        () => {
          for (let property in this.state.error) {
            if (
              this.state.error.hasOwnProperty(property) &&
              this.state.error[property] !== ""
            ) {
              formHasError = true;
            }
          }

          if (formHasError) {
            this.setState({
              formError: "Des erreurs sont présentes dans le formulaire",
            });
          } else {
            this.setState({
              formError: "",
              loadingForm: true,
            });
          }

          resolve(formHasError);
        }
      );
    });
  }

  validateForm() {
    if (this.state.form.archivedat) {
      return this.props.onArchive(this.state.form);
    }

    this.checkFormValid().then(formHasError => {
      if (!this._isMounted) return;
      if (formHasError) return;

      // Send form if not form errorR
      this.setState(
        state => ({
          form: {
            ...state.form,
            file: state.form.file.size ? state.form.file : {},
            loadingForm: false,
          },
        }),
        () => {
          let form = this.state.form;
          if (this.state.categories.length === 0) {
            form.thematic = this.thematic;
          }

          // Set the periodicity

          if (form.maptype && form.maptype.value !== "") {
            let periodicity = "";
            const YEAR = this.state.form.yearPeriodicity;
            // MONTH
            if (form.maptype.value === MAP_TYPES[0].value) {
              periodicity = `${YEAR}-${this.state.form.monthPeriodicity}-01`;
              // QUARTER
            } else if (form.maptype.value === MAP_TYPES[1].value) {
              periodicity = `${YEAR}-${
                this.state.form.quarterlyPeriodicity * 3
              }-01`;
              // YEAR
            } else {
              periodicity = `${YEAR}-01-01`;
            }

            form.periodicity = DateHandler.formatForApi(new Date(periodicity));
          }

          return this.props.onValid(form);
        }
      );
    });
  }

  selectCategoriesOnChange(category) {
    let cat = category[0];

    if (cat === undefined) {
      cat = {};
    }

    if (cat && !cat.hasOwnProperty("parent")) {
      this.thematic = cat;
    } else {
      cat.thematic = this.thematic;
      cat.color = this.thematic.color;
      this.setState(state => ({ form: { ...state.form, category: cat } }));
    }

    if (cat.hasspecificfilters) {
      this.setState({
        categoryWithSpecificFilters: cat,
      });
    } else {
      this.setState(state => ({
        categoryWithSpecificFilters: null,
        form: { ...state.form, maptype: "", vehicletype: "" },
      }));
    }
  }

  removeFileUpload() {
    this.setState(state => ({
      form: {
        ...state.form,
        file: {
          name: "",
          size: 0,
        },
      },
    }));
  }

  render() {
    const MONTH = [];

    for (let i = 1; i < 13; i++) {
      MONTH.push({
        id: i,
        name: DateHandler.getMonthName(i),
      });
    }

    const QUART = [];
    for (let i = 1; i < 5; i++) {
      QUART.push({
        id: i,
        name: i + "T",
      });
    }

    return (
      <div className={"formAdDoc"}>
        {this.props.file ? (
          <div className={"headerFormAddDoc"}>
            <div className="row">
              <div className="col">
                <h3 className={"titleModal"}>
                  Editer le document « {this.state.form.title} »
                </h3>
              </div>
              <div className="col-md-auto">
                <div className={"stateCurrentDoc"}>
                  <CheckBoxSlider
                    id={"archivedat"}
                    name={"archivedat"}
                    labelLeft={"Publié"}
                    labelRight={"Archivé"}
                    checked={!!this.state.form.archivedat}
                    onChange={e =>
                      this.handleFormValue(
                        "archivedat",
                        e.target.checked ? "checked" : ""
                      )
                    }
                  />
                  <button
                    type={"button"}
                    className={"underline blue"}
                    onClick={() => this.props.onDelete(this.props.file)}
                  >
                    Supprimer
                  </button>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <h3 className={"titleModal"}>Ajouter un document</h3>
        )}
        <hr />
        <br />

        <form>
          <div className="row">
            <div className="col">
              {/* Title */}
              <InputTextLight
                label={"Titre du document*"}
                value={this.state.form.title}
                errorMsg={this.state.error.title}
                onChange={e => this.handleFormValue("title", e.target.value)}
              />

              {/* Tags */}
              <TagsInputWithButton
                label={"Tags du document (max. 3 tags)"}
                placeholder={"Nom du tag"}
                value={this.state.tag}
                textButton={"Ajouter"}
                errorMsg={this.state.error.tags}
                inputRef={this.inputTagRef}
                onSubmit={e => this.addTag(e)}
                onKeyDown={e => this.onKeyDownTag(e)}
                onChange={value => this.handleTagValue(value)}
                withAutoComplete={true}
              />

              {/* Tags */}
              <ListTags
                tags={this.state.form.tags}
                skin={"blue"}
                onRemove={item => this.removeTags(item)}
              />
            </div>
            <div className="col">
              {/* Date picker */}
              <div
                className={`timePickerWrapper${
                  this.state.error.date ? " error" : ""
                }`}
              >
                <div className="agendCtrls-time-picker">
                  <label>Date de publication*</label>
                  <Rdate
                    value={this.state.form.date}
                    input={false}
                    viewMode="days"
                    timeFormat={false}
                    onChange={e => this.handleFormValue("date", e.toDate())}
                  />
                </div>
                {this.state.error.date && (
                  <span className={"errorMsg"}>{this.state.error.date}</span>
                )}
              </div>
            </div>
          </div>

          {/* File */}
          <InputFile
            id={"attachments"}
            label={"Pièce-jointe*"}
            fileName={this.state.form.file ? this.state.form.file.name : "qs"}
            errorMsg={this.state.error.file}
            onChange={file => this.handleFileChange(file)}
            onRemove={() => this.removeFileUpload()}
          />

          {/* Editor WYSIWYG */}
          <h5 className={"subTitle"} style={{ marginBottom: 10, marginTop: 0 }}>
            Description
          </h5>
          <CKEditor
            onBeforeLoad={(CKEditor) => {
                CKEditor.disableAutoInline = true;
                CK_EDITOR.config.filebrowserUploadUrl = CK_EDITOR.baseUploadUrl + Auth.getToken();
            }}
            config={CK_EDITOR.config}
            data={this.state.form.content}
            type="classic"
            onChange={event => {
              const data = event.editor.getData();
              this.handleFormValue("content", data);
            }}
          />

          {/* Categories */}
          {this.state.categories.length > 0 && (
            <>
              <h5
                className="subTitle"
                style={{ marginBottom: 10, marginTop: 40 }}
              >
                Thématique du document*
              </h5>
              <SelectCategories
                title={"Sélectionnez une catégorie"}
                selected={
                  Object.keys(this.state.form.category || {}).length > 0
                    ? [this.state.form.category]
                    : []
                }
                onChange={category => this.selectCategoriesOnChange(category)}
                multiple={false}
                errorMsg={this.state.error.categories}
                thematic={this.props.thematic}
                categories={this.state.categories}
                whenNotMultipleUnselect={true}
              />
            </>
          )}

          {this.state.categoryWithSpecificFilters && (
            <>
              <h5
                className="subTitle"
                style={{ marginBottom: 10, marginTop: 40 }}
              >
                Type de carte*
              </h5>
              <Select
                title={"Sélectionnez un type de carte"}
                placeholder={"Sélectionnez un type de carte"}
                value={Object.values(MAP_TYPES_JSON)}
                selected={
                  this.state.form.maptype
                    ? JSON.stringify({
                        type: this.state.form.maptype.type,
                        value: this.state.form.maptype.value,
                      })
                    : ""
                }
                onChange={e => {
                  this.handleFormValue(
                    "maptype",
                    e.target.value ? JSON.parse(e.target.value) : ""
                  );
                }}
                multiple={false}
                errorMsg={this.state.error.maptype}
              />

              <h5
                className="subTitle"
                style={{ marginBottom: 10, marginTop: 40 }}
              >
                Type de véhicule*
              </h5>
              <Select
                title={"Sélectionnez un type de véhicule"}
                placeholder={"Sélectionnez un type de véhicule"}
                value={VEHICLE_TYPES_JSON}
                selected={
                  this.state.form.vehicletype !== ""
                    ? JSON.stringify({
                        type: this.state.form.vehicletype.type,
                        value: this.state.form.vehicletype.value,
                      })
                    : ""
                }
                onChange={e => {
                  this.handleFormValue(
                    "vehicletype",
                    e.target.value ? JSON.parse(e.target.value) : ""
                  );
                }}
                multiple={false}
                errorMsg={this.state.error.vehicletype}
              />
            </>
          )}
          {this.state.form.maptype && this.state.form.maptype !== "" && (
            <>
              {this.state.form.maptype.value === MAP_TYPES[0].value && (
                <>
                  <h5
                    className="subTitle"
                    style={{ marginBottom: 10, marginTop: 40 }}
                  >
                    Mois*
                  </h5>
                  <Select
                    title={"Mois"}
                    placeholder={"Sélectionnez un mois"}
                    value={MONTH}
                    selected={this.state.form.monthPeriodicity}
                    onChange={e => {
                      this.handleFormValue(
                        "monthPeriodicity",
                        e.target.value ? e.target.value : ""
                      );
                    }}
                    multiple={false}
                  />
                </>
              )}

              {this.state.form.maptype.value === MAP_TYPES[1].value && (
                <>
                  <h5
                    className="subTitle"
                    style={{ marginBottom: 10, marginTop: 40 }}
                  >
                    Trimestre*
                  </h5>
                  <Select
                    title={"Trimestre"}
                    placeholder={"Sélectionnez un trimestre"}
                    value={QUART}
                    selected={this.state.form.quarterlyPeriodicity}
                    onChange={e => {
                      this.handleFormValue(
                        "quarterlyPeriodicity",
                        e.target.value ? e.target.value : ""
                      );
                    }}
                    multiple={false}
                  />
                </>
              )}

              <InputTextLight
                type="number"
                label={"Année*"}
                value={this.state.form.yearPeriodicity.toString()}
                onChange={e => {
                  this.handleFormValue("yearPeriodicity", e.target.value);
                }}
                min={new Date().getFullYear() - 10}
                max={new Date().getFullYear() + 10}
              />
            </>
          )}
        </form>

        <p className={"required"}>
          <b>* Champs obligatoires</b>
        </p>

        <br />

        {this.state.formError && (
          <p className={"centerText"} style={{ color: "red" }}>
            {this.state.formError}
          </p>
        )}

        {/* Button */}
        <div className={"buttonModal"}>
          <button
            type={"button"}
            className={"cancel"}
            onClick={() => this.props.onCancel()}
          >
            Annuler
          </button>
          <button
            type={"button"}
            onClick={() => this.validateForm()}
            disabled={this.state.loadingForm}
          >
            {this.state.loadingForm ? (
              <Loader type={"button"} backgroundColor={"transparent"} />
            ) : (
              "Valider"
            )}
          </button>
        </div>
      </div>
    );
  }
}
