import React from 'react';
import ApiWorkGroup from "../../Services/ApiWorkGroup";
import ApiDocument, { DOC_RESULT_PAGE } from "../../Services/ApiDocument";
import ApiWorkGroupUser from "../../Services/ApiWorkGroupUser";
import ApiUser from "../../Services/ApiUser";
import * as qs from "qs";
import Auth from "../../Security/Auth";
import Master from "../../Components/Layout/Master";
import Button from "../../Components/Form/Button/Button";
import MembersListForWorkGroup from "../../Components/List/MembersListForWorkGroup/MembersListForWorkGroup";
import CarouselLastEvent from "../../Components/Carousel/CarouselLastEvent";
import CarouselDocument from "../../Components/Carousel/CarouselDocument";
import Loader from "../../Components/Loader/Loader";
import Search from "../../Components/Search/Search";
import Modal from "../../Components/Modal/Modal";
import FormAddDocWorkGroup from "../../Components/Form/FormAddDocWorkGroup/FormAddDocWorkGroup";
import ToolsManage from "../../Components/ToolsManage/ToolsManage";
import ModalConfirm from "../../Components/Modal/ModalConfirm/ModalConfirm";
import SearchUsers from "../../Components/Form/SearchUsers/SearchUsers";
import {DateHandler} from "../../Utils/DateHandler";
import SmoothScroll from "../../Utils/SmoothScroll";
import Roles from "../../Security/Roles";
import {filterDocument} from "../Documentation/DocumentationList";
import {Http} from "../../Utils/Api";
import "./WorkGroup.scss";
import Alert from "../../Components/Alert/Alert";
import {path} from "../../Config/routes";

export default class WorkGroup extends React.Component {

    state = {
        breadCrumb: [{
            url: path('WorkGroupList'),
            label: 'Mes groupes de travail'
        }],
        workGroup: {
            id: null,
            name: '',
            subscriptions: [],
            thematic: {
                color: '',
                name: ''
            },
            contributors: [],
            animators: [],
            formattedAnimators: '',
        },
        documents: [],
        featuredDocuments: [],
        editMode: false,
        deleteMode: false,
        archiveMode: false,
        pagination: null,
        httpError: false,
        permissionsError: false,
        // Temp data
        tempFile: null,
        tempUser: undefined,
        tempError: '',
        // Toggle item
        showMemberList: false,
        showModalAddDoc: false,
        showModalAddMember: false,
        showModalConfirm: false,
        showModalConfirmDeleteDoc: false,
        //Loading
        loadingWorkGroup: true,
        loadingCarouselEvent: true,
        loadingDocuments: true,
        loadingForm: false,
        loadingAlert: false,
        loadingToggleMembers: false,
        alert: {
            show: false,
            action: 'archive',
            value: ''
        },
        filters: {meeting: {}, doctype: {}},
        refreshed: false
    };

    constructor(props) {
        super(props);
        this._isMounted   = false;
        this.id           = null;
        this.pagination   = null;
        this.fromCarousel = false;

        this._filter = {};
    }

    componentDidMount() {
        this._isMounted = true;
        this._baseSate = this.state;
        this.init();
    }

    componentWillUnmount() {
        this._isMounted = false;
        Http.cancel();
    }

    componentDidUpdate(prevProps, prevState) {
        // Refresh if param change from menu
        if (prevProps.match.params.id !== this.props.match.params.id && !this.fromCarousel) {
            window.scroll(0, 0);
            this.init();
        }
    }

    init() {
        if (this.props.match.params.id !== undefined) {
            this.id = parseInt(this.props.match.params.id);
        }

        this._filter      = {
            where: ''
        };

        this.setState({
            ...this._baseSate,
            breadCrumb: [this.state.breadCrumb[0]],
            loadingWorkGroup: true,
            loadingDocuments: true,
            loadingCarouselEvent: true,
            editMode: false,
            archiveMode: false,
            deleteMode: false
        });

        ApiWorkGroup.get(this.id, ['thematic,users.user.profile.company,subscriptions.user,contributors'], true).then((workGroup) => {
            if (!this._isMounted) return;
            ApiUser.getUserSubscription(Auth.getUser().id).then((user) => {
                if (workGroup === null) {
                    this.setState({permissionsError: true})
                } else {
                    // Get document
                    ApiDocument.getDocumentsFeaturedWorkgroup(this.id).then((payload) => {

                        // Sort documents by publishedat DESC, createdat DESC
                        let documents = payload.sort((document1, document2) => {
                            let publishedat1 = new Date(document1.publishedat.date);
                            let publishedat2 = new Date(document2.publishedat.date);

                            if (publishedat1 > publishedat2) return -1;
                            if (publishedat1 < publishedat2) return 1;

                            let createdat1 = new Date(document1.createdat.date);
                            let createdat2 = new Date(document2.createdat.date);

                            if (createdat1 > createdat2) return -1;
                            if (createdat1 < createdat2) return 1;
                        });

                        this.setState({
                            featuredDocuments: documents,
                        }, () => {
                            workGroup.subscriptions = user.subscriptions;
                            this.updateData(workGroup)
                        });

                        console.log(workGroup)
                    });
                }
            });
        }).catch((err) => {
            if (!this._isMounted) return;
            if (err.status && err.status >= 500) {
                this.setState({
                    httpError: true
                });
            }
        });
    }

    updateData(workGroup) {
        let breadCrumb = this.state.breadCrumb;

        breadCrumb[1] = {
            url: path('WorkGroupList', {category: workGroup.thematic.uid}),
            label: workGroup.thematic.name
        };

        breadCrumb[2] = {label: workGroup.name};

        // Custom sort order (too hard to implements order parameter into nested_entities)
        workGroup.users.sort((user1, user2) => {
            // Sort by membertype, lastname, firstname

            if (+user1.membertype > +user2.membertype) return 1;
            if (+user1.membertype < +user2.membertype) return -1;

            let lastname = user1.user.profile.lastname.localeCompare(user2.user.profile.lastname, 'fr', {ignorePunctuation: true});
            let firstname = user1.user.profile.firstname.localeCompare(user2.user.profile.firstname, 'fr', {ignorePunctuation: true});

            return lastname || firstname;
        });

        workGroup.animators = this.getAnimatorsFromUsers(workGroup.users);
        workGroup.formattedAnimators = this.getFormattedAnimatorsFromUsers(workGroup.users);

        this.setState({
            workGroup: workGroup,
            breadCrumb: breadCrumb,
            loadingWorkGroup: false,
            loadingCarouselEvent: false
        }, () => {
            const PARAM = qs.parse(this.props.location.search, {ignoreQueryPrefix: true});
            this.getDocumentsWorkgroup(PARAM.page || 1)
        });

        if (this.fromCarousel) {
            // Update url
            this.props.history.push('/work-groups/' + workGroup.id);
            this.fromCarousel = false;
        }
    }

    getDocumentsWorkgroup(page) {
        // Get document
        ApiDocument.getDocumentsWorkgroup(this.id, page, DOC_RESULT_PAGE, false, this._filter).then(({payload, extra}) => {
            if (!this._isMounted) return;
            this.pagination = extra;
            this.setState({
                documents: payload,
                loadingDocuments: false
            })
        });
    }

    onPaginationClick(page) {
        this.setState({loadingDocuments: true}, () => this.getDocumentsWorkgroup(page));
    }

    handlerCarousel(direction) {
        if (direction) {
            Http.cancel();
            // Init state
            this.setState({
                breadCrumb: [this.state.breadCrumb[0]],
                documents: [],
                loadingDocuments: true,
                loadingCarouselEvent: true,
                loadingWorkGroup: true,
                workGroup: {
                    id: null,
                    name: '',
                    subscriptions: [],
                    thematic: {
                        color: '',
                        name: ''
                    }
                },
            });

            const AFTER = direction === 'next';
            const ORDER = direction === 'next' ? 'asc' : 'desc';

            // Get closest workgroup

            ApiWorkGroup.getClosest(this.state.workGroup.id, AFTER, ORDER, ['thematic,users.user.profile.company,documents,subscriptions']).then((workgroup) => {
                if (!this._isMounted) return;
                if (workgroup.length > 0) {
                    this.fromCarousel = true;
                    this.updateData(workgroup[0])
                } else {
                    // If no result
                    ApiWorkGroup.getClosest(0, AFTER, ORDER, ['thematic,users.user.profile.company,documents,subscriptions']).then((workgroup) => {
                        if (!this._isMounted) return;
                        this.fromCarousel = true;
                        this.updateData(workgroup[0])
                    });
                }
            });
        }
    }

    openFormAddDoc = () => {
        this.setState({
            editMode: false,
            deleteMode: false,
            showModalAddDoc: true
        })
    };

    openFormEditDoc(file) {
        file.file   = file.files[0];
        file.author = file.author && file.author[0];
        if (file.date === undefined) {
            file.date = file.publishedat && DateHandler.getJsDate(file.publishedat.date);
        }

        this.setState({
            tempFile: file,
            showModalAddDoc: true
        });
    }

    toggleEditMode() {
        this.setState((state) => ({
            deleteMode: false,
            archiveMode: false,
            editMode: !state.editMode
        }))
    }

    toggleDeleteMode() {
        this.setState((state) => ({
            editMode: false,
            archiveMode: false,
            deleteMode: !state.deleteMode
        }))
    }

    toggleArchiveMode() {
        this.setState((state) => ({
            editMode: false,
            deleteMode: false,
            archiveMode: !state.archiveMode
        }))
    }

    deleteOrArchiveDoc(file) {
        this.setState({
            tempFile: file,
            showModalConfirmDeleteDoc: true
        });
    }

    confirmDeleteDoc(file, safeDelete = true) {
        this.setState({loadingForm: true});

        const archived = !!file.archivedat;
        const PROMISE = safeDelete ? ApiDocument.archive(file.id, archived ? 0 : 1) : ApiDocument.remove(file.id);

        PROMISE.then(() => {


            if (!this._isMounted) return;


            // @TODO reload results with current pagination filters

            let editedDocuments = this.state.documents;
            // On maintien leur système qui remanie coté front la liste
            if (!safeDelete) {
                for (let property in editedDocuments) {
                    if (editedDocuments.hasOwnProperty(property) && editedDocuments[property].id === file.id) {
                        editedDocuments.splice(property, 1);
                        break;
                    }
                }
                this.pagination.resources.total--;
            } else {
                for (let property in editedDocuments) {
                    if (editedDocuments.hasOwnProperty(property) && editedDocuments[property].id === file.id) {
                        if (archived) {
                            editedDocuments[property].archivedat = null;
                        } else {
                            editedDocuments[property].archivedat = DateHandler.getPrettyDate();
                        }
                    }
                }
            }

            this.setState({
                documents: editedDocuments,
                showModalAddDoc: false,
                showModalConfirm: false,
                showModalConfirmDeleteDoc: false,
                loadingForm: false,
                loadingDocuments: true,
              //  deleteMode: false,
              //  archiveMode: false,
                alert: {
                    show: true,
                    action: safeDelete ? (archived ? 'unarchive' : 'archive') : 'delete',
                    value: file.title
                },
                refreshed: true
            }, () => {
                this.setState({
                    tempFile: null,
                    loadingDocuments: false,
                    refreshed: false
                });
            });
        })
    }

    /* HERE */
    validDocument(document, isNewDoc) {
        const WORK_GROUP     = this.state.workGroup;
        let docWithWorkGroup = document;
        let documents        = this.state.documents;

        // Add workgroup
        docWithWorkGroup.workgroup = WORK_GROUP;

        if (isNewDoc) {
            // Add new document to list of documents
            documents.unshift(docWithWorkGroup);
            this.pagination.resources.total++;
        } else {
            // Update doc
            documents.forEach((doc, i) => {
                if (doc.id === document.id)
                    documents[i] = docWithWorkGroup;
            })
        }

        this.setState({
            documents: documents,
            showModalAddDoc: false,
            loadingDocuments: true,
           // editMode: false,
            filters: {meeting: docWithWorkGroup.meeting || {}, doctype: docWithWorkGroup.doctype || {}},
            alert: {
                show: true,
                action: isNewDoc ? 'add' : 'update',
                value: document.title
            },
            refreshed: true
        }, () => {
            this.setState({
                loadingDocuments: false,
                refreshed: false
            })
        });
    }

    changeRole(users, memberType) {
        ApiWorkGroupUser.updateMemberType(users.id, memberType);

        // Update users state
        for (let property in this.state.workGroup.users) {
            const USER = this.state.workGroup.users[property];

            if (USER.id === users.id) {
                let newsUsers                  = this.state.workGroup.users;
                newsUsers[property].membertype = memberType;

                this.setState({
                    workGroup: {
                        ...this.state.workGroup,
                        users: newsUsers,
                        animators: this.getAnimatorsFromUsers(newsUsers),
                        formattedAnimators: this.getFormattedAnimatorsFromUsers(newsUsers)
                    }
                });

                break;
            }
        }
    }

    cancelRemoveUserFromWorkGroup = () => {
        this.setState({
            showModalConfirm: false,
            loadingForm: false,
            tempUser: undefined
        })
    };

    removeUserFromWorkGroup = (users, i) => {
        this.setState({
            showModalConfirm: true,
            tempUser: {
                users: users,
                index: i
            }
        })
    };

    confirmRemoveUserFromWorkGroup = () => {
        this.setState({loadingForm: true});

        ApiWorkGroupUser.remove(this.state.tempUser.users.id).then(() => {
            if (!this._isMounted) return;

            let newUsers = this.state.workGroup.users;
            // Remove user from users
            newUsers.splice(this.state.tempUser.index, 1);

            this.setState({
                tempUser: undefined,
                showModalConfirm: false,
                workGroup: {
                    ...this.state.workGroup,
                    users: newUsers,
                    animators: this.getAnimatorsFromUsers(newUsers),
                    formattedAnimators: this.getFormattedAnimatorsFromUsers(newUsers)
                }
            }, () => {
                const NUMBERS_OF_MEMBER = document.querySelectorAll('.numbersOfMembers');
                for (let i = 0; i < NUMBERS_OF_MEMBER.length; i++) {
                    NUMBERS_OF_MEMBER[i].innerHTML = this.state.workGroup.users.length || ''
                }
            });
        }).finally(() => {
            this.setState({
                loadingForm: false,
            })
        });
    };

    toggleMembersPanel = () => {
        if (this.state.documents.length === 0) {
            this.updateData(this.state.workGroup)
        }
        this.setState({showMemberList: !this.state.showMemberList})
    };

    getAnimatorsFromUsers(users) {
        return users.filter(user => user.membertype === '2');
    }

    getFormattedAnimatorsFromUsers(users) {
        let animators = users.filter(user => user.membertype === '2');
        if (animators.length === 0) {
            return 'Aucun animateur';
        }
        let html = '';
        animators.forEach((val, key, arr) => {
            html += (val.user.profile.firstname + ' ' + val.user.profile.lastname);
            if (!Object.is(arr.length - 1, key)) {
                html += ', ';
            }
        })

        return html;
    }

    /**
     * Toggle display members for all user
     */
    toggleMembers() {
        const WG = {
            ...this.state.workGroup,
            showuser: !this.state.workGroup.showuser
        };

        // Remove useless field
        delete WG.createdat;
        delete WG.publishedat;
        delete WG.updatedat;
        delete WG.subscriptions;
        delete WG.contributors;
        delete WG.users;
        delete WG.documents;
        delete WG.uuid;

        this.setState({
            loadingToggleMembers: true
        }, () => {
            ApiWorkGroup.update(WG).then(() => {
                this.setState({
                    workGroup: {
                        ...this.state.workGroup,
                        showuser: !this.state.workGroup.showuser
                    },
                    loadingToggleMembers: false
                });
            });
        });
    }

    filter(payload, page) {
        this.setState({loadingDocuments: true});
        const PARAMS = qs.parse(this.props.location.search, {ignoreQueryPrefix: true});

        if (false === this.userCanManage(Auth.getUser())) {
            this._filter      = {
                // where: '`archived_at` = `null`'
            };
        } else {
            this._filter = {};
        }

        let filter = filterDocument(payload, page, PARAMS);

        this._filter.where += filter.where;
        this._filter.sort    = filter.sort;
        this._filter.orderBy = filter.orderBy;

        this.getDocumentsWorkgroup(filter.page)
    }

    addMemberFromWorkGroup() {
        this.setState({loadingForm: true, tempError: ''});

        const USERS = this.state.tempUser;

        if (USERS) {
            const USERS_IDS    = Object.keys(USERS);
            let wgUserPromises = [];

            for (let i = 0; i < USERS_IDS.length; i++) {
                wgUserPromises.push(ApiWorkGroupUser.add(USERS[USERS_IDS[i]].id, this.state.workGroup.id, USERS[USERS_IDS[i]].memberType));
            }

            // Add users to workgroup
            Promise.all(wgUserPromises).then((users) => {
                if (!this._isMounted) return;

                let newsUsers = [];

                users.forEach((user) => {
                    newsUsers.push(user)
                });
                newsUsers = this.state.workGroup.users.concat(newsUsers);

                this.setState({
                    showModalAddMember: false,
                    workGroup: {
                        ...this.state.workGroup,
                        users: newsUsers,
                        formattedAnimators: this.getFormattedAnimatorsFromUsers(newsUsers)
                    }
                }, () => {
                    // Update number of member in carousel
                    const NUMBERS_OF_MEMBER = document.querySelectorAll('.numbersOfMembers');
                    for (let i = 0; i < NUMBERS_OF_MEMBER.length; i++) {
                        NUMBERS_OF_MEMBER[i].innerHTML = this.state.workGroup.users.length || '';
                    }
                });
            }).finally(() => {
                this.setState({loadingForm: false});
            })
        } else {
            this.setState({loadingForm: false, tempError: 'Aucun membre selectionné.'}, () => {
                const MODAL_CONTENT = document.querySelector('#workGroups .modalContent');
                new SmoothScroll(MODAL_CONTENT.querySelector('.errorMsg'), {
                    context: MODAL_CONTENT
                });
            })
        }
    }

    addAlert() {
        ApiUser.addSubscription({workgroups: this.state.workGroup.id}).then((response) => {
            if (!this._isMounted) return;
            this.updateBtnTextAlert('Désactiver les alertes');
            this.setState({
                workGroup: {
                    ...this.state.workGroup,
                    subscriptions: [{
                        id: response.id,
                        workgroups: [{id: this.id}]
                    }]
                }
            });
        })
    }

    removeAlert() {
        const subscription = this.state.workGroup.subscriptions.find((sub) => this.findCurrentUser(sub));
        ApiUser.deleteSubscription(subscription.id).then(() => {
            if (!this._isMounted) return;
            this.updateBtnTextAlert('Recevoir des alertes');
            this.setState({
                workGroup: {
                    ...this.state.workGroup,
                    subscriptions: []
                }
            });
        })
    }

    updateBtnTextAlert(text) {
        const BUTTONS = document.querySelectorAll('.btnAlert em');
        for (let i = 0; i < BUTTONS.length; i++) {
            BUTTONS[i].innerText = text;
            BUTTONS[i].setAttribute('disabled', 'false');
        }
    }

    findCurrentUser(sub) {
        return sub.workgroups && sub.workgroups.length > 0 && sub.workgroups[0].id === this.id;
    }

    userCanManage(user) {
        if (user.role === Roles.ROLE_ADMINISTRATOR.uid && user.hasOwnProperty('thematic_administrator_of')) {
            return user.thematic_administrator_of.indexOf(this.state.workGroup.thematic.id) !== -1
        } else {
            return Auth.getRoleRank() >= Roles.ROLE_ROOT.rank;
        }
    }

    render() {
        let pageContent;

        // Member list
        if (this.state.showMemberList) {
            pageContent = <div className={'container'}>
                <ToolsManage onAdd={() => this.setState({showModalAddMember: true})}
                             edit={false}
                             role={(user) => this.userCanManage(user)}
                             delete={false}/>
                {this.state.workGroup.users && this.state.workGroup.users.length > 0
                    ? <MembersListForWorkGroup thematicId={this.state.workGroup.thematic.id}
                                               users={this.state.workGroup.users}
                                               onRemoveUser={this.removeUserFromWorkGroup}
                                               onChangeRole={(users, memberType) => this.changeRole(users, memberType)}/>
                    : <p className={'centerText'} style={{padding: '65px 0'}}><em>Aucun membre</em></p>}
            </div>;
        } else {
            /* Doc carousel & event carousel */
            pageContent = <>
                <div className="row no-gutters">
                    <div className="col-md-6">
                        <CarouselDocument documents={this.state.featuredDocuments}
                                          color={this.state.workGroup.thematic.color}
                                          loading={this.state.loadingDocuments}
                                          infinite={false}/>
                    </div>
                    <div className="col-md-6">
                        <CarouselLastEvent title={`AGENDA ${this.state.workGroup.thematic.name.toUpperCase()}`}
                                           thematicId={this.state.workGroup.thematic.id}
                                           loading={this.state.loadingCarouselEvent}/>
                    </div>
                </div>

                {/* Result doc */}
                <Search.Result results={this.state.documents}
                               nbResults={this.state.documents.length}
                               isForDocument={false}
                               label={`document${this.state.documents.length > 1 ? 's' : ''} disponible${this.state.documents.length > 1 ? 's' : ''}`}
                               loading={this.state.loadingDocuments}
                               adminModeFront={true}
                               onAdd={this.openFormAddDoc}
                               pagination={this.pagination}
                               fileColor={this.state.workGroup.thematic.color}
                               onPaginationClick={(page) => this.onPaginationClick(page)}
                               editMode={this.state.editMode}
                               deleteMode={this.state.deleteMode}
                               archiveMode={this.state.archiveMode}
                               onToggleEditMode={() => this.toggleEditMode()}
                               onToggleDeleteMode={() => this.toggleDeleteMode()}
                               onToggleArchiveMode={() => this.toggleArchiveMode()}
                               onEdit={(file) => this.openFormEditDoc(file)}
                               onDelete={(file) => this.deleteOrArchiveDoc(file)}
                               onArchive={(file) => this.deleteOrArchiveDoc(file)}
                               onFilter={(payload) => this.filter(payload)}
                               type={'workgroup'}
                               id={parseInt(this.props.match.params.id)}
                               filters={this.state.filters}
                               refreshed={this.state.refreshed}
                               requiredRoleForManagement={(userAuthenticated, userAuthenticatedRole) => {
                                   if (userAuthenticatedRole.uid === Roles.ROLE_CONTRIBUTOR.uid) {
                                       let isAllowed = false;
                                       // The authenticated user is contributor, so he must be defined as a contributor on the workgroup to be able to manage it
                                       for (let i = 0; i < this.state.workGroup.contributors.length; i++) {
                                           if (this.state.workGroup.contributors[i]['userid'] === userAuthenticated.id) {
                                               isAllowed = true;
                                               break; // Stop loop we found him
                                           }
                                       }

                                       return isAllowed;
                                   }
                                   // The authenticated user is admin, so he must be defined as a admin of the thematic of the workgroup to be able to manage it
                                   else if (userAuthenticatedRole.uid === Roles.ROLE_ADMINISTRATOR.uid && Auth.isThematicAdministrator(this.state.workGroup.thematic.id)) {

                                       return true;
                                   }

                                   // In other case he has to be root
                                   return userAuthenticatedRole.rank >= Roles.ROLE_ROOT.rank;
                               }}
                />

                {/* Modal add doc */}
                {this.state.showModalAddDoc && <Modal onClose={() => this.setState({showModalAddDoc: false})}>
                    <FormAddDocWorkGroup currentWorkGroup={this.state.workGroup}
                                         document={this.state.editMode && this.state.tempFile ? this.state.tempFile : null}
                                         onCancel={() => this.setState({showModalAddDoc: false, tempFile: null})}
                                         onValid={(document, isNewDoc) => this.validDocument(document, isNewDoc)}
                                         onArchive={(file) => this.confirmDeleteDoc(file, true)}
                                         onDelete={(file) => this.confirmDeleteDoc(file, false)}
                                         loading={this.state.loadingForm}
                    />
                </Modal>}
            </>;
        }

        const USER = Auth.getUser();

        const USER_IS_SUB =  this.state.workGroup.subscriptions.length > 0 && this.state.workGroup.subscriptions.find((sub) => {
            return this.findCurrentUser(sub)
        });
        
        return (
            <Master id={"workGroups"} breadCrumb={this.state.breadCrumb} {...this.props}
                    httpError={this.state.httpError} permissionsError={this.state.permissionsError}>

                {/* Carousel header*/}
                <div className={`work-group-carousel${this.state.showMemberList === true ? ' memberActive' : ''}`}>
                    {this.state.loadingWorkGroup
                        ? <Loader type={"inline"} backgroundColor={'transparent'}/>
                        :
                        <div data-id={this.state.workGroup.id} data-name={this.state.workGroup.name}
                             id={'work-group-' + this.state.workGroup.id} className={"container item"}>
                            <div className={'topItem'}>
                                <div className={'categories'}>
                                    <span className="group-name">{this.state.workGroup.name}</span>
                                    <span className={"thematicName"}
                                          style={{color: this.state.workGroup.thematic.color}}>{this.state.workGroup.thematic.name}</span>
                                </div>
                            </div>

                            {/* Animateurs */}
                            <p><strong>Animateur{this.state.workGroup.animators.length > 1 ? 's' : ''}</strong> : {this.state.workGroup.formattedAnimators}</p>

                            {/* Description */}
                            <p dangerouslySetInnerHTML={{__html: this.state.workGroup.description}}/>

                            {/* Button see member */}
                            {(this.userCanManage(USER) || this.state.workGroup.showuser) &&
                            <div className={'members-btn'}>
                                <button className={`btnMembers`}
                                        type={'button'}
                                        onClick={this.toggleMembersPanel}>
                                        <span>Voir les <em
                                            className={'numbersOfMembers'}> {this.state.workGroup.users.length > 0 ? this.state.workGroup.users.length : ''}</em> membres</span>
                                    <i>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="61" height="61"
                                             viewBox="0 0 61 61">
                                            <g id="Groupe_457" data-name="Groupe 457" transform="translate(-509 -441)">
                                                <g id="Ellipse_14" data-name="Ellipse 14" transform="translate(509 441)"
                                                   fill="#fff" stroke="#707070" strokeWidth="1">
                                                    <circle cx="30.5" cy="30.5" r="30.5" stroke="none"/>
                                                    <circle cx="30.5" cy="30.5" r="30" fill="none"/>
                                                </g>
                                                <g id="Groupe_135" data-name="Groupe 135"
                                                   transform="translate(-501.652 309.88)">
                                                    <g id="Groupe_134" data-name="Groupe 134"
                                                       transform="translate(1025.425 140.12)">
                                                        <circle id="Ellipse_15" data-name="Ellipse 15" cx="2.936"
                                                                cy="2.936" r="2.936"
                                                                transform="translate(0.202 10.927) rotate(-45)"
                                                                fill="#0449a7"/>
                                                        <circle id="Ellipse_16" data-name="Ellipse 16" cx="2.936"
                                                                cy="2.936" r="2.936"
                                                                transform="translate(23.588 9.973) rotate(-31.717)"
                                                                fill="#0449a7"/>
                                                        <circle id="Ellipse_17" data-name="Ellipse 17" cx="3.978"
                                                                cy="3.978" r="3.978"
                                                                transform="translate(11.096 7.667) rotate(-74.518)"
                                                                fill="#0449a7"/>
                                                        <g id="Groupe_133" data-name="Groupe 133"
                                                           transform="translate(0 15.273)">
                                                            <path id="Trace_156" data-name="Trace 156"
                                                                  d="M1032.81,275.093l-.47-.485-.037-.675-.432-7.861h-3.478c-2.689,0-3.042,1.74-2.956,3.816l.321,5.829,1.709,1.761.985,10.612a.993.993,0,0,0,.9.961h3.6a.993.993,0,0,0,.9-.961l.931-10.034-.1-1.034Z"
                                                                  transform="translate(-1025.425 -266.072)"
                                                                  fill="#0449a7"/>
                                                            <path id="Trace_157" data-name="Trace_157"
                                                                  d="M1218.37,266.072h-3.48l-.432,7.861-.037.675-.471.485-1.871,1.929-.094,1.018.932,10.05a.993.993,0,0,0,.9.961h3.6a.993.993,0,0,0,.9-.961l.985-10.612,1.709-1.761.321-5.829C1221.412,267.812,1221.33,266.072,1218.37,266.072Z"
                                                                  transform="translate(-1189.362 -266.072)"
                                                                  fill="#0449a7"/>
                                                        </g>
                                                    </g>
                                                    <path id="Trace_158" data-name="Trace 158"
                                                          d="M1104.807,222.332h-7.467c-3.643,0-4.121,2.358-4,5.169l.434,7.9,2.315,2.386,1.334,14.377a1.346,1.346,0,0,0,1.219,1.3h4.873a1.345,1.345,0,0,0,1.219-1.3l1.334-14.377,2.315-2.386.435-7.9C1108.928,224.69,1108.817,222.332,1104.807,222.332Z"
                                                          transform="translate(-59.662 -72.243)" fill="#0449a7"/>
                                                </g>
                                            </g>
                                        </svg>
                                    </i>
                                </button>
                                {this.userCanManage(USER) &&
                                <button className={'btnMembers'}
                                        type={'button'}
                                        onClick={() => this.toggleMembers()}>
                                    {this.state.loadingToggleMembers ?
                                        <Loader type={'button'} backgroundColor={'transparent'}/> :
                                        <span>
                                                        {this.state.workGroup.showuser ? 'cacher l\'annuaire' : 'afficher l\'annuaire'}
                                                    </span>
                                    }
                                </button>
                                }
                            </div>
                            }
                            <div className="cta">
                                <Button className={'btnAlert'}
                                        style={{borderColor: this.state.workGroup.thematic.color}}
                                        onClick={() => USER_IS_SUB ?
                                            this.removeAlert() :
                                            this.addAlert()}>
                                    {USER_IS_SUB ?
                                        'Désactiver les alertes' :
                                        'Recevoir des alertes'}
                                </Button>
                            </div>
                        </div>}
                </div>

                <Alert show={this.state.alert.show}
                       type={"document"}
                       action={this.state.alert.action}
                       value={this.state.alert.value}
                       onFinish={() => this.state.alert.show && this.setState({
                           alert: {
                               ...this.state.alert,
                               show: false
                           }
                       })}
                />

                {/* Content page, member list or document/events */}
                {pageContent}

                {/* Modal confirm remove user from work group */}
                {this.state.showModalConfirm &&
                <ModalConfirm
                    loading={this.state.loadingForm}
                    confirmText={`Etes-vous certain de vouloir retirer le membre « ${this.state.tempUser.users.user.profile.firstname} ${this.state.tempUser.users.user.profile.lastname} » du groupe de travail « ${this.state.workGroup.name} » ?`}
                    onConfirm={this.confirmRemoveUserFromWorkGroup}
                    onCancel={this.cancelRemoveUserFromWorkGroup}
                    onClose={this.cancelRemoveUserFromWorkGroup}/>}

                {/* Modal confirm delete document */}
                {this.state.showModalConfirmDeleteDoc &&
                <ModalConfirm
                    loading={this.state.loadingForm}
                    confirmText={`Etes-vous certain de vouloir ${this.state.deleteMode ? 'supprimer' : (this.state.tempFile.archivedat == null) ? 'archiver' : 'désarchiver'} le document «${this.state.tempFile.title}»`}
                    onCancel={() => this.setState({showModalConfirmDeleteDoc: false})}
                    onClose={() => this.setState({showModalConfirmDeleteDoc: false})}
                    onConfirm={() => this.state.deleteMode
                        ? this.confirmDeleteDoc(this.state.tempFile, false)
                        : this.confirmDeleteDoc(this.state.tempFile, true)}
                />}

                {/* Modal add member */}
                {this.state.showModalAddMember &&
                <Modal title={`Ajouter un membre au groupe de travail « ${this.state.workGroup.name} »`}
                       saveLabel={'Valider'}
                       onValidate={() => this.addMemberFromWorkGroup(this.state.tempUser)}
                       onCancel={() => this.setState({showModalAddMember: false, tempUser: undefined, tempError: ''})}
                       onClose={() => this.setState({showModalAddMember: false, tempUser: undefined, tempError: ''})}
                       loading={this.state.loadingForm}>
                    <SearchUsers
                        workGroup={this.state.workGroup}
                        error={this.state.tempError}
                        onChange={(users) => this.setState({tempUser: users})}/>
                </Modal>}
            </Master>
        )
    }
}
