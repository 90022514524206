import React from "react";
import ApiSearch from "../../Services/ApiSearch";
import ApiTag from "../../Services/ApiTag";
import Master from "../../Components/Layout/Master";
import CarouselNews from "../../Components/Layout/CarouselNews";
import Search from "../../Components/Search/Search";
import Button from "../../Components/Form/Button/Button";
import Document from "../../Components/Layout/Document";
import Banner from "../../Components/Banner/Banner";
import CheckBoxTag from "../../Components/Form/CheckBoxTag/CheckBoxTag";
import CarouselLastEvent from "../../Components/Carousel/CarouselLastEvent";
import CarouselWorkGroups from "../../Components/Carousel/CarouselWorkGroups";
import Loader from "../../Components/Loader/Loader";
import DateRangePicker from "../../Components/DateRangePicker/DateRangePicker";
import { DateHandler } from "../../Utils/DateHandler";
import { getAllType, getMimeType } from "../../Utils/DocType";
import Select from "react-select";
import { Http, QueryBuilder } from "../../Utils/Api";
import "./Dashboard.scss";
import moment from "moment";

export default class Dashboard extends React.Component {
  state = {
    nbResults: 0,
    results: [],
    showResultSearch: false,
    documentType: "",
    workgroups: [],
    news: [],
    advancedSearch: false,
    panelRangeDate: false,
    loadingSearch: false,
    tags: [],
    formSearch: {
      searchText: "",
      errorSearch: null,
      searchType: "",
      searchTag: null,
      advancedSearch1: "z",
      advancedSearch2: "and",
      advancedSearch3: "Dans tout le document",
    },
    httpError: false,
    pagination: null,
    startDate: "",
    endDate: "",
  };

  constructor(props) {
    super(props);
    this._isMounted = false;
    this.button = React.createRef();
    this.typeDocument = [
      {
        value: "",
        label: "Type de document",
      },
    ];

    this._QB = new QueryBuilder()
      .setLimit(6)
      .setOrder("desc")
      .setSort("_score");

    this._dateRangePickerRef = React.createRef();
    this._dateRange = React.createRef();

    getAllType().forEach(type => {
      this.typeDocument.push({
        value: type,
        label: type.toUpperCase(),
      });
    });
  }

  componentDidMount() {
    this._isMounted = true;
    if (this.props.history.location.search) {
      this.searchFromParams(this.props.history.location.search);
    }
    // Listener param change
    this.props.history.listen(location => {
      this.searchFromParams(location.search);
    });

    ApiTag.mostUse().then(tags => {
      if (!this._isMounted) return;

      let rTags = [];

      tags.forEach(tag => {
        rTags.push(tag.name);
      });

      this.setState({ tags: rTags });
    });
  }

  componentWillUnmount() {
    this._isMounted = false;
    Http.cancel();
  }

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    return !this.state.httpError;
  }

  searchFromParams(searchParam) {
    const SEARCH_PARAM = searchParam || this.props.location.search;

    if (SEARCH_PARAM.indexOf("recherche") !== -1) {
      const WORD_SEARCH = SEARCH_PARAM.split("=")[1];

      Http.cancel();

      this.setState(
        state => {
          return {
            formSearch: {
              ...state.formSearch,
              searchText: WORD_SEARCH,
            },
          };
        },
        () => {
          this.submitSearch();
        }
      );
    }
  }

  handleSearch(e) {
    const target = e.target;
    const name = target.name;
    const value = target.value;
    let errorSearch = null;

    if (name === "searchText") {
      errorSearch =
        value.length >= 3 ? null : this.state.formSearch.errorSearch;
    }

    this.setState(state => {
      return {
        showResultSearch: false,
        formSearch: {
          ...state.formSearch,
          [name]: value,
          errorSearch: errorSearch,
        },
      };
    });
  }

  searchFilter(filter) {
    this._QB.setOrder(filter.increasing ? "asc" : "desc");

    if (filter.filter === "date") {
      this._QB.setSort("date");
    } else if (filter.filter === "name") {
      this._QB.setSort("title");
    } else if (filter.filter === "views") {
      this._QB.setSort("views");
    }

    if (filter.minDate && filter.maxDate) {
      this._QB.setDateFrom(filter.minDate.replace(/ .*/, ""));
      this._QB.setDateTo(filter.maxDate.replace(/ .*/, ""));
    } else {
      this._QB.setDateFrom(null);
      this._QB.setDateTo(null);
    }

    this.submitSearch();
  }

  handleChangeTag(e) {
    const target = e.target;
    const value = target.value;

    if (target.checked) {
      this.setState(
        {
          formSearch: {
            ...this.state.formSearch,
            searchTag: value,
          },
        },
        () => {
          this.submitSearch(null, 1, value);
        }
      );
    }
  }

  handleClickTag = radio => {
    if (this.state.formSearch.searchTag === radio.value) {
      this.setState({
        formSearch: {
          ...this.state.formSearch,
          searchTag: null,
          searchText: "",
        },
        showResultSearch: false,
      });
    } else {
      this.setState(
        {
          formSearch: {
            ...this.state.formSearch,
            searchTag: radio.value,
          },
        },
        () => {
          this.submitSearch(null, 1, radio.value);
        }
      );
    }
  };

  toggleAdvancedSearch() {
    if (this.state.advancedSearch) {
      this.refs.advancedSearchButton.textContent = "Recherche avancée";
    } else {
      this.refs.advancedSearchButton.textContent = "Recherche rapide";
    }

    this.setState(state => {
      return { advancedSearch: !state.advancedSearch };
    });
  }

  submitSearch(e, page = 1, search = null) {
    e && e.preventDefault();
    this.closePanelRangeDate();
    if (this.state.loadingSearch) return;

    const SEARCH = search || this.state.formSearch.searchText;

    if (SEARCH.length < 3) {
      this.setState({
        formSearch: {
          ...this.state.formSearch,
          errorSearch: "La recherche doit comprendre au moins trois caractères",
        },
      });
      return;
    }

    this.setState({ loadingSearch: true });

    if (SEARCH.length > 0) {
      const DOC_TYPE = this.state.formSearch.searchType;
      const TAGS = this.state.formSearch.searchTag;
      this._QB
        .setMethod(this.state.formSearch.advancedSearch1)
        .setInTitle(
          this.state.formSearch.advancedSearch3 === "dans-le-titre-uniquement"
        )
        .setOperator(this.state.formSearch.advancedSearch2)
        .setPage(page);

      if (DOC_TYPE !== "") {
        let mimeTypes = getMimeType(DOC_TYPE.toLowerCase());
        let type = [mimeTypes[0]];
        for (let i = 1; i < mimeTypes.length; i++) {
          type.push(mimeTypes[i]);
        }
        this._QB.setType(type);
      } else {
        this._QB.setType([]);
      }

      // if (TAGS.length > 0) {
      //   this._QB.setTags(TAGS);
      // }

      if (this.state.startDate && this.state.endDate) {
        this._QB.setDateFrom(
          DateHandler.getPrettyDate(this.state.startDate, "YYYY-MM-DD")
        );
        this._QB.setDateTo(
          DateHandler.getPrettyDate(this.state.endDate, "YYYY-MM-DD")
        );
      }

      ApiSearch.search(SEARCH, this._QB)
        .then(search => {
          if (!this._isMounted) return;

          const RESULTS = search.payload ? search.payload.results : {};
          const NB_RESULTS = search.extra ? search.extra.resources.total : 0;
          const PAGINATION = search.extra
            ? search.extra
            : {
                page: {
                  current: 1,
                  total: 1,
                },
                resources: {
                  count: 0,
                  total: 0,
                },
              };

          const START_RESULT =
            (PAGINATION.page.current - 1) * this._QB.getLimit();

          this.setState({
            results:
              RESULTS === null
                ? []
                : Object.values(RESULTS).splice(
                    START_RESULT,
                    this._QB.getLimit()
                  ),
            pagination: PAGINATION,
            nbResults: NB_RESULTS,
            formSearch: {
              ...this.state.formSearch,
              searchText: SEARCH,
            },
            showResultSearch: true,
            loadingSearch: false,
          });
        })
        .catch(e => {
          if (!this._isMounted) return;
          this.setState({
            loadingSearch: false,
            formSearch: {
              ...this.state.formSearch,
              errorSearch: "Une erreur est survenue veuillez réessayer",
            },
          });
        });
    }
  }

  searchPagination(page) {
    this.submitSearch(null, page);
  }

  childIsLoad(success) {
    this.setState({
      httpError: !success,
    });
  }

  _dateChange(e) {
    if (e.startDate && e.endDate) {
      this.setState(
        {
          startDate: e.startDate,
          endDate: e.endDate,
        },
        this.closePanelRangeDate()
      );
    }
  }

  closePanelRangeDate = () => {
    this.setState({ panelRangeDate: false });
  };

  render() {
    let tags = [];

    for (let i = 0; i < this.state.tags.length; i++) {
      const tag = this.state.tags[i];
      tags.push(
        <div className="groupTag" key={i}>
          <CheckBoxTag
            type={"radio"}
            name={"tag"}
            id={`tagSearch${i + 1}`}
            value={tag}
            checked={this.state.formSearch.searchTag === tag}
            // onChange={e => this.handleChangeTag(e)}
            onClick={this.handleClickTag.bind(this)}
          />
        </div>
      );
    }

    return (
      <Master id="dashboard" httpError={this.state.httpError} {...this.props}>
        <Banner height={650} imgSrc={"/assets/images/background/home.jpg"}>
          <form id={"searchForm"} onSubmit={e => this.submitSearch(e)}>
            {/* Search */}
            <div className="inlineSearch">
              <Select
                name={"searchType"}
                placeholder="Type de document"
                classNamePrefix="react-select"
                className="react-select__container"
                isSearchable={false}
                options={this.typeDocument}
                onChange={e =>
                  this.setState(state => {
                    return {
                      formSearch: { ...state.formSearch, searchType: e.value },
                    };
                  })
                }
              />
              <div className={"inputLineGroup"}>
                <input
                  type="text"
                  name="searchText"
                  value={this.state.formSearch.searchText}
                  onChange={e => this.handleSearch(e)}
                  placeholder="Que recherchez-vous ?"
                  autoComplete={"off"}
                />
                <label className={"line"} />
              </div>
              <Button type="submit" disabled={this.state.loadingSearch}>
                {this.state.loadingSearch ? (
                  <Loader type={"inline"} backgroundColor={"transparent"} />
                ) : (
                  <span className="fas fa-search" />
                )}
              </Button>
            </div>

            {/* Advanced search */}
            <div
              className={`advancedSearch${
                this.state.advancedSearch ? " active" : ""
              }`}
            >
              {this.state.advancedSearch && (
                <div className="row align-items-center">
                  <div className="col col-md-auto col-md">Recherche sur :</div>
                  <div className="col-md select">
                    <Select
                      name={"advancedSearch3"}
                      placeholder="Dans tout le document"
                      classNamePrefix="react-select"
                      className="react-select__container"
                      onMenuOpen={() => this.closePanelRangeDate()}
                      isSearchable={false}
                      options={[
                        {
                          value: "dans-le-titre-uniquement",
                          label: "Dans le titre uniquement",
                        },
                        {
                          value: "dans-tout-le-document",
                          label: "Dans tout le document",
                        },
                      ]}
                      onChange={e =>
                        this.setState(state => {
                          return {
                            formSearch: {
                              ...state.formSearch,
                              advancedSearch3: e.value,
                            },
                          };
                        })
                      }
                    />
                  </div>
                  <div className="col-md select">
                    <div className={"rangeDate"}>
                      <button
                        type={"button"}
                        ref={this._dateRange}
                        onClick={() =>
                          this.setState(state => ({
                            panelRangeDate: !state.panelRangeDate,
                          }))
                        }
                      >
                        <span class="toto">
                          {this.state.startDate && this.state.endDate
                            ? `${moment(this.state.startDate).format(
                                "DD/MM/YYYY"
                              )} au ${moment(this.state.endDate).format(
                                "DD/MM/YYYY"
                              )}`
                            : "Période"}
                        </span>
                        <svg
                          height="20"
                          width="20"
                          viewBox="0 0 20 20"
                          aria-hidden="true"
                          focusable="false"
                          className="css-19bqh2r"
                        >
                          <path
                            d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"
                            fill={"#fff"}
                          />
                        </svg>
                      </button>
                      {this.state.panelRangeDate && (
                        <div className={"panelRangeDate"}>
                          <ul className={"rangeFilter range"}>
                            <li>
                              <DateRangePicker
                                ref={this._dateRangePickerRef}
                                startDate={this.state.startDate}
                                endDate={this.state.endDate}
                                onChange={e => this._dateChange(e)}
                              />
                            </li>
                            <li className={"resetPeriodicityFilter"}>
                              <button
                                type="button"
                                onClick={() =>
                                  this.setState(
                                    {
                                      startDate: null,
                                      endDate: null,
                                    },
                                    () => {
                                      this._QB.setDateFrom(null);
                                      this._QB.setDateTo(null);
                                      this._dateRangePickerRef.current.setState(
                                        { startDate: null, endDate: null }
                                      );
                                      this.closePanelRangeDate();
                                    }
                                  )
                                }
                              >
                                Retirer le filtre
                              </button>
                            </li>
                          </ul>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-md select">
                    <Select
                      name={"advancedSearch2"}
                      placeholder="Tous les mots"
                      classNamePrefix="react-select"
                      className="react-select__container"
                      onMenuOpen={() => this.closePanelRangeDate()}
                      isSearchable={false}
                      options={[
                        {
                          value: "and",
                          label: "Tous les mots",
                        },
                        {
                          value: "or",
                          label: "N’importe quel mot",
                        },
                      ]}
                      onChange={e =>
                        this.setState(state => {
                          return {
                            formSearch: {
                              ...state.formSearch,
                              advancedSearch2: e.value,
                            },
                          };
                        })
                      }
                    />
                  </div>
                  <div className="col-md select">
                    <Select
                      name={"advancedSearch1"}
                      placeholder="Meilleur résultat"
                      classNamePrefix="react-select"
                      className="react-select__container"
                      onMenuOpen={() => this.closePanelRangeDate()}
                      isSearchable={false}
                      options={[
                        {
                          value: "z",
                          label: "Meilleur résultat",
                        },
                        {
                          value: "d",
                          label: "Mot distinct",
                        },
                        {
                          value: "p",
                          label: "Portion d’un mot",
                        },
                        {
                          value: "s",
                          label: "Première partie d’un mot",
                        },
                        {
                          value: "f",
                          label: "Dernière partie d’un mot",
                        },
                        {
                          value: "c",
                          label: "Prononciation semblable",
                        },
                        {
                          value: "e",
                          label: "Expression exacte",
                        },
                      ]}
                      onChange={e =>
                        this.setState(state => {
                          return {
                            formSearch: {
                              ...state.formSearch,
                              advancedSearch1: e.value,
                            },
                          };
                        })
                      }
                    />
                  </div>
                </div>
              )}
            </div>

            {/*  Tags */}
            <div className="tags">{tags.map(tag => tag)}</div>

            <p className={"centerText"}>
              <button
                ref="advancedSearchButton"
                type={"button"}
                className={"underline"}
                onClick={() => this.toggleAdvancedSearch()}
              >
                Recherche avancée
              </button>
            </p>
          </form>
          {this.state.formSearch.errorSearch && (
            <span className={"error"}>{this.state.formSearch.errorSearch}</span>
          )}
        </Banner>

        {/* Search result */}
        {this.state.showResultSearch && (
          <Search.Result
            results={this.state.results}
            color="green"
            nbResults={this.state.nbResults}
            showTypeFilter={false}
            pagination={this.state.pagination}
            onPaginationClick={page => this.searchPagination(page)}
            category={{ hasspecificfilters: false }}
            onFilter={(by, e) => this.searchFilter(by, e)}
          />
        )}

        {/* Carousel work groups */}
        <CarouselWorkGroups onLoaded={success => this.childIsLoad(success)} />

        {/* News & document */}
        <div className="contentFlex">
          <div className={"bigSide"}>
            <Document onLoaded={success => this.childIsLoad(success)} />
          </div>
          <div className={"smallSide"}>
            <CarouselLastEvent
              onLoaded={success => this.childIsLoad(success)}
            />
            <CarouselNews onLoaded={success => this.childIsLoad(success)} />
          </div>
        </div>
      </Master>
    );
  }
}
