import React from "react";
import PropTypes from "prop-types";
import ListTags from "../../List/ListTags/ListTags";
import Button from "../Button/Button";
import Loader from "../../Loader/Loader";
import Select from "react-select";
import {MEMBER_TYPE_ADMINISTRATOR, MEMBER_TYPE_ANIMATOR, MEMBER_TYPE_MEMBER} from "../../../Services/ApiWorkGroupUser";
import "./SelectMultiple.scss"
import ListRoleLegend from "../../List/ListRoleLegend/ListRoleLegend";
import ListThematicLegend from "../../List/ListThematicLegend/ListThematicLegend";


export default class SelectMultiple extends React.Component {

    static propTypes = {
        title: PropTypes.string,
        selected: PropTypes.array,
        onChange: PropTypes.func,
        onRemove: PropTypes.func,
        onAdd: PropTypes.func,
        multiple: PropTypes.bool,
        errorMsg: PropTypes.string,
        thematic: PropTypes.number,
        selectAllButton: PropTypes.bool,
        height: PropTypes.number,
        validateButton: PropTypes.bool,
        // When it's not a multiple select if whenNotMultipleUnselect is set to true, then when you have a selected category
        // and you click on an other one, this will unselect the current selected one, and select the one clicked
        whenNotMultipleUnselect: PropTypes.bool,
        selectWithChildItem: PropTypes.bool,
        onSave: PropTypes.func,
        loadingSave: PropTypes.bool,
        listRoleLegend: PropTypes.bool,
        listThematicLegend: PropTypes.bool
    };

    static defaultProps = {
        thematic: null,
        selectAllButton: false,
        validateButton: false,
        height: 0,
        whenNotMultipleUnselect: false,
        selectWithChildItem: false,
        onChange: () => {
        },
        onRemove: () => {
        },
        onAdd: () => {
        },
        onSave: undefined
    };

    state = {
        titles: [this.props.title],
        itemSelected: [],
        itemPreSelected: [],
        multiple: true,
        allSelected: false,
        role: 0
    };

    constructor(props) {
        super(props);
        this.selectInputs = React.createRef();
        this.tree         = React.createRef();
    }

    componentDidMount() {
        this.setState({itemSelected: this.props.selected});
        this.isSelected();
    }

    addItem(item, el, itemsSelected) {
        let selected = this.props.validateButton ? 'itemPreSelected' : 'itemSelected';

        if (!this.props.multiple && this.state[selected].length >= 1 && !this.props.whenNotMultipleUnselect) return;

        if (this.props.whenNotMultipleUnselect && !this.props.multiple) {
            if (this.state[selected].length > 0) {
                this.removeItemSelected(this.state[selected][0]);
            }
            itemsSelected = [];
        } else {
            itemsSelected = this.state[selected];
        }

        itemsSelected.push(item);
        // Add item
        el.classList.add('select');

        // Trigger onAdd to
        if (!this.props.validateButton) {
            this.props.onAdd(item);
        }

        let update       = {};
        update[selected] = itemsSelected;
        this.setState(update, () => this.isAllSelected());

        this.props.onChange(itemsSelected)
    }

    onChange(item, target, isCategory) {
        let selected      = this.props.validateButton ? 'itemPreSelected' : 'itemSelected';
        let itemsSelected = [...this.state[selected], item];

        // Open sub level
        if (target.classList.contains('withChild')) {
            const GRAND_PARENT = target.parentNode.parentNode;
            GRAND_PARENT.classList.remove('active');
            GRAND_PARENT.classList.add('ghost');
            target.nextElementSibling.classList.add('active');
            this.selectInputs.current.querySelector('.selectInput .header button').classList.add('prev');

            let titles = this.state.titles;
            titles.push(target.innerText);
            this.setState({
                titles: titles
            });

            if (this.props.selectWithChildItem) {
                this.addItem(item, target, itemsSelected);
            }
        }
        // Select/Unselect item
        else if (isCategory) {
            if (target.classList.contains('select')) {
                target.classList.remove('select');
                this.removeItemSelected(item);
            } else {
                this.addItem(item, target, itemsSelected);
            }
        }

        this.stateCheckedAllChildren();
    }

    onPrev() {
        const ACTIVE_LIST = this.selectInputs.current.querySelector('.selectInput ul.active');

        // Remove prev
        if (ACTIVE_LIST.classList.contains('level-2')) {
            this.selectInputs.current.querySelector('.selectInput .header button').classList.remove('prev')
        }

        if (ACTIVE_LIST.classList.contains('level-1')) return;

        // Add prev
        const GRAND_PARENT = ACTIVE_LIST.parentNode.parentNode;

        GRAND_PARENT.classList.add('active');
        GRAND_PARENT.classList.remove('ghost');
        ACTIVE_LIST.classList.remove('active');

        let titles = this.state.titles;
        titles.pop();

        this.setState({titles: titles}, () => this.isAllSelected());
    }

    removeItemSelected(item, isPreSelected = this.props.validateButton) {
        let selected        = isPreSelected ? 'itemPreSelected' : 'itemSelected';
        let newItemSelected = this.state[selected];
        let itemId;
        let removeItem      = null;

        for (let property in newItemSelected) {
            if (newItemSelected.hasOwnProperty(property) && ((item.id && newItemSelected[property].id === item.id) || newItemSelected[property].id === item)) {
                itemId     = newItemSelected[property].uid + '-' + newItemSelected[property].id;
                removeItem = newItemSelected[property];
                newItemSelected.splice(property, 1);
            }
        }

        this.setState({allSelected: false});

        let update       = {};
        update[selected] = newItemSelected;
        this.setState(update, () => {
            this.props.onChange(this.state.itemSelected);
            this.props.onRemove(removeItem);
        });

        // Remove class select
        const EL = document.getElementById(itemId);
        EL && EL.classList.remove('select');
    }

    isSelected() {
        const NUMBER_SELECT = this.props.selected.length;

        // If selected options
        if (this.props.selected && NUMBER_SELECT) {
            this.setState({itemSelected: this.props.selected});

            // Set class select to options list
            for (let property in this.props.selected) {
                if (this.props.selected.hasOwnProperty(property)) {
                    const ID = this.props.selected[property].uid + '-' + this.props.selected[property].id;
                    const EL = document.getElementById(ID);

                    EL && !EL.classList.contains('withChild') && EL.classList.add('select');
                }
            }
        }

        // State button all select
        const QUERY                 = this.tree.current.classList.contains('active') ? 'button.select' : '.active button.select';
        const NUMBER_BUTTONS_SELECT = this.tree.current.querySelectorAll(QUERY).length;
        if (NUMBER_SELECT > 0 && NUMBER_SELECT === NUMBER_BUTTONS_SELECT) {
            this.setState({allSelected: true});
        }

        this.stateCheckedAllChildren();
    }

    stateCheckedAllChildren() {
        const CHECK = document.querySelectorAll('.checkAllChild');

        if (CHECK.length > 0) {
            for (let i = 0; i < CHECK.length; i++) {
                const LIST = Array.prototype.filter.call(CHECK[i].parentNode.children, function (child) {
                    return child.tagName === 'UL';
                })[0];

                const BUTTON            = LIST.querySelectorAll('button');
                const BUTTON_SELECT     = LIST.querySelectorAll('button.select');
                const BUTTON_WITH_CHILD = LIST.querySelectorAll('button.withChild');

                CHECK[i].checked = BUTTON.length - BUTTON_WITH_CHILD.length === BUTTON_SELECT.length;
            }
        }

        this.isAllSelected();
    }

    selectAll() {
        let query = '';

        if (this.state.allSelected) {
            query = this.tree.current.classList.contains('active') ? 'button.select' : '.active button.select';
        } else {
            query = this.tree.current.classList.contains('active') ?
                'button:not(.withChild):not(.select)' :
                '.active button:not(.withChild):not(.select)';
        }

        let items = this.tree.current.querySelectorAll(query);

        this.setState({allSelected: true});

        items.forEach((item) => item.click());
    }

    isAllSelected() {
        const QUERY_ACTIVE = this.tree.current.classList.contains('active') ? 'button.select:not(.withChild)' : '.active button.select:not(.withChild)';
        const QUERY_ALL    = this.tree.current.classList.contains('active') ? 'button:not(.withChild)' : '.active button:not(.withChild)';

        this.setState({
            allSelected: this.tree.current.querySelectorAll(QUERY_ALL).length === this.tree.current.querySelectorAll(QUERY_ACTIVE).length
        });
    }

    validate() {
        if (this.state.role === 0) return;

        let preSelected  = this.state.itemPreSelected;
        let itemSelected = this.state.itemSelected;

        preSelected.forEach((item) => {
            const FOUND = itemSelected.find((selected) => {
                return selected.id === item.id;
            });
            if (FOUND === undefined) {
                item.membertype = this.state.role;
                itemSelected.push(item);
                this.props.onAdd(item)
            }
        });

        this.setState({allSelected: false});

        this.setState({
            itemSelected: itemSelected,
            itemPreSelected: []
        });

        this.props.onChange(this.state.itemPreSelected);
    }

    render() {
        return (
            <div className={`selectMultiple${this.props.listRoleLegend || this.props.listThematicLegend ? ' withLegend' : ''}`} ref={this.selectInputs}>
                {/* Select */}
                <div className={`selectInput${this.props.onSave ? ' withSave' : ''}`}>
                    <div className={`header${this.props.selectAllButton ? ' withsAllButton' : ''}`}>
                        <button type={'button'} className={'title'} onMouseDown={() => this.onPrev()}>
                            <span>{this.state.titles[this.state.titles.length - 1]}</span>
                        </button>
                        {this.props.selectAllButton && (
                            <button type={'button'} className={'all-select-button'}
                                    onClick={() => this.selectAll()}>
                                <span>{this.state.allSelected ? 'Tout désélectionner' : 'Tout sélectionner'}</span>
                            </button>
                        )}
                    </div>
                    <div className="core" style={{height: this.props.height}}>
                        <ul className={'level-1 active'} ref={this.tree}>
                            {this.props.children}
                        </ul>
                    </div>
                    {this.props.validateButton &&
                    <div className={"validate-container"}>
                        <Select
                            name={'advancedSearch1'}
                            placeholder='Rôle'
                            classNamePrefix="react-select"
                            className="react-select__container"
                            isSearchable={false}
                            options={[
                                {
                                    value: 0,
                                    label: 'Rôle'
                                },
                                {
                                    value: MEMBER_TYPE_ADMINISTRATOR,
                                    label: 'Gestionnaire'
                                },
                                {
                                    value: MEMBER_TYPE_ANIMATOR,
                                    label: 'Animateur'
                                },
                                {
                                    value: MEMBER_TYPE_MEMBER,
                                    label: 'Membre'
                                }
                            ]}
                            onChange={(e) => this.setState({role: e.value})}
                        />

                        <button type={'button'} className={'add'} onClick={() => this.validate()}>
                            <svg id="Calque_1" data-name="Calque 1" xmlns="http://www.w3.org/2000/svg" width="64"
                                 height="64" viewBox="0 0 64 64">
                                <defs>
                                    <clipPath id="clip-path">
                                        <circle cx="32" cy="32" r="32" fill="none"/>
                                    </clipPath>
                                </defs>
                                <title>Ajouter</title>
                                <g id="Groupe_409" data-name="Groupe 409">
                                    <g id="Groupe_222" data-name="Groupe 222">
                                        <g id="Ellipse_39" data-name="Ellipse 39">
                                            <circle cx="32" cy="32" r="32" fill="#fff"/>
                                            <circle cx="32" cy="32" r="31" fill="none" stroke="#0449a7"
                                                    strokeWidth="2"/>
                                        </g>
                                        <g clipPath="url(#clip-path)">
                                            <g id="Groupe_de_masques_10" data-name="Groupe de masques 10">
                                                <g id="baseline-add-24px">
                                                    <path id="Tracé_216" data-name="Tracé 216"
                                                          d="M50.67,34.67h-16v16H29.33v-16h-16V29.33h16v-16h5.34v16h16Z"
                                                          fill="#0449a7"/>
                                                    <path id="Tracé_217" data-name="Tracé 217" d="M0,0H64V64H0Z"
                                                          fill="none"/>
                                                </g>
                                            </g>
                                        </g>
                                    </g>
                                    <g clipPath="url(#clip-path)">
                                        <g id="Groupe_de_masques_16" data-name="Groupe de masques 16">
                                            <g id="baseline-add-24px-2">
                                                <path id="Tracé_237" data-name="Tracé 237"
                                                      d="M50.67,34.67h-16v16H29.33v-16h-16V29.33h16v-16h5.34v16h16Z"
                                                      fill="#0449a7"/>
                                                <path id="Tracé_238" data-name="Tracé 238" d="M0,0H64V64H0Z"
                                                      fill="none"/>
                                            </g>
                                        </g>
                                    </g>
                                </g>
                            </svg>
                        </button>
                    </div>
                    }

                    {/* Save button */}
                    {this.props.onSave &&
                    <Button className={'btn save'} type={'button'} disabled={this.props.loadingSave} onClick={() => this.props.onSave(this.state.itemSelected)}>
                        {this.props.loadingSave ?
                            <Loader type={'button'} backgroundColor={'transparent'}/> : 'Enregistrer'}
                    </Button>}
                </div>

                {/* Error message */}
                {this.props.errorMsg && <span className={'errorMsg'}>{this.props.errorMsg}</span>}

                {/* Legend role */}
                {this.props.listRoleLegend && <ListRoleLegend/>}

                {/* Legend thematic */}
                {this.props.listThematicLegend && <ListThematicLegend/>}

                {/* Tags */}
                <ListTags tags={this.state.itemSelected} onRemove={(item) => this.removeItemSelected(item, false)}/>
            </div>
        );
    }
}
