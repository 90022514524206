export class Css {
    static getTranslate(element) {
        if (!window.getComputedStyle) return;

        let transArr = [];
        const style = getComputedStyle(element);
        const transform = style.transform || style.webkitTransform || style.mozTransform || style.msTransform;

        let mat = transform.match(/^matrix3d\((.+)\)$/);
        if (mat) return parseFloat(mat[1].split(', ')[13]);

        mat = transform.match(/^matrix\((.+)\)$/);
        mat ? transArr.push(parseFloat(mat[1].split(', ')[4])) : transArr.push(0);
        mat ? transArr.push(parseFloat(mat[1].split(', ')[5])) : transArr.push(0);

        return transArr;
    }
}