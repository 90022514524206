import React from 'react';
import './Permission.scss';

export default class Permission extends React.Component {
    componentDidMount() {
        setTimeout(() => {
            window.location.href = '/'
        }, 5000);
    }

    render() {
        return (
            <div className={'permission-error'}>
                <p>Vous n'êtes pas autorisé à accéder à cette page, vous allez redirigé dans quelques secondes.
                </p>
                <button type='button' onClick={() => window.location.href = '/'}>Revenir à la page d'accueil</button>
            </div>
        );
    }
}