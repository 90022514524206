import React from 'react';
import PropTypes from "prop-types"
import ApiMenu from "../../../Services/ApiMenu";
import ApiNotifications, {TYPE} from "../../../Services/ApiNotifications";
import Button from "../../Form/Button/Button";
import Auth from "../../../Security/Auth";
import BreadCrumb from "../../BreadCrumb/BreadCrumb";
import Loader from "../../Loader/Loader";
import {animateScroll} from 'react-scroll';
import {isSmallScreen} from "../../../Utils/Screen";
import {triggerEvent} from "../../../Utils/Event";
import {path} from "../../../Config/routes";
import {Link, NavLink} from "react-router-dom";
import "./Header.scss";
import {CT_COMMENT, CT_DOCUMENT, getNameNotification, getPathFromNotification} from "../../../Utils/Notification";


export default class Header extends React.Component {

    static propTypes = {
        breadCrumb: PropTypes.arrayOf(PropTypes.shape({
            url: PropTypes.string,
            label: PropTypes.string
        })),
        adminHeader: PropTypes.bool
    };

    static defaultProps = {
        adminHeader: false
    };

    state = {
        initial: Auth.getUser() && Auth.getUser().first_name.substr(0, 1) + Auth.getUser().last_name.substr(0, 1),
        notifications: [],
        notificationsNumber: null,
        redirect: '',
        showSearch: false,
        menu: JSON.parse(localStorage.getItem('menu')) || [],
        menuAdmin: JSON.parse(localStorage.getItem('menuAdmin')) || [],
        inputSearchHeaderValue: '',
    };

    constructor(props) {
        super(props);
        this._isMounted            = false;
        this._intervalNotification = null;
        this._showProfileMenu      = false;
        this.navRef                = React.createRef();
    }

    componentDidMount() {
        this._isMounted = true;

        // Get menu
        if (this.props.adminHeader) {
            ApiMenu.getMenuAdmin().then((menu) => {
                if (!this._isMounted) return;
                localStorage.setItem('menuAdmin', JSON.stringify(menu));
                this.setState({menuAdmin: menu})
            });
        } else {
            ApiMenu.getMenu().then((menu) => {
                if (!this._isMounted) return;
                localStorage.setItem('menu', JSON.stringify(menu));
                this.setState({menu: menu})
            });
        }

        if (this.props.location && path('notifications') !== this.props.location.pathname) {
            // Get notification
            this.getNotifications();
            this._intervalNotification = setInterval(() => {
                this.getNotifications();
            }, 1000 * 60 * 16);

            window.addEventListener('resize', this.calcWidthSearch)
        }

        window.addEventListener("category_tree.update.header_menu", this.refreshMenu);
    }

    componentWillUnmount() {
        this._isMounted = false;
        clearTimeout(this._intervalNotification);
        window.removeEventListener('resize', this.calcWidthSearch, false);
        window.removeEventListener('category_tree.update.header_menu', this.refreshMenu, false);
    }

    refreshMenu = () => {
        // Refresh le local storage
        ApiMenu.getMenu().then((menu) => {
            localStorage.setItem('menu', JSON.stringify(menu));
            if (this.props.adminHeader) {
                this.setState({menu: menu});
            }
            triggerEvent('category_tree.updated.local_storage');
        });
    }

    getNotifications() {
        ApiNotifications.get(Auth.getUser().id, -1, 'resource').then((payload) => {
            if (!this._isMounted) return;

            const NOTIF_FILTER = [];

            payload.forEach((notification) => {
                if ((notification.resourcetype === CT_DOCUMENT && notification.resource) ||
                    (notification.resourcetype === CT_COMMENT && notification.resource) ||
                    (notification.resourcetype !== CT_DOCUMENT && notification.resourcetype !== CT_COMMENT)) {
                    NOTIF_FILTER.push(notification);
                }
            });
            const NB_NOTIF = NOTIF_FILTER.length;
            if (NOTIF_FILTER.length > 3) {
                NOTIF_FILTER.splice(3, NB_NOTIF - 3);
            }
            this.setState({notifications: NOTIF_FILTER, notificationsNumber: NB_NOTIF});
        });

        // Get notification number
        /*   ApiNotifications.count(Auth.getUser().id).then((payload) => {
               if (!this._isMounted) return;
               this.setState({notificationsNumber: payload});
           });*/
    }

    calcWidthSearch = () => {
        if (this.state.showSearch) {
            // Selector
            const NAV                        = this.navRef.current;
            const HEADER_INFO                = NAV.querySelector('.header-info');
            const HEADER_SEARCH              = NAV.querySelector('#headerSearch');
            const HEADER_SEARCH_PARENT       = NAV.querySelector('.search');
            // Get style
            const STYLE_HEADER_INFO          = window.getComputedStyle(HEADER_INFO);
            const STYLE_HEADER_SEARCH_PARENT = window.getComputedStyle(HEADER_SEARCH_PARENT);
            const HEADER_LEFT                = window.innerWidth - HEADER_INFO.offsetLeft + parseFloat(STYLE_HEADER_INFO.marginRight);
            // Element width
            const HEADER_INFO_WIDTH          = (HEADER_INFO.clientWidth + HEADER_LEFT + parseFloat(STYLE_HEADER_INFO.marginRight));
            const WIDTH_TO_SUB               = parseFloat(STYLE_HEADER_SEARCH_PARENT.marginRight) + NAV.querySelector('.profile').offsetWidth + parseFloat(STYLE_HEADER_INFO.marginRight);

            // Apply style
            HEADER_SEARCH.style.width = (HEADER_INFO_WIDTH - WIDTH_TO_SUB) + 20 + 'px';
        }
    };

    onClickMainMenu(e) {
        const TARGET   = e.target;
        const SUB_MENU = TARGET.nextElementSibling;

        if (SUB_MENU && (!SUB_MENU.classList.contains('level-1') || isSmallScreen())) {
            e.preventDefault();
            SUB_MENU.classList.remove('noTouch');
        }
    }

    closeMenu() {
        let menuOpen = document.querySelectorAll('.subMenu.active');
        menuOpen.forEach(menu => menu.classList.remove('active'));
        menuOpen.forEach(menu => menu.classList.remove('last-active'));
        document.getElementById('toggleMenuMobile').checked = false;
    }

    onClickSubMenu = (e) => {
        const TARGET                = e.target;
        const UL_SUB_MENU_LEVEL_TWO = TARGET.parentNode.querySelector('ul');
        // Active sub menu
        UL_SUB_MENU_LEVEL_TWO && UL_SUB_MENU_LEVEL_TWO.classList.add('active');

        let lastActive = document.querySelectorAll('.subMenu.last-active');
        if (lastActive) {
            for (let i = 0; i < lastActive.length; i++) {
                lastActive[i].classList.remove('last-active');
            }
        }
        UL_SUB_MENU_LEVEL_TWO && UL_SUB_MENU_LEVEL_TWO.classList.add('last-active');

    };

    prevMenu(e, currentLevel, targetLevel) {
        const TARGET = e.target;

        // It's the last level of breadcrumb menu
        if (!TARGET.classList.contains('prev')) {
            return;
        }

        let currentMenuActive = TARGET.parentNode.parentNode.parentNode;

        // Disable all previous level
        for (let i = targetLevel; i < currentLevel; i++) {
            currentMenuActive && currentMenuActive.classList.remove('active');
            currentMenuActive = currentMenuActive.parentNode.parentNode;
        }
        let lastActive = document.querySelectorAll('.subMenu.last-active');
        if (lastActive) {
            for (let i = 0; i < lastActive.length; i++) {
                lastActive[i].classList.remove('last-active');
            }
        }

        if (currentMenuActive.classList.contains('level-1')) {
            const SUB_MENU = TARGET.parentNode.parentNode.parentNode;
            SUB_MENU.classList.add('noTouch');
        }

        // Show the level target
        currentMenuActive && currentMenuActive.classList.add('last-active');
        currentMenuActive = currentMenuActive.parentNode.parentNode;
        currentMenuActive && currentMenuActive.classList.add('active');
    }

    toggleSearch() {
        this.setState((state) => {
            return {
                showSearch: !state.showSearch
            }
        }, () => {
            this.calcWidthSearch();
            if (this.state.showSearch) {
                this.navRef.current.querySelector('#headerSearchInput').focus();
            }
        });
    }

    logout() {
        this.setState({
            redirect: Auth.logout()
        });
    }

    onSubmitHeaderSearchInput(e) {
        e.preventDefault();

        if (this.state.inputSearchHeaderValue === '') return;

        // Scroll smooth to top
        animateScroll.scrollToTop();

        // Push param
        this.props.history.push({
            pathname: path('home'),
            search: '?recherche=' + this.state.inputSearchHeaderValue
        });
    }

    isActive(match, location, link) {
        if (match) {
            return true;
        } else {
            if (location.pathname.match(/^\/work-groups/) && link === 'WorkGroupList') {
                // We are in workgroups
                return true;
            } else if (location.pathname.match(/^\/document-work-group/) && link === 'WorkGroupList') {
                // We are in workgroup document
                return true;
            } else if (location.pathname.match(/^\/document\//) && link === 'documentationList') {
                // We are in the documentation
                return true;
            } else if (location.pathname.match(/^\/admin\/documentation-gestion-de-l-arborescence/) && link === 'adminDocumentation') {
                return true;
            }
        }
    }

    generateMenu(menu, level = 0, prevMenu = {}, breadCrumb = []) {
        let className = 'menu';
        let style     = {};
        let firstLi   = (
            <li className={'mobileNameMenu'}>MENU</li>
        );

        // It's a submenu
        if (level !== 0) {
            className = `subMenu level-${level}`;
            style     = {
                backgroundColor: prevMenu.color
            };
            firstLi   = null;
        }

        return (
            <ul className={className} style={style}>
                {/* Generate the breadcrumb */}
                {firstLi ||
                <li className={'menuBreadcrumb'}>
                    {breadCrumb.map((item, i) => {
                        const CLASSNAME = (i === breadCrumb.length - 1) ? '' : ' prev';
                        const SPAN      = (<span onClick={(e) => {
                            this.prevMenu(e, level, i + 1)
                        }} className={'item' + CLASSNAME}>
                                {item.name}
                            </span>);
                        if (level === 1) {
                            return (
                                <span key={i} className={'first'}>
                                    <span className={'item prev'} onClick={(e) => {
                                        this.prevMenu(e, level, i + 1)
                                    }}/>
                                    <NavLink to={path(prevMenu.link)} exact={prevMenu.exact || false}
                                             activeClassName="current-menu">
                                        {prevMenu.name}
                                    </NavLink>
                                </span>
                            );
                        } else if (i === 0) {
                            return (
                                <span key={i}>
                                    {SPAN}
                                </span>
                            );
                        } else {
                            return (
                                <span key={i}>
                                    <i className={'chevron'}>></i>
                                    {SPAN}
                                </span>
                            );
                        }
                    })}
                </li>
                }
                {/* Generate menu for this level */}
                {menu.map((menuItem, i) => {
                    // If the bread crumb has more element than level remove the difference
                    if (breadCrumb.length > level) {
                        breadCrumb = breadCrumb.slice(0, -(breadCrumb.length - level));
                    }

                    // Add the menu in the breadcrumb
                    breadCrumb.push({
                        name: menuItem.name,
                        link: menuItem.link,
                        exact: menuItem.exact
                    });

                    const IS_DISABLED = (level === 1 && menuItem.hasOwnProperty('isDocumentation') && menuItem.isDocumentation);
                    const IS_ARCHIVED = (menuItem.hasOwnProperty('archivedat') && menuItem.archivedat);

                    return (<li key={i}>
                        {level === 0 ? (
                            <NavLink to={path(menuItem.link)}
                                     exact={menuItem.exact || false}
                                     isActive={(match, location) => this.isActive(match, location, menuItem.link)}
                                     activeClassName="current-menu"
                                     onClick={(e) => this.onClickMainMenu(e)}>
                                {menuItem.name}
                            </NavLink>
                        ) : (
                            menuItem.menu ?
                                <button type={'button'} onClick={this.onClickSubMenu} className={IS_ARCHIVED ? 'archived' : ''}>{menuItem.name}</button>
                                :
                                <NavLink to={menuItem.link} exact={menuItem.exact || false}
                                         activeClassName="current-menu"
                                         onClick={() => {
                                             this.closeMenu();
                                         }}
                                         className={IS_DISABLED ? 'disabled' : (IS_ARCHIVED ? 'archived' : '')}>
                                    {menuItem.name}
                                </NavLink>
                        )}
                        {/* This level have a submenu so generate it */}
                        {menuItem.menu && this.generateMenu(menuItem.menu, level + 1, menuItem, breadCrumb)}
                    </li>)
                })}
            </ul>
        );
    }

    async onClickNotification(notification) {
        // Set read
        await ApiNotifications.markAsRead(notification.id);

        // Redirect to resource
        this.props.history.push(getPathFromNotification(notification));

        // Refresh notification
        this.getNotifications();
    }

    toggleProfileMenu(e, force) {
        const element = e.currentTarget;
        if (force) {
            element.classList.add('active');
        } else if (force === false) {
            element.classList.remove('active');
        } else {
            this._showProfileMenu = !this._showProfileMenu;
            this.toggleProfileMenu(e, this._showProfileMenu);
        }
    }

    render() {
        const MENU         = this.props.adminHeader ? this.state.menuAdmin : this.state.menu;
        const NOTIFICATION = this.state.notifications.length > 0 ?
            <li className={'notifications'}>
                <span className="notification">{this.state.notificationsNumber}</span>
                <span className={'textNotification'}>Mes notifications</span>
                <ul>
                    {this.state.notifications.map((notification, i) => {
                        return <li key={i}>
                            <button>
                                <p>
                                    <span
                                        onClick={() => this.onClickNotification(notification, i)}>{getNameNotification(notification)}</span>
                                    <i className={TYPE[notification.type].toLowerCase()}/>
                                </p>
                            </button>
                        </li>
                    })}
                    <li className={'readAllNotifications'}>
                        <Link to={path('notifications')} className={'underline'}>Voir toutes les notifications</Link>
                    </li>
                </ul>
            </li> : '';

        return (

            this.state.menu.length > 0 ? <header className={this.props.adminHeader ? 'admin' : null}>
                {this.state.redirect}
                <nav ref={this.navRef}>
                    <div id={'logoHeader'}>
                        <Link to={path('home')}><img src={'/assets/images/logo/MyASFA_CMJN.png'} alt="Accueil"/></Link>
                    </div>
                    <input id={'toggleMenuMobile'} type="checkbox"/>
                    <label id={'burger'} htmlFor="toggleMenuMobile">
                        <span/>
                        <span/>
                        <span/>
                    </label>
                    {this.generateMenu(MENU)}
                    <ul className="header-info">
                        {/* Notification/Back*/}
                        {this.props.adminHeader
                            ?
                            <li id={'backVisitor'}>
                                <Link to={path('home')}><img src="/assets/images/icon/backAdmin.svg" alt=""/><span>Retour visiteur</span></Link>
                            </li>
                            : NOTIFICATION
                        }
                        {/* Search */}
                        <li className="search">
                            <Button onClick={() => this.toggleSearch()}
                                    className={this.state.showSearch ? 'active' : null}>
                                <span className="fas fa-search"/>
                            </Button>
                            {/* Search */}
                            {this.state.showSearch && <div id={'headerSearch'}>
                                <form onSubmit={(e) => this.onSubmitHeaderSearchInput(e)}>
                                    <input id={'headerSearchInput'} type="text" placeholder={'Que recherchez-vous ?'}
                                           onChange={(e) => {
                                               this.setState({inputSearchHeaderValue: e.target.value})
                                           }}/>
                                    <input id={'submitHeaderSearchInput'} type="submit"/>
                                </form>
                            </div>}
                        </li>
                        {/* Profile */}
                        <li>
                            <div className="profile"
                                 onClick={(e) => this.toggleProfileMenu(e)}
                                 onMouseEnter={(e) => this.toggleProfileMenu(e, true)}
                                 onMouseLeave={(e) => this.toggleProfileMenu(e, false)}>

                                <span className="profile-image">{this.state.initial}</span>
                                <div className="dropDown">
                                    <ul className="subMenu">
                                        <li>
                                            <span>{Auth.getUser().first_name}</span><span>{Auth.getUser().last_name}</span>
                                        </li>
                                        {Auth.isAdminAtLeast() &&
                                        <li>
                                            <NavLink to={path('adminUser')}
                                                     className="link"
                                                     activeClassName="current-menu"
                                                     exact={false}
                                                     isActive={(match, location) => {
                                                         const ADMIN_PATH = [
                                                             path('adminUser'),
                                                             path('adminDocumentation'),
                                                             path('adminManageDocumentationEditTree'),
                                                             path('adminManageDocumentation'),
                                                             path('adminNews')
                                                         ];
                                                         return ADMIN_PATH.includes(location.pathname);
                                                     }}>
                                                <span>Mode administration</span>
                                            </NavLink>
                                        </li>
                                        }
                                        <li>
                                            <NavLink to={path('profile')}
                                                     className="link"
                                                     activeClassName="current-menu">
                                                <span>Paramètres</span>
                                            </NavLink>
                                        </li>
                                        <li>
                                            <Button className="link"
                                                    onClick={() => this.logout()}><span>Se déconnecter</span></Button>
                                        </li>
                                    </ul>
                                </div>

                            </div>
                        </li>
                    </ul>
                </nav>
                {/* Breadcrumb */}
                {this.props.breadCrumb && <BreadCrumb levels={this.props.breadCrumb}/>}
            </header> : <Loader/>
        );
    }
}
