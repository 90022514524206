import React from 'react';
import PropTypes from "prop-types";
import Button from "../Form/Button/Button";
import {isMobile, isPad} from "../../Utils/Screen";
import "./Carousel.scss";


export default class Carousel extends React.Component {

    static propTypes = {
        slidesToShow: PropTypes.number,
        infinite: PropTypes.bool,
        showDots: PropTypes.bool,
        showArrow: PropTypes.bool,
        center: PropTypes.bool,
        vertical: PropTypes.bool,
        onChange: PropTypes.func,
        startAt: PropTypes.number,
        gapBetweenItem: PropTypes.number,
        arrowBottom: PropTypes.bool
    };

    static defaultProps = {
        slidesToShow: 1,
        infinite: false,
        showDots: false,
        showArrow: false,
        center: false,
        vertical: false,
        gapBetweenItem: 0,
        startAt: 0,
        arrowBottom: false,
        onChange: () => {
        }
    };

    state = {
        size: 0,
        translate: 0,
        transition: null
    };

    constructor(props) {
        super(props);
        this.currentIndex   = 0;
        this.nbItems        = 0;
        this.containerRef   = React.createRef();
        this.container      = null;
        this.carouselCenter = 0;
        this.offset         = 0;
        this.children       = [];
        this.offsetIndex    = 0;
        this.elements       = [];
        this.slideToShow    = this.props.slidesToShow;
        this.initCarousel   = true;
        this.dotsList       = '';
        this.arrow          = '';
        this.dots           = [];
        this.timeout        = null;

        this.prevArrow = React.createRef();
        this.nextArrow = React.createRef();
    }

    componentDidMount() {
        this.container = this.containerRef.current;
        if (this.props.children.length > 0) {
            this.init(this.props);
        }
    }

    componentDidUpdate() {
        // When children change, update the carousel position
        if (this.children.length !== this.container.children.length) {
            this.children = this.container.children;

            if (this.children.length > 1) {
                this.dotsList = this.createDots();
                this.arrow    = this.createArrow();
            }

            if (this.props.vertical) {
                this.setState({
                    size: this.children[0].offsetHeight - (isPad() || isMobile() ? 0 : 20)
                });
            }

            this.timeout = setTimeout(() => {
                this.goTo(this.props.startAt, false);
                this.updateCarousel();
            }, 500);

            window.addEventListener('resize', this.resize);
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.resize);
        clearTimeout(this.timeout);
    }

    resize = () => {
        this.init(this.container);
        this.updateCarousel(false, true);
    };

    init(carousel) {
        this.breakPoints();

        this.elements = this.initCarousel ? carousel.children : this.elements;

        // Carousel needs to be infinite
        if (this.props.infinite && carousel.children.length > 1) {
            this.slideToShow = (carousel.children.length < this.slideToShow) ? carousel.children.length : this.slideToShow;
            // Add an offset index equals to the number of slide who needs to be show
            this.offsetIndex = this.slideToShow;
            this.nbItems     = this.initCarousel ? carousel.children.length + this.offsetIndex + 1 : this.nbItems;

            if (this.initCarousel) {
                let prevInfinite = [];
                let nextInfinite = [];

                const duplicate = (carousel.children.length < this.props.slidesToShow) ? carousel.children.length : this.props.slidesToShow;
                // Duplicate the first and the last element
                for (let i = 0; i < duplicate; i++) {
                    prevInfinite.push(React.cloneElement(this.elements[carousel.children.length - duplicate + i], {
                        key: -(i + 1)
                    }));
                    nextInfinite.push(React.cloneElement(this.elements[i], {
                        key: carousel.children.length + i
                    }));
                }

                // Add the last element to the beginning
                this.elements = prevInfinite.concat(this.elements);
                // Add the first element to the end
                this.elements = this.elements.concat(nextInfinite);
            }
        } else {
            this.nbItems = carousel.children.length;
        }

        // Get the middle of the carousel
        this.carouselCenter = this.container.parentElement.offsetWidth / 2;

        if (!this.props.vertical) {
            let size = (this.nbItems / this.slideToShow) * 100;
            if (this.nbItems === 1) {
                size = 100
            }
            this.setState({
                size: size
            });
        } else if (!this.initCarousel) {
            this.setState({
                size: this.children[0].offsetHeight - (isPad() || isMobile() ? 0 : 20)
            });
        }

        this.initCarousel = false;
    }

    breakPoints() {
        // Force slide to show if we are on mobile or pad
        if (isMobile() || isPad()) {
            this.slideToShow = 1;
        } else {
            this.slideToShow = this.props.slidesToShow;
        }
    }

    setStyle() {
        // Reduce opacity for all element
        for (let i = 0; i < this.children.length; i++) {
            if (this.slideToShow > 1) {
                this.children[i].style.opacity = '0.5';
            }
            // Disabled focus for all element
            let buttons = this.children[i].getElementsByTagName('a');
            for (let i = 0; i < buttons.length; i++) {
                buttons[i].setAttribute('tabindex', '-1');
            }
        }

        // Reset focus for the current element
        let buttons = this.children[this.getIndex()].getElementsByTagName('a');

        for (let i = 0; i < buttons.length; i++) {
            buttons[i].setAttribute('tabindex', '');
        }

        // Reset opacity for visible elements
        this.children[this.getIndex()].style.opacity = '';

        if (this.props.infinite) {
            for (let i = this.getIndex() + 1; i < this.getIndex() + this.slideToShow - 1; i++) {
                this.children[i].style.opacity = '';
            }
            for (let i = this.getIndex() - 1; i > this.getIndex() - this.slideToShow + 1; i--) {
                this.children[i].style.opacity = '';
            }
        } else {
            if (this.currentIndex === 0) {
                this.prevArrow.current.btn.classList.add('hide');
            } else if (this.currentIndex === this.children.length - 1) {
                this.nextArrow.current.btn.classList.add('hide');
            } else {
                this.prevArrow.current.btn.classList.remove('hide');
                this.nextArrow.current.btn.classList.remove('hide');
            }
        }
    }

    /**
     * Active all dots for visible dots
     */
    activeDots() {
        // Disable all dots
        for (let i = 0; i < this.dots.length; i++) {
            this.dots[i].current.reference().classList.remove('active');
        }

        // Active current dot
        this.dots[this.getCurrentIndex()].current.reference().classList.add('active');

        let loop = 0;

        // Active all dots visible after the current
        for (let i = this.getCurrentIndex() + 1; i < this.getCurrentIndex() + this.slideToShow - 1; i++) {
            let index = i;
            if (i >= this.dots.length) {
                index = loop;
                loop++;
            }
            this.dots[index].current.reference().classList.add('active');
        }
        loop = 0;
        // Active all dots visible before the current
        for (let i = this.getCurrentIndex() - 1; i > this.getCurrentIndex() - this.slideToShow + 1; i--) {
            let index = i;
            if (i < 0) {
                index = this.dots.length - 1 - loop;
                loop++;
            }
            this.dots[index].current.reference().classList.add('active');
        }
    }

    /**
     * Get all previous item width
     *
     * @param {number} [lastIndex]
     * @return {number}
     */
    allItemsWidth(lastIndex = this.elements.length) {
        let width = 0;

        for (let i = 0; i < lastIndex; i++) {
            width = width + this.children[i].offsetWidth;
        }

        return width;
    }

    /**
     * Apply the new translate to the carousel
     *
     * @param {boolean} [withAnimation=true] Need to apply an animation to the new translate
     * @param resize
     */
    updateCarousel(withAnimation = true, resize = false) {
        if (this.children[this.getIndex()]) {
            // Get the offset between two element
            if ((this.offset === 0 && this.getIndex() !== 0) || resize) {
                this.offset = (this.container.offsetWidth - this.allItemsWidth()) / (this.elements.length - 1);
            }

            if (!withAnimation) {
                this.setState({
                    transition: 'none'
                })
            }

            if (this.children.length > 1) {
                this.setStyle();
                if (withAnimation || resize) {
                    if (this.props.showDots) {
                        this.activeDots();
                    }
                }
            }

            /**
             * Calculate the translate to apply the current element
             */
            let translate = 0;

            if (this.props.vertical) {
                translate = (20 + this.children[0].offsetHeight) * -(this.getIndex())
            } else {
                // Get the middle of the carousel
                if (this.props.center) {
                    translate = this.carouselCenter;
                }

                if (this.getIndex() > 0) {
                    // Subtract how many element are past and the number of offsets
                    translate = translate - this.allItemsWidth(this.getIndex()) - (this.getIndex() * this.offset);
                }
                if (this.props.center) {
                    // Get the middle of the current element
                    let currentElement = this.children[this.getIndex()].offsetWidth / 2;

                    // Subtract the half of the current element
                    translate = translate - currentElement;
                }
            }

            this.setState({
                translate: translate,
            });
        }
    }

    createDots() {
        let dotsList = '';

        if (this.props.showDots) {
            dotsList = (
                <ul className="dots-list">
                    {/* Create the dot list */}
                    {this.props.children.map((child, i) => {
                        let ref = React.createRef();

                        let dots = (<li key={i}>
                            <Button type="button" ref={ref} aria-label={`Aller à l'élément ${i + 1}`} className={child.props.workGroup && child.props.workGroup.category} onClick={() => this.goTo(i)}/>
                        </li>);

                        this.dots.push(ref);

                        return dots;
                    })}
                </ul>
            );
        }

        return dotsList;
    }

    createArrow() {
        let arrow = '';

        if (this.props.showArrow) {
            arrow = (
                <>
                    <Button className={'btn btn-prev'} onClick={() => this.prev()} ref={this.prevArrow} aria-label="Aller à l'élément précédant">
                        <span className="arrow"/>
                    </Button>
                    <Button className={'btn btn-next'} onClick={() => this.next()} ref={this.nextArrow} aria-label="Aller à l'élément suivant">
                        <span className="arrow"/>
                    </Button>
                </>
            );
        }

        return this.props.arrowBottom
            ? <div className="bottom" style={{
                paddingLeft: this.props.gapBetweenItem,
                paddingRight: this.props.gapBetweenItem,
            }}>
                <div>{arrow}</div>
            </div>
            : arrow;
    }

    next() {
        this.enableTransition();

        // Go to the next element if we do not at the last position
        if (this.currentIndex < this.props.children.length - 1 || this.props.infinite) {
            this.currentIndex++;
            this.props.onChange('next');
            this.updateCarousel();
        }
    }

    prev() {
        this.enableTransition();
        // Go to the previous element if we do not at the first position
        if (this.currentIndex > 0 || this.props.infinite) {
            this.currentIndex--;
            this.props.onChange('prev');
            this.updateCarousel();
        }
    }

    goTo(index, withAnimation = true) {
        if (index < 0 || index >= this.props.children.length) {
            console.error(`index needs to be between 0 and ${this.props.children.length - 1}, current: ${index}`);
            return;
        }
        this.enableTransition();

        this.currentIndex = index;
        this.updateCarousel(withAnimation);
        this.props.onChange();
    }

    transitionEnd() {
        if (this.currentIndex >= this.props.children.length) {
            this.currentIndex = 0;
        } else if (this.currentIndex < 0) {
            this.currentIndex = this.props.children.length - 1;
        }
        // Change the position without transition when we loop on the carousel
        if (this.currentIndex === this.props.children.length - 1 || this.currentIndex === 0) {
            this.updateCarousel(false);
        }
    }

    enableTransition() {
        if (this.state.transition !== null) {
            this.setState({
                transition: null
            })
        }
    }

    /**
     * Get the Index with the offset
     **/
    getIndex() {
        return this.currentIndex + this.offsetIndex;
    }

    /**
     * Get the index without the offset
     **/
    getCurrentIndex() {
        if (this.currentIndex >= this.props.children.length) {
            return 0;
        } else if (this.currentIndex < 0) {
            return this.props.children.length - 1;
        }

        return this.currentIndex;
    }

    render() {
        const styleCarousel = {
            width: (this.props.vertical) ? 'auto' : this.state.size + '%',
            transform: (this.props.vertical) ? `translateY(${this.state.translate}px)` : `translateX(${this.state.translate}px)`,
            transition: this.state.transition,
            height: (this.props.vertical) ? this.state.size + 'px' : 'auto'
        };

        return (
            <div className={`carousel${this.nbItems === 1 ? ' alone' : ''}${(this.props.vertical) ? ' vertical' : ''}`}>
                <div className="carousel-container" style={styleCarousel} onTransitionEnd={() => this.transitionEnd()} ref={this.containerRef}>
                    {this.elements.map((child, i) => (
                        <div key={i} style={{
                            transition: this.state.transition,
                            paddingLeft: isMobile() ? this.props.gapBetweenItem / 2 : this.props.gapBetweenItem,
                            paddingRight: isMobile() ? this.props.gapBetweenItem / 2 : this.props.gapBetweenItem,
                            width: '100%'
                        }}>
                            {child}
                        </div>
                    ))}
                </div>

                {this.dotsList}

                {this.arrow}
            </div>
        )
    }

}