import Api, {PATH} from "../Utils/Api";
import axios from "axios";
import Auth from "../Security/Auth";

const PATH_API = `${PATH}/user`;

export default {

    /**
     * Add a role to an user
     * Warning: It will add it, not delete the others. If you want to do it, call deleteRole/clearRoles before calling it
     *
     * @param userId
     * @param roleId
     * @returns {AxiosPromise<any>}
     */
    addRole: (userId, roleId) => {
        return axios.patch(`${PATH_API}/user/${userId}`, {role: roleId});
    },

    /**
     * Edit user
     * @param userId
     * @param data
     * @returns {AxiosPromise<any>}
     */
    edit: (userId, data) => {
        return axios.patch(`${PATH_API}/user/${userId}`, data);
    },

    /**
     * Get user
     * @param userId
     * @param nestedEntities
     * @returns {AxiosPromise<any>}
     */
    getUser: (userId, nestedEntities = []) => {
        const PARAMS = {
            "api[nested_entities]": Api.buildNestedEntity(nestedEntities),
        };

        return axios.get(`${PATH_API}/user/${userId}`, {params: PARAMS});
    },

    getUserSubscription: (userId) => {
      const PARAMS = {
          "api[nested_entities]": 'subscriptions.categories, subscriptions.workgroups',
      };

      return axios.get(`${PATH_API}/user/${userId}`, {params: PARAMS});
    },

    /**
     *
     * @param {QueryBuilder} queryBuilder
     * @returns {AxiosPromise<any>}
     */
    getUsers: (queryBuilder) => {
        return axios.get(`${PATH_API}/user`, {params: queryBuilder.build()});
    },

    /**
     * Search user
     *
     * @param searchText
     * @param {QueryBuilder} queryBuilder
     * @return {AxiosPromise<any>}
     */
    searchUsers: (searchText, queryBuilder) => {
        let search = searchText.split(' ');
        let where  = `\`profile.lastname\` like \`%${search[0]}%\` OR \`profile.firstname\` like \`%${search[0]}%\``;
        if (search[1]) {
            where += ` \`profile.lastname\` like \`%${search[1]}%\` OR \`profile.firstname\` like \`%${search[1]}%\``;
        }

        queryBuilder.setWhere(where);

        return axios.get(`${PATH_API}/user`, {params: queryBuilder.build()});
    },

    /**
     * Add an user
     *
     * @param {Object} userData
     * @returns {Promise<unknown>}
     */
    add: (userData) => {
        return axios.post(`${PATH_API}/user`, userData);
    },

    /**
     * Update an user
     *
     * @param {Object} userData
     * @returns {Promise<unknown>}
     */
    update: (userData) => {
        return axios.patch(`${PATH_API}/user/${userData.id}?api[nested_entities]=profile.company,thematicsAdministrator,categories,workgroups.workgroup`, userData);
    },

    /**
     * Update password
     * @param userId
     * @param password
     * @returns {AxiosPromise<any>}
     */
    updatePassword: (userId, password) => {
        return axios.patch(`${PATH_API}/user/${userId}`, {
            password: password
        });
    },

    /**
     * Update user subscription
     * @param subscription {subscription. : {workgroups,categories,thematics}}
     * @param userId
     * @returns {AxiosPromise<any>}
     */
    addSubscription: (subscription, userId) => {
        let data = {};

        if (subscription.workgroups) {
            data.workgroups = subscription.workgroups;
        }
        if (subscription.categories) {
            data.categories = subscription.categories;
        }
        if (subscription.thematics) {
            data.thematics = subscription.thematics;
        }

        data.user = userId || Auth.getUser().id;

        return axios.post(`${PATH_API}/subscription`, data);
    },

    /**
     * Update user subscription
     * @param subscriptionId
     * @returns {AxiosPromise<any>}
     */
    deleteSubscription: (subscriptionId) => {
        return axios.delete(`${PATH_API}/subscription/${subscriptionId}`);
    },

    /**
     *  Delete all user's categories
     * @param userId
     * @returns {AxiosPromise}
     */
    deleteCategories: userId => {
        return axios.delete(`${PATH_API}/user/${userId}/categories`);
    },

    /**
     *  Delete all user's roles
     * @param userId
     * @returns {AxiosPromise}
     */
    deleteRoles: userId => {
        return axios.delete(`${PATH_API}/user/${userId}/roles`);
    },

    /**
     * Remove user
     *
     * @param userId
     * @returns {Promise<unknown>}
     */
    delete: (userId) => {
        return axios.delete(`${PATH_API}/user/${userId}`);
    },

    /**
     * Get an user by his e-mail address
     * Optionally you can exclude an user by giving his ID in excludedId parameter
     *
     * @param email
     * @param excludedId
     * @returns {AxiosPromise<any>}
     */
    getUserByEmail(email, excludedId = 0) {
        let where = `where \`email\` = \`${email}\``;

        if (excludedId > 0) {
            where += ` AND \`id\` <> ${excludedId}`;
        }

        const PARAMS = {
            "api[where]": where,
            "api[singleResult]": true,
        };

        return axios.get(`${PATH_API}/user`, {params: PARAMS});
    },

    /**
     * Approve user
     * @param userId
     * @returns {AxiosPromise<any>}
     */
    approve: (userId) => {
        return axios.patch(`${PATH_API}/user/${userId}/approved`);
    },

    /**
     * Get thematics for which user is administrator
     *
     * @param userId
     * @returns {Promise<[]|*|Array>}
     */
    getThematicsAdministrator(userId) {
        return new Promise(async (resolve) => {
            const USER = await this.getUser(userId, ['thematicsAdministrator']);

            if (USER && USER.hasOwnProperty('thematicsadministrator')) {
                return resolve(USER.thematicsadministrator);
            }

            return resolve([]);
        });

    },

    /**
     * Get user's categories
     *
     * @param userId
     * @param withArchived
     * @returns {Promise<[]|*|Array>}
     */
    getCategories(userId, withArchived = false) {
        return new Promise(async (resolve) => {
            const PARAMS = {
                "api[nested_entities]": 'categories.categories',
                "api[where]": `where \`categories.archived_at\` = \`null\``,
            };

            const USER = await axios.get(`${PATH_API}/user/${userId}`, {params: PARAMS});

            if (USER && USER.hasOwnProperty('categories')) {
                return resolve(USER.categories);
            }

            return resolve([]);
        });

    },

    clearCache() {
        return axios.get(`${PATH}/clear/cache`);
    },
}
