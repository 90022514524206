import React from 'react';
import {Link} from "react-router-dom";
import {path} from "../../../Config/routes";
import "./Footer.scss";
import Auth from "../../../Security/Auth";

export default class Footer extends React.Component {
    constructor(props) {
        super(props);

        this.footer = [
            {
                label: 'Autoroutes.fr',
                link: 'https://www.autoroutes.fr/index.htm',
                external: true,
                public: true
            },
            {
                label: 'Mentions légales',
                link: path('legalNotice')
            },
            {
                label: 'Politique des données',
                link: path('rgpd'),
                public: true
            },
            {
                label: 'Contact',
                link: path('contact')
            }
        ]
    }

    render() {
        return (
            <footer>
                <nav>
                    <ul>
                        {this.footer.map((footerItem, i) => {
                           if (footerItem.public || Auth.isAuthenticated()) {
                               return (<li key={i}>
                                   {footerItem.external ?
                                       <a href={footerItem.link} target={'_blank'}>{footerItem.label}</a> :
                                       <Link to={footerItem.link}>
                                           {footerItem.label}
                                       </Link>
                                   }
                               </li>)
                           }
                           else {
                               return null;
                           }
                        })}
                    </ul>
                </nav>
            </footer>
        )
    }
}
