import {PATH} from "../Utils/Api";

export const CK_EDITOR = {
           config: {
               toolbarGroups: [
                   {name: 'styles', groups: ['styles']},
                   {name: 'basicstyles', groups: ['basicstyles', 'cleanup']},
                   {name: 'links', groups: ['links']},
                   {name: 'document', groups: ['mode', 'document', 'doctools']},
                   {name: 'editing', groups: ['find', 'selection', 'spellchecker', 'editing']},
                   {name: 'forms', groups: ['forms']},
                   {name: 'paragraph', groups: ['list', 'indent', 'blocks', 'align', 'bidi', 'paragraph']},
                   {name: 'insert', groups: ['insert']},
                   {name: 'colors', groups: ['colors']},
                   {name: 'clipboard', groups: ['clipboard', 'undo']},
                   {name: 'tools', groups: ['tools']},
                   {name: 'others', groups: ['others']},
                   {name: 'about', groups: ['about']}
               ],
               removeButtons: 'About,Maximize,TextColor,Save,Templates,Cut,NewPage,Preview,Print,PasteFromWord,PasteText,Paste,Copy,Replace,Find,SelectAll,Scayt,Form,Checkbox,Underline,Strike,TextField,Subscript,Textarea,Select,Superscript,Button,ImageButton,HiddenField,RemoveFormat,CopyFormatting,Indent,Outdent,JustifyLeft,JustifyCenter,CreateDiv,JustifyRight,JustifyBlock,Language,BidiRtl,BidiLtr,Unlink,Anchor,Flash,Smiley,HorizontalRule,PageBreak,Iframe,FontSize,Font,Styles,BGColor,ShowBlocks,Source,Radio,SpecialChar',
               link: {
                   addTargetToExternalLinks: true
               },
           },

           baseUploadUrl: `${PATH}/ckeditor/upload?jwt=`
         }
;

export const CK_EDITOR_EMPTY = {
           config: {
               toolbarGroups: [
                   { name: 'document', groups: [ 'mode', 'document', 'doctools' ] },
                   { name: 'clipboard', groups: [ 'clipboard', 'undo' ] },
                   { name: 'editing', groups: [ 'find', 'selection', 'spellchecker', 'editing' ] },
                   { name: 'forms', groups: [ 'forms' ] },
                   { name: 'basicstyles', groups: [ 'basicstyles', 'cleanup' ] },
                   { name: 'paragraph', groups: [ 'list', 'indent', 'blocks', 'align', 'bidi', 'paragraph' ] },
                   { name: 'links', groups: [ 'links' ] },
                   { name: 'insert', groups: [ 'insert' ] },
                   '/',
                   { name: 'styles', groups: [ 'styles' ] },
                   { name: 'colors', groups: [ 'colors' ] },
                   { name: 'tools', groups: [ 'tools' ] },
                   { name: 'others', groups: [ 'others' ] },
                   { name: 'about', groups: [ 'about' ] }
               ],
               removeButtons: 'Source,Save,Templates,NewPage,Preview,Print,Cut,Copy,Paste,PasteText,PasteFromWord,Undo,Redo,Replace,Find,SelectAll,Scayt,Form,About,Maximize,ShowBlocks,TextColor,BGColor,Styles,Format,Font,FontSize,Iframe,PageBreak,SpecialChar,Smiley,HorizontalRule,Table,Flash,Image,Link,Unlink,Anchor,Language,BidiRtl,BidiLtr,JustifyLeft,JustifyCenter,JustifyRight,JustifyBlock,CreateDiv,Blockquote,Outdent,Indent,NumberedList,BulletedList,CopyFormatting,RemoveFormat,Italic,Bold,Underline,Strike,Subscript,Superscript,Button,ImageButton,HiddenField,Select,Textarea,TextField,Radio,Checkbox'
           }
       }
;
