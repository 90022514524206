import {path} from "../Config/routes";

const NT_RESOURCE_CREATED = 8;  // Resource created
const NT_RESOURCE_UPDATED = 16; // Resource updated
//const NT_USER_ADDED       = 32; // User added in a workgroup
const NT_USER_REGISTERED  = 64; // User registered, now waiting for approval

export const CT_DOCUMENT = 8;  // Content Type Document
export const CT_NEWS     = 16; // Content Type News
export const CT_PAGE     = 32; // Content Type Page
export const CT_COMMENT  = 64; // Content Type Comment


export function getPathFromNotification(notification) {
    let link;

    if (notification && notification.resource && (notification.resourcetype === CT_DOCUMENT || notification.resourcetype === CT_COMMENT)) {
        link = notification.resource.slug;
    } else if (notification.resourcetype === CT_NEWS) {
        link = path('news');
    } else if (notification.resourcetype === CT_PAGE) {
        link = path('page');
    } else if (notification.type === NT_USER_REGISTERED) {
        const EMAIL = notification.description.match(/\((.*)\)/)[1];
        link = path('adminUser') + `?email=${EMAIL}`;
    } else {
        link = '#';
    }

    return link
}

export function getNameNotification(notification) {
    let name = notification.name;

    if (notification.resourcetype === CT_DOCUMENT) {

        if (notification.type === NT_RESOURCE_CREATED) {
            name = 'Ajout ';
        } else if (notification.type === NT_RESOURCE_UPDATED) {
            name = 'Modification ';
        }

        if (notification.membername.toLowerCase() === 'utilisateur supprimé') {
            name += `du document "${notification.resource.title}"`;
        } else {
            name += `du document "${notification.resource.title}" par ${notification.membername}`;
        }
    } else if (notification.type === NT_USER_REGISTERED) {
        name += ` de ${notification.description.replace(/\(.*\)/i, '')}`;
    } else if (notification.resourcetype === CT_COMMENT) {
        if (notification.type === NT_RESOURCE_CREATED) {
            name = 'Nouveau ';
        }

        let  user = notification.user;

        name += `commentaire sur le document "${notification.resource.title}" par ${user.profile.firstname} ${user.profile.lastname}`;
    }

    return name;
}
