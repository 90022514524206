import React from 'react';
import './HttpError.scss';

export default class HttpError extends React.Component {
    render() {
        return (
            <div className={'http-error'}>
                <p>Une erreur est survenue merci de recharger la page.
                </p>
                <button type='button' onClick={() => window.location.reload()}>Recharger</button>
            </div>
        );
    }
}