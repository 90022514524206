import React from "react";
import {Link, Redirect} from "react-router-dom";
import {path} from "../../../Config/routes";
import Master from "../../../Components/Layout/Master";
import "./UnsubscribeNews.scss";
import * as qs from "qs";


export default class UnsubscribeNews extends React.Component {

    state = {
        message: '',
    };

    static keytoMessages = {
        success : 'Votre désabonnement a bien été pris en compte.',
        error : 'Erreur, veuillez contacter un administrateur.',
    }

    constructor(props) {
        super(props);
        this._isMounted = false;
    }

    componentDidMount() {
        this._isMounted = true;

        const PARAMS = qs.parse(this.props.location.search, {ignoreQueryPrefix: true});

        if (PARAMS.messageKey) {
            let message = UnsubscribeNews.keytoMessages[PARAMS.messageKey] || '';
            this.setState({
                message: message,
            });
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    render() {
        return (
            <Master showMenu={false} id={"unsubcribe_news"} footer={false} {...this.props}>
                <div className="overlay"/>
                <div className={'content'}>
                    <div className={'wrapContent'}>
                        <h1>Désabonnement</h1>
                        <p>{this.state.message}</p>
                        <Link to={path('login')}>
                            Retour à la connexion
                        </Link>
                    </div>
                </div>

                <footer>
                    <nav>
                        <ul>
                            <li>
                                <a href={'https://www.autoroutes.fr/index.htm'} target={'_blank'}>Autoroutes.fr</a> :
                            </li>
                            <li>
                                <Link to={path('rgpd')}>
                                    Politique des données
                                </Link>
                            </li>
                        </ul>
                    </nav>
                </footer>
            </Master>
        );
    }
}
