import ApiWorkGroupUser from "../../../Services/ApiWorkGroupUser";
import React from 'react';
import "./ListRoleLegend.scss";


export default class ListRoleLegend extends React.Component {
    render() {
        return (
            <ul className={'ListRoleLegend'}>
                {Object.entries(ApiWorkGroupUser.getAllMemberTypes()).map(([key, value], i) => {
                    return <li className={'memberTypeId-'+key} key={i}>{value}</li>
                })}
            </ul>
        )
    }
}