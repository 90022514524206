import React from 'react';
import Master from "../../Components/Layout/Master";
import './NotFound.scss';
import {Link, Redirect} from "react-router-dom";
import axios from "axios";
import {path} from "../../Config/routes";
import Loader from "../../Components/Loader/Loader";
import {LOCATION} from "../../Config/location";

export default class NotFound extends React.Component {

    state = {
        loading: true,
        redirect: false
    };
    
    componentDidMount() {
        axios.get(LOCATION.api + this.props.location.pathname).then(redirect => {
            this.setState({
                redirect: redirect
            });
        }).catch(e => {
            this.setState({
                loading: false
            });
        })
    }

    render() {
        let content;

        if (this.state.loading && !this.state.redirect) {
            content = <Loader/>;
        } else if (this.state.redirect) {
            content = <Redirect to={this.state.redirect}/>
        } else {
            content = <Master>
                <div id={'notFound'}>
                    <div className="container">
                        <h1>Erreur 404</h1>
                        <p>La page n’existe pas !</p>
                        <br/>
                        <Link to={path('home')} className={'btn'}>Retour à la page d’accueil</Link>
                    </div>
                </div>
            </Master>
        }

        return (
            content
        )
    }
}