import React from 'react';
import PropTypes from "prop-types";
import Carousel from "../Carousel/Carousel";
import Loader from "../Loader/Loader";
import Block from "../Layout/Block";
import File from "../File/File";
import './CarouselDocument.scss'


export default class CarouselDocument extends React.Component {

    static propTypes = {
        documents: PropTypes.array,
        label: PropTypes.string,
        featured: PropTypes.bool,
        loading: PropTypes.bool,
        color: PropTypes.string,
        infinite: PropTypes.bool,
    };

    static defaultProps = {
        documents: [],
        forWorkGroupId: null,
        label: 'DOCUMENTS À LA UNE',
        featured: true,
        color: '#000000',
        infinite: true,
    };

    render() {
        let documents = this.props.documents;

        if (this.props.featured) {
            documents = this.props.documents.filter((doc) => {
                return doc.featured;
            });
        }

        return (
            <Block title={this.props.label} color="blue" className='carouselDocument block-news' titleColor={'#D0ED4D'} iconTitleColor={'white'} bigTitleColor={'black'}>
                {/* Button see all new */}
                {this.props.loading
                    ? <div className={'wrapLoader'}><Loader type={"inline"} backgroundColor={'transparent'} color={'white'}/></div>
                    : <>
                        {documents.length > 0
                            ? <Carousel slidesToShow={1} showDots={false} infinite={this.props.infinite} center={true} showArrow={true} gapBetweenItem={70}>
                                {documents.map((document, i) => (<File file={document} isFileForDocument={false} className={"carousel"} color={this.props.color} key={i}/>))}
                            </Carousel>
                            : <div className={'wrapLoader'}><span className={'no-event'}><em>Aucun document</em></span></div>}
                    </>}
            </Block>
        )
    }
}