import React from "react";
import ApiUser from "../../Services/ApiUser";
import ApiProfile from "../../Services/ApiProfile";
import ApiCompanies from "../../Services/ApiCompanies";
import { THEMATIC_TYPE_DOCUMENTATION } from "../../Services/ApiThematics";
import Master from "../../Components/Layout/Master";
import Banner from "../../Components/Banner/Banner";
import ToolsManage from "../../Components/ToolsManage/ToolsManage";
import InputRadioInline from "../../Components/Form/InputRadioInline/InputRadioInline";
import Modal from "../../Components/Modal/Modal";
import InputTextLight from "../../Components/Form/InputTextLight/InputTextLight";
import Auth from "../../Security/Auth";
import Loader from "../../Components/Loader/Loader";
import Select from "../../Components/Form/Select/Select";
import SelectCategories from "../../Components/Form/SelectCategories/SelectCategories";
import Button from "../../Components/Form/Button/Button";
import { Http } from "../../Utils/Api";
import "./Profile.scss";
import Thematics from "../../Utils/Thematics";
import Roles from "../../Security/Roles";
import {
    MEMBER_TYPE_ADMINISTRATOR,
    MEMBER_TYPE_ANIMATOR,
} from "../../Services/ApiWorkGroupUser";
import ListRoleLegend from "../../Components/List/ListRoleLegend/ListRoleLegend";

export default class Profile extends React.Component {
    state = {
        tabProfileActive: true,
        tabNotificationActive: false,
        tabAlertActive: false,
        showModalEditProfile: false,
        showModalEditPassword: false,
        password: "",
        password_confirm: "",
        password_error: "",
        password_error_confirm: "",
        userTemp: null,
        error: {
            firstname: "",
            lastname: "",
            job: "",
            company: "",
        },
        loadingForm: false,
        loadingUser: true,
        loadingCategories: true,
        loadingButtonSubscription: false,
        formError: "",
        httpError: false,
        subscriptionsSelected: [],
        //Data
        companies: [],
        categories: [],
        user: {
            email: "",
            password: "",
            member_type: "",
            approved_at: null,
            locked_at: null,
            id: null,
            profile: {
                firstname: "",
                lastname: "",
                address: null,
                zipcode: null,
                city: null,
                phone: null,
                mobile: null,
                job: "",
                id: null,
                emailsubscription: null,
                newssubscription: null,
                company: {
                    name: "",
                    description: null,
                    id: null,
                },
            },
            bookmarks: null,
            permissions: null,
            roles: null,
            tokens: null,
            workgroups: null,
            documents: null,
            pages: null,
            news: null,
            comments: null,
            categories: [],
            subscriptions: [],
        },
    };

    constructor(props) {
        super(props);
        this._isMounted = false;
        this._subscriptionEdited = {};
        this._tempCategories = [];
    }

    componentDidMount() {
        this._isMounted = true;

        ApiUser.getUser(Auth.getUser().id, [
            "profile.company",
            "workgroups.workgroup",
            "subscriptions.categories",
            "subscriptions.workgroups",
        ])
            .then((user) => {
                if (!this._isMounted) return;

                let subscriptionsSelected = [];

                // Set subscriptions selected
                user.subscriptions.forEach((subscription) => {
                    if (subscription.categories[0]) {
                        subscription.categories[0].subscriptionId =
                            subscription.id;
                        subscription.categories[0].color = Thematics.getThematic(
                            subscription.categories[0].thematicid
                        ).color;
                        subscriptionsSelected.push(subscription.categories[0]);
                    }
                });

                let workgroupSubscription = user.subscriptions.filter(
                    (sub) => sub.workgroups.length > 0
                );

                for (let i = 0; i < user.workgroups.length; i++) {
                    user.workgroups[i].subscribe = workgroupSubscription.find(
                        (el) =>
                            el.workgroups[0].id ===
                            user.workgroups[i].workgroup.id
                    );
                }

                workgroupSubscription.forEach((wgSub) => {
                    const FIND = user.workgroups.find(
                        (wg) => wg.workgroup.id === wgSub.workgroups[0].id
                    );
                    if (!FIND) {
                        wgSub.workgroup = wgSub.workgroups[0];
                        delete wgSub.workgroups;
                        user.workgroups.push(wgSub);
                        user.workgroups[
                            user.workgroups.length - 1
                        ].subscribe = { id: wgSub.id };
                    }
                });

                this.setState({
                    subscriptionsSelected: subscriptionsSelected,
                    user: user,
                    loadingUser: false,
                });
            })
            .catch((err) => {
                if (!this._isMounted) return;
                if (err.status && err.status >= 500) {
                    this.setState({
                        httpError: true,
                    });
                }
            });

        // Get companies for user profile
        ApiCompanies.getAll()
            .then((companies) => {
                if (!this._isMounted) return;

                companies.sort((a, b) => {
                    return a.name > b.name ? 1 : -1;
                });

                this.setState({ companies: companies });
            })
            .catch((err) => {
                if (!this._isMounted) return;
                if (err.status && err.status >= 500) {
                    this.setState({
                        httpError: true,
                    });
                }
            });

        // Get categories for alert subscription
        let categories = Thematics.getLocalThematics(
            THEMATIC_TYPE_DOCUMENTATION,
            true
        );
        categories = categories.filter(
            (category) => category.categories.length > 0
        );
        this.setState({ categories: categories, loadingCategories: false });
    }

    componentWillUnmount() {
        this._isMounted = false;
        Http.cancel();
    }

    openEditModal() {
        this.setState({
            showModalEditProfile: true,
            userTemp: {
                profile: {
                    firstname: this.state.user.profile.firstname,
                    lastname: this.state.user.profile.lastname,
                    address: this.state.user.profile.address,
                    job: this.state.user.profile.job,
                    zipcode: this.state.user.profile.zipcode,
                    phone: this.state.user.profile.phone,
                    city: this.state.user.profile.city,
                    mobile: this.state.user.profile.mobile,
                    company: this.state.user.profile.company,
                },
            },
        });
    }

    handleTabs(tabState) {
        this.setState(
            {
                tabProfileActive: false,
                tabNotificationActive: false,
                tabAlertActive: false,
            },
            () => {
                this.setState({
                    [tabState]: true,
                });
            }
        );
    }

    handleEditProfile(e) {
        const name = e.target.name;
        const value = e.target.value;

        this.setState((state) => ({
            userTemp: {
                profile: {
                    ...state.userTemp.profile,
                    [name]: value,
                },
            },
        }));
    }

    handleEditCompany(e) {
        const VALUE = e.target.value;
        const TEXT =
            e.nativeEvent.target[e.nativeEvent.target.selectedIndex].text;

        this.setState((state) => ({
            userTemp: {
                profile: {
                    ...state.userTemp.profile,
                    company: {
                        id: +VALUE,
                        name: TEXT,
                    },
                },
            },
        }));
    }

    cancelEditProfile() {
        this.setState({
            userTemp: null,
            showModalEditProfile: false,
            formError: "",
        });
    }

    cancelEditPassword() {
        this.setState({
            showModalEditPassword: false,
            password: "",
            password_confirm: "",
            password_error: "",
            password_error_confirm: "",
            formError: "",
        });
    }

    validEditProfile() {
        // Clean error
        this.setState({
            error: {
                firstname: "",
                lastname: "",
                job: "",
                company: "",
            },
        });

        let hasFormError = false;
        const PROFILE = this.state.userTemp.profile;
        const ERRORS = {
            firstname: PROFILE.firstname === "" ? "Le prénom est requis." : "",
            lastname: PROFILE.lastname === "" ? "Le nom est requis." : "",
            job: PROFILE.job === "" ? "La fonction est requise." : "",
            id: PROFILE.company.id === 0 ? "La société est requise." : "",
        };

        // Check form error
        Object.values(ERRORS).forEach((value) => {
            if (value !== "") hasFormError = true;
        });

        if (hasFormError) {
            this.setState({ error: ERRORS });
        } else {
            this.setState({ loadingForm: true });

            ApiProfile.update(this.state.user.profile.id, {
                ...PROFILE,
                company: PROFILE.company.id,
            })
                .then((payload) => {
                    if (!this._isMounted) return;
                    Auth.updateUser({
                        first_name: PROFILE.firstname,
                        last_name: PROFILE.lastname,
                    });
                    this.setState((state) => ({
                        user: {
                            ...state.user,
                            profile: {
                                ...state.user.profile,
                                ...state.userTemp.profile,
                            },
                        },
                        showModalEditProfile: false,
                    }));
                })
                .catch(() => {
                    if (!this._isMounted) return;
                    this.setState({
                        formError: "Une erreur est survenue.",
                    });
                })
                .finally(() => {
                    this.setState({ loadingForm: false });
                });
        }
    }

    validEditPassword(password, passwordConfirm) {
        if (password && password === passwordConfirm) {
            // Password must have between 8 and 20 characters, including a capital letter, a lowercase, a number and a special character.
            const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,20}$/;

            this.setState({
                formError: "",
                password_error: "",
                password_error_confirm: "",
            });

            // If regex not match
            if (regex.exec(password) === null) {
                this.setState({
                    password_error: true,
                    password_error_confirm: true,
                    formError:
                        "Le mot de passe doit comporter entre 8 et 20 caractères, dont une majuscule, une minuscule, un chiffre et un caractère spécial (@$!%*?&).",
                });
            } else {
                this.setState({ loadingForm: true });

                ApiUser.updatePassword(this.state.user.id, password)
                    .then(() => {
                        if (!this._isMounted) return;
                        this.setState({ showModalEditPassword: false });
                    })
                    .catch(() => {
                        if (!this._isMounted) return;
                        this.setState({
                            formError: "Une erreur est survenue.",
                        });
                    })
                    .finally(() => {
                        this.setState({ loadingForm: false });
                    });
            }
        } else {
            this.setState({
                password_error: password
                    ? "Les mots de passe ne sont pas identiques"
                    : "Champs obligatoire",
                password_error_confirm: passwordConfirm
                    ? "Les mots de passe ne sont pas identiques"
                    : "Champs obligatoire",
            });
        }
    }

    onChangeEmailSubscription(value) {
        Http.cancel();
        this.setState(
            (state) => ({
                user: {
                    ...state.user,
                    profile: {
                        ...state.user.profile,
                        emailsubscription: value,
                    },
                },
            }),
            () => {
                ApiProfile.updateEmailSubscription(
                    this.state.user.profile.id,
                    value
                );
            }
        );
    }

    onChangeNewsSubscription(value) {
        Http.cancel();
        this.setState(
            (state) => ({
                user: {
                    ...state.user,
                    profile: {
                        ...state.user.profile,
                        newssubscription: value,
                    },
                },
            }),
            () => {
                ApiProfile.updateNewsSubscription(
                    this.state.user.profile.id,
                    value
                );
            }
        );
    }

    onAddAlertSubscription(category) {
        this._subscriptionEdited[category.id] = {
            category: category,
            state: "add",
        };
    }

    onRemoveAlertSubscription(category) {
        this._subscriptionEdited[category.id] = {
            category: category,
            state: "remove",
        };
    }

    onSaveAlertSubscription() {
        if (Object.keys(this._subscriptionEdited).length === 0) return;

        this.setState({ loadingButtonSubscription: true });

        let requests = [];

        for (let categoryId in this._subscriptionEdited) {
            if (this._subscriptionEdited[categoryId].state === "add") {
                const CATEGORY = this._subscriptionEdited[categoryId].category;
                requests.push(
                    ApiUser.addSubscription({ categories: CATEGORY.id })
                );
                this._tempCategories.push(
                    this._subscriptionEdited[categoryId].category
                );
            } else {
                requests.push(
                    ApiUser.deleteSubscription(
                        this._subscriptionEdited[categoryId].category
                            .subscriptionId
                    )
                );
                this._tempCategories.push(null);
            }

            delete this._subscriptionEdited[categoryId];
        }

        Promise.all(requests)
            .then((payload) => {
                if (!this._isMounted) return;
                let categories = this.state.categories;

                payload.forEach((item, i) => {
                    if (item.id) {
                        categories.forEach((thematic) => {
                            // Parse all categories for set new subscriptionId
                            this.parseCategoriesAndSetSubscription(
                                thematic.categories,
                                this._tempCategories[i],
                                item.id
                            );
                        });
                    }
                });
                this._tempCategories = [];
                this.setState({ categories: categories });
            })
            .finally(() => {
                this.setState({ loadingButtonSubscription: false });
            });
    }

    parseCategoriesAndSetSubscription(
        categories,
        categoryToUpdate,
        subscriptionId
    ) {
        categories.forEach((category) => {
            if (category.id === categoryToUpdate.id) {
                category.subscriptionId = subscriptionId;
            }

            if (category.categories.length > 0) {
                this.parseCategoriesAndSetSubscription(
                    category.categories,
                    categoryToUpdate,
                    subscriptionId
                );
            }
        });
    }

    addWorkgroupSubscription(id) {
        ApiUser.addSubscription({ workgroups: id }).then((result) => {
            let user = this.state.user;
            for (let i = 0; i < user.workgroups.length; i++) {
                if (user.workgroups[i].workgroup.id === id) {
                    user.workgroups[i].subscribe = result;
                    break;
                }
            }

            this.setState({ user: user });
        });
    }

    disableWorkGroupSubscription(e, i) {
        const TARGET = e.target;
        const SUBSCRIPTION_ID = +TARGET.value;

        this.setState(
            (state) => {
                let subscription = state.user.subscriptions;
                // Remove subscription by index
                subscription.splice(i, 1);

                return {
                    user: {
                        ...state.user,
                        subscription: subscription,
                    },
                };
            },
            () => {
                ApiUser.deleteSubscription(SUBSCRIPTION_ID).then(() => {
                    let user = this.state.user;
                    for (let j = 0; j < user.workgroups.length; j++) {
                        if (
                            user.workgroups[j].subscribe &&
                            user.workgroups[j].subscribe.id === SUBSCRIPTION_ID
                        ) {
                            user.workgroups[j].subscribe = false;
                            break;
                        }
                    }

                    this.setState({ user: user });
                });
            }
        );
    }

    render() {
        const USER = Auth.getUser();

        return (
            <Master
                id={"profile"}
                httpError={this.state.httpError}
                breadCrumb={[{ label: "Profil" }]}
                {...this.props}
            >
                {/* Banner */}
                <Banner size={"small"} backgroundColor={"#C6C6C6"}>
                    <div className="summaryProfile">
                        <div className={"picture"}>
                            <span>{USER.first_name.substr(0, 1)}</span>
                            <span>{USER.last_name.substr(0, 1)}</span>
                        </div>
                        <div className={"text"}>
                            <div className={"name"}>
                                <span>
                                    {USER.first_name.toLocaleLowerCase()}{" "}
                                    {USER.last_name.toLocaleLowerCase()}
                                </span>
                            </div>
                            <div className={"email"}>
                                <span>{USER.email}</span>
                            </div>
                            <button
                                type={"button"}
                                className={"btn white"}
                                onClick={() =>
                                    this.setState({
                                        showModalEditPassword: true,
                                    })
                                }
                            >
                                Modifier le mot de passe
                            </button>
                        </div>
                    </div>
                </Banner>

                <div className="container groupTabsContainer">
                    <div className="groupTabs">
                        <button
                            type={"button"}
                            className={
                                this.state.tabProfileActive ? "active" : null
                            }
                            onClick={() => this.handleTabs("tabProfileActive")}
                        >
                            Gérer mon profil
                        </button>
                        <button
                            type={"button"}
                            className={
                                this.state.tabNotificationActive
                                    ? "active"
                                    : null
                            }
                            onClick={() =>
                                this.handleTabs("tabNotificationActive")
                            }
                        >
                            Gérer mes notifications
                        </button>
                        <button
                            type={"button"}
                            className={
                                this.state.tabAlertActive ? "active" : null
                            }
                            onClick={() => this.handleTabs("tabAlertActive")}
                        >
                            Gérer mes alertes
                        </button>
                        {Auth._isAtLeastRole(Roles.ROLE_DEVELOPER.uid) && (
                            <button
                                type={"button"}
                                onClick={() => ApiUser.clearCache()}
                            >
                                Administration: Vider le cache
                            </button>
                        )}
                    </div>
                </div>

                <div className="container">
                    <div className="panelTabs">
                        {/* Profile */}
                        {this.state.tabProfileActive && (
                            <div>
                                {this.state.loadingUser ? (
                                    <Loader type={"inline"} />
                                ) : (
                                    <>
                                        <div
                                            className={
                                                "headProfile align-items-center"
                                            }
                                        >
                                            <h3 className={"titleSetting"}>
                                                Mes informations personnelles
                                            </h3>
                                            <ToolsManage
                                                role={Roles.ROLE_USER}
                                                add={false}
                                                delete={false}
                                                disableDeleteMode={true}
                                                disableToggle={true}
                                                onEdit={() =>
                                                    this.openEditModal()
                                                }
                                            />
                                        </div>
                                        <ul className={"listInfo"}>
                                            <li>
                                                <span>
                                                    {
                                                        this.state.user.profile
                                                            .company.name
                                                    }
                                                </span>
                                            </li>
                                            <li>
                                                <span>
                                                    {
                                                        this.state.user.profile
                                                            .job
                                                    }
                                                </span>
                                            </li>
                                            <li>
                                                <span>
                                                    {
                                                        this.state.user.profile
                                                            .address
                                                    }
                                                </span>
                                            </li>
                                            <li>
                                                <span>
                                                    {
                                                        this.state.user.profile
                                                            .city
                                                    }
                                                </span>
                                            </li>
                                            <li>
                                                <span>
                                                    {
                                                        this.state.user.profile
                                                            .zipcode
                                                    }
                                                </span>
                                            </li>
                                            <li>
                                                <span>
                                                    {
                                                        this.state.user.profile
                                                            .phone
                                                    }
                                                </span>
                                            </li>
                                            <li>
                                                <span>
                                                    {
                                                        this.state.user.profile
                                                            .mobile
                                                    }
                                                </span>
                                            </li>
                                        </ul>

                                        <br />

                                        <h3 className={"titleSetting"}>
                                            Mes groupes de travail
                                        </h3>

                                        <ListRoleLegend />

                                        <ul className={"listWorkGroup"}>
                                            {this.state.user.workgroups ? (
                                                this.state.user.workgroups.map(
                                                    (workGroup, i) => {
                                                        let className = "grey";
                                                        if (
                                                            workGroup.membertype &&
                                                            workGroup.workgroup
                                                        ) {
                                                            if (
                                                                +workGroup.membertype ===
                                                                MEMBER_TYPE_ANIMATOR
                                                            ) {
                                                                className =
                                                                    "green";
                                                            } else if (
                                                                +workGroup.membertype ===
                                                                MEMBER_TYPE_ADMINISTRATOR
                                                            ) {
                                                                className =
                                                                    "blue";
                                                            }
                                                            return (
                                                                <li
                                                                    key={i}
                                                                    className={
                                                                        className
                                                                    }
                                                                >
                                                                    <span>
                                                                        {
                                                                            workGroup
                                                                                .workgroup
                                                                                .name
                                                                        }
                                                                    </span>
                                                                </li>
                                                            );
                                                        } else {
                                                            return null;
                                                        }
                                                    }
                                                )
                                            ) : (
                                                <ul>
                                                    <li
                                                        className={
                                                            "noWorkGroup"
                                                        }
                                                    >
                                                        <em>
                                                            Aucun groupe de
                                                            travail
                                                        </em>
                                                    </li>
                                                </ul>
                                            )}
                                        </ul>
                                    </>
                                )}
                            </div>
                        )}

                        {/* Notifications */}
                        {this.state.tabNotificationActive && (
                            <div>
                                {this.state.loadingUser ? (
                                    <Loader type={"inline"} />
                                ) : (
                                    <>
                                        <h3 className={"titleSetting"}>
                                            Gérer mes notifications :
                                        </h3>
                                        {/*<p className={"titleSetting"}>
                                            Vous pouvez consulter toutes les
                                            notifications liées à vos
                                            abonnements sur votre page
                                            d’accueil.
                                        </p>
                                        <p className={"titleSetting"}>
                                            Souhaitez-vous également recevoir
                                            ces notifications par email&thinsp;?
                                            (une fois par semaine)&thinsp;?
                                        </p>*/}
                                        <p className={"titleSetting"}>
                                            Recevoir les
                                            notifications liées à vos
                                            abonnements par email&thinsp;?
                                            (une fois par semaine)&thinsp;?
                                        </p>
                                        <InputRadioInline
                                            onChange={(value) =>
                                                this.onChangeEmailSubscription(
                                                    value
                                                )
                                            }
                                            name={"emailSubscription"}
                                            data={[
                                                {
                                                    id:
                                                        "emailSubscriptionYes",
                                                    label: "Oui",
                                                    value: "1",
                                                    checked:
                                                        +this.state.user.profile
                                                            .emailsubscription ===
                                                        1,
                                                },
                                                {
                                                    id: "emailSubscriptionNo",
                                                    label: "Non",
                                                    value: "0",
                                                    checked:
                                                        +this.state.user.profile
                                                            .emailsubscription ===
                                                        0,
                                                },
                                            ]}
                                        />
                                        <br />
                                        <br />
                                        <p className={"titleSetting"}>
                                            Vous souhaitez recevoir une notification par email dès qu’une actualité est publiée&thinsp;?
                                        </p>
                                        <InputRadioInline
                                            onChange={(value) =>
                                                this.onChangeNewsSubscription(
                                                    value
                                                )
                                            }
                                            name={"newsSubscription"}
                                            data={[
                                                {
                                                    id:
                                                        "newsSubscriptionYes",
                                                    label: "Oui",
                                                    value: "1",
                                                    checked:
                                                        +this.state.user.profile
                                                            .newssubscription ===
                                                        1,
                                                },
                                                {
                                                    id: "newsSubscriptionNo",
                                                    label: "Non",
                                                    value: "0",
                                                    checked:
                                                        +this.state.user.profile
                                                            .newssubscription ===
                                                        0,
                                                },
                                            ]}
                                        />
                                        <br />
                                        <br />
                                    </>
                                )}
                            </div>
                        )}

                        {/* Alert */}
                        {this.state.tabAlertActive && (
                            <div>
                                {this.state.loadingCategories ||
                                this.state.loadingUser ? (
                                    <Loader type={"inline"} />
                                ) : (
                                    <>
                                        <h3 className={"titleSetting"}>
                                            Mes alertes groupes de travail
                                        </h3>
                                        <div className={"editAlertForm"}>
                                            <table>
                                                <tbody>
                                                    {this.state.user.workgroups.map(
                                                        (workgroup, i) => {
                                                            if (
                                                                workgroup.workgroup
                                                            ) {
                                                                return (
                                                                    <tr key={i}>
                                                                        <td>
                                                                            <span>
                                                                                {
                                                                                    workgroup
                                                                                        .workgroup
                                                                                        .name
                                                                                }
                                                                            </span>
                                                                        </td>
                                                                        <td>
                                                                            <button
                                                                                type={
                                                                                    "button"
                                                                                }
                                                                                value={
                                                                                    workgroup.subscribe
                                                                                        ? workgroup
                                                                                              .subscribe
                                                                                              .id
                                                                                        : workgroup
                                                                                              .workgroup
                                                                                              .id
                                                                                }
                                                                                onClick={(
                                                                                    e
                                                                                ) =>
                                                                                    workgroup.subscribe
                                                                                        ? this.disableWorkGroupSubscription(
                                                                                              e,
                                                                                              i
                                                                                          )
                                                                                        : this.addWorkgroupSubscription(
                                                                                              workgroup
                                                                                                  .workgroup
                                                                                                  .id
                                                                                          )
                                                                                }
                                                                            >
                                                                                {workgroup.subscribe
                                                                                    ? "Désactiver"
                                                                                    : "Activer"}
                                                                            </button>
                                                                        </td>
                                                                    </tr>
                                                                );
                                                            } else {
                                                                return null;
                                                            }
                                                        }
                                                    )}
                                                </tbody>
                                            </table>
                                        </div>
                                        <h3 className={"titleSetting"}>
                                            Mes alertes thématique ou
                                            sous-catégorie
                                        </h3>

                                        <SelectCategories
                                            title={
                                                "Sélectionnez les thématiques"
                                            }
                                            multiple={true}
                                            selectAllButton={true}
                                            selectAllChildren={true}
                                            categories={this.state.categories}
                                            selected={
                                                this.state.subscriptionsSelected
                                            }
                                            onAdd={(category) =>
                                                this.onAddAlertSubscription(
                                                    category
                                                )
                                            }
                                            onRemove={(category) =>
                                                this.onRemoveAlertSubscription(
                                                    category
                                                )
                                            }
                                            onSave={(items) =>
                                                this.onSaveAlertSubscription(
                                                    items
                                                )
                                            }
                                            loadingSave={
                                                this.state
                                                    .loadingButtonSubscription
                                            }
                                        />
                                    </>
                                )}
                            </div>
                        )}
                    </div>
                </div>

                {/* Modal edit profile */}
                {this.state.showModalEditProfile && (
                    <Modal
                        onClose={() =>
                            this.setState({ showModalEditProfile: false })
                        }
                    >
                        <div className="headModal">
                            <h3 className={"titleModal"}>
                                Editer mes informations personnelles
                            </h3>
                        </div>

                        <hr />

                        <div className="coreModal">
                            <div className="row">
                                <div className="col-md-6">
                                    <InputTextLight
                                        label={"Prénom*"}
                                        placeholder={"Prénom"}
                                        value={
                                            this.state.userTemp.profile
                                                .firstname
                                        }
                                        name={"firstname"}
                                        errorMsg={this.state.error.firstname}
                                        onChange={(e) =>
                                            this.handleEditProfile(e)
                                        }
                                    />
                                </div>
                                <div className="col-md-6">
                                    <InputTextLight
                                        label={"Nom*"}
                                        placeholder={"Nom"}
                                        value={
                                            this.state.userTemp.profile.lastname
                                        }
                                        name={"lastname"}
                                        errorMsg={this.state.error.lastname}
                                        onChange={(e) =>
                                            this.handleEditProfile(e)
                                        }
                                    />
                                </div>
                                <div className="col-md-6">
                                    <Select
                                        label={"Société"}
                                        placeholder={"Sélectionner la société"}
                                        required={true}
                                        value={this.state.companies}
                                        selected={
                                            this.state.userTemp.profile.company
                                                .id
                                        }
                                        errorMsg={this.state.error.company}
                                        onChange={(e) =>
                                            this.handleEditCompany(e)
                                        }
                                    />
                                </div>
                                <div className="col-md-6">
                                    <InputTextLight
                                        label={"Adresse postale"}
                                        placeholder={"Adresse postale"}
                                        autoComplete={"off"}
                                        value={
                                            this.state.userTemp.profile.address
                                        }
                                        name={"address"}
                                        onChange={(e) =>
                                            this.handleEditProfile(e)
                                        }
                                    />
                                </div>
                                <div className="col-md-6">
                                    <InputTextLight
                                        label={"Fonction*"}
                                        placeholder={"Fonction"}
                                        autoComplete={"off"}
                                        value={this.state.userTemp.profile.job}
                                        name={"job"}
                                        errorMsg={this.state.error.job}
                                        onChange={(e) =>
                                            this.handleEditProfile(e)
                                        }
                                    />
                                </div>
                                <div className="col-md-6">
                                    <InputTextLight
                                        label={"Code postal"}
                                        placeholder={"Code postal"}
                                        autoComplete={"off"}
                                        value={
                                            this.state.userTemp.profile.zipcode
                                        }
                                        name={"zipcode"}
                                        onChange={(e) =>
                                            this.handleEditProfile(e)
                                        }
                                    />
                                </div>
                                <div className="col-md-6">
                                    <InputTextLight
                                        label={"Téléphone fixe"}
                                        placeholder={"Téléphone fixe"}
                                        autoComplete={"off"}
                                        value={
                                            this.state.userTemp.profile.phone
                                        }
                                        name={"phone"}
                                        onChange={(e) =>
                                            this.handleEditProfile(e)
                                        }
                                    />
                                </div>
                                <div className="col-md-6">
                                    <InputTextLight
                                        label={"Ville"}
                                        placeholder={"Ville"}
                                        autoComplete={"off"}
                                        value={this.state.userTemp.profile.city}
                                        name={"city"}
                                        onChange={(e) =>
                                            this.handleEditProfile(e)
                                        }
                                    />
                                </div>
                                <div className="col-md-6">
                                    <InputTextLight
                                        label={"Téléphone mobile"}
                                        placeholder={"Téléphone mobile"}
                                        autoComplete={"off"}
                                        value={
                                            this.state.userTemp.profile.mobile
                                        }
                                        name={"mobile"}
                                        onChange={(e) =>
                                            this.handleEditProfile(e)
                                        }
                                    />
                                </div>
                            </div>

                            <p className={"required"}>
                                <b>* Champs obligatoires</b>
                            </p>

                            {/* Error */}
                            {this.state.formError && (
                                <p
                                    className={"centerText"}
                                    style={{ color: "red" }}
                                >
                                    {this.state.formError || this.props.error}
                                </p>
                            )}

                            <div className={"buttonModal"}>
                                <button
                                    type={"button"}
                                    className={"cancel"}
                                    onClick={() => this.cancelEditProfile()}
                                >
                                    Annuler
                                </button>
                                <button
                                    type={"button"}
                                    onClick={() =>
                                        this.validEditProfile(this.state.user)
                                    }
                                >
                                    {this.state.loadingForm ? (
                                        <Loader
                                            type={"button"}
                                            backgroundColor={"transparent"}
                                        />
                                    ) : (
                                        "Valider"
                                    )}
                                </button>
                            </div>
                        </div>
                    </Modal>
                )}

                {/* Modal edit password */}
                {this.state.showModalEditPassword && (
                    <Modal
                        autoHeight={true}
                        onClose={() => this.cancelEditPassword()}
                    >
                        <div className="headModal">
                            <h3 className={"titleModal"}>
                                Editer votre nouveau mot de passe
                            </h3>
                        </div>

                        <hr />

                        <div className="coreModal">
                            {/* Info */}
                            <div className="alertPrimary" role="alert">
                                Le mot de passe doit avoir entre 8 et 20
                                caractères incluant une majuscule, une
                                minuscule, un chiffre et un caractère spécial
                                (@$!%*?&).
                            </div>

                            <form
                                onSubmit={(e) => {
                                    e.preventDefault();
                                    this.validEditPassword(
                                        this.state.password,
                                        this.state.password_confirm
                                    );
                                }}
                            >
                                <div className="row">
                                    <div className="col-md-6">
                                        <InputTextLight
                                            label={"Nouveau mot de passe*"}
                                            autoComplete={"off"}
                                            name={"password"}
                                            type={"password"}
                                            errorMsg={this.state.password_error}
                                            value={this.state.password}
                                            onChange={(e) =>
                                                this.setState({
                                                    password: e.target.value,
                                                })
                                            }
                                        />
                                    </div>
                                    <div className="col-md-6">
                                        <InputTextLight
                                            label={
                                                "Confirmer le nouveau mot de passe*"
                                            }
                                            autoComplete={"off"}
                                            name={"password_confirm"}
                                            type={"password"}
                                            errorMsg={
                                                this.state
                                                    .password_error_confirm
                                            }
                                            value={this.state.password_confirm}
                                            onChange={(e) =>
                                                this.setState({
                                                    password_confirm:
                                                        e.target.value,
                                                })
                                            }
                                        />
                                    </div>

                                    <Button
                                        type={"submit"}
                                        style={{ display: "none" }}
                                    >
                                        Envoyer
                                    </Button>
                                </div>
                            </form>

                            <p className={"required"}>
                                <b>* Champs obligatoires</b>
                            </p>

                            {/* Error */}
                            {this.state.formError && (
                                <p
                                    className={"centerText"}
                                    style={{ color: "red" }}
                                >
                                    {this.state.formError || this.props.error}
                                </p>
                            )}

                            <div className={"buttonModal"}>
                                <button
                                    type={"button"}
                                    className={"cancel"}
                                    onClick={() => this.cancelEditPassword()}
                                >
                                    Annuler
                                </button>
                                <button
                                    type={"button"}
                                    onClick={() =>
                                        this.validEditPassword(
                                            this.state.password,
                                            this.state.password_confirm
                                        )
                                    }
                                >
                                    {this.state.loadingForm ? (
                                        <Loader
                                            type={"button"}
                                            backgroundColor={"transparent"}
                                        />
                                    ) : (
                                        "Valider"
                                    )}
                                </button>
                            </div>
                        </div>
                    </Modal>
                )}
            </Master>
        );
    }
}
