import React from 'react';
import PropTypes from "prop-types";
import "./CheckBoxSlider.scss";


export default class CheckBoxSlider extends React.Component {

    static propTypes = {
        id: PropTypes.string,
        name: PropTypes.string,
        labelLeft: PropTypes.string,
        labelRight: PropTypes.string,
        checked: PropTypes.bool,
        onChange: PropTypes.func
    };

    static defaultProps = {
        onChange: () => {}
    };

    render() {
        return (
            <div className={'checkBoxSlider'}>
                <input type={'checkbox'}
                       id={this.props.id}
                       name={this.props.name}
                       checked={this.props.checked}
                       onChange={(e) => this.props.onChange(e)}
                />
                <label htmlFor={this.props.id}>
                    {this.props.labelLeft && <span className={'labelLeft'}>{this.props.labelLeft}</span>}
                    <span className={'box'}/>
                    {this.props.labelRight && <span className={'labelRight'}>{this.props.labelRight}</span>}
                </label>
            </div>
        )
    }
}