import ApiThematics, {THEMATIC_TYPE_COMMON, THEMATIC_TYPE_DOCUMENTATION, THEMATIC_TYPE_WORKGROUP} from "./ApiThematics";
import {path} from "../Config/routes";
import Thematics, {sortCategories} from "../Utils/Thematics";
import Auth from "../Security/Auth";

export default {

    /**
     * Get menu
     * @returns {Promise<any>}
     */
    getMenu: () => {
        return new Promise((resolve) => {
            ApiThematics.getAll(['categories.categories.categories.categories.categories.categories,categories.thematic,workgroups']).then(thematics => {
                let treeForDocumentation = [];
                let treeForWorkGroups    = [];

                thematics.forEach(thematic => {
                    if (thematic.type === THEMATIC_TYPE_COMMON || thematic.type === THEMATIC_TYPE_DOCUMENTATION) {
                        treeForDocumentation.push(thematic);
                    }
                    if (thematic.type === THEMATIC_TYPE_COMMON || thematic.type === THEMATIC_TYPE_WORKGROUP) {
                        treeForWorkGroups.push(thematic);
                    }
                });


                // Check if the user has at least one category
                let docHasCategory = false;
                for (let i = 0; i < treeForDocumentation.length; i++) {
                    if (treeForDocumentation[i].categories.length > 0) {
                        docHasCategory = true;
                        break;
                    }
                }

                let workGroupHasCategory = false;
                for (let i = 0; i < treeForWorkGroups.length; i++) {
                    if (treeForWorkGroups[i].workgroups.length > 0) {
                        workGroupHasCategory = true;
                        break;
                    }
                }

                let menu    = [{
                    'name': 'Mon tableau de bord',
                    'link': 'home',
                    'exact': true,
                }];
                let menuDoc = [];

                if (docHasCategory) {
                    menuDoc = generateMenuDocumentation(treeForDocumentation);

                    if (menuDoc.length !== 0) {
                        menu.push({
                            'name': 'Documentation',
                            'link': 'documentationList',
                            'menu': menuDoc,
                        })
                    }
                }

                if (workGroupHasCategory) {
                    const MENU_WORKGROUP = generateMenuWorkGroup(treeForWorkGroups);

                    if (MENU_WORKGROUP.length !== 0) {
                        menu.push({
                            'name': 'Groupes de travail',
                            'link': 'WorkGroupList',
                            'menu': MENU_WORKGROUP,
                        })
                    }
                }

                menu.push(
                    {
                        'name': 'Actualités',
                        'link': 'news',
                    },
                    {
                        'name': 'Agenda',
                        'link': 'agenda',
                    });

                resolve(menu);

                // Let's store thematics in local storage
                let localThematics = {};
                for (let i = 0; i < treeForDocumentation.length; i++) {
                    localThematics[treeForDocumentation[i]['id']] = treeForDocumentation[i];
                }

                for (let i = 0; i < treeForWorkGroups.length; i++) {
                    // Be sure to net erase the documentation categories. We need to keep it because there is all nested categories inside
                    if (!localThematics.hasOwnProperty(treeForWorkGroups[i]['id'])) {
                        // Here we are sure it's a workGroup thematic not a documentation one
                        localThematics[treeForWorkGroups[i]['id']] = treeForWorkGroups[i];
                        // Remove the category because this thematic do not need
                        if (localThematics[treeForWorkGroups[i]['id']].hasOwnProperty('categories')) {
                            localThematics[treeForWorkGroups[i]['id']].categories = [];
                        }
                    } else {
                        localThematics[treeForWorkGroups[i]['id']].workgroups = treeForWorkGroups[i].workgroups
                    }
                }

                // Graphene doesn't return it like a real tree, with all categories and their subcategories etc... we need to build it
                Object.keys(localThematics).forEach((key) => {
                    if (localThematics[key].hasOwnProperty('categories')) {
                        localThematics[key].categories = sortCategories(parseCategoryTree(localThematics[key].categories));
                    }
                });

                Thematics.storeThematicsLocally(localThematics);
            });
        });
    },

    /**
     * Get admin menu
     * @returns {Promise<any>}
     */
    getMenuAdmin: () => {
        return new Promise((resolve) => {
            const MENU = [{
                name: 'Utilisateurs',
                link: 'adminUser'
            },
                {
                    name: 'Documentation',
                    link: 'adminDocumentation',
                    menu: [
                        {
                            name: 'Gestion des documents',
                            link: '/admin/documentation',
                        },
                        {
                            name: 'Gestion de l\'arborescence',
                            link: '/admin/documentation-gestion-de-l-arborescence',
                        }
                    ]
                }];

            if (Auth.isAdminAtLeast()) {
                MENU.push({
                    name: 'Actualités',
                    link: 'adminNews'
                })
            }

            resolve(MENU);
        });
    }
}

function generateMenuDocumentation(thematics) {
    let menu = [];
    thematics.forEach((thematic) => {
        if (thematic.categories) {
            const CHILD = parseCategoryTreeForMenu(thematic.categories, thematic.color);
            if (CHILD.length > 0) {
                let menuItem = {
                    color: thematic.color,
                    id: thematic.id,
                    name: thematic.name,
                    uid: thematic.uid,
                    menu: CHILD,
                    link: '#',
                    isDocumentation: true,
                    isArchived: !!thematic.archivedat,
                };
                if (menuItem.menu.length === 0) {
                    delete menuItem.menu
                }
                menu.push(menuItem);
            }
        }
    });

    return menu;
}

function generateMenuWorkGroup(thematics) {
    let menu = [];
    
    thematics.forEach((thematic) => {
        const THEMATIC_WORKGROUP = thematic.workgroups.filter((wg) => !wg.archivedat);
        let workGroups           = [];
        let specifics            = [];
        let standby              = [];
        let committee            = null;

        THEMATIC_WORKGROUP.forEach((workGroup) => {
            const menuWorkGroup = {
                id: workGroup.id,
                link: path('workGroups', {id: workGroup.id}),
                name: workGroup.name
            };

            if (workGroup.status === 'ACTIVE') {
                workGroups.push(menuWorkGroup);
            } else if (workGroup.status === 'SPECIFIC') {
                specifics.push(menuWorkGroup);
            } else if (workGroup.status === 'STANDBY') {
                standby.push(menuWorkGroup);
            } else {
                committee = menuWorkGroup;
            }
        });

        if (committee !== null) {
            workGroups.unshift(committee);
        }

        if (specifics.length > 0) {
            workGroups.push({
                menu: specifics,
                link: '#',
                name: 'Groupes spécifiques',
                color: thematic.color,
            });
        }

        if (standby.length > 0) {
            workGroups.push({
                menu: standby,
                link: '#',
                name: 'Groupes en veille',
                color: thematic.color,
            });
        }

        if (workGroups.length > 0) {
            let menuItem = {
                color: thematic.color,
                id: thematic.id,
                name: thematic.name,
                uid: thematic.uid,
                menu: workGroups,
                link: '#'
            };

            if (menuItem.menu.length === 0) {
                delete menuItem.menu
            }

            menu.push(menuItem);
        }
    });

    return menu;
}

function parseCategoryTreeForMenu(categories, color, parentId = 0) {
    // We need to get the index of the global categories containing a category id, so we extract all columns ['id']
    let categoriesId = [];

    categories.forEach((category) => {
        categoriesId.push(category.id);
    });

    let branch = [];

    categories.forEach((category) => {

        if (category.parentid === parentId) {
            let children = parseCategoryTreeForMenu(categories, color, category.id);
            // We need to reverse the order since we are parsing link from depth to beginning
            let link = getCategoryLink(category, categoriesId, categories).reverse();

            // We need leading slash, so add a first empty part, then documentation, then the thematic to the link
            link = '/documentation/' + link.join('/');

            category.link  = link;
            category.color = color;

            if (children.length > 0) {
                category.menu = children;
            }

            branch.push(category);
        }
    });

    return branch;
}

function parseCategoryTree(categories, parentId = 0) {
    let branch = [];

    Object.keys(categories).forEach((key) => {
        let category = categories[key];

        if (category.parentid === parentId) {
            let children = parseCategoryTree(categories, category.id);

            if (children.length > 0) {
                category.categories = children;
                category.categories = sortCategories(children);
            }

            branch.push(category);
        }
    });

    return branch;
}

function getCategoryLink(category, categoriesId, categoriesOrderById, link = []) {
    link.push(category.uid);
    if (category.parentid !== undefined) {
        // Search in which categoriesId key if the parent stored-in
        let key = categoriesId.indexOf(category.parentid);
        if (key !== -1) {
            getCategoryLink(categoriesOrderById[key], categoriesId, categoriesOrderById, link);
        }
    }

    if (category.thematic && link.indexOf(category.thematic.uid) === -1) {
        link.push(category.thematic.uid);
    }

    return link;
}
