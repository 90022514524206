import React from 'react';
import ApiNews from "../../Services/ApiNews";
import Master from "../../Components/Layout/Master";
import Banner from "../../Components/Banner/Banner";
import PreviewNews from "../../Components/Layout/PreviewNews";
import Loader from "../../Components/Loader/Loader";
import "./ListNews.scss";
import {Http, QueryBuilder} from "../../Utils/Api";
import Pagination from "../../Components/Pagination/Pagination";
import * as qs from "qs";


export default class ListNews extends React.Component {

    state = {
        news: [],
        single: {},
        nbResults: 0,
        results: [],
        selects: [],
        isSearch: true,
        searchText: '',
        loading: true,
        httpError: false,
        searchLoading: false
    };

    constructor(props) {
        super(props);
        this._isMounted = false;
        this.id         = null;
        this.searchText = '';
        this._QB        = new QueryBuilder()
            .setSort('published_at')
            .setOrder('desc')
            .setNestedEntities(['visual,files,author'])
            .setPage(1)
            .setLimit(8);

        if (this.props.match.params.id !== undefined) {
            this.id = this.props.match.params.id;
        }
    }

    componentDidMount() {
        this._isMounted = true;
        const PARAMS    = qs.parse(this.props.location.search, {ignoreQueryPrefix: true});

        const PAGE = PARAMS.page || 1;

        this.getNews(PAGE);
    }

    componentWillUnmount() {
        this._isMounted = false;
        Http.cancel();
    }

    getNews(page = 1) {
        this._QB.setPage(page);

        ApiNews.getNews('visual', this._QB, this.searchText).then((news) => {
            if (!this._isMounted) return;
            this.setState({
                news: news.payload,
                pagination: news.extra,
                loading: false,
                searchLoading: false
            });
        }).catch((err) => {
            if (!this._isMounted) return;
            if (err.status && err.status >= 500) {
                this.setState({
                    httpError: true
                });
            }
        });
    }

    handleSearch(searchText) {
        this.searchText = searchText;
        Pagination.resetPagination();

        if (searchText) {
            this.setState({searchLoading: true});

            this.getNews(1);
        } else {
            this.resetNews();
        }
    }

    resetNews() {
        this.setState((state) => ({news: state.news}));
    }

    render() {
        let news = '';
        if (this.state.news.length > 0) {
            news = this.state.news.map((news, i) => {
                return <div className={'col-md-6'} key={i}>
                    <PreviewNews news={news} left={true}/>
                </div>
            })
        } else {
            news = <div className={"noNews"}>Aucune actualité à afficher</div>;
        }

        return (
            <Master id={'news'} httpError={this.state.httpError} breadCrumb={[{label: 'Actualités'}]} {...this.props}>
                <Banner imgSrc={'/assets/images/background/news.jpg'}
                        title={'Actualités'}
                        search={true}
                        searchLoading={this.state.searchLoading}
                        onSearch={(searchText) => this.handleSearch(searchText)}/>
                <div id="listNews" className={'container'}>

                    {this.state.loading
                        ? <Loader type={"inline"}/>
                        : (
                            <>
                                <div className="row">
                                    {news}
                                </div>
                                {this.state.pagination &&
                                <Pagination
                                    pagination={this.state.pagination}
                                    onClick={async (page) => {
                                        this.setState({loading: true});
                                        this.getNews(page);
                                    }}/>
                                }
                            </>)

                    }

                </div>
            </Master>
        )
    }
}
