import React from 'react';
import './Loader.scss';
import PropTypes from "prop-types";


export default class Loader extends React.Component {

    static propTypes = {
        type: PropTypes.oneOf(['button', 'normal', 'inline']),
        color: PropTypes.oneOf(['white', 'blue']),
        backgroundColor: PropTypes.string
    };

    static defaultProps = {
        type: 'normal',
        color: 'blue',
        backgroundColor: 'white'
    };

    render() {
        const CLASS = 'loader ' + this.props.type + ' ' + this.props.color;

        return (
            <div className={CLASS} style={{backgroundColor: this.props.backgroundColor}}>
                <div className="bar bar-1"/>
                <div className="bar bar-2"/>
                <div className="bar bar-3"/>
                <div className="bar bar-4"/>
                <div className="bar bar-5"/>
                <div className="bar bar-6"/>
                <div className="bar bar-7"/>
            </div>
        )
    }
}