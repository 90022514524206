import React from 'react';
import PropTypes from 'prop-types';
import Moment from 'moment';
import ApiAgenda from "../../Services/ApiAgenda";
import Block from "../Layout/Block";
import {Css} from "../../Utils/Css";
import {String} from "../../Utils/String";
import Button from "../Form/Button/Button";
import {Calendar} from "../../Utils/Calendar";
import {path} from "../../Config/routes";
import {Link} from "react-router-dom";
import './CarouselLastEvent.scss'


export default class CarouselLastEvent extends React.Component {

    static propTypes = {
        date: PropTypes.instanceOf(Date),
        title: PropTypes.string,
        thematicId: PropTypes.number,
        loading: PropTypes.bool,
        onLoaded: PropTypes.func
    };

    static defaultProps = {
        date: new Date(),
        title: 'Agenda',
        thematicId: null,
        onLoaded: () => {}
    };

    state = {
        events: ['', ''], // Keep this: wee need dummy data at start to display nice placeholder during the retrieving of events from API
        disabledSlide: true,
        isNoNextEvent: true,
    };

    currentOffset = 0;

    constructor(props) {
        super(props);
        this._isMounted = false;
        this.list       = React.createRef();
    }

    componentDidMount() {
        this._isMounted = true;
        this.getEvents();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.loading !== this.props.loading && this.props.loading) {
            this.enablePlaceHolderLoading();
        }

        if (prevProps.thematicId !== this.props.thematicId && this.props.thematicId && !this.props.loading) {
            this.enablePlaceHolderLoading();
            this.getEvents();
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    getEvents() {
        if (this.props.loading) {
            this.enablePlaceHolderLoading();
        } else {
            return ApiAgenda.getClosestEventsFromDate(this.props.date, this.currentOffset, 3, this.props.thematicId).then((events) => {
                if (!this._isMounted) return;
                if (!this.props.loading) {
                    let isNoNextEvent;

                    if (events.length === 3) {
                        delete events[2];
                        isNoNextEvent = false;
                    } else {
                        isNoNextEvent = true;
                    }

                    this.props.onLoaded(true);

                    return this.setState({
                        events: events,
                        disabledSlide: false,
                        isNoNextEvent: isNoNextEvent
                    });
                }
            }).catch((err) => {
                if(!this._isMounted) return;
                if (err.status && err.status === 500) {
                    this.props.onLoaded(false);
                }
            });
        }
    }

    enablePlaceHolderLoading() {
        this.setState({events: ['', '']});
    }

    handleChangeSlide(direction) {
        const LIST = this.list.current;

        const LIST_WIDTH       = LIST.offsetWidth;
        const LIST_TRANSLATE_X = Css.getTranslate(LIST)[0];
        let translate;

        this.setState({
                events: ['dummy1', 'dummy2'],
                disabledSlide: true
            }
        );

        if (direction === 'prev') {
            translate          = LIST_TRANSLATE_X - LIST_WIDTH / 3;
            this.currentOffset = this.currentOffset - 2;
        } else {
            translate          = LIST_TRANSLATE_X + LIST_WIDTH / 3;
            this.currentOffset = this.currentOffset + 2;
        }

        // Move carousel left or right
        LIST.style.transform = `translate(${translate}px,0)`;
        const PLACEHOLDERS   = LIST.querySelectorAll('.placeholder');

        // Add empty event animation
        for (let i = 0; i < PLACEHOLDERS.length; i++) {
            PLACEHOLDERS[i].innerHTML = '';
        }

        // When API call is resolved,
        this.getEvents().then(() => {
            if(!this._isMounted) return;
            // Reset position
            setTimeout(() => {
                LIST.classList.add('noTransition');
                LIST.style.transform = `translate(0,0)`;

                // reflow and reset transition
                setTimeout(() => LIST.classList.remove('noTransition'), 100)
            }, 200)
        })
    }

    render() {
        let eventItem    = [];
        const EVENT_LIST = <ul className={'itemGroupListEventCarousel'}>{eventItem}</ul>;

        for (let key in this.state.events) {
            const EVENT = this.state.events[key];

            eventItem.push(<li key={key}>
                <div className={'dateEvent'}>
                    {EVENT.startingat
                        ? <span className={'day'}>{Moment(EVENT.startingat.date).format('D')}</span>
                        : <span> </span>
                    }
                    {EVENT.startingat
                        ? <span className={'month'}>{Moment(EVENT.startingat.date).format('MMM').toUpperCase()}</span>
                        : <span> </span>
                    }
                    {EVENT.startingat
                        ? <span className={'year'}>{Moment(EVENT.startingat.date).format('YYYY')}</span>
                        : <span> </span>
                    }
                </div>
                <div className={'detailsEvents'}>
                    <div className="bubble">
                        <Link className={'titleDescEvent'} to={path('agendaDetail', {id: EVENT.id})}>
                            <div className={'titleEvent placeholder'}>{EVENT.title
                                ? EVENT.title
                                : <div className={'placeholderEmpty title'}/>}
                            </div>
                            <div className={'descEvent placeholder'}>{EVENT.excerpt
                                ? String.excerpt(EVENT.excerpt, 20)
                                : (typeof EVENT.excerpt === 'undefined') ? <div className={'placeholderEmpty paragraph'}/> : ''}
                            </div>
                        </Link>

                        {EVENT.title && <button type={'button'} onClick={() => Calendar.downloadIcs(EVENT)}>
                            <img src="/assets/images/icon/agenda/calendar-more-blue.svg" alt="Ajouter à votre calendrier"/>
                        </button>}
                    </div>
                </div>
            </li>)
        }

        return (
            <Block title={this.props.title} color="green" className='CarouselLastEvent block-agenda' titleColor={'#0449A7'} iconTitleColor={'white'}
                   bigTitleColor={"whiteBold"}>
                {/* Carousel */}
                <div className={'lastEventCarousel'}>
                    <Button className={'btn' + (this.currentOffset <= 0 ? ' disabled' : ' btn-prev')}
                            onClick={() => this.currentOffset > 0 && this.handleChangeSlide('prev')}
                            aria-label="Aller à l'élément précédent" disabled={this.state.disabledSlide}>
                        <span className="arrow"/>
                    </Button>

                    <div className={'containerLastNews'}>
                        {eventItem.length > 0 ?
                            <ul id={'groupListEventCarousel'} ref={this.list}>
                                <li>{EVENT_LIST}</li>
                                <li>{EVENT_LIST}</li>
                                <li>{EVENT_LIST}</li>
                            </ul>
                            :
                            <ul id={'groupListEventCarousel'} ref={this.list}>
                                <li><span className={'no-event'}><em>Aucun événement à venir</em></span></li>
                            </ul>
                        }
                    </div>
                    <Button className={'btn' + (eventItem.length <= 0 || this.state.isNoNextEvent ? ' disabled' : ' btn-next')}
                            onClick={() => eventItem.length > 0 && this.handleChangeSlide('next')}
                            aria-label="Aller à l'élément suivant" disabled={this.state.disabledSlide || this.state.isNoNextEvent}>
                        <span className="arrow"/>
                    </Button>
                </div>

                {/* Button see agenda */}
                <div className="show-agenda">
                    <Link to={path('agenda')} className={'underline'}>Voir l'agenda</Link>
                </div>
            </Block>
        )
    }
}