export const DOCUMENT_FILTER_TYPE_MAP = 'MAP';

const MAP_TYPES = [
    {name: 'Mensuelle', type: DOCUMENT_FILTER_TYPE_MAP, value: 'MONTHLY'},
    {name: 'Trimestrielle', type: DOCUMENT_FILTER_TYPE_MAP, value: 'QUARTERLY'},
    {name: 'Annuelle', type: DOCUMENT_FILTER_TYPE_MAP, value: 'ANNUALLY'},
];

export const MAP_TYPES_JSON = [
    {id: JSON.stringify({type: MAP_TYPES[0].type, value: MAP_TYPES[0].value}), name: MAP_TYPES[0].name},
    {id: JSON.stringify({type: MAP_TYPES[1].type, value: MAP_TYPES[1].value}), name: MAP_TYPES[1].name},
    {id: JSON.stringify({type: MAP_TYPES[2].type, value: MAP_TYPES[2].value}), name: MAP_TYPES[2].name},
];

export default MAP_TYPES;
