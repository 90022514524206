import React from 'react';
import PropTypes from "prop-types";
import "./Autocomplete.scss";

export default class Autocomplete extends React.Component {
    static propTypes = {
        suggestions: PropTypes.array.isRequired,
        children: PropTypes.element.isRequired,
        onBlur: PropTypes.func,
        onClick: PropTypes.func,
        showSuggestion: PropTypes.bool
    };

    static defaultProps = {
        onBlur: () => {
        },
        onClick: () => {
        },
        showSuggestion: true
    };

    state = {
        activeSuggestion: -1,
    };

    onClick(value) {
        this.props.onClick(value);
    }

    onKeyDown(e) {
        if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
            e.preventDefault();
            this.props.children.ref.current.parentNode.focus();
        }
        if (e.key === 'ArrowUp') {
            let activeSuggestion = this.state.activeSuggestion;
            activeSuggestion--;
            if (activeSuggestion < 0) {
                activeSuggestion = this.props.suggestions.length - 1;
            }

            this.setState({activeSuggestion: activeSuggestion});
        } else if (e.key === 'ArrowDown') {
            let activeSuggestion = this.state.activeSuggestion;
            activeSuggestion++;
            if (activeSuggestion >= this.props.suggestions.length) {
                activeSuggestion = 0;
            }

            this.setState({activeSuggestion: activeSuggestion});
        } else if (e.key === 'Enter' || e.keyCode === 32) {
            this.props.children.ref.current.focus();
            this.onClick(this.props.suggestions[this.state.activeSuggestion])
        }
    }

    onBlur(e) {
        const TARGET = e.currentTarget;
        setTimeout(() => {
            // Call onblur if the new focused element is different of the target
            if (TARGET !== document.activeElement) {
                this.props.onBlur()
            }
        }, 10)
    }

    render() {
        let suggestions = null;
        if (this.props.showSuggestion && this.state.activeSuggestion >= -1 && this.props.suggestions.length > 0) {
            suggestions = (<ul className="autocomplete-list">
                {this.props.suggestions.map((suggestion, key) => {
                    let className;

                    if (key === this.state.activeSuggestion) {
                        className = 'suggestion-active';
                    }

                    return (
                        <li key={key} className={className} onClick={e => this.onClick(e.target.innerText)}>{suggestion}</li>
                    )
                })}
            </ul>);
        }

        return (
            <div className="autocomplete"
                 tabIndex={-1}
                 onKeyDown={(e) => this.onKeyDown(e)}
                 onBlur={(e) => this.onBlur(e)}>
                {this.props.children}
                {suggestions}
            </div>
        )
    }
}