import React from "react";
import PropTypes from "prop-types";
import Button from "../Form/Button/Button";
import "./Filter.scss";

export default class FilterUser extends React.Component {
    static propTypes = {
        onFilter: PropTypes.func,
        roles: PropTypes.array,
        total: PropTypes.number,
        totalActive: PropTypes.number,
    };

    static defaultProps = {
        roles: [],
    };

    state = {
        mobileFilter: false,
        roleFilter: "tous-les-role",
    };

    constructor(props) {
        super(props);
        this.btnFilters = [];
        this._role = React.createRef();
    }

    filter(by, e) {
        const TARGET = e.currentTarget;
        const IS_ACTIVE = TARGET.classList.contains("active");
        const IS_DECREASING = TARGET.classList.contains("decreasing");

        // remove all other active filter
        for (let i = 0; i < this.btnFilters.length; i++) {
            this.btnFilters[i].classList.remove("active");
            this.btnFilters[i].classList.remove("decreasing");
        }

        TARGET.classList.add("active");

        if (IS_ACTIVE && !IS_DECREASING) {
            TARGET.classList.add("decreasing");
        } else {
            TARGET.classList.remove("decreasing");
        }

        this.props.onFilter({
            filter: by,
            way: !(IS_ACTIVE && !IS_DECREASING) ? "asc" : "desc",
        });
    }

    setRef(input) {
        if (input !== undefined && input !== null) {
            this.btnFilters.push(input.reference());
        }
    }

    subFilter(value, e) {
        const BUTTONS = document.querySelectorAll(".subFilter button");

        for (let i = 0; i < BUTTONS.length; i++) {
            BUTTONS[i].classList.remove("active");
        }

        e.target.classList.add("active");

        this.props.onFilter({
            filter: value,
            way: "asc",
        });
    }

    openSubMenu(elem) {
        elem.current.btn.parentElement.classList.toggle("active");
    }

    render() {
        return (
            <div className="filter">
                <div className="container">
                    <ul className={this.state.mobileFilter ? "mobile" : null}>
                        <li>
                            <div>TRIER PAR :</div>
                            <button
                                type={"button"}
                                className={"toggleMobile"}
                                onClick={() =>
                                    this.setState((state) => ({
                                        mobileFilter: !state.mobileFilter,
                                    }))
                                }
                            >
                                <span>Filtre</span>
                            </button>
                        </li>
                        <li>
                            <Button
                                type="button"
                                onClick={(e) => this.filter("alphabetic", e)}
                                ref={(input) => this.setRef(input)}
                                className={"active"}
                            >
                                Ordre alphabétique
                            </Button>
                        </li>
                        <li>
                            <Button
                                type="button"
                                onClick={() => this.openSubMenu(this._role)}
                                ref={this._role}
                            >
                                Rôle
                            </Button>
                            <ul className={"subFilter"}>
                                <li>
                                    <button
                                        type={"button"}
                                        onClick={(e) =>
                                            this.subFilter("tous-les-role", e)
                                        }
                                    >
                                        Tous les rôles
                                    </button>
                                </li>
                                {this.props.roles.map((role, i) => (
                                    <li key={i}>
                                        <button
                                            type={"button"}
                                            onClick={(e) =>
                                                this.subFilter(role.uid, e)
                                            }
                                        >
                                            {role.name}
                                        </button>
                                    </li>
                                ))}
                            </ul>
                        </li>
                    </ul>

                    <div className="filter-total">
                        {`${this.props.total} utilisateur${
                            this.props.total > 1 ? "s" : ""
                        }`} dont {this.props.totalActive} actif{this.props.totalActive > 1 ? "s" : ""}
                    </div>

                </div>
            </div>
        );
    }
}
