import React from 'react';
import PropTypes from "prop-types";
import {THEMATIC_TYPE_COMMON} from "../../Services/ApiThematics";
import "./CategoryTree.scss";
import Thematics from "../../Utils/Thematics";

export default class CategoryTree extends React.Component {

    static propTypes = {
        tree: PropTypes.array,
        editMode: PropTypes.bool,
        archiveMode: PropTypes.bool,
        onClickEditCategory: PropTypes.func,
        onArchive: PropTypes.func,
        onUnArchive: PropTypes.func,
    };

    static defaultProps = {
        tree: null,
        editMode: false,
        archiveMode: false,
        onClickEditCategory: (category) => {},
        onArchive: (category) => {},
        onUnArchive: (category) => {}
    };

    state = {
        archiveMode: false,
        editMode: this.props.editMode || false,
        tree: [],
    };

    constructor(props) {
        super(props);
        this._isMounted = false;
    }

    static handleTreeNav(e) {
        e.preventDefault();
        e.stopPropagation();
        let clickedEl = e.target;
        clickedEl     = clickedEl.classList.contains('category-name') ? e.currentTarget : clickedEl;

        if (clickedEl.classList.contains('has-child-closed')) {
            clickedEl.classList.remove('has-child-closed');
            clickedEl.classList.add('has-child-opened');
        } else if (clickedEl.classList.contains('has-child-opened')) {
            clickedEl.classList.remove('has-child-opened');
            clickedEl.classList.add('has-child-closed');
        }
    }

    static getDerivedStateFromProps(props, state) {
        // Update state.tree when props change
        return state.tree = props.tree;
    }

    componentDidMount() {
        this._isMounted = true;

        if (!this.props.tree) {
            let thematics = Thematics.getLocalThematics(THEMATIC_TYPE_COMMON, true);
                if (!this._isMounted) return;
                this.setState({tree: thematics});
        } else {
            this.setState({tree: this.props.tree});
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.editMode !== this.props.editMode) {
            this.setState({editMode: this.props.editMode})
        }

        if (prevProps.archiveMode !== this.props.archiveMode) {
            this.setState({archiveMode: this.props.archiveMode})
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    buildTree(categories, level = 0) {
        const CLASS_FIRST_LEVEL = (level === 0) ? ' first-level' : '';
        return categories.map((category, i) => {
            const SUB_CATEGORIES = category.categories && category.categories.length > 0 ? category.categories : null;

            let className  = [];
            let isArchived = false;

            if (category.archivedat) {
                className.push('archived');
                isArchived = true;
            }

            if (SUB_CATEGORIES) {
                className.push('has-child has-child-closed');
            }

            return (
                <li key={i} className={className.join(' ')}
                    onClick={CategoryTree.handleTreeNav}>
                    <span className={"category-name" + CLASS_FIRST_LEVEL}>
                        {category.name}
                        {this.state.archiveMode &&
                        <div className={"mode" + (isArchived ? ' unarchive' : ' archive')}
                             title={isArchived ? "Désarchiver" : "Archiver"}
                             onClick={() => {isArchived ? this.props.onUnArchive(category) : this.props.onArchive(category)}}
                        />}
                        {this.state.editMode &&
                        <div className={"mode edit"} onClick={() => this.props.onClickEditCategory(category)}/>}
                    </span>
                    {SUB_CATEGORIES && <ul>{this.buildTree(SUB_CATEGORIES, level + 1)}</ul>}
                </li>
            )
        });
    }

    render() {
        return (
            <ul className={"category-tree"}>
                {this.buildTree(this.state.tree)}
            </ul>
        )
    }
}
