import React from 'react';
import PropTypes from "prop-types";
import Loader from "../../Loader/Loader";
import "./InputSearch.scss";

export default class InputSearch extends React.Component {

    static propTypes = {
        label: PropTypes.string,
        placeholder: PropTypes.string,
        id: PropTypes.string,
        value: PropTypes.string,
        autoComplete: PropTypes.string,
        name: PropTypes.string,
        errorMsg: PropTypes.string,
        onSubmit: PropTypes.func,
        onChange: PropTypes.func,
        loading: PropTypes.bool,
    };

    static defaultProps = {
        onSubmit: (value) => {
        },
        onChange: (value) => {
        },
        inputRef: null,
    };

    state = {
        value: '',
        users: [],
    };

    onChange(value) {
        this.setState({value: value});

        this.props.onChange(value);
    }

    render() {
        let input = (<input type={this.props.type}
                            id={this.props.id}
                            className={this.props.errorMsg ? ' error' : null}
                            name={this.props.name ? this.props.name : this.props.id}
                            value={this.state.value}
                            placeholder={this.props.placeholder}
                            autoComplete={this.props.autoComplete}
                            ref={this.props.inputRef}
                            onChange={(e) => this.onChange(e.target.value)}/>);

        return (
            <div className={'inputSearch'} style={this.props.style}>
                {this.props.label && <span className={'label'}>{this.props.label}</span>}
                <div className={'inputButton'}>
                    <label htmlFor={this.props.id}>
                        {input}
                        <span className={'line'}/>
                        {this.props.errorMsg && <span className={'errorMsg'}>{this.props.errorMsg}</span>}
                    </label>
                    <button type={'button'} className={!this.props.loading ? "search-icon" : ""}
                            value={this.state.value}
                            onClick={(e) => this.props.onSubmit(e.target.value)}>
                        {this.props.loading && <Loader type={"button"} backgroundColor={"#D0ED4D"}/>}
                    </button>
                </div>
            </div>
        )
    }
}