import React from 'react';
import PropTypes from "prop-types";
import SVGFile from "../../SVG/SVGFile";
import {CT_DOCUMENT, getNameNotification, getPathFromNotification} from "../../../Utils/Notification";
import {Link} from "react-router-dom";
import {DateHandler} from "../../../Utils/DateHandler";
import "./ListNotifications.scss";
import {getType} from "../../../Utils/DocType";
import {getColor} from "../../../Utils/Colors";
import Loader from "../../Loader/Loader";
import ApiNotifications from "../../../Services/ApiNotifications";


export default class ListNotifications extends React.Component {

    static propTypes = {
        data: PropTypes.arrayOf(PropTypes.shape({
            createdat: PropTypes.oneOfType([PropTypes.object, PropTypes.number]),
            updatedat: PropTypes.oneOfType([PropTypes.object, PropTypes.number]),
            id: PropTypes.number,
            name: PropTypes.string,
            description: PropTypes.string,
            type: PropTypes.number,
            resourcetype: PropTypes.number,
            resourceid: PropTypes.number,
        })).isRequired,
    };

    onKnowMoreClick(e, notification = null, prevent = false) {
        if (prevent) {
            e.preventDefault();
            ApiNotifications.markAsRead(notification.id).then(() => {
                this.props.history.push(getPathFromNotification(notification));
            });
        }
    }

    render() {

        if (this.props.loading) {
            return <div style={{margin: '50px 0'}}><Loader type={'inline'}/></div>
        }

        return (
            <ul className={'listNotifications'}>
                {this.props.data.length === 0
                    ? <p className={'centerText'}><em>Aucune notification</em></p>
                    : this.props.data.map((notification, i) => {
                        const COLOR = notification.resource ? notification.resource.thematic.color : getColor('darkPurple');

                        return <li key={i}>
                            <div className={"itemNotification list"}>
                                <div className="rowItemNotification">
                                    <div className="info">
                                        <div>
                                            {notification.resourcetype === CT_DOCUMENT &&
                                            <SVGFile className='icon' color={COLOR}>
                                                {getType(notification.resource.files[0].type)}
                                            </SVGFile>}
                                            <div>
                                                <p className="date">{DateHandler.getPrettyDate(notification.createdat.date)}</p>
                                                <p className="title">{getNameNotification(notification)}</p>
                                                {/*<p className="desc">{notification.excerpt}</p>*/}
                                            </div>
                                        </div>
                                    </div>
                                    <div className='cta'>
                                        {notification.resource && notification.resourcetype === CT_DOCUMENT &&
                                        <a href={notification.resource.files[0].url} target={'_blank'}
                                           style={{borderColor: COLOR, color: COLOR}}>
                                            <span tabIndex="-1"><em>Télécharger</em></span>
                                        </a>}
                                        <Link to={getPathFromNotification(notification)} onClick={(e) => this.onKnowMoreClick(e, notification, true)} style={{
                                            borderColor: COLOR,
                                            color: COLOR
                                        }}><span><em>En savoir plus</em></span></Link>
                                    </div>
                                </div>
                            </div>
                        </li>;
                    })}
            </ul>
        )
    }
}