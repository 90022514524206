import axios from "axios";
import {PATH} from "../Utils/Api";

export default {

    /**
     * Get document
     * @returns {Promise<any>}
     */
    addTags: (tags) => {
        const PARAMS = {
            'api[cine]': 1
        };

        return axios.post(`${PATH}/content/tag`, {name: tags, uid: tags}, {params: PARAMS});
    },

    /**
     * Delete tag
     * @param id
     */
    delete: (id) => {
        return axios.delete(`${PATH}/content/document/${id}/tags`,);
    },

    /**
     * Find tag
     * @param query
     * @param limit
     * @returns {AxiosPromise<any>}
     */
    findTags: (query, limit = 5) => {
        const PARAMS = {
            "api[limit]": `${limit}`,
            'api[where]': `where \`name\` like \`%${query}%\``
        };

        return axios.get(`${PATH}/content/tag`, {
            params: PARAMS
        });
    },

    /**
     * Most tag use
     * @param limit
     * @returns {AxiosPromise<any>}
     */
    mostUse: (limit = 8) => {
        const PARAMS = {
            'api[limit]': limit,
            'api[sort]': `count`,
            'api[order]': `desc`
        };

        return axios.get(`${PATH}/content/keyword`, {params: PARAMS})
    }
}
