import React from 'react';
import PropTypes from "prop-types";
import "./Select.scss";

export default class Select extends React.Component {

    static propTypes = {
        type: PropTypes.string,
        label: PropTypes.string,
        placeholder: PropTypes.string,
        id: PropTypes.string,
        value: PropTypes.array,
        selected: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
        ]),
        name: PropTypes.string,
        autoComplete: PropTypes.string,
        errorMsg: PropTypes.string,
        onChange: PropTypes.func,
        className: PropTypes.string,
        required: PropTypes.bool
    };

    static defaultProps = {
        type: 'text',
        className: '',
        placeholder: '',
        required: false,
    };

    render() {
        let classWrapSelect = 'wrapSelect';
        if (this.props.errorMsg) {
            classWrapSelect += ' error'
        }

        return (
            <label htmlFor={this.props.id} className={`select ${this.props.className}${this.props.required ? ' required' : ''}`} style={this.props.style}>
                {this.props.label && <span className={'label'}>{this.props.label}{this.props.required && '*'}</span>}
                <div className={classWrapSelect}>
                    <select id={this.props.id}
                            name={this.props.name ? this.props.name : this.props.id}
                            value={this.props.selected}
                            onChange={(e) => {e.persist(); this.props.onChange(e)}}
                            required={this.props.required}>
                        {/* Placeholder */}
                        {this.props.placeholder && <option value="">{this.props.placeholder}</option>}
                        {/* Options */}
                        {this.props.value.map((value, i) => {
                            return <option key={i} value={value.id}>{value.name}</option>
                        })}
                    </select>
                </div>
                {this.props.errorMsg && <span className={'errorMsg'}>{this.props.errorMsg}</span>}
            </label>
        )
    }
}