import React from 'react';
import PropTypes from "prop-types";
import "./Archive.scss";


export default class Archive extends React.Component {

    static propTypes = {
        title: PropTypes.string,
    };

    static defaultProps = {
        title: 'Archiver',
    };

    render() {
        return <svg id="Calque_1" data-name="Calque 1" xmlns="http://www.w3.org/2000/svg" width="64"
                    height="64" viewBox="0 0 64 64">
            <defs>
                <clipPath id="clip-path">
                    <circle cx="32" cy="32" r="21.26" fill="none"/>
                </clipPath>
            </defs>
            <title>{this.props.title}</title>
            <g id="Groupe_445" data-name="Groupe 445">
                <g id="Ellipse_41" data-name="Ellipse 41">
                    <circle cx="32" cy="32" r="32" fill="#fff"/>
                    <circle className="penTraceBackground" cx="32" cy="32" r="31" fill="none"
                            stroke="#0449a7" strokeWidth="2"/>
                </g>
                <g clipPath="url(#clip-path)">
                    <g id="Groupe_de_masques_12" data-name="Groupe de masques 12">
                        <g id="baseline-archive-24px">
                            <path className={'penTrace'} id="Tracé_220" data-name="Tracé 220"
                                  d="M47.13,20l-2.46-3a2.62,2.62,0,0,0-2-1H21.37a2.66,2.66,0,0,0-2.06,1l-2.44,3a3.45,3.45,0,0,0-.82,2.24V44.4a3.55,3.55,0,0,0,3.55,3.54H44.4a3.55,3.55,0,0,0,3.54-3.54V22.25A3.46,3.46,0,0,0,47.13,20ZM32,41.74,22.25,32h6.2V28.45h7.09V32h6.2ZM19.81,19.6l1.44-1.77H42.51l1.66,1.77Z"
                                  fill="#0449a7"/>
                            <path id="Tracé_221" data-name="Tracé 221"
                                  d="M10.74,10.74H53.26V53.26H10.74Z" fill="none"/>
                        </g>
                    </g>
                </g>
            </g>
        </svg>

    }
}