import {LOCATION} from "../Config/location";
import axios from "axios";

export const TYPE = {
    8: 'CREATED ',
    16: 'UPDATED',
    32: 'ADDED',
    64: 'REGISTERED'
};

export default {

    /**
     * Get notification
     * @param id
     * @param limit
     * @param nested
     * @returns {AxiosPromise<any>}
     */
    get: (id, limit = -1, nested = '') => {
        const PARAMS = {
            'api[where]': `where (\`user\` = \`${id}\` AND \`seen\`=\`0\`)`,
            'api[nested_entities]': 'user.profile',
            'api[sort]': 'created_at',
            'api[order]': 'desc'
        };

        if (limit > 0) {
            PARAMS[`api[limit]`] = limit;
        }

        if (nested !== '') {
            PARAMS['api[nested_entities]'] += ',' + nested;
        }

        return axios.get(`${LOCATION.api}/api/${LOCATION.apiVersion}/user/notification`, {params: PARAMS});
    },

    /**
     * Mark notification as read
     * @param id
     * @returns {AxiosPromise<any>}
     */
    markAsRead: (id) => {
        return axios.patch(`${LOCATION.api}/api/${LOCATION.apiVersion}/user/notification/${id}`, {seen: 1});
    },

    /**
     * Count notification
     * @param id
     * @returns {AxiosPromise<any>}
     */
    count: (id) => {
        return axios.get(`${LOCATION.api}/api/${LOCATION.apiVersion}/user/notification/${id}/count?api[where]=where \`seen\`=\`0\``);
    }
}
