import React from 'react';
import ApiSearch from '../../Services/ApiSearch';
import ApiDocument from "../../Services/ApiDocument";
import ApiThematics, {THEMATIC_TYPE_WORKGROUP, THEMATIC_TYPE_COMMON} from "../../Services/ApiThematics";
import ApiWorkGroup, {WORKGROUP_STATUS_COMMITTEE, WORKGROUP_STATUS_ACTIVE} from "../../Services/ApiWorkGroup";
import Master from "../../Components/Layout/Master";
import Banner from "../../Components/Banner/Banner";
import WorkGroup from "../../Components/WorkGroup/WorkGroup";
import ToolsManage from "../../Components/ToolsManage/ToolsManage";
import Modal from "../../Components/Modal/Modal";
import FormAddWorkGroup from "../../Components/Form/FormAddWorkGroup/FormAddWorkGroup";
import Roles from "../../Security/Roles";
import ModalConfirm from "../../Components/Modal/ModalConfirm/ModalConfirm";
import Auth from "../../Security/Auth";
import Alert from "../../Components/Alert/Alert";
import FilterWorkGroup from "../../Components/Filter/FilterWorkGroup";
import {getColor, lightenDarkenColor} from '../../Utils/Colors';
import {path} from "../../Config/routes";
import Select from 'react-select'
import {Http} from "../../Utils/Api";
import "./WorkGroupList.scss";
import Thematics from "../../Utils/Thematics";
import ButtonThematic from "../../Components/Form/Button/ButtonThematic";
import {isMobile} from "../../Utils/Screen";


export default class WorkGroupList extends React.Component {

    _selects         = [];
    _optionSelected  = [];
    _currentThematic = null;

    state = {
        thematics: [],
        results: [],
        resultsBackup: [],
        nbResults: 0,
        selectDepth: 0,
        optionSelected: [-1],
        selects: [],
        isSearch: false,
        breadCrumb: [{url: path('WorkGroupList'), label: 'Groupes de travail'}],
        currentThematic: null,
        currentThematicColor: '',
        showFormAddWorkGroup: false,
        httpError: false,
        editMode: false,
        archiveMode: false,
        deleteMode: false,
        showModalConfirmDeleteWorkgroup: false,
        showModalConfirmArchiveWorkgroup: false,
        currentlyHandledWorkgroup: null,
        loader: true,
        loadingDocuments: [],
        loadingButtonModal: false,
        alert: {
            show: false,
            action: 'add',
            value: ''
        }
    };

    constructor(props) {
        super(props);
        this._isMounted        = false;
        this.backgroundColor   = [getColor('blue')];
        this.enableNewMode     = this.enableNewMode.bind(this);
        this.enableEditMode    = this.enableEditMode.bind(this);
        this.enableArchiveMode = this.enableArchiveMode.bind(this);
        this.enableDeleteMode  = this.enableDeleteMode.bind(this);
    }

    componentDidMount() {
        this._isMounted = true;

        // @TODO change with  ApiThematics.getAll(['categories.categories.categories.categories.categories.categories,categories.thematic,workgroups'])
        // Comprendre pourquoi les changements appliqués dans ApiMenu, car incompatible avec Banner
        const REQUEST = new Promise((resolve) => resolve(Thematics.getLocalThematics(THEMATIC_TYPE_WORKGROUP, true, false)));

        REQUEST.then((thematics) => {
            if (!this._isMounted) return;

            thematics = thematics.filter((thematic) => thematic.workgroups.length > 0);
            let selects = this.setOptionsSelected(Object.keys(this.props.match.params), thematics);

            this.setState({
                thematics: thematics,
                optionSelected: selects.optionSelected,
                selects: selects.selects,
                loader: false
            });
            // Update the breadcrumb
            selects = selects.selects;

            for (let i = 0; i < selects.length; i++) {

                let option = selects[i].props.value;
                let key    = parseInt(selects[i].key);

                if (option.value !== -1) {
                    this.selectHandler(option, key, false);
                }
            }
        }).catch((err) => {
            if (!this._isMounted) return;
            if (err.status && err.status >= 500) {
                this.setState({
                    httpError: true
                });
            }
            console.log(err);
        });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.location.pathname !== prevProps.location.pathname) {
            if (this.props.location.pathname === '/work-groups-list/') {
                this.setState({
                    breadCrumb: [{url: path('WorkGroupList'), label: 'Groupes de travail'}]
                });
                this.change(-1, 1, true);
            }
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
        Http.cancel();
    }

    /*
     * Generate all select based of the router parameters
     *
     * @param {array} routerParams - All the router parameters
     * @param {array} thematics - All thematics for this level
     * @param {number} [index=0] - Index for the router parameters to watch
     *
     * @return {{selects: [number], optionSelected: [Select]}}
     */
    setOptionsSelected(routerParams, thematics, index = 0) {
        let params = this.props.match.params;

        // The router parameter is set
        if (params[routerParams[index]]) {
            let indexThematics = -1;
            // Get the index category for the the current router parameter
            for (let i = 0; i < thematics.length; i++) {
                if (thematics[i].uid === params[routerParams[index]]) {
                    this._currentThematic = thematics[i];
                    indexThematics        = i;
                    break;
                }
            }

            // Router parameter not found
            if (indexThematics === -1) {
                console.error(`${params[routerParams[index]]} do not exist in documentation`);
                return;
            }

            // Save the options selected and the select for this router parameter
            this._optionSelected.push(indexThematics);
            this._selects.push(this.createSelect(thematics, indexThematics, index));

            return this.setOptionsSelected(routerParams, thematics[indexThematics].workgroups, index + 1);
        } else if (thematics) {
            // Init the next select if it exist
            this._optionSelected.push(-1);
            this._selects.push(this.createSelect(thematics, -1, index));
            return {
                optionSelected: this._optionSelected,
                selects: this._selects
            };
        } else {
            return {
                optionSelected: this._optionSelected,
                selects: this._selects
            };
        }
    }

    getDocuments(workgroups) {
        let requests         = [];
        let results          = workgroups;
        let loadingDocuments = [];

        // Prepare request for get 3 last doc for each work group
        workgroups.forEach((workgroup) => {
            loadingDocuments.push(true);
            requests.push(ApiDocument.getDocumentsWorkgroup(workgroup.id, 1, 3, false, {where: ''}));
        });

        this.setState({loadingDocuments: loadingDocuments});

        // Exec. request
        requests.forEach((request, i) => {
            request.then(({payload}) => {
                results[i].documents = payload;
                loadingDocuments[i]  = false;
                this.setState({
                    loadingDocuments: loadingDocuments,
                    results: results,
                    resultsBackup: results
                });
            })
        })
    }

    selectHandler(optionSelected, select = 1, recreate = true) {

        // Redirect to work group detail
        if (select === 2 && recreate && optionSelected.id) {
            this.props.history.push('/work-groups/' + optionSelected.id);
            return
        }

        let breadCrumb = this.state.breadCrumb;
        // If the select changed is not the last one
        if (select !== breadCrumb.length) {
            // Delete all index saved after the select changed
            breadCrumb = breadCrumb.slice(0, select - breadCrumb.length);
        }
        if (optionSelected.value !== -1) {
            breadCrumb[select] = {url: optionSelected.url, label: optionSelected.label};
        }

        // Update the url with the select
        let urlParams = Object.keys(this.props.match.params);
        let param     = {};
        for (let i = 0; i < urlParams.length; i++) {
            if (breadCrumb[i + 1]) {
                param[urlParams[i]] = breadCrumb[i + 1].url
            } else {
                break;
            }
        }


        this.props.history.replace({pathname: path('WorkGroupList', param)});

        this.setState({
            nbResults: 0,
            breadCrumb: breadCrumb
        });

        if (optionSelected.emulated && isMobile()) {
            Banner.selects.current.className = 'selects selects-translate-1';
            Banner.reset.current.className   = 'fas fa-chevron-left';
        }

        this.change(optionSelected.value, select, true);
    }

    change(index, select = 1, recreate = true) {
        // Get option selected for the select changed
        let newSelect         = this.state.optionSelected;
        newSelect[select - 1] = index;
        let selects           = recreate ? [] : this.state.selects;
        let thematics         = this.state.thematics;

        // If the select changed is not the last one
        if (select !== newSelect.length && recreate) {
            // Delete all index saved after the select changed
            newSelect = newSelect.slice(0, select - newSelect.length);
        }

        // Recreate all previous select
        for (let i = 0; i < newSelect.length; i++) {

            if (recreate) {
                selects.push(this.createSelect(thematics.workgroups || thematics, this.state.optionSelected[i], i));
            }
            if (this.state.optionSelected[i] !== -1) {
                thematics = thematics[this.state.optionSelected[i]].workgroups || thematics[this.state.optionSelected[i]];
            }
        }

        this.setState({
            selects: selects,
            optionSelected: newSelect,
            selectDepth: select
        });

        // Set result
        let result;

        // It is not the default option for the select changed
        if (index !== -1 || (newSelect.length > 1 && select < 1)) {
            // Create a new select if the previous as children
            if (index !== -1 && (thematics.workgroups !== undefined || Array.isArray(thematics)) && recreate) {
                newSelect.push(-1);
                selects.push(this.createSelect(thematics.workgroups || thematics, -1, newSelect.length - 1))
            }


            if (select === 1) {
                result = this.state.thematics[newSelect[0]].workgroups
            } else if (select === 2) {
                result = this.state.thematics[newSelect[0]].workgroups[newSelect[1]];
            }

            this.setState({
                selects: selects,
                optionSelected: newSelect,
                results: result,
                resultsBackup: result
            });
            this.getDocuments(result);
        } else {
            result = (select === 2) ? this.state.thematics[newSelect[0]].workgroups : [];

            this.setState({
                selectDepth: selects.length - 1,
                results: result,
                resultsBackup: result
            });
        }
    }

    handleSearch(searchText) {
        ApiSearch.search(searchText).then((search) => {
            if (!this._isMounted) return;
            this.setState({
                nbResults: search.nbResults,
                results: search.results
            });
        });
    }

    /**
     * Create a select
     * @param {array} options - List of select's options
     * @param {number} [value=-1] - The value of options selected
     * @param {number } [key=0] - Key to loop
     * @return {string}
     */
    createSelect(options, value = -1, key = 0) {
        key++;
        const PLACEHOLDER = key === 1 ? 'Choisissez un thème' : 'Choisissez une catégorie';

        let optionSelected = {
            value: -1,
            label: PLACEHOLDER
        };

        let selectOption = [optionSelected];

        for (let i = 0; i < options.length; i++) {
            let option = {
                id: options[i].id,
                value: i,
                label: options[i].name,
                url: options[i].uid,
            };

            selectOption.push(option);

            if (value === i) {
                optionSelected = option;
            }
        }

        const THEMATIC       = options[optionSelected.value] || null;
        const THEMATIC_COLOR = (THEMATIC && THEMATIC.color) || this.backgroundColor[1];

        if (THEMATIC !== null) this._currentThematic = THEMATIC;

        if (options[optionSelected.value] !== undefined) {
            this.backgroundColor[key]     = lightenDarkenColor(THEMATIC_COLOR, -(key - 1) * 20);
            this.backgroundColor[key + 1] = lightenDarkenColor(THEMATIC_COLOR, -(key) * 20);
        }

        this.setState({currentThematicColor: THEMATIC_COLOR});

        let COLOR = (value === -1 && key === 1) ? this.backgroundColor[0] : this.backgroundColor[key];
        let STYLE = {
            container: (provided) => ({
                ...provided,
                height: '100%',
                width: '100%',
            }),
            control: (provided) => ({
                ...provided,
                backgroundColor: COLOR,
                boxShadow: 'none',
                border: 0,
                borderRadius: 0,
                height: '100%',
                width: '100%',
            }),
            valueContainer: (provided) => ({
                ...provided,
                justifyContent: 'center',
            }),
            singleValue: (provided) => ({
                ...provided,
                color: getColor('white'),
            }),
            indicatorSeparator: (provided) => ({
                ...provided,
                display: 'none',
            }),
            menu: (provided) => ({
                ...provided,
                backgroundColor: COLOR,
            }),
            option: (provided) => ({
                ...provided,
                color: getColor('white'),
            }),
        };

        return (
            <Select value={optionSelected}
                    placeholder={PLACEHOLDER}
                    key={key}
                    name={`selectFilter${key}`}
                    id={`selectFilter${key}`}
                    styles={STYLE}
                    onChange={(e) => this.selectHandler(e, key)}
                    classNamePrefix="react-select"
                    className="react-select__container"
                    isSearchable={false}
                    options={selectOption}
            />
        )
    }

    validWorkGroup(workGroup, isNew = false) {
        if (workGroup) {
            // Update the list if the thematic is the same
            let results = this.state.results;
            if (this._currentThematic['id'] === workGroup.thematic.id) {
                if (isNew) {
                    workGroup.documents = [];
                    results.push(workGroup);
                } else {
                    // Parse each result and update the modified workgroup
                    for (let i = 0; i < results.length; i++) {
                        if (results[i].id === workGroup.id) {
                            results[i] = workGroup;
                            break; // Stop here, we found it
                        }
                    }
                }
            }
            // Not the same thematic, remove from list
            else {
                // Parse each result and update the modified workgroup
                for (let i = 0; i < results.length; i++) {
                    if (results[i].id === workGroup.id) {
                        results.splice(i, 1);
                        break; // Stop here, we found it
                    }
                }
            }

            // Close modal and update workgroup list
            this.setState({
                showFormAddWorkGroup: false,
                nbResults: this.state.nbResults + 1,
                results: results,
                alert: {
                    show: true,
                    action: isNew ? 'add' : 'update',
                    value: workGroup.name
                }
            });
        }
    }

    _switchMode(enableEdit, enableDelete, enableArchive) {
        this.setState({
            editMode: enableEdit,
            deleteMode: enableDelete,
            archiveMode: enableArchive,
        })
    }

    enableNewMode() {
        this.setState({showFormAddWorkGroup: true, currentlyHandledWorkgroup: null});
        this._switchMode(false, false, false);
    }

    enableEditMode() {
        this._switchMode(!this.state.editMode, false, false);
    }

    enableDeleteMode() {
        this._switchMode(false, !this.state.deleteMode, false);
    }

    enableArchiveMode() {
        this._switchMode(false, false, !this.state.archiveMode);
    }

    async editWorkgroup(workgroup) {
        // get user from workgroup
        const {users} = await ApiWorkGroup.get(workgroup.id, ['users.user.profile']);

        workgroup.users = users;

        this.setState({showFormAddWorkGroup: true, currentlyHandledWorkgroup: workgroup});
    }

    deleteWorkgroup(workgroup) {
        this.setState({showModalConfirmDeleteWorkgroup: true, currentlyHandledWorkgroup: workgroup});
    }

    archiveWorkgroup(workgroup) {
        this.setState({showModalConfirmArchiveWorkgroup: true, currentlyHandledWorkgroup: workgroup});
    }

    _filterResults(resultIdToRemove) {
        // Parse each result and update the modified workgroup
        return this.state.results.filter(result => {
            return result.id !== resultIdToRemove;
        });
    }

    async confirmDeleteWorkgroup(workgroupId) {
        this.setState({loadingButtonModal: true});

        await ApiWorkGroup.delete(workgroupId);

        this.setState({
            loadingButtonModal: false,
            showModalConfirmDeleteWorkgroup: false,
            results: this._filterResults(workgroupId),
            alert: {
                show: true,
                action: 'delete',
                value: this.state.currentlyHandledWorkgroup.name
            }
        });
    }

    async confirmArchiveWorkgroup(workgroupId) {
        this.setState({loadingButtonModal: true});

        let isArchived = this.state.currentlyHandledWorkgroup.archivedat ? true : false;

        await isArchived ? ApiWorkGroup.unArchive(workgroupId) : ApiWorkGroup.archive(workgroupId);

        // @TODO: Refetch les Thematics sans passer par le localstorage car les cascades API ne sont pas prise en compte
        // Api.getLocaThematics retourne le localStorage qui est set dans ApiMenu.

        let results = this.state.results;
        for (let i = 0; i < results.length; i++) {
            let result = results[i];
            if (result.id === workgroupId) {
                console.log(111);
                results[i].archivedat = isArchived ? null : new Date();
                break;
            }
        }

        this.setState({
            loadingButtonModal: false,
            showModalConfirmArchiveWorkgroup: false,
            results: results,
            alert: {
                show: true,
                action: isArchived ? 'unarchive' : 'archive',
                value: this.state.currentlyHandledWorkgroup.name
            }
        });
    }

    filterStatus(status) {
        if (status) {
            const FILTER_RESULTS = this.state.resultsBackup.filter((result) => {
                // If workgroup is "ACTIVE", display too workgroup "COMMITTEE"
                return result.status === status || (status === WORKGROUP_STATUS_ACTIVE && result.status === WORKGROUP_STATUS_COMMITTEE)
            });

            this.setState({results: FILTER_RESULTS});
        } else {
            this.setState({results: this.state.resultsBackup});
        }
    }

    render() {
        return (
            <Master id="workGroup" httpError={this.state.httpError} breadCrumb={this.state.breadCrumb} {...this.props}>
                <Banner imgSrc={'/assets/images/background/workgroup.jpg'}
                        title={'Groupes de travail'}
                        search={!this.state.loader}
                        selects={this.state.selects}
                        onSearch={(searchText) => this.handleSearch(searchText)}
                        {...this.props}
                />

                {/* Thematics */}
                {this.state.results.length === 0 && this.state.selectDepth === 0 &&
                <ButtonThematic thematics={this.state.thematics}
                                isWorkgroup={true}
                                onClick={(thematic, i) => {
                                    this.selectHandler({
                                        label: thematic.name,
                                        url: thematic.uid,
                                        value: i,
                                        emulated: true
                                    })
                                }}/>
                }

                {/* Alert */}
                <Alert show={this.state.alert.show}
                       type={"workgroup"}
                       action={this.state.alert.action}
                       value={this.state.alert.value}
                       onFinish={() => this.state.alert.show && this.setState({
                           alert: {
                               ...this.state.alert,
                               show: false
                           }
                       })}
                />

                {/*Filter */}
                {this.state.resultsBackup.length > 0 &&
                <FilterWorkGroup onFilter={(value) => this.filterStatus(value)} numberResult={this.state.results.length || 0}/>}

                {/* Result/ */}
                {this.state.selectDepth > 0 && <div className={'container'}>
                    {/* Tool manage */}
                    <div className="container">
                        <ToolsManage
                            role={(userAuthenticated, userAuthenticatedRole) => {
                                // The authenticated user is admin, so he must be defined as a admin of the thematic to be able to manage it
                                if (userAuthenticatedRole.uid === Roles.ROLE_ADMINISTRATOR.uid && Auth.isThematicAdministrator(this._currentThematic['id'])) {
                                    return true;
                                }

                                // In other case he has to be root
                                return userAuthenticatedRole.rank >= Roles.ROLE_ROOT.rank;
                            }}
                            archive={true}
                            onAdd={this.enableNewMode}
                            onEdit={this.enableEditMode}
                            onDelete={this.enableDeleteMode}
                            onArchive={this.enableArchiveMode}
                        />
                    </div>

                    {this.state.results.length > 0
                        ? <div className={'row'}>
                            {this.state.results.map((result, i) => {
                                if (result.documents) {
                                    return <div className="col-md-4" key={i}>
                                        <WorkGroup workGroup={result}
                                                   color={this.state.currentThematicColor}
                                                   editMode={this.state.editMode}
                                                   deleteMode={this.state.deleteMode}
                                                   archiveMode={this.state.archiveMode}
                                                   onEdit={(workgroup) => this.editWorkgroup(workgroup)}
                                                   onArchive={(workgroup) => this.archiveWorkgroup(workgroup)}
                                                   onDelete={(workgroup) => this.deleteWorkgroup(workgroup)}
                                                   limitDoc={3}
                                                   loading={this.state.loadingDocuments[i]}
                                        />
                                    </div>
                                } else {
                                    return <div className="col-md-12" key={i}>
                                        Liste des groupes de travail
                                    </div>
                                }
                            })}
                        </div>
                        : <div className={'content'}>
                            <p className={'centerText'}>
                                <i>Aucun groupe de travail</i>
                            </p>
                        </div>}
                </div>}

                {/* Modal confirm delete workgroup*/}
                {this.state.showModalConfirmDeleteWorkgroup && 0 == this.state.currentlyHandledWorkgroup.documents.length && 
                <ModalConfirm onClose={() => this.setState({showModalConfirmDeleteWorkgroup: false})}
                              confirmText={`Êtes-vous sûr de vouloir supprimer le groupe de travail «${this.state.currentlyHandledWorkgroup.name}» ?`}
                              onCancel={() => this.setState({showModalConfirmDeleteWorkgroup: false})}
                              onConfirm={() => this.confirmDeleteWorkgroup(this.state.currentlyHandledWorkgroup.id)}
                              loading={this.state.loadingButtonModal}
                />}

                {/* Modal warning impossible delete workgroup*/}
                {this.state.showModalConfirmDeleteWorkgroup && 0 != this.state.currentlyHandledWorkgroup.documents.length && 
                <Modal onClose={() => this.setState({showModalConfirmDeleteWorkgroup: false})} autoWidth={true} autoHeight={true}>
                    <p>Il est impossible de supprimer un groupe de travail qui contient des documents</p>
                </Modal>}

                {/* Modal confirm archive workgroup*/}
                {this.state.showModalConfirmArchiveWorkgroup &&
                <ModalConfirm onClose={() => this.setState({showModalConfirmArchiveWorkgroup: false})}
                              confirmText={`Êtes-vous sûr de vouloir ${this.state.currentlyHandledWorkgroup.archivedat ? 'désarchiver' : 'archiver'} le groupe de travail «${this.state.currentlyHandledWorkgroup.name}» ?`}
                              onCancel={() => this.setState({showModalConfirmArchiveWorkgroup: false})}
                              onConfirm={() => this.confirmArchiveWorkgroup(this.state.currentlyHandledWorkgroup.id)}
                              loading={this.state.loadingButtonModal}
                />}

                {/* Modal add workgroup */}
                {this.state.showFormAddWorkGroup && <Modal onClose={() => this.setState({showFormAddWorkGroup: false})}>
                    <FormAddWorkGroup
                        thematic={this._currentThematic}
                        workGroup={this.state.editMode && this.state.currentlyHandledWorkgroup ? this.state.currentlyHandledWorkgroup : null}
                        onCancel={() => this.setState({showFormAddWorkGroup: false})}
                        onValid={(workGroup, isNew) => this.validWorkGroup(workGroup, isNew)}
                        loading={this.state.loadingForm}
                    />
                </Modal>}
            </Master>
        )
    }
}
