import React from "react";
import "./Alert.scss"
import PropTypes from "prop-types";

export default class Alert extends React.Component {

    static propTypes = {
        type: PropTypes.oneOf(['document', 'user', 'category', 'workgroup']).isRequired,
        show: PropTypes.bool,
        action: PropTypes.oneOf(['add', 'delete', 'update', 'archive', 'unarchive', 'refuse']).isRequired,
        value: PropTypes.string.isRequired,
        onFinish: PropTypes.func
    };

    static defaultProps = {
        onFinish: () => {},
    };

    state = {
        show: false
    };

    constructor(props) {
        super(props);
        this._isMounted = false;
        this._timeOut   = null;
        this.alert      = React.createRef();
    }

    componentDidMount() {
        this._isMounted = true;

        this.setState({
            show: this.props.show
        }, () => {
            if (this.state.show) {
                setTimeout(() => {
                    if (!this._isMounted) return;
                    this.hide();
                });
            }
        });
    }

    componentDidUpdate(prevProps) {
        if (this.props.show && !prevProps.show) {
            this.setState({
                show: true
            }, () => {
                this.hide();
            });
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
        clearTimeout(this._timeOut);
    }

    hide() {
        this._timeOut = setTimeout(() => {
            if (!this._isMounted) return;
            this.alert.current.classList.add('hide');
            this.alert.current.addEventListener('animationend', () => {
                this.setState({
                    show: false
                });
                this.props.onFinish();
            });
        }, 4000);
    }

    getType() {
        if (this.props.type === 'document') {
            return 'Le document';
        } else if (this.props.type === 'user') {
            return 'L\'utilisateur';
        } else if (this.props.type === 'category') {
            return 'La sous-catégorie';
        } else {
            return 'Le groupe de travail';
        }
    }

    getAction() {
        let action = '';
        if (this.props.action === 'add') {
            action = 'ajouté';
        } else if (this.props.action === 'delete') {
            action = 'supprimé';
        } else if (this.props.action === 'update') {
            action = 'édité';
        } else if (this.props.action === 'refuse') {
            action = 'refusé';
        } else if (this.props.action === 'unarchive') {
            action = 'désarchivé';
        } else {
            action = 'archivé';
        }

        if (this.props.type === 'category') {
            action += 'e';
        }

        return action;
    }

    render() {
        return (
            <>
                {this.state.show && (
                    <div className={'alert-action'} ref={this.alert}>
                        <p>
                            {this.getType()} « {this.props.value} » a bien été {this.getAction()}
                        </p>
                    </div>
                )}
            </>
        );
    }
}