import React from 'react';
import PropTypes from "prop-types";
import "./TagsInputWithButton.scss";
import Autocomplete from "../Autocomplete/Autocomplete";
import ApiTag from "../../../Services/ApiTag";
import Loader from "../../Loader/Loader";

export default class TagsInputWithButton extends React.Component {

    static propTypes = {
        label: PropTypes.string,
        placeholder: PropTypes.string,
        id: PropTypes.string,
        value: PropTypes.string,
        name: PropTypes.string,
        autoComplete: PropTypes.string,
        textButton: PropTypes.string,
        errorMsg: PropTypes.string,
        onChange: PropTypes.func,
        onSubmit: PropTypes.func,
        onKeyDown: PropTypes.func,
        inputRef: PropTypes.object,
        withAutoComplete: PropTypes.bool,
        loading: PropTypes.bool
    };

    static defaultProps = {
        onChange: (e) => {
        },
        onKeyDown: (e) => {
        },
        onSubmit: (value) => {
        },
        inputRef: null,
        withAutoComplete: false
    };

    state = {
        value: '',
        suggestions: [],
        showSuggestion: true
    };

    constructor(props) {
        super(props);
        this._isMounted = false;
    }

    componentDidMount() {
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    onChange(e) {
        const VALUE = e.target.value;

        if (this.props.withAutoComplete) {
            ApiTag.findTags(VALUE, 5).then((result) => {
                if (!this._isMounted) return;
                let tags = [];
                result.forEach((tag) => {
                    tags.push(tag.name);
                });
                this.setState({
                    suggestions: tags
                })
            }).catch(e => console.error(e));
        }

        this.setState({
            value: VALUE,
            showSuggestion: VALUE.length > 0
        });
        this.props.onChange(VALUE);
    }

    autocompleteClick(value) {
        this.setState({
            value: value,
            showSuggestion: false
        });
        this.props.onSubmit(value);
    }

    autocompleteBlur() {
        this.setState({
            showSuggestion: false
        });
    }

    render() {
        let input = (<input type={this.props.type}
                            id={this.props.id}
                            className={this.props.errorMsg ? ' error' : null}
                            name={this.props.name ? this.props.name : this.props.id}
                            value={this.props.value}
                            placeholder={this.props.placeholder}
                            autoComplete={this.props.autoComplete}
                            onKeyDown={(e) => this.props.onKeyDown(e)}
                            ref={this.props.inputRef}
                            onChange={(e) => this.onChange(e)}/>);

        if (this.props.withAutoComplete) {
            input = (
                <Autocomplete
                    suggestions={this.state.suggestions}
                    onClick={(value) => this.autocompleteClick(value)}
                    onBlur={() => this.autocompleteBlur()}
                    showSuggestion={this.state.showSuggestion}>
                    {input}
                </Autocomplete>
            );
        }

        return (
            <div className={'tagsInputWithButton'} style={this.props.style}>
                {this.props.label && <span className={'label'}>{this.props.label}</span>}
                <div className={'inputButton'}>
                    <label htmlFor={this.props.id}>
                        {input}
                        <span className={'line'}/>
                        {this.props.errorMsg && <span className={'errorMsg'}>{this.props.errorMsg}</span>}
                    </label>
                    <button type={'button'} onClick={() => this.props.onSubmit(this.state.value)}>
                        {this.props.loading ? <Loader type={"button"} backgroundColor={'transparent'}/> : this.props.textButton}
                    </button>
                </div>
            </div>
        )
    }
}