import React from 'react';
import PropTypes from "prop-types";
import "./RadioRoles.scss";
import Roles from "../../../../../Security/Roles";
import ThematicsRectangle from "../../../../Form/ThematicsRectangle/ThematicsRectangle";


export default class RadioRoles extends React.Component {

    static propTypes    = {
        name: PropTypes.string,
        roles: PropTypes.arrayOf(PropTypes.shape({
            uid: PropTypes.string,
            name: PropTypes.string,
            description: PropTypes.string,
            id: PropTypes.int,
        })).isRequired,
        onChange: PropTypes.func,
        selectedRole: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        administratorThematicsChecked: PropTypes.array,
        errorMsg: PropTypes.string
    };
    static defaultProps = {
        onChange: () => {
        },
        onSelectAdministratorThematics: () => {
        },
        administratorThematicsChecked: [],
        errorMsg: '',
    };
    state               = {
        selectedRole: null,
        inputsData: [],
        thematicsChecked: []
    };

    componentDidMount() {
        let dataRoles = [];

        this.props.roles.map((role, i) => {
            const LABEL     = role.uid === Roles.ROLE_USER.uid ? `Aucun droit d'administration` : role.name;
            const SUB_LABEL = role.uid === Roles.ROLE_USER.uid ? `` : role.description;
            return dataRoles.push({
                id: role.uid,
                value: JSON.stringify(role),
                label: LABEL,
                subLabel: SUB_LABEL,
            })
        });

        this.setState({inputsData: dataRoles, selectedRole: this.props.selectedRole, thematicsChecked: this.props.administratorThematicsChecked});
    }

    handleThematicChange(thematic, thematics) {
        this.setState({...this.state, thematicsChecked: thematics}, () => {
            // Call props onChange event
            this.props.onSelectAdministratorThematics(this.state.thematicsChecked)
        });

    }

    onRoleChange(e) {
        const ROLE = JSON.parse(e.target.value);
        this.setState({selectedRole: ROLE.uid, thematicsChecked: []});

        // Empty selected admin thematics for role other than admin
        if(ROLE.uid !== Roles.ROLE_ADMINISTRATOR) {
            this.props.onSelectAdministratorThematics([])
        }

        // Call props onChange event
        this.props.onChange(e.target.value)
    }

    render() {
        return (
            <div className={'radioRoles'}>
                {this.state.inputsData.map((inputData, i) => (
                    <div className={`radioRoles-${inputData.id}`} key={i}>
                        <div className={`inputLabel${inputData.subLabel ? ' withSubLabel' : ''}`}>
                            <input id={inputData.id}
                                   type="radio"
                                   name={this.props.name}
                                   value={inputData.value}
                                   defaultChecked={inputData.defaultChecked}
                                   checked={this.state.selectedRole === inputData.id}
                                   onChange={this.onRoleChange.bind(this)}
                            />
                            <label htmlFor={inputData.id}>
                                <span>{inputData.label}</span>
                                {inputData.subLabel && <div className={'subLabel'}>
                                    <span>{inputData.subLabel}</span>
                                </div>}
                            </label>
                            {}
                        </div>
                        {inputData.id === Roles.ROLE_ADMINISTRATOR.uid && this.state.selectedRole === Roles.ROLE_ADMINISTRATOR.uid &&
                        <ThematicsRectangle title={'Thématiques'}
                                            defaultCheckedId={this.state.thematicsChecked}
                                            isMultiple={true}
                                            onChange={this.handleThematicChange.bind(this)}/>
                        }

                    </div>
                ))}
                {this.props.errorMsg !== '' && <span className={'errorMsg'}>{this.props.errorMsg}</span>}
            </div>
        )
    }
}