import React from 'react';
import PropTypes from 'prop-types';
import Roles from "../../../Security/Roles";
import Modal from "../../Modal/Modal";
import Loader from "../../Loader/Loader";
import './MembersList.scss';


export default class MembersList extends React.Component {

    static propTypes = {
        users: PropTypes.arrayOf(PropTypes.shape({
            user: PropTypes.shape({
                profile: PropTypes.shape({
                    firstname: PropTypes.string,
                    lastname: PropTypes.string,
                    address: PropTypes.string,
                    job: PropTypes.string,
                    phone: PropTypes.string,
                    company: PropTypes.shape({
                        id: PropTypes.id,
                        name: PropTypes.string,
                    }),
                }),
                email: PropTypes.string,
                lowestrole: PropTypes.string,
                highestrole: PropTypes.string,
                approvedat: PropTypes.string
            })
        })).isRequired,
        skin: PropTypes.oneOf(['default', 'admin']),
        editMode: PropTypes.bool,
        deleteMode: PropTypes.bool,
        roles: PropTypes.array,
        onEdit: PropTypes.func,
        onDelete: PropTypes.func,
        onApprove: PropTypes.func,
        onReject: PropTypes.func,
        openUser: PropTypes.string
    };

    static defaultProps = {
        skin: 'default',
    };

    state = {
        showModalApproved: false,
        loadingNotApproved: false,
        loadingApproved: false,
        user: undefined,
    };

    constructor(props) {
        super(props);
        this._isMounted = false;
    }

    componentDidMount() {
        this._isMounted = true;
    }

    static getDerivedStateFromProps(props, state) {
        if (props.openUser) {
            for (let i = 0; i < props.users.length; i++) {
                const USER         = props.users[i].user || props.users[i];
                const NOT_APPROVED = !USER.approvedat;

                if (NOT_APPROVED && props.openUser === USER.email) {
                    state.showModalApproved = true;
                    return state.user = USER;
                }
            }
        }

        return null;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    handleEvent(member) {
        if (this.props.editMode) {
            return this.props.onEdit(member)
        } else if (this.props.deleteMode) {
            return this.props.onDelete(member)
        }
    }

    hideModal() {
        this.setState({
            showModalApproved: false,
            loadingApproved: false,
            loadingNotApproved: false,
            user: undefined
        });
    }

    showModal(user) {
        this.setState({
            showModalApproved: true,
            user: user
        });
    }

    handleApprovedUser(approved) {
        let user = this.state.user;

        this.setState({
            loadingApproved: approved,
            loadingNotApproved: !approved
        });

        if (approved) {
            this.props.onApprove(user);
            this.hideModal();
        } else {
            this.hideModal();
            this.props.onReject(user);
        }
    }

    render() {
        const IS_MANAGE_MODE = this.props.editMode || this.props.deleteMode;

        return (
            <>
                <div className={'membersList ' + this.props.skin}>
                    <ul key>
                        {this.props.users.map((users, i) => {
                                const USER         = users.user || users;
                                const NOT_APPROVED = !USER.approvedat;

                                let statusClass = null;

                                if (NOT_APPROVED) {
                                    statusClass = 'notApproved'
                                }

                                return <li key={i} className={statusClass}>
                                    <button type={'button'} className={IS_MANAGE_MODE ? 'manage' : null}
                                            onClick={() => this.handleEvent(USER)}>
                                        <div
                                            className={'profile'}>{USER.profile.lastname.substr(0, 1)}{USER.profile.firstname.substr(0, 1)}</div>
                                        <div>
                                            <b className={'name'}>{USER.profile.lastname} {USER['profile'].firstname}</b>
                                            <div className={'details'}>
                                                {this.props.skin === 'default' && <table cellSpacing={0}>
                                                    <tbody>
                                                    <tr>
                                                        <td><span>{USER.profile.company.name}</span></td>
                                                        <td><span>{USER.profile}</span></td>
                                                    </tr>
                                                    <tr>
                                                        <td><span>{USER.profile.job}</span></td>
                                                        <td><span>{USER.profile.address}</span></td>
                                                    </tr>
                                                    <tr>
                                                        <td><span>{USER.profile.phone}</span></td>
                                                    </tr>
                                                    </tbody>
                                                </table>}
                                                {this.props.skin === 'admin' &&
                                                <span className={'role'}>
                                                    {NOT_APPROVED ? `Nouvelle demande d'accès` :
                                                        <>
                                                            {[Roles.ROLE_DEVELOPER.uid, Roles.ROLE_ANONYMOUS.uid].indexOf(USER.highestrole) < 0 && this.props.roles.map(role => {
                                                                if (USER.highestrole === role.uid) {
                                                                    return role.name
                                                                }
                                                                return '';
                                                            })}
                                                            {Roles.ROLE_DEVELOPER.uid === USER.highestrole &&
                                                            "Développeur"
                                                            }
                                                            {Roles.ROLE_ANONYMOUS.uid === USER.highestrole &&
                                                            "Utilisateur anonyme"
                                                            }
                                                        </>
                                                    }
                                        </span>}
                                            </div>
                                        </div>
                                        {/* Edit mode */}
                                        {this.props.editMode && <div className={'mode edit'}/>}
                                        {/* Delete mode */}
                                        {this.props.deleteMode && <div className={'mode delete'}/>}
                                    </button>

                                    {/* Approved button */}
                                    {(NOT_APPROVED) && !IS_MANAGE_MODE && <div className={'wrapBtnApproved'}>
                                        <button type={'button'} className={'btn btnApproved'}
                                                onClick={() => this.showModal(USER)}>Voir
                                        </button>
                                    </div>}
                                </li>
                            }
                        )}
                    </ul>
                </div>

                {/* Modal */}
                {this.state.showModalApproved &&
                <Modal title={'Fiche récapitulative'} onClose={() => this.hideModal()} autoHeight={true}>
                    <h5 className={'subTitle'}>
                        <span>Informations personnelles</span>
                    </h5>

                    <table className={'tableData'}>
                        <tbody>
                        <tr>
                            <td><b>Nom</b></td>
                            <td><span>{this.state.user.profile.lastname}</span></td>
                        </tr>
                        <tr>
                            <td><b>Prénom</b></td>
                            <td><span>{this.state.user.profile.firstname}</span></td>
                        </tr>
                        <tr>
                            <td><b>Société</b></td>
                            <td><span>{this.state.user.profile.company.name}</span></td>
                        </tr>
                        <tr>
                            <td><b>Fonction</b></td>
                            <td><span>{this.state.user.profile.job}</span></td>
                        </tr>
                        <tr>
                            <td><b>Adresse email</b></td>
                            <td><span>{this.state.user.email}</span></td>
                        </tr>
                        <tr>
                            <td><b>Adresse postale</b></td>
                            <td><span>{this.state.user.profile.address}</span></td>
                        </tr>
                        <tr>
                            <td><b>Code postal</b></td>
                            <td><span>{this.state.user.profile.zipcode}</span></td>
                        </tr>
                        <tr>
                            <td><b>Ville</b></td>
                            <td><span>{this.state.user.profile.city}</span></td>
                        </tr>
                        <tr>
                            <td><b>Téléphone fixe</b></td>
                            <td><span>{this.state.user.profile.phone}</span></td>
                        </tr>
                        <tr>
                            <td><b>Téléphone mobile</b></td>
                            <td><span>{this.state.user.profile.mobile}</span></td>
                        </tr>
                        </tbody>
                    </table>

                    {/* Button */}
                    <div className={"modalFooter"}>
                        <div className={'buttonModal'}>
                            <button type={'button'} className={'cancel reject'}
                                    onClick={() => this.handleApprovedUser(false)}
                                    disabled={this.state.loadingApproved || this.state.loadingNotApproved}>
                                {this.state.loadingNotApproved ?
                                    <Loader type="button" color={'blue'} backgroundColor={"transparent"}/> : 'Refuser'}
                            </button>
                            <button type={'button'} onClick={() => this.handleApprovedUser(true)}
                                    disabled={this.state.loadingApproved || this.state.loadingNotApproved}>
                                {this.state.loadingApproved ?
                                    <Loader type="button" color={'blue'} backgroundColor={"transparent"}/> : 'Accepter et compléter le profil'}
                            </button>
                        </div>
                    </div>
                </Modal>}
            </>
        )
    }

}