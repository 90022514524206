import React from 'react';
import PropTypes from "prop-types";
import Button from "../Form/Button/Button";
import "./Filter.scss";

export default class FilterNews extends React.Component {

    static propTypes = {
        onFilter: PropTypes.func
    };

    state = {
        mobileFilter: false,
        onFilter: () => {
        }
    };

    constructor(props) {
        super(props);
        this.btnFilters = [];
    }

    filter(by, e) {
        const TARGET        = e.currentTarget;
        const IS_ACTIVE     = TARGET.classList.contains('active');
        const IS_DECREASING = TARGET.classList.contains('decreasing');

        // remove all other active filter
        for (let i = 0; i < this.btnFilters.length; i++) {
            this.btnFilters[i].classList.remove('active');
            this.btnFilters[i].classList.remove('decreasing');
        }

        TARGET.classList.add('active');

        if (!IS_ACTIVE) {
            TARGET.classList.add('decreasing');
        } else if (IS_ACTIVE && !IS_DECREASING) {
            TARGET.classList.add('decreasing')
        } else {
            TARGET.classList.remove('decreasing')
        }

        this.props.onFilter({
            sort: by,
            orderBy: !(IS_ACTIVE && IS_DECREASING) ? 'asc' : 'desc'
        });
    }

    setRef(input) {
        if (input !== undefined && input !== null) {
            this.btnFilters.push(input.reference());
        }
    }

    render() {
        return (
            <div className="filter">
                <div className="container">
                    <ul className={this.state.mobileFilter ? 'mobile' : null}>
                        <li>
                            <div>TRIER PAR :</div>
                            <button type={'button'} className={'toggleMobile'} onClick={() => this.setState((state) => ({mobileFilter: !state.mobileFilter}))}>
                                <span>Filtre</span>
                            </button>
                        </li>
                        <li>
                            <Button type="button" onClick={(e) => this.filter('views', e)} ref={(input) => this.setRef(input)}>
                                Les plus consultés
                            </Button>
                        </li>
                        <li>
                            <Button type="button" onClick={(e) => this.filter('created_at', e)} ref={(input) => this.setRef(input)}>
                                Les plus récents
                            </Button>
                        </li>
                        {/*<li>*/}
                        {/*    <Button type="button" onClick={(e) => this.filter('period', e)} ref={(input) => this.setRef(input)}>*/}
                        {/*        Période*/}
                        {/*    </Button>*/}
                        {/*</li>*/}
                        {/*<li>*/}
                        {/*    <Button type="button" onClick={(e) => this.filter('thematics', e)} ref={(input) => this.setRef(input)}>*/}
                        {/*        Ordre Thématiques*/}
                        {/*    </Button>*/}
                        {/*</li>*/}
                    </ul>
                </div>
            </div>
        )
    }
}