import React from 'react';
import PropTypes from "prop-types";
import {path} from "../../Config/routes";
import {getColor} from "../../Utils/Colors";
import {Link} from "react-router-dom";
import InfoBubble from "../InfoBubble/InfoBubble";
import Button from "../Form/Button/Button";
import {DateHandler} from "../../Utils/DateHandler";


export default class PreviewNews extends React.Component {

    static propTypes = {
        news: PropTypes.shape({
            id: PropTypes.number,
            title: PropTypes.string,
            excerpt: PropTypes.string,
            content: PropTypes.string,
            createdat: PropTypes.shape({
                date: PropTypes.string
            }),
        }),
        left: PropTypes.bool,
        infoBubble: PropTypes.bool,
        excerptLength: PropTypes.number,
        showDate: PropTypes.bool,
        editMode: PropTypes.bool,
        deleteMode: PropTypes.bool,
        onEdit: PropTypes.func,
        onDelete: PropTypes.func
    };

    static defaultProps = {
        news: {
            id: null,
            title: '',
            excerpt: '',
            content: '',
            visual: {},
            createdat: {
                date: ''
            },
        },
        header: false,
        infoBubble: true,
        showMore: true,
        showDate: false,
        excerptLength: 200,
        onEdit: () => {},
        onDelete: () => {}
    };

    handleEvent(news) {
        if (this.props.editMode) return this.props.onEdit(news);
        else if (this.props.deleteMode) return this.props.onDelete(news);
    }

    render() {
        const IS_MANAGE_MODE = this.props.editMode || this.props.deleteMode;
        const DATE = this.props.news.publishedat && this.props.news.publishedat.date && DateHandler.getPrettyDate(this.props.news.publishedat.date);
        const EXCERPT_NO_ELLIPSIS = this.props.news.excerpt ? this.props.news.excerpt.substring(0, this.props.news.excerpt.length - 3) : '';
        const EXCERPT = EXCERPT_NO_ELLIPSIS && EXCERPT_NO_ELLIPSIS.length >= this.props.excerptLength
            ? EXCERPT_NO_ELLIPSIS.substring(0, this.props.excerptLength) + '...'
            : this.props.news.excerpt;

        const VISUAL = this.props.news.visual && this.props.news.visual.url ? this.props.news.visual.url : this.props.news.visual;


        if (Object.keys(this.props.news).length) {
            return (
                <Link className={`link-single-news${IS_MANAGE_MODE ? ' manage' : ''}`} to={path('news', {id: this.props.news.id})} onClick={(e) => {
                    if (this.props.editMode || this.props.deleteMode) e.preventDefault();
                    this.handleEvent(this.props.news)
                }}>
                    <div className={`single-news-container ${this.props.left ? 'left' : ''}`}>
                        {/* Header */}
                        <div className={'news-header'}>
                            <div className={'imageNoOverflow'}>
                                <div style={{backgroundImage: `url(${VISUAL})`}}/>
                            </div>
                            {this.props.header}
                            {/* Info bubble */}
                            {this.props.infoBubble && <InfoBubble color={getColor('green')}>
                                <span>{DATE}</span>
                            </InfoBubble>}
                        </div>
                        <div className="news-content">
                            {this.props.showDate && <div className={'news-date'}>{DATE}</div>}
                            <p className="news-title">{this.props.news.title}</p>
                            <p className="news-description">
                                <span dangerouslySetInnerHTML={{__html: EXCERPT}}/>&nbsp;
                                {this.props.showMore && <Button type={'button'} className={'blue'}>En savoir plus</Button>}
                            </p>
                            {/* Edit mode */}
                            {this.props.editMode && <div className={'mode edit'}/>}
                            {/* Delete mode */}
                            {this.props.deleteMode && <div className={'mode delete'}/>}
                        </div>
                    </div>
                </Link>
            );
        } else {
            return <></>;
        }
    }

}