import React from 'react';
import PropTypes from "prop-types";
import scssVariable from '../../Styles/helpers/_variables.scss';
import InfoBubble from "../InfoBubble/InfoBubble";
import {DateHandler} from "../../Utils/DateHandler";
import {path} from "../../Config/routes";
import {Link} from "react-router-dom";
import "./WorkGroup.scss";
import {red, blue, darkGreen} from '../../Styles/helpers/_variables.scss';
import Loader from "../Loader/Loader";


export default class WorkGroup extends React.Component {

    static propTypes = {
        workGroup: PropTypes.object.isRequired,
        color: PropTypes.string,
        header: PropTypes.bool,
        editMode: PropTypes.bool,
        archiveMode: PropTypes.bool,
        deleteMode: PropTypes.bool,
        limitDoc: PropTypes.number,
        onEdit: PropTypes.func,
        onArchive: PropTypes.func,
        onDelete: PropTypes.func,
        loading: PropTypes.bool
    };

    static defaultProps = {
        header: false,
        color: scssVariable.lightGrey,
        editMode: false,
        archiveMode: false,
        deleteMode: false,
        onEdit: () => {
        },
        onArchive: () => {
        },
        onDelete: () => {
        },
    };

    constructor(props) {
        super(props);
        this.nbFiles = this.props.workGroup.files && this.props.workGroup.files.length;

        if (this.nbFiles < 10) {
            this.nbFiles = '0' + this.nbFiles;
        }
    }

    render() {

        let button = '';

        switch (true) {
            case this.props.editMode:
                button = (<button style={{backgroundColor: darkGreen}}
                                  onClick={() => this.props.onEdit(this.props.workGroup)}>
                    <span>Modifier le groupe de travail</span>
                </button>);
                break;

            case this.props.archiveMode:
                button = (
                    <button style={{backgroundColor: blue}} onClick={() => this.props.onArchive(this.props.workGroup)}>
                        <span>{(this.props.workGroup['archivedat'] ? 'Désarchiver' : 'Archiver') + ' le groupe de travail'}</span>
                    </button>);
                break;

            case this.props.deleteMode && false == this.props.loading:
                button = (
                    <button style={{backgroundColor: red}} onClick={() => this.props.onDelete(this.props.workGroup)}>
                        <span>Supprimer le groupe de travail</span>
                    </button>);
                break;

            case false == this.props.loading:
                button = (<Link to={path('workGroups', {id: this.props.workGroup.id})}
                            style={{backgroundColor: this.props.color}}>
                        <span>Accéder au groupe de travail</span>
                    </Link>);
                break;

            default:
                // React wants it
                break;
        }

        return (
            <div className={`work-group ${this.props.workGroup['archivedat'] ? 'archived' : ''}`} style={{position: 'relative'}}>
                {/* Header */}
                {this.props.header
                    ? <div className="group-header" style={{backgroundImage: `url(${this.props.workGroup.img})`}}>
                        <InfoBubble color={'#fff'}> <span>{this.nbFiles}</span> </InfoBubble>
                        <h3 className={this.props.workGroup['archivedat'] ? "archived-icon" : ''}>{this.props.workGroup.name}</h3>
                    </div>
                    : <div className={this.props.workGroup['archivedat'] ? "title archived-icon" : 'title'}
                           title={this.props.workGroup['archivedat'] ? `Archivé le ${DateHandler.getPrettyDate(this.props.workGroup['archivedat'].date)}` : ''}>
                        {this.props.workGroup.name}
                    </div>}
                <ul>
                    {this.props.loading
                        ? <li className={'empty'}><Loader type={"button"} backgroundColor={'transparent'}/></li>
                        : this.props.workGroup.documents && this.props.workGroup.documents.length > 0
                            // Each documents
                            ? this.props.workGroup.documents.filter((doc) => !doc.archivedat).slice(0, this.props.limitDoc).map((document, i) => {
                                // Each file
                                return document.files.map((file) => {
                                    const FILE_EXTENSION = file.name.split('.').pop().toUpperCase();
                                    return <li key={i}>
                                        <a className={'download'} href={file.url} target={'_blank'}>
                                            <div>
                                            <span
                                                className="date">{DateHandler.getPrettyDate(document.publishedat.date)}</span>
                                                <span className={"document"} style={{
                                                    borderColor: this.props.color,
                                                    color: this.props.color
                                                }}>{FILE_EXTENSION}</span>
                                            </div>
                                            <p>{document.title}</p>
                                        </a>
                                    </li>
                                });

                            })
                            : <li className={'empty'}><i>Accès réservé</i></li>}
                </ul>
                {button}
            </div>
        );
    }

}
