import React from 'react';
import PropTypes from "prop-types";
import "./InputFile.scss";

const DEFAULT_EMPTY_NAME = 'Aucun fichier choisi';

export default class InputFile extends React.Component {

    static propTypes = {
        id: PropTypes.string.isRequired,
        label: PropTypes.string,
        name: PropTypes.string,
        fileName: PropTypes.string,
        errorMsg: PropTypes.string,
        onChange: PropTypes.func,
        onRemove: PropTypes.func,
        accept: PropTypes.string,
    };

    static defaultProps = {
        accept: '.xls,.xlsx,.xlsm,.doc,.docx,.ppt,.pptx,.pdf,.zip,.rar,.odp,.odt,.ods',
        onRemove: () => {}
    };

    state = {
        fileName: DEFAULT_EMPTY_NAME
    };

    constructor(props) {
        super(props);
        this.input = React.createRef();
    }


    handleChange(e) {
        const FILE = e.target.files[0];

        if (FILE) {
            this.setState({fileName: FILE && FILE.name});
        }

        return this.props.onChange(FILE);
    }

    handleRemove() {
        this.input.current.value = '';
        this.setState({fileName: DEFAULT_EMPTY_NAME});
        this.props.onRemove(this.props.id);
    }

    render() {
        const fileName = this.props.fileName || this.state.fileName;
        let displayRemoveButton;

        if (fileName !== DEFAULT_EMPTY_NAME) {
            displayRemoveButton = true;
        }

        return (
            <div className={`inputFile${this.props.errorMsg ? ' error' : ''}`}>
                <span className={'label'}>{this.props.label}</span>
                <div>
                    <label className={'button'} htmlFor={this.props.id}>Parcourir</label>
                    <span className={'fileName'}>{fileName}</span>
                    <input id={this.props.id}
                           ref={this.input}
                           type="file"
                           accept={this.props.accept}
                           name={this.props.name || this.props.id}
                           onChange={(e) => this.handleChange(e)}/>
                    {/* Remove button */}
                    {displayRemoveButton && <button type={'button'} onClick={() => this.handleRemove()}>x</button>}
                </div>
                {this.props.errorMsg && <span className={'errorMsg'}>{this.props.errorMsg}</span>}
            </div>
        )
    }
}