import React from 'react';
import PropTypes from "prop-types";
import "./InfoBubble.scss";

export default class InfoBubble extends React.Component {

    static propTypes = {
        color: PropTypes.string.isRequired
    };

    static defaultProps = {
        className: ''
    };

    render() {
        return (
            <div className={"info-bubble " + this.props.className}>
                <svg xmlns="http://www.w3.org/2000/svg" width="103.961" height="85.302" viewBox="0 0 103.961 85.302">
                    <path d="M69.977,103.961H0V0H69.977V29.572a19.536,19.536,0,0,0,6.462,14.869l8.862,7.54-8.409,6.633a19.406,19.406,0,0,0-6.915,15.241Z" transform="translate(103.961) rotate(90)" fill={this.props.color}/>
                </svg>
                {this.props.children}
            </div>
        );
    }
}