import Login from "../Pages/Login/Login";
import Dashboard from "../Pages/Dashboard/Dashboard";
import DocumentationList from "../Pages/Documentation/DocumentationList";
import Document from "../Pages/Documentation/Document";
import ListNews from "../Pages/News/ListNews";
import Agenda from "../Pages/Agenda/Agenda";
import NotFound from "../Errors/NotFound/NotFound";
import WorkGroupList from "../Pages/WorkGroup/WorkGroupList";
import WorkGroup from "../Pages/WorkGroup/WorkGroup";
import AdminUser from "../Pages/Admin/AdminUser/AdminUser";
import AdminDocumentation from "../Pages/Admin/AdminDocumentation/AdminDocumentation";
import SingleNew from "../Pages/News/SingleNew";
import LegalNotices from "../Pages/Static/LegalNotices/LegalNotices";
import AdminNews from "../Pages/Admin/AdminNews/AdminNews";
import Profile from "../Pages/Profile/Profile";
import Contact from "../Pages/Static/Contact/Contact";
import MyNotifications from "../Pages/MyNotifications/MyNotifications";
import AgendaDetail from "../Pages/Agenda/AgendaDetail";
import AdminManageDocumentation from "../Pages/Admin/AdminDocumentation/AdminManageDocumentation/AdminManageDocumentation";
import EditTree from "../Pages/Admin/AdminDocumentation/AdminManageDocumentation/EditTree/EditTree";
import Recovery from "../Pages/Login/Recovery";
import Rgpd from "../Pages/Static/Rgpd/Rgpd";
import UnsubscribeNews from "../Pages/Static/Unsubscribe/UnsubscribeNews";

export const routes = [
    {
        path: '/',
        name: 'home',
        component: Dashboard,
        exact: true
    },
    {
        path: '/login',
        name: 'login',
        component: Login,
        public: true
    },
    {
        path: '/password-recovery',
        name: 'recovery',
        component: Recovery,
        public: true
    },
    {
        path: '/desabonnement/actualites',
        name: 'unsubcribe_news',
        component: UnsubscribeNews,
        public: true
    },
    {
        path: '/documentation/:category?/:categoryTwo?/:categoryThree?/:categoryFour?/:categoryFive?',
        name: 'documentationList',
        component: DocumentationList
    },
    {
        path: '/document/:category?/:categoryTwo?/:categoryThree?/:categoryFour?/:categoryFive?/:document?',
        name: 'document',
        component: Document
    },
    {
        path: '/document-work-group/:workgroup?/:document?',
        name: 'workgroup-document',
        component: Document
    },
    {
        path: '/work-groups-list/:category?/:subcategory?',
        name: 'WorkGroupList',
        component: WorkGroupList
    },
    {
        path: '/work-groups/:id?',
        name: 'workGroups',
        component: WorkGroup
    },
    {
        path: '/news/:id',
        name: 'news',
        exact: true,
        component: SingleNew
    },
    {
        path: '/news',
        name: 'news',
        component: ListNews
    },
    {
        path: '/agenda',
        name: 'agenda',
        exact: true,
        component: Agenda
    },
    {
        path: '/agenda/:id',
        name: 'agendaDetail',
        exact: true,
        component: AgendaDetail
    },
    {
        path: '/create-account',
        name: 'createAccount',
        component: Login,
        public: true
    },
    {
        path: '/profil',
        name: 'profile',
        component: Profile
    },
    {
        path: '/notifications',
        name: 'notifications',
        component: MyNotifications
    },
    {
        path: '/mentions-legals',
        name: 'legalNotice',
        component: LegalNotices
    },
    {
        path: '/politique-des-donnees',
        name: 'rgpd',
        public: true,
        component: Rgpd
    },
    {
        path: '/contact',
        name: 'contact',
        component: Contact
    },
    {
        path: '/admin/utilisateur',
        name: 'adminUser',
        isAdmin: true,
        component: AdminUser
    },
    {
        path: '/admin/documentation/:category?/:categoryTwo?/:categoryThree?/:categoryFour?/:categoryFive?',
        name: 'adminDocumentation',
        isAdmin: true,
        component: AdminDocumentation
    },
    {
        path: '/admin/documentation-gestion-de-l-arborescence/editTree/:id',
        name: 'adminManageDocumentationEditTree',
        isAdmin: true,
        component: EditTree,
    },
    {
        path: '/admin/documentation-gestion-de-l-arborescence',
        name: 'adminManageDocumentation',
        isAdmin: true,
        component: AdminManageDocumentation
    },
    {
        path: '/admin/news',
        name: 'adminNews',
        isAdmin: true,
        component: AdminNews
    },
    {
        path: "**",
        component: NotFound
    }
];

/**
 * Return the path for a route name or false if not found
 * @param name Route's name
 * @param [args] path args
 * @return {boolean|string}
 */
export const path = (name, args = {}) => {
    if (typeof name !== "string" || typeof args !== "object") {
        console.log(name, args);
        console.error('Parameter has wrong type');
        return false
    }

    for (let i = 0; i < routes.length; i++) {
        const ROUTE = routes[i];

        // We found the route
        if (name === ROUTE.name) {
            let path = ROUTE.path;
            let keys = Object.keys(args);
            // Check if we have some args
            if (keys.length > 0) {
                // If we have two or more args sort it too match to order of path
                // if (keys.length > 1) {
                //     keys.sort((a, b) => {
                //         const MATCH_A = path.match(`:${a}`);
                //         const MATCH_B = path.match(`:${b}`);
                //
                //         if (MATCH_A !== null && MATCH_B !== null) {
                //             if (MATCH_A.index < MATCH_B.index) {
                //                 return -1;
                //             } else {
                //                 return 1;
                //             }
                //         }
                //
                //         return 0;
                //     });
                //
                //     // Replace path parameter with args
                //     for (let j = 0; j < keys.length; j++) {
                //         if (!path.match(`:${keys[j]}`)) {
                //             console.error(`args ${keys[j]} do not exist`);
                //             return false;
                //         }
                //         path = path.replace(`:${keys[j]}`, `${args[keys[j]]}`);
                //     }
                // } else {
                //     if (!path.match(`:${keys[0]}`)) {
                //         console.error(`args ${keys[0]} not exist`);
                //         return false;
                //     }
                //     path = path.replace(`:${keys[0]}`, `${args[keys[0]]}`);
                // }
                let computedPath = '';

                // Parse all path parts (removing leading /)
                path.replace(/^\/+/, '').split('/').forEach((part, j) => {
                    // Replace token param identifier : and ? by nothing
                    let cleanedPart = part.replace(/[:?]/g, '');
                    // Then check if the token identifier cleaned is part of the given parameters
                    if (args[cleanedPart]) {
                        // Transform the part with the given value from args and add it to the URL that will be returned
                        computedPath += '/' + part.replace(`${part}`, `${args[cleanedPart]}`);
                    } else if (part.match(/[:?]/g) === null) {
                        // No token param identifier found, we can add the part of the URL directly to the one that will be returned
                        computedPath += '/' + part;
                    }
                });
                path = computedPath;

                return path;
            } else if (path.match(/:.*$/)) {
                return path.replace(/:.*$/, '');
            }

            return path;
        }
    }

    // Route name not found
    console.error(`Route ${name} not found`);
    return false;
};

export const findRouteWithPathName = (pathname) => {
    if (typeof pathname !== "string") {
        console.error('Pathname has wrong type');
        return false
    }

    for (let i = 0; i < routes.length; i++) {

        if (pathname === routes[i].path) {
            return routes[i];
        }
    }

    return false;
};
