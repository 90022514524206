import axios from "axios";
import Api, { PATH } from "../Utils/Api";
import { DateHandler } from "../Utils/DateHandler";

export default class ApiAgenda {
    /**
     * Get single event
     * @param id
     * @param nestedEntities
     * @returns {AxiosPromise<any>}
     */
    static getEvent = (id, nestedEntities = []) => {
        const PARAMS = {
            "api[nested_entities]": Api.buildNestedEntity(nestedEntities),
        };

        return axios.get(`${PATH}/agenda/event/${id}`, { params: PARAMS });
    };

    /**
     * Get the closest events from a given date
     * @param date
     * @param offset
     * @param limit
     * @param thematicId
     * @returns {AxiosPromise<any>}
     */
    static getClosestEventsFromDate = (
        date,
        offset = 0,
        limit = 2,
        thematicId = null
    ) => {
        const PARAMS = {
            "api[sort]": "starting_at",
            "api[order]": "asc",
            "api[where]": `where (\`starting_at\` > \`${date
                .toJSON()
                .slice(0, 10)}\`${
                thematicId ? " AND `thematic` = " + thematicId : ""
            })`,
            "api[offset]": `${offset}`,
            "api[limit]": `${limit}`,
        };

        return axios.get(`${PATH}/agenda/event`, { params: PARAMS });
    };

    /**
     * Get the closest events from a given date
     * @param date
     * @returns {AxiosPromise<any>}
     */
    static getCurrentWeekEvents = (date = undefined) => {
        const startingDate = DateHandler.getMondayFromCurrentDate(date)
            .hour(0)
            .minute(0)
            .second(0)
            .format("Y-MM-DD HH:mm:ss");
        const endingDate = DateHandler.getSundayFromCurrentDate(date)
            .hour(23)
            .minute(59)
            .second(59)
            .format("Y-MM-DD HH:mm:ss");

        return this.getEventsStartingInDateRange(startingDate, endingDate);
    };

    /**
     * Get event from range
     * @param {string}startingDate
     * @param {string}endingDate
     * @returns {AxiosPromise<any>}
     */
    static getEventsStartingInDateRange = (startingDate, endingDate) => {
        const START = DateHandler.getPrettyDate(
            startingDate,
            "Y-MM-DD HH:mm:ss"
        );
        const END = DateHandler.getPrettyDate(endingDate, "Y-MM-DD HH:mm:ss");

        const PARAMS = {
            "api[nested_entities]": `thematic`,
            "api[sort]": "starting_at",
            "api[order]": "asc",
            "api[where]": `where (\`starting_at\` > \`${START}\`) and (\`starting_at\` < \`${END}\`)`,
        };

        return axios.get(`${PATH}/agenda/event`, { params: PARAMS });
    };

    static addEvent = (event) => {
        return axios.post(`${PATH}/agenda/event`, event);
    };

    static updateEvent = (event) => {
        return axios.patch(`${PATH}/agenda/event/${event.id}`, event);
    };

    static removeEvent = (id) => {
        return axios.delete(`${PATH}/agenda/event/${id}`);
    };
}
