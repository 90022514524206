import {path as routePath} from "../Config/routes";
import Thematic from "./Thematics";

function getPath(document, path, isFileForDocument = true) {
    if (!document || document.id === 0 || !isNaN(document)) {
        path.href = `${isFileForDocument ? '/document' : '/document-work-group'}` + path.href;
        if (!isNaN(document)) {
            let thematic = Thematic.getThematic(document);
            path.breadCrumb.unshift({url: thematic.uid, label: thematic.name});
        }

        path.breadCrumb.unshift({url: routePath('documentationList'), label: 'Documentation'});
        return path;
    } else {
        path.href = '/' + document.uid + path.href;
        path.breadCrumb.unshift({url: document.uid, label: document.name});
        return getPath(document.parent || document.thematicid, path, isFileForDocument);
    }
}

export default {
    getDocumentPath: (document, isFileForDocument = true) => {
        let path = getPath(isFileForDocument ? document.category : document.workgroup, {
                href: '/' + document.uid,
                breadCrumb: [{label: document.title}]
            },
            isFileForDocument);

        let pathParameters = {category: '', categoryTwo: '', categoryThree: '', categoryFour: '', categoryFive: ''};
        let keyParameters  = Object.keys(pathParameters);
        let breadCrumb     = [path.breadCrumb[0]];

        for (let i = 1; i < path.breadCrumb.length - 1; i++) {
            let level = path.breadCrumb[i];

            pathParameters[keyParameters[i - 1]] = level.url;

            breadCrumb.push({
                url: routePath('documentationList', pathParameters),
                label: level.label
            });
        }

        breadCrumb.push(path.breadCrumb[path.breadCrumb.length - 1]);

        path.breadCrumb = breadCrumb;

        return path;
    }
}