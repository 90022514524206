import React, { Component } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { guid, getLast, getFirst } from "react-agenda/src/helpers";
import Rdate from "react-datetime";
import CKEditor from "ckeditor4-react";
import ThematicsRectangle from "../Form/ThematicsRectangle/ThematicsRectangle";
import { CK_EDITOR } from "../../Config/ckEditor";
import "./AgendaCtrl.scss";
import { DateHandler } from "../../Utils/DateHandler";
import InputTextLight from "../Form/InputTextLight/InputTextLight";
import Loader from "../Loader/Loader";
import Auth from "../../Security/Auth";
import joursFeries from "@socialgouv/jours-feries";

const now = new Date();

export default class AgendaCtrl extends Component {
    static propTypes = {
        items: PropTypes.array,
        itemColors: PropTypes.object,
        thematic: PropTypes.array.isRequired,
        content: PropTypes.string,
        selectedCells: PropTypes.array,
        edit: PropTypes.func,
        Addnew: PropTypes.func,
        cancel: PropTypes.func,
        loading: PropTypes.bool,
    };

    static defaultProps = {
        items: [],
        thematic: [],
        itemColors: {},
        selectedCells: [],
    };

    constructor(props) {
        super(props);
        this.state = {
            editMode: false,
            showCtrl: false,
            multiple: {},
            name: "",
            place: "",
            classes: "priority-1",
            startDateTime: now,
            endDateTime: now,
            content: "",
            // thematic: this.props.selectedCells[0].thematic,
            thematic: null,
            error: {
                name: "",
                thematic: "",
            },
            formError: "",
        };
        this.handleDateChange = this.handleDateChange.bind(this);
        this.addEvent = this.addEvent.bind(this);
        this.updateEvent = this.updateEvent.bind(this);
        this.dispatchEvent = this.dispatchEvent.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleEdit = this.handleEdit.bind(this);
    }

    componentDidMount() {
        if (this.props.itemColors) {
            this.setState({
                classes: Object.keys(this.props.itemColors)[0],
            });
        }

        if (!this.props.selectedCells) {
            let start = now;
            let endT = moment(now).add(15, "Minutes");
            return this.setState({
                editMode: false,
                name: "",
                startDateTime: start,
                endDateTime: endT,
            });
        }

        // if edit mode
        if (
            this.props.selectedCells &&
            this.props.selectedCells[0] &&
            this.props.selectedCells[0]._id
        ) {
            let start = moment(this.props.selectedCells[0].startDateTime);
            let endT = moment(this.props.selectedCells[0].endDateTime);

            return this.setState({
                editMode: true,
                name: this.props.selectedCells[0].name,
                classes: this.props.selectedCells[0].classes,
                startDateTime: start,
                endDateTime: endT,
                content: this.props.selectedCells[0].content,
                thematic: this.props.selectedCells[0].thematic,
                place: this.props.selectedCells[0].place,
            });
        }

        if (this.props.selectedCells && this.props.selectedCells.length === 1) {
            let start = moment(getFirst(this.props.selectedCells));
            let endT = moment(getLast(this.props.selectedCells)).add(
                60,
                "Minutes"
            );
            return this.setState({
                editMode: false,
                name: "",
                startDateTime: start,
                endDateTime: endT,
            });
        }

        if (this.props.selectedCells && this.props.selectedCells.length > 0) {
            let start = moment(getFirst(this.props.selectedCells));
            let endT = moment(getLast(this.props.selectedCells)) || now;
            this.setState({
                editMode: false,
                name: "",
                startDateTime: start,
                endDateTime: endT,
            });
        }
    }

    handleChange(event) {
        if (event.target.tagName === "BUTTON") {
            event.preventDefault();
        }

        const data = this.state;
        data[event.target.name] = event.target.value;
        data.error.name = "";

        if (!data.error.thematic && !data.error.name) {
            data.formError = "";
        }

        this.setState(data);
    }

    handleThematicChange(selectedThematic) {
        this.setState(
            (state) => ({
                thematic: selectedThematic,
                error: {
                    ...state.error,
                    thematic: "",
                },
            }),
            () => {
                if (!this.state.error.name && !this.state.error.thematic) {
                    this.setState({ formError: "" });
                }
            }
        );
    }

    handleDateChange(ev, date) {
        const endD = moment(this.state.endDateTime);
        const data = this.state;
        data[ev] = date;

        if (ev === "startDateTime" && endD.diff(date) < 0) {
            data["endDateTime"] = moment(date).add(15, "minutes");
        }

        this.setState(data);
    }

    dispatchEvent(obj) {
        const newAdded = [];
        const items = this.props.items;
        if (obj["multiple"]) {
            let array = obj["multiple"];
            Object.keys(array).forEach(function (key) {
                let newAr = array[key].filter(function (val, ind) {
                    return array[key].indexOf(+val) === +ind;
                });
                let start = newAr[0];
                let endT = newAr[newAr.length - 1] || now;
                let lasobj = {
                    _id: guid(),
                    name: obj.name,
                    startDateTime: new Date(start),
                    endDateTime: new Date(endT),
                    classes: obj.classes,
                };
                items.push(lasobj);
                newAdded.push(lasobj);
            });
            return this.props.Addnew(items, newAdded);
        }

        obj._id = guid();
        items.push(obj);
        this.props.Addnew(items, obj);
    }

    checkForm() {
        let error = this.state.error;
        let hasError = false;
        if (this.state.name.length < 1) {
            error.name = "Le titre est requis";
            hasError = true;
        }
        if (this.state.thematic === undefined) {
            error.thematic = "La thématique est requise";
            hasError = true;
        }

        if (hasError) {
            this.setState({
                error: error,
                formError: "Des erreurs sont présentes dans le formulaire.",
            });

            return true;
        } else {
            return false;
        }
    }

    addEvent(e) {
        if (!this.checkForm()) {
            if (
                this.props.selectedCells &&
                this.props.selectedCells.length > 0
            ) {
                // eslint-disable-next-line no-sequences
                let obj = this.props.selectedCells.reduce(
                    (r, v, i, a, k = v.substring(0, 10)) => (
                        (r[k] = r[k] || []).push(v), r
                    ),
                    {}
                );

                if (Object.values(obj).length > 1) {
                    let newObj = {
                        title: this.state.name,
                        startingat: DateHandler.formatForApi(
                            this.state.startDateTime
                        ),
                        finishingat: DateHandler.formatForApi(
                            this.state.endDateTime
                        ),
                        classes: this.state.classes,
                        content: this.state.content,
                        thematic: this.state.thematics,
                        multiple: obj,
                        place: this.state.place,
                    };

                    return this.dispatchEvent(newObj);
                }
            }

            let newObj = {
                title: this.state.name,
                startingat: DateHandler.formatForApi(this.state.startDateTime),
                finishingat: DateHandler.formatForApi(this.state.endDateTime),
                classes: this.state.classes,
                content: this.state.content,
                thematic: this.state.thematic,
                place: this.state.place,
            };

            this.dispatchEvent(newObj);
        }
    }

    updateEvent(e) {
        if (this.props.selectedCells[0]._id && this.props.items) {
            let newObj = {
                id: this.props.selectedCells[0].id,
                title: this.state.name,
                startingat: DateHandler.formatForApi(this.state.startDateTime),
                finishingat: DateHandler.formatForApi(this.state.endDateTime),
                classes: this.state.classes,
                content: this.state.content,
                thematic: this.state.thematic,
                place: this.state.place,
            };
            let items = this.props.items;
            for (let i = 0; i < items.length; i++) {
                if (items[i].id === newObj.id) items[i] = newObj;
            }

            if (this.props.edit) {
                this.props.edit(items, newObj);
            }
        }
    }

    handleSubmit(e) {
        e.preventDefault();
        this.addEvent(e);
    }

    handleEdit(e) {
        e.preventDefault();
        this.updateEvent(e);
    }

    render() {
        const divStyle = {};
        const thematics = this.props.thematics;

        return (
            <div
                className="agendCtrls-wrapper agendCtrls-container"
                style={divStyle}
            >
                <h5>
                    {this.state.editMode
                        ? "Éditer un événement"
                        : "Ajouter un événement"}
                </h5>

                <hr />

                <form
                    onSubmit={
                        this.state.editMode
                            ? this.handleEdit
                            : this.handleSubmit
                    }
                >
                    <div className="topEvent">
                        <div className="inputs">
                            {/* Name */}
                            <div className="group-label-input">
                                <InputTextLight
                                    label={"Titre de l'événement*"}
                                    autoFocus
                                    autoComplete={"off"}
                                    type="text"
                                    ref="eventName"
                                    name="name"
                                    className="agendCtrls-event-input"
                                    value={this.state.name}
                                    errorMsg={this.state.error.name}
                                    onChange={this.handleChange.bind(this)}
                                />
                            </div>

                            {/* Place */}
                            <div className="group-label-input">
                                <InputTextLight
                                    label={"Lieu"}
                                    type="text"
                                    autoComplete={"off"}
                                    name="place"
                                    className="agendCtrls-event-input"
                                    value={this.state.place}
                                    onChange={this.handleChange.bind(this)}
                                />
                            </div>
                        </div>
                        <div>
                            {/* Date picker */}
                            <div className="agendCtrls-timePicker-wrapper">
                                <div className="agendCtrls-time-picker">
                                    <label>Début</label>
                                    <Rdate
                                        value={this.state.startDateTime}
                                        onChange={this.handleDateChange.bind(
                                            null,
                                            "startDateTime"
                                        )}
                                        input={false}
                                        viewMode="time"
                                        isValidDate={DateHandler.isWorkingDay}
                                    />
                                </div>
                                <div className="agendCtrls-time-picker">
                                    <label>Fin</label>
                                    <Rdate
                                        value={this.state.endDateTime}
                                        onChange={this.handleDateChange.bind(
                                            null,
                                            "endDateTime"
                                        )}
                                        input={false}
                                        viewMode="time"
                                        isValidDate={DateHandler.isWorkingDay}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Textarea */}
                    <div className="group-label-input">
                        <label>Description</label>
                        <CKEditor
                            onBeforeLoad={(CKEditor) => {
                                CKEditor.disableAutoInline = true;
                                CK_EDITOR.config.filebrowserUploadUrl =
                                    CK_EDITOR.baseUploadUrl + Auth.getToken();
                            }}
                            config={CK_EDITOR.config}
                            data={this.state.content}
                            type="classic"
                            onChange={(event) => {
                                const data = this.state;
                                data["content"] = event.editor.getData();
                                this.setState(data);
                            }}
                        />
                    </div>

                    {/* Thematics */}
                    <ThematicsRectangle
                        title={"Thématique de l'événement*"}
                        thematics={thematics}
                        defaultCheckedId={
                            this.state.thematic ? [this.state.thematic.id] : []
                        }
                        onChange={this.handleThematicChange.bind(this)}
                    />
                    {this.state.error.thematic && (
                        <p style={{ color: "red" }}>
                            {this.state.error.thematic}
                        </p>
                    )}

                    <p className={"group-label-input blue"}>
                        <b>* Champs obligatoires</b>
                    </p>

                    <br />

                    {/* Submit */}
                    <div className={"buttonModal"}>
                        <button
                            type="button"
                            className="cancel"
                            onClick={() => this.props.cancel()}
                        >
                            Annuler
                        </button>
                        <button type="submit" disabled={this.props.loading}>
                            {this.props.loading ? (
                                <Loader
                                    type="button"
                                    color={"white"}
                                    backgroundColor={"#D0ED4D "}
                                />
                            ) : this.state.editMode ? (
                                "Enregistrer"
                            ) : (
                                "Publier"
                            )}
                        </button>
                    </div>
                    <br />
                    {this.state.formError && (
                        <p style={{ color: "red" }}>{this.state.formError}</p>
                    )}
                </form>
            </div>
        );
    }
}
