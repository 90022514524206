import React from "react";
import * as qs from "qs";
import ApiUser from "../../../Services/ApiUser";
import ApiRole from "../../../Services/ApiRole";
import Master from "../../../Components/Layout/Master";
import Banner from "../../../Components/Banner/Banner";
import ToolsManage from "../../../Components/ToolsManage/ToolsManage";
import MembersList from "../../../Components/List/MembersList/MembersList";
import FilterUser from "../../../Components/Filter/FilterUser";
import Modal from "../../../Components/Modal/Modal";
import FormAddUser from "../../../Components/Form/FormAddUser/FormAddUser";
import Loader from "../../../Components/Loader/Loader";
import Pagination from "../../../Components/Pagination/Pagination";
import Roles from "../../../Security/Roles";
import Alert from "../../../Components/Alert/Alert";
import { Http, QueryBuilder } from "../../../Utils/Api";
import "./AdminUser.scss";

export default class AdminUser extends React.Component {
    state = {
        toApproveUsers: [],
        users: [],
        usersBackUp: [],
        roles: [],
        userActiveCount: 0,
        userActiveCountBackUp: 0,
        results: [],
        selects: [],
        isSearch: true,
        searchText: "",
        searchLoading: false,
        editMode: false,
        deleteMode: false,
        fromApprove: true,
        showModalAddMember: false,
        showModalConfirmDeleteMember: false,
        tempUser: null,
        loading: true,
        pagination: null,
        httpError: false,
        openUser: "",
        alert: {
            show: false,
            action: "add",
            value: "",
        },
    };

    constructor(props) {
        super(props);
        this._isMounted = false;
        this.id = null;
        this._QB = this._getQueryBuilder().setWhere("`approved_at` not `null`");

        if (this.props.match.params.id !== undefined) {
            this.id = this.props.match.params.id;
        }
    }

    componentDidMount() {
        this._isMounted = true;

        const PARAMS = qs.parse(this.props.location.search, {
            ignoreQueryPrefix: true,
        });
        const TO_APPROVE_QUERY_BUILDER = this._getQueryBuilder()
            .setWhere("`approved_at` = `null`")
            .setPage(null)
            .setLimit(null);

        const ACTIVE_QUERY_BUILDER = new QueryBuilder()
            .setWhere("`last_login_at` > `" + this.getInnactiveDate().toISOString() + "`")
            .setPage(1)
            .setLimit(1);

        Promise.all([
            this.getUsers(TO_APPROVE_QUERY_BUILDER),
            this.getUsers(this._QB.setPage(PARAMS.page || 1)),
            ApiRole.getRoles(),
            this.getUsers(ACTIVE_QUERY_BUILDER),
        ])
            .then(([toApprove, users, roles, inactiveUsers]) => {
                if (!this._isMounted) return;
                this._handleGetUsersResults(
                    users["payload"],
                    users["extra"],
                    toApprove
                );

                const { email, ...rest } = qs.parse(
                    this.props.location.search,
                    { ignoreQueryPrefix: true }
                );

                this.setState(
                    {
                        roles: roles,
                        openUser: email,
                        userActiveCount: inactiveUsers['extra']['resources']['total'],
                        userActiveCountBackUp: inactiveUsers['extra']['resources']['total']
                    },
                    () => {
                        const search = qs.stringify(rest);
                        this.props.history.replace({
                            ...this.props.location,
                            search,
                        });

                        this.setState({
                            openUser: "",
                        });
                    }
                );
            })
            .catch(() => {
                if (!this._isMounted) return;
                this.setState({
                    httpError: true,
                });
            });
    }

    componentWillUnmount() {
        this._isMounted = false;
        Http.cancel();
    }

    _getQueryBuilder() {
        return new QueryBuilder()
            .setPage(1)
            .setLimit(10)
            .setNestedEntities(["profile.company"])
            .setSort("profile.lastname")
            .setOrderAsc();
    }

    getUsers(queryBuilder) {
        return ApiUser.getUsers(queryBuilder);
    }

    filter(payload) {


        const filter = payload.filter;
        let users = this.state.users;
        this.setState({ loading: true });

        // If sort
        if (filter === "alphabetic") {
            this._QB.setOrder(payload.way).setPage(1);

            this.getUsers(this._QB).then(({ payload, extra }) => {
                if (!this._isMounted) return;
                this._handleGetUsersResults(payload, extra);
            });
        }
        // If filter
        else {
            this._QB.setPage(1);
            if (filter !== "tous-les-role") {
                this._QB.setWhere(
                    `\`approved_at\` not \`null\` AND \`roles.uid\` = \`${payload.filter}\``
                );
            } else {
                this._QB.resetWhere().setWhere("`approved_at` not `null`");
            }

            this.getUsers(this._QB).then(({ payload, extra }) => {
                if (!this._isMounted) return;
                this._handleGetUsersResults(payload, extra);
            });
        }
        // Apply sort
        this.setState({ users: users });
    }

    toggleEditMode() {
        this.setState((state) => ({
            deleteMode: false,
            editMode: !state.editMode,
        }));
    }

    toggleDeleteMode() {
        this.setState((state) => ({
            editMode: false,
            deleteMode: !state.deleteMode,
        }));
    }

    openFormEditMember(member, fromApprove = false) {
        this.setState({
            fromApprove: fromApprove,
            tempUser: member,
            showModalAddMember: true,
        });
    }

    openFormAddUser() {
        this.setState({
            editMode: false,
            deleteMode: false,
            showModalAddMember: true,
        });
    }

    updateStateUsers(member = null, fromApprove = false) {
        if (member) {
            if (fromApprove) {
                this.updateStateUsersToApprove(member);
            }

            // Editing member
            if (member.hasOwnProperty("update") && member.id > 0) {
                const USER_LIST_TO_UPDATE = this.state.fromApprove
                    ? "toApproveUsers"
                    : "users";
                const USERS = [];
                this.state[USER_LIST_TO_UPDATE].forEach((user) => {
                    if (user.id === member.id) {
                        USERS.push(member);
                    } else {
                        USERS.push(user);
                    }
                });

                this.setState(
                    {
                        [USER_LIST_TO_UPDATE]: USERS,
                        alert: {
                            show: true,
                            action: "update",
                            value: this.getMemberName(member),
                            fromApprove: false,
                        },
                    },
                    () => {
                        this.setState({ showModalAddMember: false });
                    }
                );
            }
            // Adding member
            else {
                let newUsers = this.state.users;
                newUsers.push(member);

                newUsers.sort((a, b) => {
                    let aName = a.profile.lastname.toLowerCase();
                    let bName = b.profile.lastname.toLowerCase();
                    if (aName < bName) {
                        return -1;
                    } else {
                        return 1;
                    }
                });

                if (newUsers.length > this._QB.getLimit()) {
                    newUsers.pop();
                }

                this.setState(
                    {
                        users: newUsers,
                        alert: {
                            show: true,
                            action: "add",
                            value: this.getMemberName(member),
                            fromApprove: false,
                        },
                    },
                    () => {
                        this.setState({ showModalAddMember: false });
                    }
                );
            }
        }
    }

    updateStateUsersToApprove(member) {
        this.setState((state) => {
            let toApproveUsers = state.toApproveUsers.filter(
                (toApproveUser) => {
                    return toApproveUser.id !== member.id;
                }
            );

            return { toApproveUsers: toApproveUsers };
        });
    }

    deleteMember(member) {
        this.setState({
            tempUser: member,
            showModalConfirmDeleteMember: true,
        });
    }

    confirmDeleteMember(member, isReject = false) {
        ApiUser.delete(member.id).then(() => {
            if (!this._isMounted) return;
            let newsUsers = this.state.users;

            for (let property in newsUsers) {
                if (
                    newsUsers.hasOwnProperty(property) &&
                    newsUsers[property].id === member.id
                ) {
                    newsUsers.splice(property, 1);
                }
            }

            this.setState(
                {
                    users: newsUsers,
                    showModalConfirmDeleteMember: false,
                    alert: {
                        show: true,
                        action: isReject ? "refuse" : "delete",
                        value: this.getMemberName(member),
                    },
                },
                () => {
                    this.setState({ tempUser: null });
                }
            );

            if (isReject) this.updateStateUsersToApprove(member);
        });
    }

    handleSearch(searchText) {
        if (searchText) {
            this.setState({ searchLoading: true });

            ApiUser.searchUsers(searchText, this._QB).then((users) => {
                if (!this._isMounted) return;

                this.setState(
                    {
                        searchLoading: false,
                    },
                    () => {
                        this._handleGetUsersResults(
                            users.payload,
                            users.extra,
                            []
                        );
                    }
                );
            });
        } else {
            this.resetUsers();
        }
    }

    _handleGetUsersResults(users, pagination, toApproveUsers = null) {
        let state = {
            users: users,
            usersBackUp: users,
            pagination: pagination,
            loading: false,
        };

        if (toApproveUsers !== null) {
            state.toApproveUsers = toApproveUsers;
        }

        let activeQueryBuilder = new QueryBuilder()
            .setWhere('`last_login_at` > `' + this.getInnactiveDate().toISOString() + '` AND (' + this._QB._where + ')')
            .setPage(1)
            .setLimit(1)
        ;

        Promise.all([this.getUsers(activeQueryBuilder)]).then(([inactiveUsers]) => {
            state.userActiveCount = inactiveUsers['extra']['resources']['total'];
            this.setState(state);
        });
    }

    resetUsers() {
        this.setState((state) => ({ 
            users: state.usersBackUp,
            // userActiveCount: state.userActiveCountBackUp,
        }));
    }

    getMemberName(member) {
        return `${member.profile.firstname} ${member.profile.lastname}`;
    }

    getInnactiveDate() {
        let innactiveDate = new Date();
        innactiveDate.setDate(innactiveDate.getDate() - 30); // 30 jours

        return innactiveDate;
    }

    render() {
        return (
            <Master
                id="adminUser"
                httpError={this.state.httpError}
                admin={true}
                {...this.props}
            >
                {/* Banner */}
                <Banner
                    title={"Liste des utilisateurs"}
                    search={true}
                    onSearch={(searchText) => this.handleSearch(searchText)}
                    onToggleSearch={() => this.resetUsers()}
                    searchLoading={this.state.searchLoading}
                />

                <Alert
                    show={this.state.alert.show}
                    type={"user"}
                    action={this.state.alert.action}
                    value={this.state.alert.value}
                    onFinish={() =>
                        this.state.alert.show &&
                        this.setState({
                            alert: {
                                ...this.state.alert,
                                show: false,
                            },
                        })
                    }
                />

                {/* Filter */}
                <FilterUser
                    onFilter={(by, e) => this.filter(by, e)}
                    roles={this.state.roles}
                    total={
                        this.state.pagination
                            ? this.state.pagination.resources.total
                            : 0
                    }
                    totalActive={this.state.userActiveCount}
                />

                {/* Tools */}
                <div className="container">
                    {!this.state.loading && (
                        <ToolsManage
                            onAdd={() => this.openFormAddUser()}
                            onEdit={() => this.toggleEditMode()}
                            onDelete={() => this.toggleDeleteMode()}
                            role={Roles.ROLE_ROOT}
                            loading={this.state.roles.length === 0}
                        />
                    )}
                </div>

                {/* List */}
                <div id="listUser" className={"container"}>
                    {this.state.loading ? (
                        <Loader type={"inline"} />
                    ) : (
                        <>
                            {this.state.toApproveUsers.length > 0 && (
                                <MembersList
                                    users={this.state.toApproveUsers}
                                    roles={this.state.roles}
                                    openUser={this.state.openUser}
                                    skin={"admin"}
                                    editMode={this.state.editMode}
                                    deleteMode={this.state.deleteMode}
                                    onEdit={(member) =>
                                        this.openFormEditMember(member)
                                    }
                                    onDelete={(member) =>
                                        this.deleteMember(member)
                                    }
                                    onApprove={(member) =>
                                        this.openFormEditMember(member, true)
                                    }
                                    onReject={(member) =>
                                        this.confirmDeleteMember(member, true)
                                    }
                                />
                            )}

                            {this.state.users.length > 0 ? (
                                <MembersList
                                    users={this.state.users}
                                    roles={this.state.roles}
                                    skin={"admin"}
                                    editMode={this.state.editMode}
                                    deleteMode={this.state.deleteMode}
                                    onEdit={(member) =>
                                        this.openFormEditMember(member)
                                    }
                                    onDelete={(member) =>
                                        this.deleteMember(member)
                                    }
                                    onApprove={(member) =>
                                        this.openFormEditMember(member, true)
                                    }
                                    onReject={(member) =>
                                        this.confirmDeleteMember(member)
                                    }
                                />
                            ) : (
                                <div className={"col"}>
                                    <h6 className={"centerText"}>
                                        Aucun Résultat
                                    </h6>
                                </div>
                            )}
                        </>
                    )}
                </div>
                {this.state.pagination && (
                    <Pagination
                        pagination={this.state.pagination}
                        onClick={async (page) => {
                            this.setState({ loading: true });
                            let { payload, extra } = await this.getUsers(
                                this._QB.setPage(page)
                            );
                            this._handleGetUsersResults(payload, extra);
                        }}
                    />
                )}

                {/* Modal add member */}
                {this.state.showModalAddMember && (
                    <FormAddUser
                        user={
                            (this.state.editMode && this.state.tempUser) ||
                            this.state.fromApprove
                                ? this.state.tempUser
                                : null
                        }
                        onClose={() =>
                            this.setState({
                                showModalAddMember: false,
                                fromApprove: false,
                            })
                        }
                        onCancel={() => this.setState({ fromApprove: false })}
                        roles={this.state.roles}
                        onValid={(member) => this.updateStateUsers(member)}
                    />
                )}

                {/* Modal confirm delete member*/}
                {this.state.showModalConfirmDeleteMember && (
                    <Modal
                        autoWidth={true}
                        autoHeight={true}
                        onClose={() =>
                            this.setState({
                                showModalConfirmDeleteMember: false,
                            })
                        }
                    >
                        <p>
                            Êtes-vous sûr de vouloir supprimer «
                            {this.state.tempUser.profile.firstname}{" "}
                            {this.state.tempUser.profile.lastname}» ?
                        </p>
                        <div className={"buttonModal"}>
                            <button
                                type={"button"}
                                className={"cancel"}
                                onClick={() =>
                                    this.setState({
                                        showModalConfirmDeleteMember: false,
                                    })
                                }
                            >
                                Non
                            </button>
                            <button
                                type={"button"}
                                onClick={() =>
                                    this.confirmDeleteMember(
                                        this.state.tempUser
                                    )
                                }
                            >
                                Oui
                            </button>
                        </div>
                    </Modal>
                )}
            </Master>
        );
    }
}
