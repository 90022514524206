export function loadScript(scriptUrl) {
    const SCRIPTS = document.querySelectorAll('script');
    const SCRIPT = document.createElement('script');
    const FIRST_SCRIPT_TAG = document.getElementsByTagName('script')[0];

    return new Promise((resolve, reject) => {
        // Check if script is already loaded
        for (let i = 0; i < SCRIPTS.length; i++) {
            if (scriptUrl.split('//')[1] === SCRIPTS[i].src.split('//')[1]) {
                resolve();
                return;
            }
        }
        SCRIPT.src = scriptUrl;
        FIRST_SCRIPT_TAG.parentNode.insertBefore(SCRIPT, FIRST_SCRIPT_TAG);


        SCRIPT.onload = () => {
            resolve();
        };

        SCRIPT.onerror = () => {
            reject();
        };
    });
}