export const DOCUMENT_FILTER_TYPE_VEHICLE = 'VEHICLE';

const VEHICLE_TYPES = [
    {name: 'Véhicule léger', type: DOCUMENT_FILTER_TYPE_VEHICLE, value: 'LIGHT_VEHICLE'},
    {name: 'Poids lourds', type: DOCUMENT_FILTER_TYPE_VEHICLE, value: 'HEAVY_WEIGHTS'},
    {name: 'Total', type: DOCUMENT_FILTER_TYPE_VEHICLE, value: 'TOTAL'}
];

export const VEHICLE_TYPES_JSON = [
    {id: JSON.stringify({type: VEHICLE_TYPES[0].type, value: VEHICLE_TYPES[0].value}), name: VEHICLE_TYPES[0].name},
    {id: JSON.stringify({type: VEHICLE_TYPES[1].type, value: VEHICLE_TYPES[1].value}), name: VEHICLE_TYPES[1].name},
    {id: JSON.stringify({type: VEHICLE_TYPES[2].type, value: VEHICLE_TYPES[2].value}), name: VEHICLE_TYPES[2].name},
];

export default VEHICLE_TYPES;
