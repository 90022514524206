import React from "react";
import Moment from "moment";
import EventRow from "./EventRow";
export default function EventsList({
    events,
    onEdit,
    onDelete,
    onDownload,
    onAdd,
    role,
}) {
    return (
        <div className="daysList">
            {Object.keys(events).length > 0 &&
                Object.keys(events).map((day) => {
                    const dayEvents = events[day];
                    return (
                        <div className="daysList-item" key={day}>
                            <h3 className="daysList-title">
                                {Moment(day).format("dddd Do MMMM YYYY")}
                            </h3>
                            <ul className="eventsList">
                                {dayEvents.map((evt, index) => (
                                    <EventRow
                                        key={`${day}-${index}`}
                                        evt={evt}
                                        role={role}
                                        onEdit={onEdit}
                                        onDelete={onDelete}
                                        onDownload={onDownload}
                                    />
                                ))}
                            </ul>
                        </div>
                    );
                })}
            {Object.keys(events).length === 0 && (
                <p className="daysList-empty">
                    Aucun événement ce mois-ci.
                    <br />
                    <button onClick={() => onAdd()}>
                        Ajouter un événement
                    </button>
                </p>
            )}
        </div>
    );
}
