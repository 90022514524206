import axios from "axios";
import Api, {PATH} from "../Utils/Api";

const PATH_API_PAGE = `${PATH}/content/page`;

export default {

    /**
     * Get page by slug
     * @param slug
     * @param nestedEntities
     * @returns {AxiosPromise<any>}
     */
    getBySlug: (slug, nestedEntities = []) => {
        const params = {
            "api[nested_entities]": Api.buildNestedEntity(nestedEntities),
            'api[where]': `where \`slug\` = \`${slug}\``,
            'api[limit]': 1
        };
        return axios.get(`${PATH_API_PAGE}`, {params: params});
    },

    /**
     * Add page
     * @param data
     * @returns {Promise<unknown>}
     */
    post: (data) => {
        return axios.post(`${PATH_API_PAGE}`, data);
    },

    /**
     * Patch news
     * @param id
     * @param data
     * @returns {AxiosPromise<any>}
     */
    patch: (id, data) => {
        return axios.patch(`${PATH_API_PAGE}/${id}`, data);
    },

    /**
     * Remove news
     * @param id
     * @returns {Promise<unknown>}
     */
    delete: (id) => {
        return axios.delete(`${PATH_API_PAGE}/${id}`);
    }
}
