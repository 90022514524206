import React from 'react';
import PropTypes from "prop-types";
import './TitleLeftIcon.scss';

export default class TitleLeftIcon extends React.Component {

    static propTypes = {
        color: PropTypes.string,
        iconColor: PropTypes.string
    };

    static defaultProps = {
        color: '#0449A7',
        iconColor: '#D0ED4D',
    };

    render() {

        return (
            <span className={'titleLeftIcon'} style={{color: this.props.color}}>
                <svg xmlns="http://www.w3.org/2000/svg" width="69.754" height="53.121" viewBox="0 0 69.754 53.121">
                    <defs>
                    <clipPath id="clip-path">
                      <rect width="69.754" height="53.121" fill="none"/>
                    </clipPath>
                  </defs>
                  <g id="Grille_de_répétition_28" data-name="Grille de répétition 28" transform="translate(0)" clipPath="url(#clip-path)">
                    <g transform="translate(-0.29 -1539.879)">
                      <path id="Tracé_56" data-name="Tracé 56" d="M199.756,381.121H137.833V328h61.924v15.11a9.982,9.982,0,0,0,3.3,7.6l4.528,3.853-4.3,3.389a9.916,9.916,0,0,0-3.534,7.788Z"
                            transform="translate(-137.543 1211.879)" fill={this.props.iconColor}/>
                    </g>
                  </g>
                </svg>
                {this.props.children}
            </span>
        )
    }
}