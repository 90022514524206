import React from 'react';
import PropTypes from "prop-types";
import "./InputRadioSimple.scss";


export default class InputRadioSimple extends React.Component {

    static propTypes = {
        name: PropTypes.string,
        data: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.string,
            label: PropTypes.string,
            subLabel: PropTypes.string,
            value: PropTypes.string,
            defaultChecked: PropTypes.bool,
            checked: PropTypes.bool
        })).isRequired,
        onChange: PropTypes.func
    };

    static defaultProps = {
        onChange: () => {}
    };

    render() {
        return (
            <>
                {this.props.data.map((data, i) => (
                    <div className={'inputRadioSimple'} key={i}>
                        <div className={`InputLabel${data.subLabel ? ' withSubLabel' : ''}`}>
                            <label htmlFor={data.id}>
                                <input id={data.id}
                                       type="radio"
                                       name={this.props.name}
                                       value={data.value}
                                       defaultChecked={data.defaultChecked}
                                       checked={data.checked}
                                       onChange={(e) => this.props.onChange(e.target.value)}
                                />
                                <span>{data.label}</span>
                                {data.subLabel && <div className={'subLabel'}>
                                    <span>{data.subLabel}</span>
                                </div>}
                            </label>
                        </div>
                    </div>
                ))}
            </>
        )
    }
}