import Api, {PATH} from "../Utils/Api";
import axios from "axios";

const PATH_API = `${PATH}/user/profile`;

export default {

    /**
     * Add a user profile
     * @param {Object} profileData
     * @returns {Promise<unknown>}
     */
    add: (profileData) => {
        return axios.post(`${PATH_API}`, profileData);
    },

    /**
     * Update user profile
     * @param profileId
     * @param profile
     * @returns {Promise<unknown>}
     */
    update: (profileId, profile) => {
        return axios.patch(`${PATH_API}/${profileId}`, profile);
    },

    /**
     * Delete user profile
     * @param profileId
     * @returns {AxiosPromise}
     */
    delete: (profileId) => {
        return axios.delete(`${PATH_API}/${profileId}`);
    },

    /**
     * Update email subscription
     * @param profileId
     * @param value
     */
    updateEmailSubscription: (profileId, value) => {
        return axios.patch(`${PATH_API}/${profileId}`, {
            email_subscription: value
        });
    },

    updateNewsSubscription: (profileId, value) => {
        return axios.patch(`${PATH_API}/${profileId}`, {
            news_subscription: value
        });
    },

    /**
     * Search by first name or last name
     * @param searchToken
     * @param excludedId
     * @param nestedEntities
     * @returns {AxiosPromise<any>}
     */
    searchByFirstNameOrLastName: (searchToken, excludedId = [], nestedEntities = ['company', 'user']) => {
        let where = `where (\`lastname\` LIKE \`${searchToken}%\` OR \`firstname\` LIKE \`${searchToken}%\`)`;

        if (excludedId.length>0) {
            where += ` AND (\`id\` NOT IN ('${excludedId.join("','")}'))`;
        }

        const PARAMS = {
            "api[limit]": 10,
            "api[where]": where,
            "api[nested_entities]": Api.buildNestedEntity(nestedEntities),
        };


        return axios.get(`${PATH_API}`, {params: PARAMS});
    },
}