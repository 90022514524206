import Api, {PATH} from "../Utils/Api";
import axios from "axios";

export default {

    /**
     * Add new workgroup
     * @param workgroup
     * @returns {AxiosPromise<any>}
     */
    add: workgroup => {
        return axios.post(`${PATH}/workGroup/workGroup?api[nested_entities]=users.user.profile,thematic,documents`, workgroup);
    },

    /**
     * Add new workgroup
     * @param workgroup
     * @returns {AxiosPromise<any>}
     */
    update: workgroup => {
        return axios.patch(`${PATH}/workGroup/workGroup/${workgroup.id}?api[nested_entities]=users.user.profile,thematic,documents`, workgroup);
    },

    /**
     * Delete workgroup
     * @param id
     * @returns {AxiosPromise}
     */
    delete: id => {
        return axios.delete(`${PATH}/workGroup/workGroup/${id}`);
    },

    /**
     * Archive workgroup
     * @param id
     * @returns {AxiosPromise<any>}
     */
    archive: id => {
        return axios.patch(`${PATH}/workGroup/workGroup/${id}/archived/1`)
    },

    /**
     * Unarchive workgroup from its ID
     * @param id
     * @returns {AxiosPromise<any>}
     */
    unArchive: id => {
        return axios.patch(`${PATH}/workGroup/workGroup/${id}/archived/0`)
    },

    /**
     * Get all work group
     * @param nestedEntities
     * @returns {AxiosPromise<any>}
     */
    getAll: (nestedEntities = []) => {
        const PARAMS = {
            "api[nested_entities]": Api.buildNestedEntity(nestedEntities),
        };

        return axios.get(`${PATH}/workGroup/workGroup`, {params: PARAMS});
    },

    /**
     * Get workgroup by id
     *
     * @param id
     * @param nestedEntities
     * @param rusers
     * @returns {AxiosPromise<any>}
     */
    get: (id, nestedEntities = [], rusers = false) => {
        const PARAMS = {
            "api[nested_entities]": Api.buildNestedEntity(nestedEntities),
        };

        if (rusers) {
            PARAMS["api[rusers]"] = 1;
        }

        return axios.get(`${PATH}/workGroup/workGroup/${id}`, {params: PARAMS});
    },

    /**
     * Get closest work group
     * @param id
     * @param after
     * @param order
     * @param nestedEntities
     * @returns {AxiosPromise<any>}
     */
    getClosest: (id, after = true, order = 'asc', nestedEntities = []) => {
        const OPERATOR = after ? '>' : '<';

        const PARAMS = {
            "api[where]": `where (\`id\` ${OPERATOR} \`${id}\`)`,
            "api[limit]": 1,
            "api[sort]": 'id',
            "api[order]": order,
            "api[nested_entities]": Api.buildNestedEntity(nestedEntities),
        };

        return axios.get(`${PATH}/workGroup/workGroup`, {params: PARAMS});
    },

    /**
     * Get work group user
     * @param userId
     * @param nestedEntities
     */
    getForUser: (userId, nestedEntities = []) => {
        const PARAMS = {
            "api[nested_entities]": Api.buildNestedEntity(nestedEntities),
        };

        return axios.get(`${PATH}/user/user/${userId}`, {params: PARAMS});
    },

    /**
     * Get document type
     * @returns {AxiosPromise<any>}
     */
    getDocumentTypes: () => {
        return axios.get(`${PATH}/content/documentType`);
    },

    /**
     * Get document type
     * @returns {AxiosPromise<any>}
     */
    getDocumentTypesByWorkgroup: (id) => {
        const PARAMS = {
            'api[where]': `where (\`workgroup\` = ${id})`,
            'api[nested_entities]': 'doctype'
        };

        return axios.get(`${PATH}/content/document/distinct/docType.name`, {params: PARAMS});
    },

    /**
     * Add document type
     * @param name
     * @param description
     * @returns {AxiosPromise<any>}
     */
    addDocumentTypes: (name, description) => {
        return axios.post(`${PATH}/content/documentType`, {
            name: name,
            description: description
        }, {
            params: {
                'api[cine]': 1
            }
        });
    },

    /**
     * Get meeting
     * @returns {AxiosPromise<any>}
     */
    getMeetings: (workgroupId) => {
        const PARAMS = {
            'api[where]': `where (\`workgroup\` = ${workgroupId})`,
            'api[nested_entities]': 'meeting'
        };

        return axios.get(`${PATH}/content/document/distinct/meeting.description,date`, {params: PARAMS});
    },

    /**
     * Add meeting
     * @param date
     * @param description
     * @param workgroupId
     * @returns {AxiosPromise<any>}
     */
    addMeeting: (date, description, workgroupId = null) => {
        let data = {
            date: date,
            description: description
        };

        if (workgroupId) {
            data.workgroup_id = workgroupId
        }

        return axios.post(`${PATH}/content/meeting`, data);
    }
}

export const WORKGROUP_STATUS_COMMITTEE = 'COMMITTEE';
export const WORKGROUP_STATUS_ACTIVE    = 'ACTIVE';
export const WORKGROUP_STATUS_SPECIFIC  = 'SPECIFIC';
export const WORKGROUP_STATUS_STANDBY   = 'STANDBY';
export const WORKGROUP_STATUS           = [WORKGROUP_STATUS_COMMITTEE, WORKGROUP_STATUS_STANDBY, WORKGROUP_STATUS_SPECIFIC, WORKGROUP_STATUS_ACTIVE];
