import React from "react";
import ApiAccount from '../../Services/ApiAccount';
import axios from "axios";
import {Link, Redirect} from "react-router-dom";
import {path} from "../../Config/routes";
import Auth from "../../Security/Auth";
import Master from "../../Components/Layout/Master";
import Button from "../../Components/Form/Button/Button";
import InputText from "../../Components/Form/InputText/InputText";
import Select from "../../Components/Form/Select/Select";
import {GoogleAutocomplete} from "../../Utils/GoogleAutocomplete";
import SelectMultipleWorkGroup from "../../Components/Form/SelectMultipleWorkGroup/SelectMultipleWorkGroup";
import {loadReCaptcha} from 'react-recaptcha-v3'
import {ReCaptcha} from 'react-recaptcha-v3'
import {Http} from "../../Utils/Api";
import {GOOGLE_API} from "../../Config/google";
import ApiGoogle from "../../Services/ApiGoogle";
import "./Login.scss";
import * as qs from "qs";


export default class Login extends React.Component {

    state = {
        email: '',
        password: '',
        emailForgot: '',
        errorMessageLogin: '',
        errorMessageRegistration: '',
        errorMessageForgot: '',
        registrationDone: false,
        mailForgotPasswordIsSend: false,
        redirect: '',
        login: true,
        register: false,
        forgot: false,
        createPassword: false,
        loading: false,
        enterprises: [],
        workgroups: [],
        isRobot: false,
        user: {
          id: 0,
          email: '',
          token: '',
          password: ''
        },
        formCreateAccount: {
            value: {
                firstname: '',
                lastname: '',
                email: '',
                job: '',
                phone: '',
                mobile: '',
                address: '',
                zipcode: '',
                city: '',
                company: '',
                password: '',
                workgroups: '',
                hasWorkGroup: 'non',
                password_c: '',
                member: "ASFA",
            },
            error: {
                firstname: '',
                lastname: '',
                email: '',
                job: '',
                phone: '',
                mobile: '',
                address: '',
                zipcode: '',
                city: '',
                company: '',
                password: '',
                workgroup: '',
                password_c: ''
            }
        }
    };

    constructor(props) {
        super(props);
        this._isMounted = false;
    }

    componentDidMount() {
        this._isMounted = true;
        // Load reCaptcha v3
        loadReCaptcha(GOOGLE_API.SITE_KEY);

        const PARAMS = qs.parse(this.props.location.search, {ignoreQueryPrefix: true});

        if (PARAMS.t) {
            ApiAccount.getUserByToken(PARAMS.t).then((response) => {
                this.setState({
                    login: false,
                    register: false,
                    forgot: false,
                    createPassword: true,
                    user: Object.assign(this.state.user, response, {token: PARAMS.t})
                });
            })
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
        Http.cancel();
    }

    verifyCaptcha = (token) => {
        ApiGoogle.verifyReCaptcha(token).then((payload) => {
            if (!this._isMounted) return;
            if (payload.score >= 0.5) return;
            this.setState({isRobot: true});
        });
    };

    handleChangeLogin(event) {
        const target = event.target;
        const name   = target.name;
        const value  = target.value;

        this.setState({[name]: value});
    }

    handleChangeRegistration(event) {
        const target = event.target;
        const name   = target.name;
        let value    = target.value;

        if (name === 'lastname') {
            //Remove special chars
            value = value.replace(/[^a-záàâäãåçéèêëíìîïñóòôöõúùûüýÿæœÁÀÂÄÃÅÇÉÈÊËÍÌÎÏÑÓÒÔÖÕÚÙÛÜÝŸ '-]/ig, '');
        } else if (name === 'firstname') {
            //Remove special chars
            value = value.replace(/[^a-záàâäãåçéèêëíìîïñóòôöõúùûüýÿæœÁÀÂÄÃÅÇÉÈÊËÍÌÎÏÑÓÒÔÖÕÚÙÛÜÝŸ '-]/ig, '');
        }

        this.setState((state) => {
            return {
                errorMessageRegistration: '',
                formCreateAccount: {
                    ...state.formCreateAccount,
                    value: {
                        ...state.formCreateAccount.value,
                        [name]: value
                    }
                }
            }
        });
    }

    handleSubmitLogin(event) {
        event.preventDefault();

        this.setState({loading: true});

        ApiAccount.login(this.state.email, this.state.password).then((userData) => {
            if (!this._isMounted) return;
            this.setState({loading: false});

            let user      = userData.user;
            user['token'] = userData.token;
            user['refreshToken'] = userData.refresh_token;

            Auth.login(user);

            axios.defaults.headers.common['Authorization'] = `Bearer ${userData.token}`;

            const callerPage = this.props.location.state ? this.props.location.state.from : null;
            this.setState({
                redirect: <Redirect to={{
                    pathname: (callerPage !== null) ? callerPage.pathname : path('home')
                }}/>
            });

            const GOOGLE_BADGE_RE_CAPTCHA = document.querySelector('.grecaptcha-badge');
            GOOGLE_BADGE_RE_CAPTCHA.parentNode.removeChild(GOOGLE_BADGE_RE_CAPTCHA);
        }).catch((error) => {
            if (!this._isMounted) return;
            this.setState({loading: false});

            let msg = '';

            if (error.status === 401) {
                msg = 'Identifiants invalides.';
            } else {
                msg = 'Erreur.';
            }
            this.setState({errorMessageLogin: msg});
        });
    }

    handleSubmitForgot(event) {
        event.preventDefault();

        this.setState({loading: true});

        if (!this.state.emailForgot) {
            this.setState({
                loading: false,
                errorMessageForgot: 'Le champs est vide'
            });
            return
        }

        ApiAccount.forgotPassword(this.state.emailForgot).then(() => {
            if (!this._isMounted) return;
            this.setState({mailForgotPasswordIsSend: true})
        }).catch((response) => {
            if (!this._isMounted) return;
            this.setState({loading: false});
            let msg = '';

            if (response.status === 404) {
                msg = "Ce compte n'existe pas";
            } else if (response.status === 403) {
                msg = "Ce compte doit être approuvé par un administrateur";
            } else if (response.payload && response.payload.messages) {
                msg = response.payload.messages;
            } else {
                msg = 'Une erreur est survenue';
            }

            this.setState({errorMessageForgot: msg});
        });
    }

    handleSubmitRegistration(e) {
        e.preventDefault();
        this.cleanError();

        // Disable form submit when select address autocomplete
        if (document.activeElement.id === 'address') return;

        this.setState({
            loading: true
        });

        ApiAccount.register(this.state.formCreateAccount.value).then(() => {
            if (!this._isMounted) return;
            this.setState({
                loading: false,
                registrationDone: true
            });
        }).catch((e) => {
            if (!this._isMounted) return;
            this.setState({loading: false});
            let msg = '';

            // Set each error to input
            if (e.payload && e.payload.errors) {

                // Set error
                this.setState((state) => {
                    return {
                        formCreateAccount: {
                            ...state.formCreateAccount,
                            error: e.payload.errors
                        }
                    }
                });

                msg = 'Des erreurs sont présentes dans le formulaire.';
            } else {

                if (e.payload && e.payload.exception && e.payload.exception.message) {

                    msg = e.payload.exception.message.replace('500 Internal Server Error: ', '');

                } else {

                    msg = 'Une erreur est survenue';

                }

            }

            this.setState({errorMessageRegistration: msg})
        });
    }

    handleCreatePassword(e) {
        e.preventDefault();

        this.cleanError();

        if (this.state.formCreateAccount.value.password !== '' &&
            this.state.formCreateAccount.value.password === this.state.formCreateAccount.value.password_c) {
            const USER = {
                id: this.state.user.id,
                token: this.state.user.token,
                password: this.state.formCreateAccount.value.password,
                password_c: this.state.formCreateAccount.value.password_c
            };

            this.setState({
                loading: true
            });

            ApiAccount.createPassword(USER).then(() => {
                this.setState({
                    email: this.state.user.email,
                    password: this.state.formCreateAccount.value.password
                }, () => {
                    this.handleSubmitLogin(e);
                });
            }).catch(err => {
                const error = err.payload.errors;
                this.setState({
                    formCreateAccount: {
                        error: {
                            password: error.password,
                            password_c: error.password_c
                        }
                    },
                    loading: false
                }, () => {
                    console.log(this.state);
                });
            })
        }
    }

    cleanError() {
        this.setState({
            formCreateAccount: {
                ...this.state.formCreateAccount,
                error: {
                    firstname: '',
                    lastname: '',
                    email: '',
                    job: '',
                    phone: '',
                    mobile: '',
                    address: '',
                    zipcode: '',
                    city: '',
                    company: '',
                    password: '',
                    workgroup: '',
                    password_c: ''
                }
            }
        })
    }

    onSwitchSignIn() {
        this.setState({
            login: false,
            forgot: false,
            register: true
        }, () => {
            if (!this.state.registrationDone) {
                // Init. google autocomplete
                const GOOGLE_AUTO_COMPLETE = new GoogleAutocomplete(document.getElementById('address'));

                GOOGLE_AUTO_COMPLETE.onValid = () => {
                    this.setState({
                        errorMessageRegistration: '',
                        formCreateAccount: {
                            ...this.state.formCreateAccount,
                            value: {
                                ...this.state.formCreateAccount.value,
                                address: document.getElementById('address').value,
                                zipcode: document.getElementById('postal_code').value,
                                city: document.getElementById('locality').value,

                            }
                        }
                    })
                }
            }
        });

        if (this.state.enterprises.length === 0 || this.state.workgroups.length === 0) {
            // Get registration data
            ApiAccount.registrationData().then((response) => {
                if (!this._isMounted) return;
                this.setState({
                    enterprises: response.enterprises,
                    workgroups: response.workgroups,
                    formCreateAccount: {
                        ...this.state.formCreateAccount,
                        value: {
                            ...this.state.formCreateAccount.value
                        }
                    }
                });
            });
        }
    }

    render() {
        if (Auth.isAuthenticated()) {
            return (
                this.state.redirect
            )
        } else {

            const FORMS = <>
                {/* Login */}
                {this.state.login && <div className={'wrapLogin'}>
                    <h1>Bienvenue sur le site myASFA</h1>
                    <p>
                        Ce site est réservé aux membres de l'ASFA. <br/>Merci de vous identifier pour y accéder.
                    </p>
                    <form onSubmit={(e) => this.handleSubmitLogin(e)}>

                        {/* email */}
                        <InputText id={'email'}
                                   value={this.state.email}
                                   label={'Adresse email*'}
                                   placeholder={'Adrien.Y@autoroutes.fr'}
                                   autoComplete={'email'}
                                   onChange={(e) => this.handleChangeLogin(e)}/>

                        {/* Password */}
                        <InputText id={'password'}
                                   value={this.state.password}
                                   label={'Mot de passe*'}
                                   placeholder={'*********'}
                                   autoComplete={'password'}
                                   type={'password'}
                                   onChange={(e) => this.handleChangeLogin(e)}/>

                        <div className={'light'}>
                            <Button className={'underline'} style={{color: 'white'}} onClick={() => {
                                if (this.state.loading) return;
                                this.setState({login: false, register: false, forgot: true})
                            }}>
                                Mot de passe oublié
                            </Button>
                        </div>

                        {/* Error message */}
                        {this.state.errorMessageLogin !== '' && <div className={'errorMessage'}>
                            {this.state.errorMessageLogin}
                        </div>}
                        <Button type={"submit"} loading={this.state.loading}>Se connecter</Button>
                    </form>

                    <Button className={'underline'} style={{color: 'white'}} onClick={() => {
                        if (this.state.loading) return;
                        this.onSwitchSignIn();
                    }}>
                        Vous n’avez pas de compte ? Faites une demande d’accès.
                    </Button>
                </div>}

                {/* Forgot password */}
                {this.state.forgot && <>
                    {this.state.mailForgotPasswordIsSend
                        ? <div className={'mailIsSend'}>
                            <h1>Email envoyé !</h1>
                            <p>Vous allez recevoir prochainement un email pour modifier votre mot de passe.</p>
                        </div>
                        : <div style={{width: '100%'}}>
                            <h1>Mot de passe oublié</h1>
                            <p>Saisissez votre adresse email et nous vous renverrons <br/>un lien pour réinitialiser votre mot de passe.</p>
                            <form onSubmit={(e) => this.handleSubmitForgot(e)}>
                                <InputText id={'emailForgot'}
                                           label={'Adresse email*'}
                                           placeholder={'Adrien.Y@autoroutes.fr'}
                                           autoComplete={'email'}
                                           onChange={(e) => this.handleChangeLogin(e)}/>

                                {/* Error message */}
                                {this.state.errorMessageForgot !== '' && <div className={'errorMessage'}>
                                    {this.state.errorMessageForgot}
                                </div>}
                                <Button type={"submit"} loading={this.state.loading}>Envoyer</Button>
                            </form>

                            <Button className={'underline'} style={{color: 'white'}} onClick={() => {
                                if (this.state.loading) return;
                                this.setState({login: true, forgot: false})
                            }}>
                                Vous avez des accès à l’extranet ? Connectez-vous
                            </Button>
                        </div>}
                </>}

                {/* Create account */}
                {this.state.register && <div className={'centerBlock'}>
                    {this.state.registrationDone
                        ?
                        <>
                            <h1>Demander un accès au site</h1>
                            <p>Votre demande d'inscription au site myasfa.autoroutes.fr a bien été prise en compte.<br/>Après vérification, nous vous confirmerons l'accès au site. L'Équipe
                                myASFA</p>
                        </>
                        :
                        <>
                            <h1>Demander un accès au site</h1>
                            <p>
                                Vous souhaitez accéder au site myasfa.autoroutes.fr, <br/>merci de remplir les champs ci-dessous et de valider le formulaire. L'Équipe myASFA
                            </p>
                            <form>
                                {/* Last name  */}
                                <InputText id={'lastname'}
                                           value={this.state.formCreateAccount.value.lastname}
                                           label={'Nom*'}
                                           errorMsg={this.state.formCreateAccount.error.lastname}
                                           onChange={(e) => this.handleChangeRegistration(e)}/>

                                {/* Last name  */}
                                <InputText id={'firstname'}
                                           value={this.state.formCreateAccount.value.firstname}
                                           label={'Prénom*'}
                                           errorMsg={this.state.formCreateAccount.error.firstname}
                                           onChange={(e) => this.handleChangeRegistration(e)}/>

                                {/* email */}
                                <InputText id={'email'} label={'Adresse email*'} autoComplete={'username'} errorMsg={this.state.formCreateAccount.error.email}
                                           onChange={(e) => this.handleChangeRegistration(e)}/>

                                {/* Enterprise */}

                                <Select id={'company'}
                                        placeholder={' '}
                                        label={'Société*'}
                                        value={this.state.enterprises.sort((a, b) => {
                                            return a.name > b.name ? 1 : -1;
                                        })}
                                        errorMsg={this.state.formCreateAccount.error.company}
                                        onChange={(e) => this.handleChangeRegistration(e)}
                                        className={"form-register"}/>

                                {/* Function  */}
                                <InputText id={'job'} label={'Fonction*'} errorMsg={this.state.formCreateAccount.error.job}
                                           onChange={(e) => this.handleChangeRegistration(e)}/>

                                {/* Address  */}
                                <InputText id={'address'}
                                           label={'Adresse postale'}
                                           errorMsg={this.state.formCreateAccount.error.address}
                                           onChange={(e) => this.handleChangeRegistration(e)}/>

                                {/* Zip code  */}
                                <InputText id={'postal_code'} name={'zipcode'} label={'Code postal'} errorMsg={this.state.formCreateAccount.error.zipcode} maxLength={6}
                                           onChange={(e) => this.handleChangeRegistration(e)}/>

                                {/* City */}
                                <InputText id={'locality'} name={'city'} label={'Ville'} errorMsg={this.state.formCreateAccount.error.city}
                                           onChange={(e) => this.handleChangeRegistration(e)}/>

                                {/* Phone  */}
                                <InputText id={'phone'} label={'Téléphone fixe'} errorMsg={this.state.formCreateAccount.error.phone} type={'number'}
                                           onChange={(e) => this.handleChangeRegistration(e)}/>

                                {/* Mobile  */}
                                <InputText id={'mobile'} label={'Téléphone mobile'} errorMsg={this.state.formCreateAccount.error.mobile} type={'number'}
                                           onChange={(e) => this.handleChangeRegistration(e)}/>

                                {/* Has work group */}
                                {Object.keys(this.state.workgroups).length > 0 &&
                                <div className={'radioGroup'} style={{marginTop: 50, marginBottom: 50}}>
                                    <span>Faites-vous partie d’un groupe de travail ?*</span>
                                    <div className={'itemRadioGroup'}>
                                        <input type="radio"
                                               id="hasWorkGroupOne"
                                               name="hasWorkGroup"
                                               value="oui"
                                               checked={this.state.formCreateAccount.value.hasWorkGroup === 'oui'}
                                               onChange={(e) => this.handleChangeRegistration(e)}/>
                                        <label htmlFor="hasWorkGroupOne">Oui <span/></label>

                                    </div>
                                    <div className={'itemRadioGroup'}>
                                        <input type="radio"
                                               id="hasWorkGroupTwo"
                                               name="hasWorkGroup"
                                               value="non"
                                               checked={this.state.formCreateAccount.value.hasWorkGroup === 'non'}
                                               onChange={(e) => this.handleChangeRegistration(e)}/>
                                        <label htmlFor="hasWorkGroupTwo">Non <span/></label>
                                    </div>
                                </div>}

                                {/* WorkGroup */}
                                {this.state.formCreateAccount.value.hasWorkGroup === 'oui' && Object.keys(this.state.workgroups).length > 0 &&
                                <SelectMultipleWorkGroup
                                    id={'workgroup'}
                                    className={"form-register"}
                                    label={'Précisez le(s) groupes(s) de travail'}
                                    workgroups={this.state.workgroups}
                                    errorMsg={this.state.formCreateAccount.error.workgroup}
                                    onChange={(e) => this.setState({
                                        formCreateAccount: {
                                            ...this.state.formCreateAccount,
                                            value: {
                                                ...this.state.formCreateAccount.value,
                                                workgroups: e.value.join(',')
                                            }
                                        }
                                    })}/>
                                }

                                {/* Password */}
                                <InputText id={'password'}
                                           label={'Mot de passe*'}
                                           type={'password'}
                                           autoComplete={'new-password'}
                                           errorMsg={this.state.formCreateAccount.error.password}
                                           styleLabel={{marginBottom: 5}}
                                           onChange={(e) => this.handleChangeRegistration(e)}/>

                                {/* Info */}
                                <div className="alertPrimary" role="alert" style={this.state.formCreateAccount.error.password ? {marginTop: 22} : null}>
                                    Le mot de passe doit avoir entre 8 et 20 caractères incluant une majuscule, une minuscule, un chiffre et un caractère spécial (@$!%*?&).
                                </div>

                                {/* Password confirm */}
                                <InputText id={'password_c'} label={'Confirmation du mot de passe*'} type={'password'} autoComplete={'new-password'}
                                           errorMsg={this.state.formCreateAccount.error.password_c}
                                           onChange={(e) => this.handleChangeRegistration(e)}/>


                                <p className="requiredInfo"><b>* Champs obligatoires</b></p>


                                {/* Error message */}
                                {this.state.errorMessageRegistration !== '' && <div className={'errorMessage'}>
                                    {this.state.errorMessageRegistration}
                                </div>}

                                {/* Submit form */}
                                <Button type={"submit"} loading={this.state.loading} onClick={(e) => this.handleSubmitRegistration(e)}>Envoyer</Button>

                                <p className={'rgpd'}>
                                    Les informations sont ici recueillies par ASFA, association regroupant des sociétés
                                    d’autoroute. Les données marquées d'un (*) sont obligatoires afin d’offrir aux
                                    utilisateurs la possibilité d’échanger, de partager des informations et d’accéder à
                                    du contenu de groupe de travail. Vous disposez du droit d'accès, rectification ou
                                    effacement de vos données, ainsi que du droit de vous opposer à d’éventuellement
                                    traitements, demander dans les limites de la loi la limitation et la portabilité de
                                    vos données. Vous pouvez exercer ces droits en écrivant à dpo@autoroutes.fr  ou à
                                    l'adresse suivante : ASFA - 152 avenue de Malakoff - 75116 PARIS (FRANCE).
                                    <br/><br/>
                                    Pour plus d'information sur ces points, vous pouvez consulter la Politique de
                                    gestion des données accessible via ce <Link to={path('rgpd')}>lien</Link>.
                                </p>
                            </form>
                        </>
                    }
                    <Button className={'underline'} style={{color: 'white'}} onClick={() => this.setState({login: true, forgot: false, register: false})}>Vous avez des accès à l’extranet ?
                        Connectez-vous</Button>
                </div>}

                {/* Init password */}
                {this.state.createPassword && <div className={'wrapLogin'}>
                    <h1>Configuration du mot de passe</h1>
                    <p>
                        Merci de configurer votre mot de passe pour accéder au site.
                    </p>
                    <form onSubmit={(e) => this.handleCreatePassword(e)}>

                        {/* Password */}
                        <InputText id={'password'}
                                   label={'Mot de passe*'}
                                   type={'password'}
                                   autoComplete={'new-password'}
                                   errorMsg={this.state.formCreateAccount.error.password}
                                   styleLabel={{marginBottom: 5}}
                                   onChange={(e) => this.handleChangeRegistration(e)}/>

                        {/* Info */}
                        <div className="alertPrimary" role="alert" style={this.state.formCreateAccount.error.password ? {marginTop: 22} : null}>
                            Le mot de passe doit avoir entre 8 et 20 caractères incluant une majuscule, une minuscule, un chiffre et un caractère spécial (@$!%*?&).
                        </div>

                        {/* Password confirm */}
                        <InputText id={'password_c'} label={'Confirmation du mot de passe*'} type={'password'} autoComplete={'new-password'}
                                   errorMsg={this.state.formCreateAccount.error.password_c}
                                   onChange={(e) => this.handleChangeRegistration(e)}/>

                        {/* Error message */}
                        {this.state.errorMessageRegistration !== '' && <div className={'errorMessage'}>
                            {this.state.errorMessageRegistration}
                        </div>}
                        <Button type={"submit"} loading={this.state.loading}>Envoyer</Button>
                    </form>
                </div>}
            </>;

            return (
                <Master showMenu={false} id={"login"} footer={false} {...this.props}>
                    <div className="overlay"/>
                    <div className={'content'}>
                        <ReCaptcha
                            sitekey={GOOGLE_API.SITE_KEY}
                            action='login'
                            verifyCallback={this.verifyCaptcha}
                        />
                        {this.state.isRobot ? <div className={'centerText'} style={{width: '100%'}}>Il semblerait que vous soyez un robot :(</div> : FORMS}
                    </div>

                    <footer>
                        <nav>
                            <ul>
                                <li>
                                    <a href={'https://www.autoroutes.fr/index.htm'} target={'_blank'}>Autoroutes.fr</a> :
                                </li>
                                <li>
                                    <Link to={path('rgpd')}>
                                        Politique des données
                                    </Link>
                                </li>
                            </ul>
                        </nav>
                    </footer>
                </Master>
            );
        }
    }
}
