import React from 'react';
import ApiNotifications from "../../Services/ApiNotifications";
import Master from "../../Components/Layout/Master";
import Banner from "../../Components/Banner/Banner";
import ListNotifications from "../../Components/List/ListNotifications/ListNotifications";
import Auth from "../../Security/Auth";
import {Http} from "../../Utils/Api";
import "./MyNotifications.scss";
import Loader from "../../Components/Loader/Loader";
import {CT_COMMENT, CT_DOCUMENT} from "../../Utils/Notification";


export default class MyNotifications extends React.Component {

    state = {
        notifications: [],
        loading: true,
        loadingButton: false,
        httpError: false
    };

    constructor(props) {
        super(props);
        this._intervalNotification = null;
        this._isMounted            = false;
    }

    componentDidMount() {
        this._isMounted = true;

        // Get notification
        this.getNotifications().then(r => null);
        this._intervalNotification = setInterval(() => {
            this.getNotifications().then(r => null);
        }, 60000);
    }

    componentWillUnmount() {
        this._isMounted = false;
        clearTimeout(this._intervalNotification);
        Http.cancel();
    }

    async getNotifications() {
        const NOTIFICATIONS = await ApiNotifications.get(Auth.getUser().id, -1, 'resource.files,resource.thematic');
        if (!this._isMounted) return;

        const NOTIF_FILTER = [];

        NOTIFICATIONS.forEach((notification) => {
            if ((notification.resourcetype === CT_DOCUMENT && notification.resource) ||
                (notification.resourcetype === CT_COMMENT && notification.resource) ||
                (notification.resourcetype !== CT_DOCUMENT && notification.resourcetype !== CT_COMMENT)) {
                NOTIF_FILTER.push(notification);
            }
        });

        this.setState({notifications: NOTIF_FILTER, loading: false});
    }

    markedAllAsRead() {
        this.setState({loadingButton: true});

        let request = [];

        // Prepare request
        this.state.notifications.forEach((notification) => {
            request.push(ApiNotifications.markAsRead(notification.id));
        });

        // Set
        Promise.all(request).then(() => {
            this.setState({notifications: []});
        });
    }

    render() {
        return (
            <Master id={'myNotification'} httpError={this.state.httpError} breadCrumb={[{label: 'Mes notifications'}]} {...this.props}>
                <Banner imgSrc={'/assets/images/background/agenda.jpg'} title={'Mes notifications'} size={'small'} content={
                    <div>
                        <br/>
                        {this.state.notifications.length > 0 &&
                        <button type={'button'} className={'btn white'} onClick={() => this.markedAllAsRead()} disabled={this.state.loadingButton}>
                            {this.state.loadingButton ? <Loader type={'button'} backgroundColor={'transparent'}/> : 'Tout marquer comme lu'}
                        </button>}
                    </div>
                }/>
                <div className="container">
                    <ListNotifications data={this.state.notifications} loading={this.state.loading} {...this.props}/>
                </div>
            </Master>
        )
    }
}