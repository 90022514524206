import React from "react";
import Auth from '../../../Security/Auth';
import Banner from "../../../Components/Banner/Banner";
import Master from "../../../Components/Layout/Master";
import './Rgpd.scss';
import Loader from "../../../Components/Loader/Loader";
import ToolsManage from "../../../Components/ToolsManage/ToolsManage";
import Roles from "../../../Security/Roles";
import Modal from "../../../Components/Modal/Modal";
import CKEditor from "ckeditor4-react";
import {CK_EDITOR} from "../../../Config/ckEditor";
import ApiPage from "../../../Services/ApiPage";

export default class Rgpd extends React.Component {

    static TITLE = 'Politique des données';
    static SLUG  = 'rgpd';

    state = {
        openModal: false,
        loading: true,
        loadingButton: false,
        page: {
            id: null,
            content: ''
        }
    };

    async componentDidMount() {
        try {
            const PAGE = await ApiPage.getBySlug(Rgpd.SLUG);
            
            this.setState({
                loading: false,
                page: (PAGE.length > 0) ? PAGE[0] : ''
            });
        } catch (e) {
            console.log(e);
        }
    }

    async saveContent(page) {
        this.setState({loadingButton: true});

        let request = undefined;
        const DATA  = {
            author: Auth.getUser().id,
            title: Rgpd.TITLE,
            slug: Rgpd.SLUG,
            content: page.content,
            published: 1
        };

        if (this.state.page.id) {
            request = ApiPage.patch(this.state.page.id, DATA);
        } else {
            request = ApiPage.post(DATA);
        }

        try {
            await request;
            this.setState({
                loadingButton: false,
                openModal: false,
            })
        } catch (e) {
            this.setState({loadingButton: false})
        }
    }

    render() {
        return (
            <Master {...this.props} showMenu={Auth.isAuthenticated()}>
                <div id={'rgpd'}>
                    <Banner title={Rgpd.TITLE} size={'small'} imgSrc={'/assets/images/background/workgroup.jpg'}/>
                    {/* Content */}
                    <div className="pageContent container">
                        {this.state.loading ? <Loader type={"inline"}/> : <>
                            {/* Manage tools */}
                            <ToolsManage add={false} delete={false} onEdit={() => this.setState({openModal: true})} disableToggle={true} role={Roles.ROLE_ROOT}/>
                            <div dangerouslySetInnerHTML={{__html: this.state.page.content}}/>
                        </>}
                    </div>
                </div>

                {/* Modal edit content */}
                {this.state.openModal && <Modal onClose={() => this.setState({openModal: false})} onValidate={() => this.saveContent(this.state.page)} loading={this.state.loadingButton}>
                    <h5 className={'subTitle'} style={{marginBottom: 10, marginTop: 0}}>Contenu</h5>
                    <CKEditor
                        onBeforeLoad={(CKEditor) => {
                            CKEditor.disableAutoInline = true;
                            CK_EDITOR.config.filebrowserUploadUrl = CK_EDITOR.baseUploadUrl + Auth.getToken();
                        }}
                        data={this.state.page.content}
                        type="classic"
                        onChange={(event) => this.setState({page: {...this.state.page, content: event.editor.getData()}})}
                    />
                </Modal>}
            </Master>
        );
    }
}