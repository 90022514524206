import Moment from "moment";
import React from "react";
import "react-agenda/build/styles.css";
import "react-datetime/css/react-datetime.css";
import Banner from "../../Components/Banner/Banner";
import Master from "../../Components/Layout/Master";
import Loader from "../../Components/Loader/Loader";
import Modal from "../../Components/Modal/Modal";
import ModalConfirm from "../../Components/Modal/ModalConfirm/ModalConfirm";
import AgendaCtrl from "../../Components/ReactAgenda/AgendaCtrl";
import ReactAgenda from "../../Components/ReactAgenda/reactAgenda";
import ToolsManage from "../../Components/ToolsManage/ToolsManage";
import Auth from "../../Security/Auth";
import Roles from "../../Security/Roles";
import ApiAgenda from "../../Services/ApiAgenda";
import ApiThematics from "../../Services/ApiThematics";
import { Http } from "../../Utils/Api";
import { Calendar } from "../../Utils/Calendar";
import { DateHandler } from "../../Utils/DateHandler";
import { renameObjectProp } from "../../Utils/Object";
import { isSmallScreen } from "../../Utils/Screen";
import "./Agenda.scss";
import EventItem from "./EventItem";
import EventsList from "./EventsList";

require("moment/locale/fr.js");

export default class Agenda extends React.Component {
    state = {
        headerDateTime: Agenda.getHeaderTime(),
        startDate: Moment().startOf("week")._d,
        numberOfDays: 7,
        currentMonth: "",
        currentYear: "",
        showModal: false,
        showModalConfirm: false,
        headFormat: "",
        colors: {},
        itemsFiltered: [],
        monthItems: [],
        selected: [],
        thematics: [],
        smallScreen: isSmallScreen(),
        isAgendaControlDisable: true,
        loading: true,
        loadingAgendaCtrl: false,
        httpError: false,
        showAgenda: true,
        isMonthView: false,
    };

    _authRole = { rank: 0, uid: "" };
    _authenticatedUserIsAllowedToAddEvent = false;

    constructor(props) {
        super(props);
        this.interval = null;
        this.filters = [];
        this.items = [];
        this.removeItems = [];
        this._authRole = Auth.getRole();
        // Administrators can add new items for their handled thematics
        this._authenticatedUserIsAllowedToAddEvent =
            this._authRole.rank >= Roles.ROLE_ADMINISTRATOR.rank;
        this._isMounted = false;
        this._timeout = null;
    }

    static getHeaderTime() {
        return Moment().format("dddd Do MMMM YYYY");
    }

    fetchMonthEvents() {
        const startDate = Moment(this.state.startDate).clone().startOf("month");

        const numberOfDays = startDate.daysInMonth();

        const endDate = Moment(startDate).clone().add(numberOfDays, "days");

        ApiAgenda.getEventsStartingInDateRange(startDate._d, endDate._d).then(
            (events) => {
                if (!this._isMounted) return;
                this.setState({
                    loading: false,
                    monthItems: events,
                });
            }
        );
    }

    componentDidMount() {
        this._isMounted = true;

        // Set month agenda
        this.setMonthYearAgenda();

        // Header hours
        this.interval = setInterval(() => {
            if (!this._isMounted) return;
            this.setState({ headerHours: Agenda.getHeaderTime() });
        }, 30000);

        // Get Work group
        ApiThematics.getForAgenda()
            .then((thematics) => {
                if (!this._isMounted) return;

                // Get and create work group color
                let colors = {};
                thematics.forEach((item, i) => {
                    colors["color-" + i] = item.color;
                });

                this.setState(
                    {
                        thematics: thematics,
                        colors: colors,
                        isAgendaControlDisable: false,
                    },
                    () => {
                        ApiAgenda.getCurrentWeekEvents().then((events) => {
                            if (!this._isMounted) return;
                            this.transformItemObjectForReactAgenda(events);
                        });
                    }
                );
            })
            .catch((err) => {
                if (!this._isMounted) return;

                if (err.status && err.status >= 500) {
                    this.setState({
                        httpError: true,
                    });
                }
            });

        window.addEventListener("resize", this.resize);
    }

    componentDidUpdate(prevProps, prevState) {
        if (
            (!Moment(prevState.startDate).isSame(this.state.startDate) &&
                this.state.isMonthView) ||
            (this.state.isMonthView && !prevState.isMonthView)
        ) {
            this.fetchMonthEvents();
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
        clearInterval(this.interval);
        clearTimeout(this._timeout);
        Http.cancel();
    }

    resize = () => {
        this.setState({ smallScreen: isSmallScreen() });
    };

    //======================================================================
    transformItemObjectForReactAgenda(events) {
        this.items = events;

        for (let i = 0; i < this.items.length; i++) {
            const item = this.items[i];
            renameObjectProp("title", "name", item);
            renameObjectProp("startingat", "startDateTime", item);
            renameObjectProp("finishingat", "endDateTime", item);
            item._id = item.id;
            item.classes = this.getClassColorFromHex(item.thematic.color);
            item.startDateTime = Moment(item.startDateTime.date).toDate();
            item.endDateTime = Moment(item.endDateTime.date).toDate();
        }

        // Set agenda items
        this.setState(
            {
                itemsFiltered: this.items,
                loading: false,
            },
            () => {
                this.filterEvent();
            }
        );
    }

    itemSuperimposed(items = this.items) {
        let skipItem = [];

        for (let i = 0; i < items.length; i++) {
            // We know that item is already check so it's useless to check again
            if (skipItem.indexOf(i) === -1) {
                let superimposed = [];

                // Ajoute dans superimposed tous les items superposé au premier élément de nextItems
                const isSuperimposed = function (
                    nextItems = items,
                    index = i + 1
                ) {
                    let elements = [nextItems[index - 1]];
                    const START = Moment(elements[0].startDateTime);
                    const END = Moment(elements[0].endDateTime);
                    for (let j = index; j < nextItems.length; j++) {
                        const nextItem = nextItems[j];
                        const START_NEXT = Moment(nextItem.startDateTime);
                        const END_NEXT = Moment(nextItem.endDateTime);

                        // Both date start or finish at the same day
                        if (
                            START.isSame(START_NEXT, "day") ||
                            END.isSame(END_NEXT, "day")
                        ) {
                            // Second item in first item or First item in second item
                            if (
                                (START.isSameOrBefore(START_NEXT, "hour") &&
                                    END.isAfter(START_NEXT, "hour")) ||
                                (START_NEXT.isSameOrBefore(START, "hour") &&
                                    END_NEXT.isAfter(START, "hour"))
                            ) {
                                skipItem.push(j);
                                elements.push(nextItem);
                            }
                        }
                    }
                    superimposed.push(elements);
                };

                const inArray = function (array, item) {
                    for (let j = 1; j < array.length; j++) {
                        for (let k = 0; k < array[j].length; k++) {
                            if (array[j][k].id === item.id) {
                                return true;
                            }
                        }
                    }
                    return false;
                };

                isSuperimposed();

                superimposed.push([superimposed[0][0]]);
                for (let j = 0; j < superimposed[0].length; j++) {
                    // Si on a que deux éléments superposé on les position correctement
                    if (superimposed[0].length <= 2) {
                        superimposed[0][j].nbEvents = superimposed[0].length;
                        superimposed[0][j].position = j;
                    } else if (
                        j < superimposed[0].length - 1 &&
                        !inArray(superimposed, superimposed[0][j + 1])
                    ) {
                        // Sinon on regarde que les autres éléments se superpause entre eux
                        isSuperimposed(superimposed[0], j + 2);
                    }
                }

                if (superimposed[0].length > 2) {
                    let superimposedElements = 0;
                    for (let j = 2; j < superimposed.length; j++) {
                        if (superimposed[j].length + 1 > superimposedElements) {
                            superimposedElements = superimposed[j].length + 1;
                        }
                    }

                    for (let j = 1; j < superimposed.length; j++) {
                        // C'est le premier élément on le place donc en première position
                        if (j === 1) {
                            // Le nombre d'élément différents qui ce superpause
                            superimposed[j][0].nbEvents = superimposedElements;
                            superimposed[0][j].position = j;
                        } else {
                            let position = 1;
                            // On parcourt le nombre de superposition différentes
                            for (let k = 1; k < superimposed.length; k++) {
                                if (superimposed[k].length === 1) {
                                    superimposed[j][0].position = position;
                                    superimposed[j][0].nbEvents =
                                        superimposedElements;
                                } else {
                                    // On parcourt le nombre d'élément qui se superpause sur cette itération
                                    for (
                                        let l = 0;
                                        l < superimposed[k].length;
                                        l++
                                    ) {
                                        superimposed[j][l].position =
                                            position + l;
                                        superimposed[j][l].nbEvents =
                                            superimposedElements;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        return items;
    }

    getClassColorFromHex(hexColor) {
        for (let key in this.state.colors) {
            if (
                this.state.colors.hasOwnProperty(key) &&
                this.state.colors[key] === hexColor
            ) {
                return key;
            }
        }
    }

    //======================================================================
    // MODAL HANDLER

    setMonthYearAgenda(startDate, callback) {
        const DATE = new Date(startDate || this.state.startDate);
        const MOMENT = Moment(DATE);
        this.setState(
            {
                currentMonth: MOMENT.format("MMM"),
                currentYear: MOMENT.format("YYYY"),
            },
            () => {
                if (callback) {
                    return callback();
                }
            }
        );
    }

    //======================================================================
    openModal() {
        this.setState({ showModal: true });
    }

    //======================================================================
    // EVENTS HANDLER

    closeModal(e) {
        if (e) {
            e.stopPropagation();
            e.preventDefault();
        }
        this.setState({ showModal: false });
    }

    //======================================================================
    handleFilter = (e) => {
        const TARGET = e.target;
        const VALUE = TARGET.value;
        const ADD_FILTER = TARGET.checked;
        let newFilters = this.filters;

        if (ADD_FILTER) {
            newFilters = [...newFilters, VALUE];
        } else {
            // Remove unchecked item
            let index = newFilters.indexOf(VALUE);

            // Remove item from array
            if (index !== -1) {
                newFilters.splice(index, 1);
            }
        }

        // Set new state filter
        this.filters = newFilters;

        this.filterEvent();
    };

    filterEvent() {
        let itemsFiltered = [];
        // Filter items
        if (this.filters.length > 0) {
            for (const ITEM of this.items) {
                if (this.filters.indexOf(ITEM.thematic.uid) !== -1) {
                    itemsFiltered = [...itemsFiltered, ITEM];
                }
            }
        } else {
            itemsFiltered = this.items;
        }

        itemsFiltered = this.itemSuperimposed(itemsFiltered);

        this.setState({ itemsFiltered: itemsFiltered });
    }

    handleControl = (direction, e) => {
        if (!this.state.isMonthView) {
            document.querySelector(".agenda__" + direction).click();
        } else {
            if ("prev" === direction) {
                const newStartDate = Moment(this.state.startDate)
                    .clone()
                    .subtract(1, "month")
                    .startOf("month");

                const currentMonth = newStartDate.format("MMMM");
                const currentYear = newStartDate.year();

                this.setState({
                    startDate: newStartDate._d,
                    numberOfDays: newStartDate.daysInMonth(),
                    currentMonth,
                    currentYear,
                });
            } else if ("next" === direction) {
                const newStartDate = Moment(this.state.startDate)
                    .clone()
                    .add(1, "month")
                    .startOf("month");

                const currentMonth = newStartDate.format("MMMM");
                const currentYear = newStartDate.year();

                this.setState({
                    startDate: newStartDate._d,
                    numberOfDays: newStartDate.daysInMonth(),
                    currentMonth,
                    currentYear,
                });
            }
        }
    };

    handleItemEdit = (item, openModal) => {
        if (
            !this._authenticatedUserIsAllowedToAddEvent ||
            !DateHandler.isWorkingDay(item)
        ) {
            return;
        }

        if (item && openModal === true) {
            this.setState({ selected: [item] });
            return this.openModal();
        }
    };

    handleCellSelection = (item) => {
        if (
            !this._authenticatedUserIsAllowedToAddEvent ||
            !DateHandler.isWorkingDay(item)
        ) {
            return;
        }

        if (this.state.selected && this.state.selected[0] === item) {
            return this.openModal();
        }

        this.setState({ selected: [item] });
    };

    handleRangeSelection = (selected) => {
        if (
            !this._authenticatedUserIsAllowedToAddEvent ||
            !DateHandler.isWorkingDay(selected)
        ) {
            return;
        }

        this.setState({
            selected: selected,
            showCtrl: true,
        });
        this.openModal();
    };

    handleItemChange = (items, item) => {
        this.setState({ itemsFiltered: items });
    };

    handleItemSize = (items, item) => {
        this.setState({ itemsFiltered: items });
    };

    handlePrevClick() {
        const newStartDate = this.state.startDate.clone().subtract(1, "month");

        this.setState({
            startDate: newStartDate,
            numberOfDays: newStartDate.daysInMonth(),
        });
    }

    handleNextClick() {
        const newStartDate = this.state.startDate.clone().add(1, "month");

        this.setState({
            startDate: newStartDate,
            numberOfDays: newStartDate.daysInMonth(),
        });
    }

    handleDateRangeChange = (startDate, endDate) => {
        let numberOfDays = this.state.numberOfDays;
        let newStartDate = startDate;

        // Cancel all previous request
        Http.cancel();

        // Update month year header
        this.setMonthYearAgenda(startDate, () => {
            // If month view
            if (this.state.numberOfDays > 7) {
                newStartDate = Moment(startDate).startOf("month")._d;
                numberOfDays = Moment(
                    `${this.state.currentYear}-${this.state.currentMonth}`,
                    "YYYY-MMM"
                ).daysInMonth();
            }
            this.setState(
                {
                    numberOfDays: numberOfDays,
                    startDate: newStartDate,
                    loading: true,
                    itemsFiltered: [],
                },
                () => {
                    // Get current week event
                    ApiAgenda.getEventsStartingInDateRange(
                        startDate,
                        endDate
                    ).then((events) => {
                        if (!this._isMounted) return;
                        this.transformItemObjectForReactAgenda(events);
                        this.setState({
                            loading: false,
                        });
                    });
                }
            );
        });
    };

    handleViewNumberOfDays(type) {
        let headFormat = "";
        let numberOfDays = null;
        let startDate = "";
        const TODAY = new Date().setHours(0, 0, 0, 0);
        const TODAY_MOMENT = Moment(TODAY);
        let startDateMoment = Moment(this.state.startDate);
        const TODAY_IN_MONTH =
            TODAY_MOMENT.isAfter(startDateMoment) &&
            TODAY_MOMENT.isSameOrBefore(
                startDateMoment.clone().add(this.state.numberOfDays, "d")
            );

        switch (type) {
            case "day":
                startDate = this.state.startDate;

                // If today is between start date and end date show today
                if (
                    (TODAY_MOMENT.isAfter(startDateMoment) &&
                        TODAY_MOMENT.isSameOrBefore(
                            startDateMoment.add(7, "d")
                        )) ||
                    (this.state.numberOfDays > 7 && TODAY_IN_MONTH)
                ) {
                    startDate = TODAY_MOMENT.toDate();
                }

                numberOfDays = 1;
                break;
            case "week":
                let START_DATE_WEEK = this.state.startDate;

                // Set the first monday

                // Previous view is month and to day is in this month
                if (this.state.numberOfDays > 7 && TODAY_IN_MONTH) {
                    START_DATE_WEEK = Moment(TODAY_MOMENT).startOf("week")._d;
                } else if (
                    Moment(this.state.startDate).month() !==
                    Moment(
                        Moment(this.state.startDate).startOf("week")._d
                    ).month()
                ) {
                    START_DATE_WEEK = Moment(
                        Moment(this.state.startDate).add(7, "d")
                    ).startOf("week")._d;
                } else {
                    START_DATE_WEEK = Moment(this.state.startDate).startOf(
                        "week"
                    )._d;
                }

                startDate = START_DATE_WEEK;
                numberOfDays = 7;
                break;
            case "month":
                headFormat = "DD";
                numberOfDays = Moment(this.state.startDate).daysInMonth();
                startDate = Moment(this.state.startDate).startOf("month")._d;
                if (
                    (TODAY_MOMENT.isAfter(startDateMoment) &&
                        TODAY_MOMENT.isSameOrBefore(
                            startDateMoment.add(7, "d")
                        )) ||
                    (this.state.numberOfDays > 7 && TODAY_IN_MONTH)
                ) {
                    startDate = TODAY_MOMENT.startOf("month");
                }

                return this.setState({
                    startDate,
                    currentMonth: Moment(startDate).format("MMMM"),
                    currentYear: Moment(startDate).format("YYYY"),
                    isMonthView: true,
                });
            default:
        }

        this.handleDateRangeChange(
            startDate,
            Moment(startDate).add(numberOfDays, "days")
        );

        this.setState(
            {
                showAgenda: false,
            },
            () => {
                this._timeout = setTimeout(
                    () =>
                        this.setState(
                            {
                                startDate: startDate,
                                headFormat: headFormat,
                                numberOfDays: numberOfDays,
                                showAgenda: true,
                                isMonthView: false,
                            },
                            () => {
                                this.setMonthYearAgenda();
                            }
                        ),
                    200
                );
            }
        );
    }

    removeEvent = (items, item) => {
        if (this.state.showModalConfirm) {
            this.setState({ loadingAgendaCtrl: true });

            // Remove element from original array of items
            ApiAgenda.removeEvent(item.id)
                .then(() => {
                    if (!this._isMounted) return;

                    this.items.forEach((ITEM, index) => {
                        if (ITEM.id === item.id) {
                            this.items.splice(index, 1);
                        }
                    });

                    const monthItems = this.state.monthItems.filter((ITEM) => {
                        return ITEM.id !== item.id;
                    });

                    // Update filtered item
                    this.setState({
                        showModalConfirm: false,
                        itemsFiltered: items,
                        loadingAgendaCtrl: false,
                        monthItems,
                    });
                    // Clean remove item
                    this.removeItems = [];
                })
                .catch(() => {
                    if (!this._isMounted) return;
                    this.setState({ loadingAgendaCtrl: false });
                });
        } else {
            this.removeItems = [items, item];
            this.setState({ showModalConfirm: true, loadingAgendaCtrl: false });
        }
    };

    addOrEditEvent = (items, newItem) => {
        this.setState({ loadingAgendaCtrl: true });

        newItem.public = true;
        newItem.published = true;
        newItem.startDateTime = Moment(newItem.startingat).toDate();
        newItem.endDateTime = Moment(newItem.finishingat).toDate();

        // Update
        if (newItem.id) {
            ApiAgenda.updateEvent(newItem).then(() => {
                if (!this._isMounted) return;

                newItem.classes = this.getClassColorFromHex(
                    newItem.thematic.color
                );
                newItem.name = newItem.title;
                newItem._id = newItem.id;

                this.items = Object.assign(this.state.itemsFiltered, newItem);
                this.itemSuperimposed();

                this.setState({
                    showModal: false,
                    selected: [],
                    itemsFiltered: this.items,
                    loadingAgendaCtrl: false,
                });

                this.closeModal();
            });
        } else {
            ApiAgenda.addEvent(newItem).then((event) => {
                if (!this._isMounted) return;

                newItem.classes = this.getClassColorFromHex(
                    newItem.thematic.color
                );
                newItem.name = newItem.title;
                newItem.id = event.id;
                newItem._id = event.id;

                const addedMonth = Moment(newItem.startingat).format("MM");
                const currentMonth = Moment(this.state.startDate).format("MM");
                const monthItems = [...this.state.monthItems];

                if (!this.state.isMonthView || addedMonth === currentMonth) {
                    this.items = Object.assign(
                        this.state.itemsFiltered,
                        newItem
                    );

                    monthItems.push(newItem);
                }

                this.itemSuperimposed();

                this.setState({
                    showModal: false,
                    selected: [],
                    itemsFiltered: this.items,
                    loadingAgendaCtrl: false,
                    monthItems,
                });

                this.closeModal();
            });
        }
    };

    handleAddPersonalEvent(item) {
        item.startingat = {};
        item.finishingat = {};
        item.startingat.date = item.startDateTime;
        item.finishingat.date = item.endDateTime;
        item.title = item.name;
        Calendar.downloadIcs(item);
    }

    remapEvent(item) {
        item._id = item.id;
        item.startDateTime = item.startingat.date;
        item.endDateTime = item.finishingat.date;
        item.name = item.title;

        return item;
    }

    sortEventsByDay() {
        const evts = {};

        if (!this.state.isMonthView) {
            return evts;
        }

        this.state.monthItems.forEach((item) => {
            const startingAtDate = item.startingat.date || item.startingat;
            const day = Moment(startingAtDate).format("YYYY-MM-DD");
            if (!(day in evts)) {
                evts[day] = [];
            }
            evts[day].push(item);
        });

        return evts;
    }

    //======================================================================
    // RENDER

    //======================================================================
    render() {
        const LIST_FILTER = (
            <ul>
                {this.state.thematics.map((thematic, i) => {
                    return (
                        <li key={i}>
                            <input
                                type="checkbox"
                                id={thematic.uid}
                                value={thematic.uid}
                                onClick={this.handleFilter}
                            />
                            <label htmlFor={thematic.uid}>
                                {thematic.name.length >= 15
                                    ? thematic.name.substring(0, 15)
                                    : thematic.name}
                                {thematic.name.length >= 15 && (
                                    <>
                                        <span className={"ellipsis"}>...</span>
                                        <span className={"wordTruncated"}>
                                            {thematic.name.substring(15)}
                                        </span>
                                    </>
                                )}
                            </label>
                            <span style={{ backgroundColor: thematic.color }} />
                        </li>
                    );
                })}
            </ul>
        );

        let views = "";
        const ROW_PER_HOUR = 4;
        const CELL_HEIGHT = isSmallScreen() ? 150 : 90;

        if (this.state.showAgenda) {
            if (this.state.numberOfDays === 1) {
                views = "day";
            } else if (this.state.numberOfDays > 7) {
                views = "month";
            } else if (this.state.numberOfDays === 7) {
                views = "week";
            }
        }

        const evts = this.sortEventsByDay();

        return (
            <Master
                id="agenda"
                httpError={this.state.httpError}
                breadCrumb={[{ label: "Agenda" }]}
                {...this.props}
            >
                <Banner imgSrc={"/assets/images/background/agenda.jpg"}>
                    {/* Title banner */}
                    <div className="bannerTitle small">
                        <div className={"textHeader"}>
                            <h1>
                                Agenda{" "}
                                {this.state.loading && (
                                    <span>
                                        <Loader
                                            type={"button"}
                                            color={"white"}
                                            backgroundColor={"transparent"}
                                        />
                                    </span>
                                )}
                            </h1>
                            <div id="dateBanner">
                                {this.state.headerDateTime}
                            </div>
                        </div>
                    </div>
                    {/* Filer category */}
                    {this.state.thematics.length > 0 &&
                        !this.state.smallScreen && (
                            <div id={"agendaFilterCategory"}>
                                <span>Filtrer par : </span>
                                {LIST_FILTER}
                            </div>
                        )}
                </Banner>

                <div
                    id="calendarASFA"
                    className={`container ${views} ${
                        !this._authenticatedUserIsAllowedToAddEvent
                            ? "cannotManage"
                            : ""
                    }`}
                >
                    <ToolsManage
                        onAdd={() => this.openModal()}
                        role={Roles.ROLE_ADMINISTRATOR}
                        edit={false}
                        delete={false}
                    />

                    {/* Header agenda */}
                    <div id={"headerAgenda"}>
                        <div id="agendaControl">
                            <button
                                type={"button"}
                                disabled={this.state.isAgendaControlDisable}
                                onClick={(e) => this.handleControl("prev", e)}
                            />
                            <span>{`${this.state.currentMonth} ${this.state.currentYear}`}</span>
                            <button
                                type={"button"}
                                className={"next"}
                                disabled={this.state.isAgendaControlDisable}
                                onClick={(e) => this.handleControl("next", e)}
                            />
                        </div>

                        <div id={"filterViewCalendar"}>
                            <div>
                                <input
                                    type="radio"
                                    id={"dayView"}
                                    name={"viewCalendar"}
                                    disabled={this.state.isAgendaControlDisable}
                                    onClick={() =>
                                        this.handleViewNumberOfDays("day")
                                    }
                                />
                                <label htmlFor={"dayView"}>Jour</label>
                            </div>
                            <div>
                                <input
                                    type="radio"
                                    id={"weekView"}
                                    name={"viewCalendar"}
                                    disabled={this.state.isAgendaControlDisable}
                                    onClick={() =>
                                        this.handleViewNumberOfDays("week")
                                    }
                                    defaultChecked={true}
                                />
                                <label htmlFor={"weekView"}>Semaine</label>
                            </div>
                            <div>
                                <input
                                    type="radio"
                                    id={"monthView"}
                                    name={"viewCalendar"}
                                    disabled={this.state.isAgendaControlDisable}
                                    onClick={() =>
                                        this.handleViewNumberOfDays("month")
                                    }
                                />
                                <label htmlFor={"monthView"}>Mois</label>
                            </div>
                        </div>
                    </div>

                    {/* Filter small screen */}
                    {this.state.smallScreen && (
                        <div id="filterMobile">
                            <button
                                type="button"
                                className={`toggleMobile${
                                    this.state.mobileFilter ? " active" : ""
                                }`}
                                onClick={() =>
                                    this.setState((state) => ({
                                        mobileFilter: !state.mobileFilter,
                                    }))
                                }
                            >
                                <span>Filtre</span>
                            </button>
                            {this.state.mobileFilter && LIST_FILTER}
                        </div>
                    )}

                    {/* React agenda */}
                    {!this.state.isMonthView ? (
                        <ReactAgenda
                            // Config
                            minDate={
                                new Date(
                                    this.state.startDate.getFullYear(),
                                    this.state.startDate.getMonth() - 3
                                )
                            }
                            maxDate={
                                new Date(
                                    this.state.startDate.getFullYear(),
                                    this.state.startDate.getMonth() + 3
                                )
                            }
                            disablePrevButton={false}
                            startDate={this.state.startDate}
                            cellHeight={CELL_HEIGHT / ROW_PER_HOUR}
                            locale={"fr"}
                            numberOfDays={this.state.numberOfDays}
                            rowsPerHour={ROW_PER_HOUR}
                            itemColors={this.state.colors}
                            autoScale={false}
                            fixedHeader={true}
                            showModal={false}
                            startAtTime={0.1}
                            endAtTime={23.9}
                            headFormat={this.state.headFormat}
                            enableDragEvent={false}
                            enableResizeEvent={false}
                            // Items
                            items={this.state.itemsFiltered}
                            itemComponent={(props) => (
                                <EventItem
                                    event={props}
                                    role={this._authRole}
                                    cellHeight={CELL_HEIGHT / ROW_PER_HOUR}
                                    rowsPerHour={ROW_PER_HOUR}
                                    numberOfDays={this.state.numberOfDays}
                                    onAddPersonalEvent={(item) =>
                                        this.handleAddPersonalEvent(item)
                                    }
                                />
                            )}
                            // Events handler
                            onRangeSelection={this.handleRangeSelection}
                            onChangeEvent={this.handleItemChange}
                            onChangeDuration={this.handleItemSize}
                            onItemEdit={this.handleItemEdit}
                            onCellSelect={this.handleCellSelection}
                            onItemRemove={this.removeEvent}
                            onDateRangeChange={this.handleDateRangeChange}
                        />
                    ) : (
                        <EventsList
                            events={evts}
                            onEdit={(evt) =>
                                this.handleItemEdit(this.remapEvent(evt), true)
                            }
                            onDelete={(evt) => this.removeEvent(evts, evt)}
                            onDownload={(evt) => {
                                Calendar.downloadIcs(evt);
                                // this.handleAddPersonalEvent(evt);
                            }}
                            onAdd={() => this.openModal()}
                            role={this._authRole}
                        />
                    )}
                </div>

                {/* Modal */}
                {this.state.showModal ? (
                    <Modal onClose={() => this.setState({ showModal: false })}>
                        <AgendaCtrl
                            items={this.state.itemsFiltered}
                            itemColors={this.state.colors}
                            thematics={this.state.thematics}
                            selectedCells={this.state.selected}
                            Addnew={this.addOrEditEvent}
                            edit={this.addOrEditEvent}
                            loading={this.state.loadingAgendaCtrl}
                            cancel={() => this.setState({ showModal: false })}
                        />
                    </Modal>
                ) : null}

                {/* Modal confirm delete */}
                {this.state.showModalConfirm && (
                    <ModalConfirm
                        confirmText={
                            "Êtes-vous sûr de vouloir supprimer l’événement ?"
                        }
                        loading={this.state.loadingAgendaCtrl}
                        onClose={() =>
                            this.setState({
                                showModalConfirm: false,
                                loadingAgendaCtrl: false,
                            })
                        }
                        onCancel={() =>
                            this.setState({
                                showModalConfirm: false,
                                loadingAgendaCtrl: false,
                            })
                        }
                        onConfirm={() =>
                            this.removeEvent(
                                this.removeItems[0],
                                this.removeItems[1]
                            )
                        }
                    />
                )}
            </Master>
        );
    }
}
