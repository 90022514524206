
export let triggerEvent = function (name, detail = {}, element = window) {
  let event = null;
  if (window.CustomEvent) {
    event = new CustomEvent(name, { detail });
  } else {
    event = document.createEvent("CustomEvent");
    event.initCustomEvent(name, true, true, detail);
  }
  if (event) {
    element.dispatchEvent(event);
  }
}
