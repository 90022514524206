import React from 'react';
import PropTypes from "prop-types";
import CKEditor from "ckeditor4-react";
import Rdate from "react-datetime";
import ApiWorkGroupUser from "../../../Services/ApiWorkGroupUser";
import ApiWorkGroup, {WORKGROUP_STATUS, WORKGROUP_STATUS_ACTIVE, WORKGROUP_STATUS_COMMITTEE, WORKGROUP_STATUS_SPECIFIC, WORKGROUP_STATUS_STANDBY} from "../../../Services/ApiWorkGroup";
import {CK_EDITOR} from "../../../Config/ckEditor";
import InputTextLight from "../InputTextLight/InputTextLight";
import {DateHandler} from "../../../Utils/DateHandler";
import Loader from "../../Loader/Loader";
import InputRadioSimple from "../InputRadioSimple/InputRadioSimple";
import ThematicsRectangle from "../ThematicsRectangle/ThematicsRectangle";
import Modal from "../../Modal/Modal";
import SmoothScroll from "../../../Utils/SmoothScroll";
import Auth from "../../../Security/Auth";
import SearchUsers from "../SearchUsers/SearchUsers";
import "./FormAddWorkGroup.scss";


export default class FormAddWorkGroup extends React.Component {

    static propTypes = {
        workGroup: PropTypes.shape({
            id: PropTypes.number,
            publishedat: PropTypes.shape({
                date: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string])
            }),
            name: PropTypes.string,
            description: PropTypes.string,
            thematic: PropTypes.array,
            users: PropTypes.arrayOf(PropTypes.shape({
                id: PropTypes.number.isRequired,
                user: PropTypes.shape({
                    profile: PropTypes.shape({
                        firstname: PropTypes.string.required,
                        lastname: PropTypes.string.required,
                    }).isRequired
                }).isRequired
            }))
        }),
        thematic: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
        onCancel: PropTypes.func,
        onValid: PropTypes.func,
        onDelete: PropTypes.func,
    };
    _modalContent    = null;
    state            = {
        form: {
            id: 0,
            name: '',
            publishedat: new Date(),
            description: '',
            thematic: '',
            status: '',
            users: {},
        },
        // Error
        error: {
            name: '',
            publishedat: '',
            thematic: '',
            status: '',
        },
        formError: '',
        currentStep: 1,
        workGroupUsersToDelete: [],
        profiles: [],
        loading: false,
        loadingAutocomplete: false,
    };

    hasChangedMembers = false;

    constructor(props) {
        super(props);

        if (this.props.workGroup) {
            this.state.form = {...this.props.workGroup};

            // Format date for API
            this.state.form.publishedat = DateHandler.formatForApi(this.props.workGroup.publishedat.date);
        }

        if (this.props.thematic) {
            this.state.form.thematic = this.props.thematic;
        }

        this.formRef = React.createRef();
        this.prev    = this.prev.bind(this);
    }

    componentDidMount() {
        this._modalContent = this.formRef.current.parentElement;
        if (!this._modalContent.classList.contains('modalContent')) {
            console.error('Warning: It looks like the DOM has changed and we are not getting the modalContent element properly. Check it to prevent unwanted behaviors!');
        }
    }

    handleFormValue(key, value, callback) {
        this.setState((state) => ({
            form: {...state.form, [key]: value}
        }), () => {
            if (callback) return callback();
        });
    }

    checkFormValid() {
        let formHasError = false;
        return new Promise((resolve, reject) => {
            if (this.state.currentStep === 1) {
                this.setState({
                    error: {
                        name: this.state.form.name ? '' : 'Le nom du groupe de travail est requis',
                        publishedat: new Date(this.state.form.publishedat).toString() === 'Invalid Date' ? 'La date de publication est invalide' : '',
                        thematic: !this.state.form.thematic ? 'La thématique est requise' : '',
                        status: WORKGROUP_STATUS.indexOf(this.state.form.status) >= 0 ? '' : 'Le status est requis',
                    }
                }, () => {
                    for (let property in this.state.error) {
                        if (this.state.error.hasOwnProperty(property) && this.state.error[property] !== '') {
                            formHasError = true;
                        }
                    }

                    if (formHasError) {
                        new SmoothScroll(this._modalContent.querySelector('.errorMsg'), {
                            context: this._modalContent,
                            threshold: -220
                        });
                        reject();
                    } else {
                        resolve()
                    }
                });
            } else {
                resolve();
            }
        });
    }

    _formatWorkGroupDataForApi() {
        const DATA = {...this.state.form};
        if (DATA.id <= 0) {
            delete DATA.id;
        }

        delete DATA.users;
        // Prevent some errors
        delete DATA.documents;
        delete DATA.uuid;

        DATA.thematic    = DATA.thematic.id;
        DATA.publishedat = DateHandler.formatForApi(DATA.publishedat);

        return DATA;
    }

    async validateForm() {
        try {
            this.setState({loading: true});

            // Validate form
            await this.checkFormValid();

            const IS_NEW = this.state.form.id <= 0;

            let workgroup    = this.state.form;
            let docWorkgroup = workgroup.documents;

            if (IS_NEW) {
                // Add new workgroup now
                workgroup = await ApiWorkGroup.add(this._formatWorkGroupDataForApi());
            }

            // Remove link between workgroup and users if some have been flagged as deleted (tag removed)
            let wgUserPromises = [];
            for (let i = 0; i < this.state.workGroupUsersToDelete.length; i++) {
                wgUserPromises.push(ApiWorkGroupUser.remove(this.state.workGroupUsersToDelete[i]));
            }

            // Wait for all promises to finish
            await Promise.all(wgUserPromises);

            // Link Workgroup and users
            let wgUsers = this.state.form.users;
            if (this.hasChangedMembers) {
                const USERS_IDS = Object.keys(this.state.form.users);
                wgUserPromises  = [];
                for (let i = 0; i < USERS_IDS.length; i++) {
                    wgUserPromises.push(ApiWorkGroupUser.add(this.state.form.users[USERS_IDS[i]]['user'].id, workgroup['id'], this.state.form.users[USERS_IDS[i]].memberType));
                }

                // Wait for all promises to finish
                wgUsers = await Promise.all(wgUserPromises);
            }

            // Modify an existing workgroup (at end yes, to have fresh related data (workgroupUsers...))
            if (!IS_NEW) {
                workgroup = await ApiWorkGroup.update(this._formatWorkGroupDataForApi());
            } else {
                workgroup.users = wgUsers;
            }

            this.setState({loading: false}, () => {
                workgroup.documents = docWorkgroup;
                return this.props.onValid(workgroup, IS_NEW);
            });


        } catch (e) {
            if (e.status === 409) {
                this.setState({
                    loading: false,
                    formError: "Il semble qu'un groupe de travail du même nom existe déjà dans cette thématique."
                });
            } else {
                this.setState({
                    loading: false,
                    formError: "Une erreur est survenue, veuillez réessayer ultérieurement"
                });
            }
        }
    }

    handleThematicChange(thematic) {
        this.handleFormValue('thematic', thematic);
    }

    next() {
        this.checkFormValid().then(() => {
            this.setState((state) => ({currentStep: state.currentStep + 1}), () => this.formRef.current.parentElement.scrollTop = 0);
        }).catch(() => {
            // Nothing... we display error by settings states, but leave it to prevent not necessary browser console error logs
        });

        this.props.onValid();
    }

    prev() {
        this.setState((state) => ({currentStep: state.currentStep - 1}), () => this.formRef.current.parentElement.scrollTop = 0);
    }

    render() {
        let formContent = '';

        if (this.state.loading) {
            formContent = <Loader/>;
        } else if (this.state.currentStep === 1) {
            /* STEP 1 */
            formContent = <>
                <h4 className={'titleHighlight'}>Étape 1 - Informations sur le GT</h4>

                <div className="row">
                    <div className="col">
                        {/* Name */}
                        <InputTextLight label={'Nom du groupe de travail*'}
                                        value={this.state.form.name}
                                        errorMsg={this.state.error.name}
                                        onChange={(e) => this.handleFormValue('name', e.target.value)}/>

                    </div>
                    <div className="col">
                        {/* Date picker */}
                        <div className={`timePickerWrapper${this.state.error.publishedat ? ' error' : ''}`}>
                            <div className="agendCtrls-time-picker">
                                <label>Date de publication*</label>
                                <Rdate value={DateHandler.getPrettyDate(this.state.form.publishedat)}
                                       input={false}
                                       viewMode="days"
                                       timeFormat={false}
                                       onChange={(e) => this.handleFormValue('publishedat', e.format('YYYY-MM-DD HH:mm:ss'))}/>

                            </div>
                            {this.state.error.publishedat &&
                            <span className={'errorMsg'}>{this.state.error.publishedat}</span>}
                        </div>
                    </div>
                </div>

                <div style={{height: 40}}/>

                {/* Editor WYSIWYG */}
                <h5 className={'subTitle'} style={{marginBottom: 10, marginTop: 0}}>Description</h5>
                <CKEditor
                    onBeforeLoad={(CKEditor) => {
                        CKEditor.disableAutoInline = true;
                        CK_EDITOR.config.filebrowserUploadUrl = CK_EDITOR.baseUploadUrl + Auth.getToken();
                    }}
                    config={CK_EDITOR.config}
                    data={this.state.form.description}
                    type="classic"
                    onChange={(event) => {
                        const data = event.editor.getData();
                        this.handleFormValue('description', data)
                    }}
                />

                {/* Thematic */}
                <ThematicsRectangle isMultiple={false}
                                    onChange={(selectThematics) => this.handleThematicChange(selectThematics)}
                                    defaultCheckedId={this.state.form.thematic ? [this.state.form.thematic.id] : []}
                                    title={"Thématique du groupe de travail*"}/>

                {/* Status */}
                <div className="row">
                    <div className="col-md-6">
                        <div className={"workgroupStatusList"}>
                            <h5 className="subTitle">Statut du groupe de
                                travail*</h5>
                            <div className={"radioList"}>
                                <InputRadioSimple name={'status'} onChange={(itemChecked) => {
                                    this.handleFormValue('status', itemChecked);
                                }} data={[
                                    {
                                        id: 'workgroupCommitteeStatus',
                                        value: WORKGROUP_STATUS_COMMITTEE,
                                        label: 'Comité',
                                        checked: this.state.form.status === WORKGROUP_STATUS_COMMITTEE
                                    },
                                    {
                                        id: 'workgroupActiveStatus',
                                        value: WORKGROUP_STATUS_ACTIVE,
                                        label: 'Groupe actif',
                                        checked: this.state.form.status === WORKGROUP_STATUS_ACTIVE
                                    },
                                    {
                                        id: 'workgroupSpecificStatus',
                                        value: WORKGROUP_STATUS_SPECIFIC,
                                        label: 'Groupe spécifique',
                                        checked: this.state.form.status === WORKGROUP_STATUS_SPECIFIC
                                    },
                                    {
                                        id: 'workgroupStandby',
                                        value: WORKGROUP_STATUS_STANDBY,
                                        label: 'Groupe en veille',
                                        checked: this.state.form.status === WORKGROUP_STATUS_STANDBY
                                    }]}
                                />
                            </div>
                            {this.state.error.status &&
                            <span className={'errorMsg'}>{this.state.error.status}</span>}
                        </div>
                    </div>
                </div>


                <br/>

                <p className={'required'}><b>* Champs obligatoires</b></p>
            </>
        } else {
            /* STEP 2 */
            formContent =
                <div>
                    <h4 className={'titleHighlight'}>Étape 2 - Participants au GT</h4>
                    <SearchUsers
                        workGroup={this.props.workGroup}
                        onChange={(users, usersLabels, userToDelete) => {
                            let formUsers = [];
                            Object.keys(users).forEach(userId => {
                                formUsers.push({memberType: users[userId]['memberType'], user: {id: userId}})
                            });

                            this.setState({
                                form: {
                                    ...this.state.form,
                                    users: formUsers
                                },
                                usersLabels: usersLabels,
                                workGroupUsersToDelete: userToDelete
                            });
                            this.hasChangedMembers = true;
                        }
                        }/>
                </div>
            ;
        }

        return (
            <Modal onClose={this.props.onCancel}
                   title={this.props.workGroup ? `Editer le groupe de travail « ${this.state.form.name} »` : "Ajouter un groupe de travail"}
                   saveLabel={this.state.currentStep >= 2 ? "Valider" : "Enregistrer et continuer"}
                   cancelLabel={this.state.currentStep === 1 ? 'Annuler' : 'Précédent'}
                   onCancel={this.state.currentStep === 1 ? this.props.onCancel : this.prev}
                   onValidate={this.state.currentStep < 2
                       ? () => this.next()
                       : () => this.validateForm()
                   }
            >
                <div className={'FormAddWorkGroup'} ref={this.formRef}>
                    <form>
                        {formContent}
                    </form>

                    <br/>

                    {this.state.formError &&
                    <p className={'centerText'} style={{color: 'red'}}>{this.state.formError}</p>}

                </div>
            </Modal>
        )
    }
}

