import axios from "axios";
import Api, {PATH, PATH_UX} from "../Utils/Api";

export const DOC_RESULT_PAGE = 16;

function createFilter(filter) {
    let sort    = 'published_at';
    let orderBy = 'desc';
    let where   = '';

    if (Object.keys(filter).length > 0) {
        if (filter.where !== '') {
            where += ` and ${filter.where}`
        }
        if (filter.sort) {
            sort = filter.sort;
        }
        if (filter.orderBy) {
            orderBy = filter.orderBy;
        }
    }

    return {
        where: where,
        sort: sort,
        orderBy: orderBy
    }
}

export default {

    /**
     * Get document for workgroup
     * @param workGroupId
     * @param limit
     * @param featured
     * @param page
     * @param filter
     * @returns {AxiosPromise<any>}
     */
    getDocumentsWorkgroup: (workGroupId = null, page = 1, limit = DOC_RESULT_PAGE, featured = false, filter = {}) => {
        let nested = 'files,meeting,docType,tags';
        let where  = '`category` = 0';
        if (featured && !workGroupId) {
            where += ' and `featured` = 1'
        } else if (workGroupId && !featured) {
            where += ` and \`workgroup_id\` = ${workGroupId}`;
        } else if (featured && workGroupId) {
            where += ` and \`featured\` = 1 AND \`workgroup_id\` = ${workGroupId}`;
        }

        let filters = createFilter(filter);
        where += filters['where'];

        where = `where (` + where + `)`;

        const params = {
            "api[sort]": filters.sort,
            "api[order]": filters.orderBy,
            "api[limit]": `${limit}`,
            "api[nested_entities]": nested,
            "api[where]": where,
            'api[page]': page,
            'api[resources]': limit
        };

        return axios.get(`${PATH}/content/document`, {params: params});
    },

    getDocumentsFeaturedWorkgroup: (workGroupId = null) => {
        let nested = 'files,meeting,docType,tags';
        let where  = `\`archived_at\` = \`null\` AND \`category\` = 0 and \`featured\` = 1 AND \`workgroup_id\` = ${workGroupId}`;

        where = `where (` + where + `)`;

        const params = {
            "api[nested_entities]": nested,
            "api[where]": where,
        };

        return axios.get(`${PATH}/content/document`, {params: params});
    },

    /**
     * Get documents for document <.<
     * @param page
     * @param limit
     * @param filter
     * @param categoriesId
     * @param searchText
     * @param nested
     * @returns {AxiosPromise<any>}
     */
    getDocuments: (page = 1, limit = DOC_RESULT_PAGE, filter = {}, categoriesId = [], searchText = '', nested = 'files,category.thematic,category.parent.parent.parent.parent,tags') => {
        let where  = 'where (`workgroup`=0';

        let filters = createFilter(filter);
        where += filters['where'];

        if (searchText !== '') {
            where += ` and \`title\` LIKE \`%${searchText}%\``
        }

        if (categoriesId.length > 0) {
            if (categoriesId[0] === 0) {
                return new Promise((resolve, reject) => reject);
            }
            where += ` and (\`category\`=${categoriesId[0]}`;
            for (let i = 1; i < categoriesId.length; i++) {
                where += ` OR \`category\`=${categoriesId[i]}`;
            }
            where += ')';
        }

        where += ')';

        const params = {
            "api[sort]": filters.sort,
            "api[order]": filters.orderBy,
            "api[limit]": `${limit}`,
            "api[nested_entities]": Api.buildNestedEntity(nested),
            "api[where]": where,
            'api[page]': page,
            'api[resources]': limit
        };

        return axios.get(`${PATH}/content/document`, {params: params});
    },

    getDocumentsFilters: (filter = {}, categoriesId = []) => {
        let where  = 'where (`type`=`MAP`';

        let filters = createFilter(filter);
        where += filters['where'];

        if (categoriesId.length > 0) {
            where += ` and (\`document.category\`=${categoriesId[0]}`;
            for (let i = 1; i < categoriesId.length; i++) {
                where += ` OR \`document.category\`=${categoriesId[i]}`;
            }
            where += ')';
        }

        where += ')';

        const params = {
            "api[sort]": filters.sort,
            "api[order]": filters.orderBy,
            "api[nested_entities]": 'document',
            "api[where]": where,
        };

        return axios.get(`${PATH}/content/documentFilter`, {params: params});
    },

    /**
     * Get document
     * @param slug
     * @param fromWorkgroup
     * @returns {AxiosPromise<any>}
     */
    get: (slug, fromWorkgroup = false) => {
        let nested   = 'files,author.profile, ';
        let where = '';

        if (fromWorkgroup) {
            nested += 'workgroup.thematic,comments.author.profile';
            where = `where (\`category\` = 0 AND \`slug\` = \`${slug}\`)`

        } else {
            nested += 'category.thematic,category.parent.parent.parent.parent,tags';
            where = `where \`slug\` = \`${slug}\``
        }

        let params = {
            "api[nested_entities]": nested,
            "api[where]": where
        };

        return axios.get(`${PATH}/content/document`, {params: params});
    },

    getShortUrl: (slug) => {
        const params = {
            "api[where]": `where \`longUrl\`=\`${slug}\``
        };

        return axios.get(`${PATH}/content/shortenedUrl`, {params: params});
    },

    /**
     * Set document
     * @param document
     * @param nestedEntities
     * @returns {AxiosPromise<any>}
     */
    add: (document, nestedEntities = []) => {
        let nested = '';

        if (nestedEntities.length > 0) {
            nested = '?api[nested_entities]=' + Api.buildNestedEntity(nestedEntities);
        }

        return axios.post(`${PATH}/content/document${nested}`, document);
    },

    /**
     * Update document
     * @param document
     * @param nestedEntities
     * @returns {AxiosPromise<any>}
     */
    update: (document, nestedEntities = []) => {
        let nested = '';

        if (nestedEntities.length > 0) {
            nested = '?api[nested_entities]=' + Api.buildNestedEntity(nestedEntities);
        }

        return axios.patch(`${PATH}/content/document/${document.id}${nested}`, document)
    },

    /**
     * Remove document
     * @param id
     * @returns {Promise<unknown>}
     */
    remove: (id) => {
        return axios.delete(`${PATH}/content/document/${id}`)
    },

    /**
     * Archive document
     * @param id
     * @param archived
     * @returns {AxiosPromise<any>}
     */
    archive: (id, archived = 1) => {
        return axios.patch(`${PATH}/content/document/${id}/archived/${archived}`)
    },

    /**
     * Upload a resource file
     * @param data
     * @returns {AxiosPromise<any>}
     */
    uploadFile: (data) => {
        return axios.post(`${PATH}/content/document/upload`, data, {headers: {'Content-Type': 'multipart/form-data'}})
    },

    getDocumentTypes: (type = 'all', id = 0, role) => {
        return axios.post(`${PATH_UX}/document/files/type/${type}/${id}`, role);
    },

    addComment: (comment) => {
        return axios.post(`${PATH}/content/comment`, comment);
    },

    /**
     * Remove all document's related filters
     * @param documentId
     * @returns {AxiosPromise}
     */
    removeFilters: documentId => {
        return axios.delete(`${PATH}/content/document/${documentId}/filters`);
    },

    getWorkGroupMeeting: workgroupId => {
        return axios.get(`${PATH_UX}/document/meeting/${workgroupId}`);
    }
}
