import variables from '../Styles/helpers/_variables.scss';

export const isMobile = () => {
    return window.innerWidth <= parseInt(variables['phone']);
};

export const isPad = () => {
    return window.innerWidth <= parseInt(variables['pad']) && window.innerWidth > parseInt(variables['phone']);
};

export const isSmallScreen = () => {
    return window.innerWidth <= parseInt(variables['pad']);
};
