import React from 'react';
import ApiAgenda from "../../Services/ApiAgenda";
import Master from "../../Components/Layout/Master";
import Banner from "../../Components/Banner/Banner";
import Loader from "../../Components/Loader/Loader";
import Modal from "../../Components/Modal/Modal";
import {DateHandler} from "../../Utils/DateHandler";
import {Calendar} from "../../Utils/Calendar";
import "./AgendaDetail.scss";
import {Http} from "../../Utils/Api";
import {path} from "../../Config/routes";


export default class AgendaDetail extends React.Component {

    state = {
        showModalAddEvent: false,
        loading: true,
        event: {
            id: '1',
            title: '',
            content: '',
            startingat: {
                date: ''
            },
            finishingat: {
                date: ''
            },
            thematic: {
                name: '',
                color: ''
            }
        },
        httpError: false
    };

    constructor(props) {
        super(props);
        this._isMounted = false;
    }


    componentDidMount() {
        this._isMounted = true;

        if (this.props.match.params.id !== undefined) {
            const ID = parseInt(this.props.match.params.id);
            // get event
            ApiAgenda.getEvent(ID, 'thematic').then((event) => {
                if (!this._isMounted) return;
                this.setState({event: event, loading: false});
            }).catch((err) => {
                if (!this._isMounted) return;
                if (err.status && err.status >= 500) {
                    this.setState({
                        httpError: true
                    });
                }
            });
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
        Http.cancel();
    }

    render() {
        const DATE = DateHandler.getFormatEvent(this.state.event.startingat.date, this.state.event.finishingat.date);
        const BREADCRUMB = [
            {
                url: path('agenda'),
                label: 'Agenda'
            },
            {
                label: this.state.event.title
            }
        ];

        return (
            <Master id={'agendaDetail'} httpError={this.state.httpError} breadCrumb={BREADCRUMB} {...this.props}>
                <Banner size={'small'} backgroundColor={this.state.event.thematic.color} placeholderLoading={this.state.loading}>
                    <div className={`bannerTitle small`}>
                        {!this.state.loading && <>
                            <div className={'date'}>
                                <span className={'bannerSupTitle'} style={{color: this.state.event.thematic.color}}>{DATE}</span>
                                <button className={'addEventToPersonalCalendar'} onClick={() => Calendar.downloadIcs(this.state.event)}>
                                    <img src="/assets/images/icon/agenda/calendar-more.svg" alt="Ajouter l'évenement à votre agenda personel"/>
                                </button>
                            </div>
                            <h1>{this.state.event.title}</h1>
                            {this.state.event.place && <h4>{this.state.event.place}</h4>}
                            <h5 style={{color: this.state.event.thematic.color}}>{this.state.event.thematic.name}</h5>
                        </>}
                    </div>
                </Banner>
                <div className="pageContent container">
                    {this.state.loading ? <Loader type={'inline'}/> : <>
                        <h5 className={'descTitle'}>DESCRIPTION</h5>
                        <div dangerouslySetInnerHTML={{__html: this.state.event.content}}/>
                    </>}
                </div>

                {this.state.showModalAddEvent && <Modal autoHeight={true} autoWidth={true} onClose={() => this.setState({showModalAddEvent: false})}>
                    <p className={'centerText'}>L'évenement à été ajouté à votre agenda. (À faire)</p>
                    <div className={'buttonModal'}>
                        <button type={'button'} className={'cancel'} onClick={() => this.setState({showModalAddEvent: false})}>Ok</button>
                    </div>
                </Modal>}
            </Master>
        )
    }
}