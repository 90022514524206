const ics = require('ics');
const moment = require('moment');
const {saveAs} = require('file-saver');
const slugify = require('slugify');


export class Calendar {

    /**
     * Download ics file
     * @param event
     * @requires ics
     * @requires moment
     * @requires saveAs from file-saver
     * @requires slugify
     */
    static downloadIcs(event = {title: '', content: '', startingat: {date: ''}, finishingat: {date: ''}}) {
        ics.createEvent({
            title: event.title,
            description: decodeHtml(event.content),
            start: moment(event.startingat.date).format('YYYY-M-D-H-m').split("-"),
            end: moment(event.finishingat.date).format('YYYY-M-D-H-m').split("-")
        }, (error, valueIcs) => {
            if (error) return;

            const BLOB = new Blob([valueIcs]);
            saveAs(BLOB, slugify(event.title) + '.ics');

            return valueIcs;
        })
    }
}

function decodeHtml(html) {
    const TXT = document.createElement("DIV");
    TXT.innerHTML = html;
    return TXT.textContent || TXT.innerText || "";
}