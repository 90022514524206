import React from 'react';
import PropTypes from "prop-types";
import Header from "../Includes/Header/Header";
import Footer from "../Includes/Footer/Footer";
import Loader from "../Loader/Loader";
import HttpError from "../../Errors/HttpError/HttpError";
import Permission from "../../Errors/Permission/Permission";


export default class Master extends React.Component {

    static propTypes = {
        id: PropTypes.string,
        breadCrumb: PropTypes.arrayOf(PropTypes.shape({
            url: PropTypes.string,
            label: PropTypes.string
        })),
        showMenu: PropTypes.bool,
        admin: PropTypes.bool,
        footer: PropTypes.bool,
        httpError: PropTypes.bool,
        permissionsError: PropTypes.bool,
    };

    static defaultProps = {
        showMenu: true,
        admin: false,
        footer: true,
        httpError: false
    };

    state = {
        loading: true
    };

    componentDidMount() {
        window.scrollTo(0, 0);
        this.setState({loading: false});
    }

    render() {
        return (
            <div id={this.props.id}>
                {this.props.showMenu && <Header breadCrumb={this.props.breadCrumb} adminHeader={this.props.admin} {...this.props}/>}
                <div id={"main"}>
                    {this.props.permissionsError && <Permission/>}
                    {this.props.httpError && <HttpError/>}
                    {!this.props.permissionsError && !this.props.httpError ? this.props.children : ''}
                    {this.state.loading && <Loader/>}
                </div>
                {this.props.footer && <Footer {...this.props}/>}
            </div>
        )
    }
}