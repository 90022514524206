import React from 'react';
import PropTypes from 'prop-types';
import Select from "../../Form/Select/Select";
import Button from "../../Form/Button/Button";
import ApiWorkGroupUser from "../../../Services/ApiWorkGroupUser";
import './WorkgroupMembersListSearch.scss';

export default class WorkgroupMembersListSearch extends React.Component {

    static propTypes = {
        profiles: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.number.isRequired,
            firstname: PropTypes.string,
            lastname: PropTypes.string,
            address: PropTypes.string,
            job: PropTypes.string,
            phone: PropTypes.string,
            mobile: PropTypes.string,
            company: PropTypes.shape({
                id: PropTypes.id,
                name: PropTypes.string,
            }),
            user: PropTypes.shape({
                email: PropTypes.string,
            }).isRequired
        })).isRequired,
        searchHasBeenDoneAtLeastOnce: PropTypes.bool,
        onAddMember: PropTypes.func.isRequired,
    };

    static defaultProps = {
        searchHasBeenDoneAtLeastOnce: false,
        onAddMember: (profile, memberType) => {}
    };

    _wgUserMemberTypes = [];
    _selectMemberTypesValues = [];
    state = {
        profiles: [],
        memberType: null,
        errorMsg: '',
        profileInError: 0,
    };

    constructor(props) {
        super(props);

        this.state.profiles = props.profiles;

        // Get all available member types
        this._wgUserMemberTypes = ApiWorkGroupUser.getAllMemberTypes();

        // Build member types select values list
        Object.keys(this._wgUserMemberTypes).forEach((memberTypeValue) => {
            this._selectMemberTypesValues.push({id: memberTypeValue, name: this._wgUserMemberTypes[memberTypeValue]});
        });
    }

    componentDidUpdate(prevProps, prevStates) {
        if (prevProps.profiles !== this.props.profiles) {
            this.setState({profiles: this.props.profiles});
        }
    }

    _checkMemberTypeValidity(memberType) {
        return Object.keys(this._wgUserMemberTypes).indexOf(memberType) >= 0;
    }

    addMember(profile) {
        if (this._checkMemberTypeValidity(this.state.memberType)) {
            let profiles = this.state.profiles;
            // Remove the member from the list
            profiles = profiles.filter(prof => {
                return prof.id !== profile.id
            });
            this.setState({profiles: profiles});

            // Notify parent component
            this.props.onAddMember(profile, this.state.memberType);
            this.setState({memberType: null});
        } else {
            this.setState({errorMsg: 'Rôle requis', profileInError: profile.id});
        }
    }

    handleChangeRole(memberType) {
        if (this._checkMemberTypeValidity(memberType)) {
            this.setState({errorMsg: '', profileInError: 0});
        }

        this.setState({memberType: memberType})
    }

    render() {
        const DISPLAY_NO_RESULT_MSG = this.props.searchHasBeenDoneAtLeastOnce && this.state.profiles.length <= 0;

        return (
            <div className={'workgroupMembersListSearch default'}>
                {!DISPLAY_NO_RESULT_MSG ?
                    <ul>
                        {this.state.profiles.map((profile, i) => {
                                return <li key={i}>
                                    <div>
                                        <div
                                            className={'profile'}>{profile.lastname.substr(0, 1)}{profile.firstname.substr(0, 1)}
                                        </div>
                                        <div>
                                            <b className={'name'}>{profile.lastname} {profile.firstname}</b>
                                            <div className={'details'}>
                                                <table cellSpacing={0}>
                                                    <tbody>
                                                    <tr>
                                                        <td><span>{profile.company.name}</span></td>
                                                        <td><span>{profile.address}</span></td>
                                                    </tr>
                                                    <tr>
                                                        <td><span>{profile.job}</span></td>
                                                        <td><span>{profile.phone}</span></td>
                                                    </tr>
                                                    <tr>
                                                        <td><span>{profile.user && profile.user.email}</span></td>
                                                        <td><span>{profile.mobile}</span></td>
                                                    </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        <div className={"roles-and-add"}>
                                            <div className={this.state.errorMsg && this.state.profileInError === profile.id ? 'error' : null}>
                                                <Select id={'role'}
                                                        placeholder={'Rôle'}
                                                        value={this._selectMemberTypesValues}
                                                        onChange={e => this.handleChangeRole(e.target.value)}
                                                        className={"form-wg-add-user"}/>
                                                {this.state.errorMsg !== '' && this.state.profileInError === profile.id && <span className={"errorMsg"}>{this.state.errorMsg}</span>}
                                            </div>
                                            <div>
                                                <Button type={"button"} onClick={() => this.addMember(profile)}>Ajouter
                                                    ce membre</Button>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            }
                        )}
                    </ul> :
                    <div className={"noResult"}>Aucun utilisateur ne correspond à votre recherche</div>
                }
            </div>
        )
    }

}