import React from 'react';
import Moment from "moment";
import ApiNews, {NEWS_RESULT_PAGE} from "../../../Services/ApiNews";
import Master from "../../../Components/Layout/Master";
import Banner from "../../../Components/Banner/Banner";
import PreviewNews from "../../../Components/Layout/PreviewNews";
import ToolsManage from "../../../Components/ToolsManage/ToolsManage";
import {DateHandler} from "../../../Utils/DateHandler";
import FilterNews from "../../../Components/Filter/FilterNews";
import Modal from "../../../Components/Modal/Modal";
import FormAddNews from "../../../Components/Form/FormAddNews/FormAddNews";
import Loader from "../../../Components/Loader/Loader";
import Auth from "../../../Security/Auth";
import {Http, QueryBuilder} from "../../../Utils/Api";
import "./AdminNews.scss";
import Pagination from "../../../Components/Pagination/Pagination";
import * as qs from "qs";
import Roles from "../../../Security/Roles";


export default class AdminNews extends React.Component {

    state = {
        news: [],
        nbResults: 0,
        selects: [],
        isSearch: true,
        searchText: '',
        searchLoading: false,
        editMode: false,
        deleteMode: false,
        showModalAddNews: false,
        showModalConfirmDeleteNews: false,
        tempNews: null,
        loading: true,
        loadingForm: false,
        formError: '',
        httpError: false,
        pagination: null
    };

    constructor(props) {
        super(props);
        this.searchText = '';
        this.id         = null;
        this._isMounted = false;
        this._QB        = new QueryBuilder()
            .setSort('published_at')
            .setOrder('desc')
            .setNestedEntities(['visual,files,author'])
            .setPage(1)
            .setLimit(NEWS_RESULT_PAGE);

        if (this.props.match.params.id !== undefined) {
            this.id = this.props.match.params.id;
        }
    }

    componentDidMount() {
        this._isMounted = true;

        const PARAMS = qs.parse(this.props.location.search, {ignoreQueryPrefix: true});
        const PAGE   = PARAMS.page || 1;

        this.getNews(PAGE);
    }

    componentWillUnmount() {
        this._isMounted = false;
        Http.cancel();
    }

    getNews(page = 1) {
        this._QB.setPage(page);
        ApiNews.getNews(['visual,files,author'], this._QB, this.searchText).then((news) => {
            if (!this._isMounted) return;

            this.setState({
                news: news.payload,
                pagination: news.extra,
                loading: false,
                searchLoading: false
            });
        }).catch(() => {
            if (!this._isMounted) return;

            this.setState({
                httpError: true
            })
        });
    }

    filter(payload) {
        this._QB
            .setSort(payload.sort)
            .setOrder(payload.orderBy);

        // Apply sort
        this.setState({loading: true});
        this.getNews();
    }

    toggleEditMode() {
        this.setState((state) => ({
            deleteMode: false,
            editMode: !state.editMode
        }))
    }

    toggleDeleteMode() {
        this.setState((state) => ({
            editMode: false,
            deleteMode: !state.deleteMode
        }))
    }

    openFormEditNews(news) {
        this.setState({
            tempNews: news,
            showModalAddNews: true
        });
    }

    openFormAddNews() {
        this.setState({
            editMode: false,
            deleteMode: false,
            showModalAddNews: true
        })
    }

    showFormError() {
        this.setState({
            formError: "Une erreur est survenue, veuillez réessayer ultérieurement",
            loadingForm: false
        });
    }

    updateNews(news) {
        this.setState({loadingForm: true, formError: ''});

        let promise = [];

        if (!news.visual.id) {
            const FORM_DATA_VISUAL = new FormData();
            FORM_DATA_VISUAL.append('visual', news.visual);
            promise[0] = ApiNews.uploadFileNews(FORM_DATA_VISUAL);
        }

        if (news.files.length > 0 && !news.files[0].id) {
            const FORM_DATA_FILE = new FormData();
            FORM_DATA_FILE.append('files', news.files[0]);
            promise[1] = ApiNews.uploadFileNews(FORM_DATA_FILE);
        }

        // Upload file/visual
        Promise.all(promise).then((payload) => {
            if (!this._isMounted) return;

            const formatDataForApi = this.formatDataForApi(news, payload);

            // Update news
            ApiNews.patch(news.id, formatDataForApi).then((payload) => {
                if (!this._isMounted) return;

                let newUpdate = payload;

                // Update current object news
                let newsArray = this.state.news;
                for (let property in this.state.news) {
                    if (this.state.news.hasOwnProperty(property) && this.state.news[property].id === news.id) {
                        // Update visual
                        if (news.visual.tempUrl) {
                            newUpdate.visual      = {};
                            newUpdate.visual.id   = formatDataForApi.visual;
                            newUpdate.visual.url  = news.visual.tempUrl;
                            newUpdate.visual.name = news.visual.name;
                        } else {
                            newUpdate.visual = newsArray[property].visual;
                        }

                        if (news.files.length > 0) {
                            newUpdate.files         = [{}];
                            newUpdate.files[0].id   = formatDataForApi.files[0];
                            newUpdate.files[0].name = news.files[0].name;
                        }

                        newsArray[property] = newUpdate;
                    }
                }

                this.setState({
                    news: newsArray,
                    showModalAddNews: false,
                    loadingForm: false
                });
            }).catch((payload) => {
                if (!this._isMounted) return;

                this.showFormError(payload);
            });
        }).catch((payload) => {
            if (!this._isMounted) return;

            this.showFormError(payload);
        });
    }

    createNews(news) {
        this.setState({loadingForm: true, formError: ''});

        let promise = [];

        // Visual form data
        if (news.visual && isNaN(news.visual)) {
            const FORM_DATA_VISUAL = new FormData();
            FORM_DATA_VISUAL.append('visual', news.visual);
            promise[0] = ApiNews.uploadFileNews(FORM_DATA_VISUAL);
        }

        // File form data
        if (news.files && news.files.length > 0) {
            const FORM_DATA_FILE = new FormData();
            FORM_DATA_FILE.append('files', news.files[0]);
            promise[1] = ApiNews.uploadFileNews(FORM_DATA_FILE);
        }

        // Upload file/visual
        Promise.all(promise).then((payload) => {
            if (!this._isMounted) return;

            const formatDataForApi = this.formatDataForApi(news, payload);

            // Create news
            ApiNews.post(formatDataForApi).then((payload) => {
                if (!this._isMounted) return;

                let newCreated = payload;

                // Set date if not return
                if (!newCreated.createdat) {
                    newCreated.createdat      = {};
                    newCreated.createdat.date = Moment().format('YYYY-MM-DD HH:mm');
                }
                // Set temp visual because is not return by api
                if (!newCreated.visual || newCreated.visual.length === 0) {
                    newCreated.visual      = {};
                    newCreated.visual.id   = formatDataForApi.visual;
                    newCreated.visual.url  = news.visual.tempUrl;
                    newCreated.visual.name = news.visual.name;
                }
                // Set file name & id if this edited
                if (formatDataForApi.files.length > 0) {
                    newCreated.files         = [{}];
                    newCreated.files[0].id   = formatDataForApi.files[0];
                    newCreated.files[0].name = news.files[0].name;
                }

                // Add news added in first array index
                let newArrayOfNews = this.state.news;
                newArrayOfNews.unshift(newCreated);

                this.setState({
                    news: newArrayOfNews,
                    loadingForm: false,
                    showModalAddNews: false
                });
            }).catch(({payload}) => {
                if (!this._isMounted) return;

                this.showFormError(payload);
            });
        }).catch((payload) => {
            if (!this._isMounted) return;

            this.showFormError(payload);
        });
    }

    formatDataForApi(news, uploadPayload) {
        const VISUAL = news.visual ? news.visual.id : undefined;
        const FILE   = news.files && news.files.length > 0 && news.files[0].id ? [news.files[0].id] : [];

        // Set format data for api
        let newForPost = {
            title: news.title,
            content: news.content,
            featured: news.featured,
            author: Auth.getUser().id,
            files: FILE,
            visual: VISUAL,
            allowNotificationPush: news.allowNotificationPush ? 1 : 0
        };

        if (news._updatePublicationDate || !news.id) {
            newForPost.publishedat = DateHandler.formatForApi(new Date(), true);
        }

        if (uploadPayload[0]) newForPost.visual = uploadPayload[0].identifier;
        if (uploadPayload[1]) newForPost.files = [uploadPayload[1].identifier];

        return newForPost;
    }

    deleteNews(news) {
        this.setState({
            tempNews: news,
            showModalConfirmDeleteNews: true
        });
    }

    confirmDeleteNews(news) {
        this.setState({loadingForm: true});

        ApiNews.delete(news.id).then(() => {
            if (!this._isMounted) return;

            let newsArray = this.state.news;

            for (let property in newsArray) {
                if (newsArray.hasOwnProperty(property) && newsArray[property].id === news.id) {
                    newsArray.splice(property, 1);
                }
            }

            this.setState({
                news: newsArray,
                showModalAddNews: false,
                showModalConfirmDeleteNews: false,
                loadingForm: false
            }, () => {
                this.setState({tempNews: null})
            });
        });
    }

    handleSearch(searchText) {
        this.searchText = searchText;
        Pagination.resetPagination();

        if (searchText) {
            this.setState({searchLoading: true});

            this.getNews(1);
        } else {
            this.resetNews();
        }
    }

    resetNews() {
        this.setState((state) => ({news: state.news}));
    }

    closeForm() {
        this.setState({
            showModalAddNews: false,
            formError: ''
        });
    }

    render() {
        return (
            <Master id="adminNews" httpError={this.state.httpError} admin={true} {...this.props}>
                {/* Banner */}
                <Banner title={'Gestion des actualités'}
                        search={true}
                        onToggleSearch={() => this.resetNews()}
                        searchLoading={this.state.searchLoading}
                        onSearch={(searchText) => this.handleSearch(searchText)}/>

                {/* Filter */}
                <FilterNews onFilter={(by, e) => this.filter(by, e)}/>

                {/* Tools */}
                <div className="container">
                    <ToolsManage role={Roles.ROLE_ADMINISTRATOR} onAdd={() => this.openFormAddNews()} onEdit={() => this.toggleEditMode()} onDelete={() => this.toggleDeleteMode()}/>
                </div>

                {/* List */}
                <div id="listNews" className={'container'}>
                    {this.state.loading ? <Loader type={"inline"}/> : this.state.news.length > 0
                        ? this.state.news.map((news, i) => {
                            return <div key={i}>
                                <PreviewNews news={news}
                                             left={true}
                                             showMore={false}
                                             excerptLength={100}
                                             showDate={true}
                                             infoBubble={false}
                                             editMode={this.state.editMode}
                                             deleteMode={this.state.deleteMode}
                                             onEdit={(news) => this.openFormEditNews(news)}
                                             onDelete={(news) => this.deleteNews(news)}
                                />
                            </div>
                        })
                        : <div className={'col'}>
                            <h6 className={'centerText'}>Aucun Résultat</h6>
                        </div>}
                </div>

                {this.state.pagination &&
                <Pagination
                    pagination={this.state.pagination}
                    onClick={async (page) => {
                        this.setState({loading: true});
                        this.getNews(page);
                    }}/>
                }

                {/* Modal add new */}
                {this.state.showModalAddNews && <Modal onClose={() => this.closeForm()}>
                    <FormAddNews news={this.state.editMode && this.state.tempNews ? this.state.tempNews : null}
                                 onCancel={() => this.closeForm()}
                                 onValid={(news) => news.id ? this.updateNews(news) : this.createNews(news)}
                                 onDelete={(news) => this.confirmDeleteNews(news)}
                                 loading={this.state.loadingForm}
                                 errors={this.state.formError}
                    />
                </Modal>}

                {/* Modal confirm delete news */}
                {this.state.showModalConfirmDeleteNews &&
                <Modal autoWidth={true} autoHeight={true} onClose={() => this.setState({showModalConfirmDeleteNews: false})}>
                    <p>Êtes-vous sûr de vouloir supprimer « {this.state.tempNews.title} » ?</p>
                    <div className={'buttonModal'}>
                        <button type={'button'} className={'cancel'} onClick={() => this.setState({showModalConfirmDeleteNews: false})}>Non</button>
                        <button type={'button'} onClick={() => this.confirmDeleteNews(this.state.tempNews)} disabled={this.state.loadingForm}>
                            {this.state.loadingForm ? <Loader type={"button"} backgroundColor={'transparent'}/> : 'Oui'}
                        </button>
                    </div>
                </Modal>}
            </Master>
        )
    }
}