import React from 'react';
import {THEMATIC_TYPE_WORKGROUP} from "../../../Services/ApiThematics";
import SelectMultiple from "../SelectMultiple/SelectMultiple";
import PropTypes from "prop-types";
import Loader from "../../Loader/Loader";
import Thematics from "../../../Utils/Thematics";

export default class SelectWorkGroups extends React.Component {

    static propTypes = {
        title: PropTypes.string,
        selected: PropTypes.array,
        onChange: PropTypes.func,
        onRemove: PropTypes.func,
        onAdd: PropTypes.func,
        multiple: PropTypes.bool.isRequired,
        selectAllButton: PropTypes.bool,
        listRoleLegend : PropTypes.bool
    };

    static defaultProps = {
        selectAllButton: false,
        onChange: () => {
        },
        onRemove: () => {
        },
        onAdd: () => {
        }
    };

    state = {
        options: [],
        loading: true,
        height: 0,
    };

    childRef = React.createRef();

    componentDidMount() {

        let thematics = Thematics.getLocalThematics(THEMATIC_TYPE_WORKGROUP, true);

        thematics.forEach((thematic) => {
            let workGroups = thematic.workgroups;
            if (workGroups && workGroups.length > 0) {
                let specific = [];
                let standby  = [];
                let common   = [];

                for (let i = 0; i < workGroups.length; i++) {
                    if (workGroups[i].status === 'SPECIFIC') {
                        specific.push(workGroups[i]);
                    } else if (workGroups[i].status === 'STANDBY') {
                        standby.push(workGroups[i]);
                    } else {
                        common.push(workGroups[i]);
                    }
                }

                thematic.workgroups = [];
                common.forEach((workgroup) => {
                    thematic.workgroups.push(workgroup);
                });
                thematic.workgroups.push({
                    name: 'Groupes spécifiques',
                    workgroups: specific
                });
                thematic.workgroups.push({
                    name: 'Groupes en veille',
                    workgroups: standby
                });
            }
        });

        this.setState({
            options: thematics,
            height: thematics.length * 70 + 10
        }, () => {
            this.setState({loading: false});
        })
    }

    buildTree(items, level = 1) {
        level++;
        return items.map((item, i) => {
            return (
                <li key={i}>
                    <button
                        id={item.uid}
                        type={'button'}
                        className={item.workgroups ? 'withChild' : null}
                        onClick={(e) => this.childRef.current.onChange(item, e.currentTarget, true)}>
                        {item.name}
                    </button>
                    {item.workgroups && <ul className={`level-${level}`}>{this.buildTree(item.workgroups, level)}</ul>}
                </li>
            )
        });
    }

    render() {
        return this.state.loading ?
            <Loader type={"inline"}/>
            :
            (<SelectMultiple
                ref={this.childRef}
                title={this.props.title}
                onChange={this.props.onChange}
                onAdd={this.props.onAdd}
                onRemove={this.props.onRemove}
                multiple={this.props.multiple}
                selected={this.props.selected}
                selectAllButton={this.props.selectAllButton}
                height={this.state.height}
                validateButton={true}
                listRoleLegend={this.props.listRoleLegend}
            >
                {this.buildTree(this.state.options)}
            </SelectMultiple>);
    }
}