import React from "react";
import PropTypes from "prop-types";
import Button from "../Form/Button/Button";
import "./Pagination.scss";

export default class Pagination extends React.Component {
    static propTypes = {
        pagination: PropTypes.shape({
            page: PropTypes.shape({
                previous: PropTypes.number,
                current: PropTypes.number,
                next: PropTypes.number,
                total: PropTypes.number,
            }),
            resources: PropTypes.shape({
                count: PropTypes.number,
                total: PropTypes.number,
            }),
        }).isRequired,
    };

    static defaultProps = {
        onClick: () => {},
    };

    onClick(page) {
        if (page !== this.props.pagination.page.current) {
            window.history.pushState(
                {
                    id: "page=" + page,
                },
                "ASFA",
                window.location.origin +
                    window.location.pathname +
                    "?page=" +
                    page
            );
            this.props.onClick(page);
        }
    }

    static resetPagination() {
        window.history.pushState(
            {
                id: "page=" + 1,
            },
            "ASFA",
            window.location.origin + window.location.pathname
        );
    }

    createPagination() {

        let pagination = [];
        const current = this.props.pagination.page.current;
        const start = 1;
        const end = this.props.pagination.page.total;

        // Le nombre d'élements affichés avant et après le current:
        const padding_current = 1;

        // Le nombre d'élements affichés après le debut et avant la fin:
        const padding_start_end = 1;

        // @internal: prevent double dots
        let previous_was_dot = false;

        const renderItem = (index) => {
            previous_was_dot = false;
            return (
                <li
                    key={index}
                    onClick={() => this.onClick(index)}
                    className={
                        this.props.pagination.page.current === index
                            ? "current"
                            : null
                    }
                >
                    {index.toString().padStart(2, "0")}
                </li>
            );
        };

        const renderDots = (index) => {
            previous_was_dot = true;
            return (
                <li className="gap" key={index}>
                    ...
                </li>
            );
        };

        for (let i = 1; i <= end; i++) {

            switch (true) {
                // Start + padding
                case i <= (start + padding_start_end):
                    // console.log(i + " Start + padding");
                    pagination.push(renderItem(i));
                    break;
                // Current + padding
                case i >= (current - padding_current) && i <= (current + padding_current):
                    // console.log(i + " Current + padding");
                    pagination.push(renderItem(i));
                    break;
                // End + padding
                case i >= (end - padding_start_end):
                    // console.log(i + " End + padding");
                    pagination.push(renderItem(i));
                    break;

                // Dots
                default:
                    // console.log(i + " Dots" + (previous_was_dot ? 'Y' : 'N'));
                    if (false === previous_was_dot) {
                        pagination.push(renderDots(i));
                    }
                    break;
            }
        }

        return pagination;
    }

    render() {
        if (this.props.pagination.page.total <= 1) {
            return <></>;
        }

        return (
            <div className="pagination">
                <div>
                    <Button
                        type="button"
                        onClick={() =>
                            this.onClick(this.props.pagination.page.previous)
                        }
                        className={
                            !this.props.pagination.page.previous ? "hidden" : ""
                        }
                    />
                    <ul>{this.createPagination()}</ul>
                    <Button
                        type="button"
                        onClick={() =>
                            this.onClick(this.props.pagination.page.next)
                        }
                        className={
                            !this.props.pagination.page.next ? "hidden" : ""
                        }
                    />
                </div>
            </div>
        );
    }
}
