import React from 'react';
import PropTypes from "prop-types";
import ApiNews from '../../Services/ApiNews';
import Block from "./Block";
import PreviewNews from "./PreviewNews";
import Carousel from "../Carousel/Carousel";
import Loader from "../Loader/Loader";
import {path} from "../../Config/routes";
import {Link} from "react-router-dom";


export default class CarouselNews extends React.Component {

    static propTypes = {
        onLoaded: PropTypes.func
    };

    static defaultProps = {
        onLoaded: () => {}
    };

    state = {
        news: [],
        loading: true
    };

    constructor(props) {
        super(props);
        this._isMounted = false;
    }


    componentDidMount() {
        this._isMounted = true;

        ApiNews.getLastNews(['visual']).then((news) => {
            if (!this._isMounted) return;

            this.setState({
                news: news,
                loading: false
            });

            this.props.onLoaded(true);
        }).catch((err) => {
            if (!this._isMounted) return;
            if (err.status && err.status === 500) {
                this.props.onLoaded(false);
            }
        });
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    render() {
        let listNews = '';
        if (this.state.news.length > 0) {
            listNews = <>
                <Carousel slidesToShow={1} showDots={false} infinite={false} center={true} showArrow={true} gapBetweenItem={70}>
                    {this.state.news.map((news, i) => (
                        <PreviewNews news={news} infoBubble={false} left={true} excerptLength={80} key={i}/>))}
                </Carousel>
                <div className="show-all-news"><Link to={path('news')} className={'underline'}>Voir toutes les actualités</Link></div>
            </>;
        } else {
            listNews = <div className={"noNews d-flex align-items-center justify-content-center"}>Aucune actualité à la une</div>
        }

        return (
            <Block title="actualités" color="blue" className='carouselNews block-news' titleColor={'#D0ED4D'} iconTitleColor={'white'} bigTitleColor={'black'}>
                {/* Button see all new */}
                {this.state.loading
                    ? <div className={'wrapLoader'}><Loader type={"inline"} backgroundColor={'transparent'} color={'white'}/></div>
                    : listNews
                }
            </Block>
        )
    }
}