import React from 'react';
import PropTypes from "prop-types";
import Rdate from "react-datetime";
import {DateHandler} from "../../Utils/DateHandler";

export default class DateRangePicker extends React.Component {

    static propTypes = {
        startDate: PropTypes.string,
        endDate: PropTypes.string,
        onChange: PropTypes.func
    };

    static defaultProps = {
        onChange: () => {
        }
    };

    state = {
        startDate: '',
        endDate: '',
        showPicker: true
    };

    constructor(props) {
        super(props);

        this.startMoment = null;
    }

    componentDidMount() {
        this.setState({
            startDate: this.props.startDate,
            endDate: this.props.endDate,
        })
    }

    toggleDatepicker() {
        this.setState({
            showPicker: !this.state.showPicker
        });
    }

    onChange(e, state) {
        let newState    = {};
        newState[state] = e._d;

        if (state === 'startDate') {
            this.startMoment = e;
            newState.endDate = this.state.endDate || ''

        } else {
            if (this.startMoment === null) {
                this.startMoment = e;
            }
        }

        this.setState(newState, () => {
            this.props.onChange({
                startDate: DateHandler.formatForApi(this.startMoment.format()),
                endDate: this.state.endDate ? DateHandler.formatForApi(new Date(this.state.endDate)) : ''
            });
        });
    }

    isSelectable(currentDate) {
        if (this.state.startDate === '') {
            return true;
        } else {
            return currentDate.isSameOrAfter(this.startMoment);
        }
    }

    render() {
        return (
            <>
                <Rdate
                    value={this.state.startDate}
                    open={this.state.showPicker}
                    input={true}
                    inputProps={{
                        placeholder: 'Début',
                        readOnly: true
                    }}
                    viewMode="days"
                    timeFormat={false}
                    onChange={(e) => this.onChange(e, 'startDate')}/>
                <Rdate
                    value={this.state.endDate}
                    open={this.state.showPicker}
                    input={true}
                    inputProps={{
                        placeholder: 'Fin',
                        readOnly: true
                    }}
                    viewMode="days"
                    timeFormat={false}
                    isValidDate={(currentDate) => this.isSelectable(currentDate)}
                    onChange={(e) => this.onChange(e, 'endDate')}/>
            </>
        )
    }
}