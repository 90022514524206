/**
 * SmoothScroll
 */
export default class SmoothScroll {
    /**
     * Constructor
     * @param el
     * @param config  {Object}
     * @param callback
     */
    constructor(el, config, callback) {
        let duration  = (config && config.duration) || 500;
        let context   = (config && config.context) || window;
        let threshold = (config && config.threshold) || 0;
        let start     = context.scrollTop || window.pageYOffset;
        let end       = (typeof el === 'number') ? parseInt(el) : SmoothScroll.getTop(el, start + threshold);
        let clock     = Date.now();

        let requestAnimationFrame =
                window.requestAnimationFrame ||
                window.mozRequestAnimationFrame ||
                window.webkitRequestAnimationFrame ||
                function (fn) {
                    window.setTimeout(fn, 15);
                };

        let step = function () {
            let elapsed = Date.now() - clock;
            if (context !== window) {
                context.scrollTop = SmoothScroll.position(start, end, elapsed, duration);
            } else {
                window.scroll(0, SmoothScroll.position(start, end, elapsed, duration));
            }

            if (elapsed > duration) {
                if (typeof callback === 'function') {
                    callback(el);
                }
            } else {
                requestAnimationFrame(step);
            }
        };
        step();
    }

    /**
     * Ease in out function
     * @param t
     * @return {number}
     */
    static easeInOutCubic(t) {
        return t < .5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1
    }

    /**
     * Position
     * @param start
     * @param end
     * @param elapsed
     * @param duration
     * @return {*}
     */
    static position(start, end, elapsed, duration) {
        if (elapsed > duration) return end;
        return start + (end - start) * SmoothScroll.easeInOutCubic(elapsed / duration); // <-- you can change the easing funtion there
        // return start + (end - start) * (elapsed / duration); // <-- this would give a linear scroll
    }

    /**
     * Get top
     * @param element
     * @param start
     * @return {*}
     */
    static getTop(element, start) {
        // return value of html.getBoundingClientRect().top ... IE : 0, other browsers : -pageYOffset
        if (element.nodeName === 'HTML') return -start;
        return element.getBoundingClientRect().top + start
    }

}


